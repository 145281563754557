<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
    <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
      <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
        <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
          <h3 class="font-bold text-gray-800 dark:text-white">
            Confirm: Generate for all Students
          </h3>
          <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold 
            rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 
            disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700" 
            :data-hs-overlay="`#hs-${props.modalName}`">
            <span class="sr-only">Close</span>
            <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M18 6 6 18"></path>
              <path d="m6 6 12 12"></path>
            </svg>
          </button>
        </div>
        <div class="p-4 overflow-y-auto">
          <p class="text-gray-800 dark:text-neutral-400">
            This is an expensive operation. Continue only if you are sure. Do you wish to proceed?
          </p>
        </div>
        <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
          <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium 
            rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
            disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 
            dark:text-white dark:hover:bg-neutral-800" @click="props.generateAllFn">
            Yes, Generate
          </button>
          <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold 
            rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 
            disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
            No, Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps([
  'modalName', 'generateAllFn'
])
</script>