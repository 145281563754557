<template>
    <div v-if="visible" class="tooltip cursor-default" :style="tooltipStyle">
      <slot></slot> <!-- Use slot for customizable content -->
    </div>
  </template>
  
  <script>
  export default {
    
    props: {
      visible: Boolean,
      position: Object
    },
    computed: {
      tooltipStyle() {
        return {
          position: 'absolute',
          top: `${this.position.y}px`,
          left: `${this.position.x}px`,
          backgroundColor: 'black',
          color: 'white',
          padding: '5px',
          borderRadius: '5px',
          // Additional styling as needed
        };
      }
    }
  };
  </script>
  
  <style scoped>
  /* Additional styles if needed */
  </style>
  