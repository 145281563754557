<template>

 <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto">
   <div class="bg-white p-5 rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-screen overflow-auto">
         
      <form @submit.prevent="createActivityObservation">
        <div class="form-group">
          <label for="title">Title</label>
          <input type="text" id="title" v-model="newActivityObservationData.title">
        </div>
  
        <div class="form-group">
          <label for="educator_name">Educator Name</label>
          <input type="text" id="educator_name" v-model="newActivityObservationData.educator_name" required>
        </div>
  
        <div class="form-group">
          <label for="date">Date</label>
          <input type="date" id="date" v-model="newActivityObservationData.date">
        </div>
  
        <div class="form-group">
          <label for="learner_group">Learner Group</label>
          <input type="text" id="learner_group" v-model="newActivityObservationData.learner_group" >
        </div>
  
        <div class="form-group">
          <label for="activity_time">Activity Time</label>
          <input type="time" id="activity_time" v-model="newActivityObservationData.activity_time" >
        </div>
  
        <div class="form-group">
          <label for="activity_presented_to">Activity Presented To</label>
          <input type="text" id="activity_presented_to" v-model="newActivityObservationData.activity_presented_to">
        </div>
  
        <div class="form-group">
          <label for="activity_summary">Activity Summary</label>
          <textarea id="activity_summary" v-model="newActivityObservationData.activity_summary" 
          class="w-full h-30 border border-gray-300 rounded p-4 text-base align-middle"
          ></textarea>
        </div>
  
        <div class="form-group">
          <label for="primary_competency">Primary Competency</label>
          <input type="text" id="primary_competency" v-model="newActivityObservationData.primary_competency" >
        </div>
  
        <div class="form-group">
          <label for="secondary_competency">Secondary Competency</label>
          <input type="text" id="secondary_competency" v-model="newActivityObservationData.secondary_competency" >
        </div>
  
        <div class="form-group">
          <label for="creativity_evidence">Creativity Evidence</label>
          <textarea id="creativity_evidence" v-model="newActivityObservationData.creativity_evidence" 
          class="w-full h-30 border border-gray-300 rounded p-4 text-base align-middle"
          ></textarea>
        </div>
  
        <div class="form-group">
          <label for="activity_rating">Activity Rating</label>
          <input type="text" id="activity_rating" v-model="newActivityObservationData.activity_rating">
        </div>
  
        <div class="form-group">
          <label for="additional_notes">Additional Notes</label>
          <textarea id="additional_notes" v-model="newActivityObservationData.additional_notes"></textarea>
        </div>
  
        <div class="form-actions">
          <button type="submit">Submit</button>
          <button type="button" @click="closeForm">Cancel</button>
        </div>
      </form>
    </div>
 </div> 
  </template>
  
  
  <script>
  export default {
    inject: ["$call"], // function from doppio library
    data() {
      return {
        newActivityObservationData: {
          title: '',
          educator_name: '',
          date: '',
          learner_group: '',
          activity_time: '',
          activity_presented_to: '',
          activity_summary: '',
          primary_competency: '',
          secondary_competency: '',
          creativity_evidence: '',
          activity_rating: '',
          additional_notes: ''
        }
      };
    },
    methods: {
      // Backend call to create a new document in the activity-observations doctype
      // Backend call to create a new document in the activity-observations doctype
  async createActivityObservation() {
    console.log('Attempting to create a new activity observation:', this.newActivityObservationData);
    try {
      await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.create_new_activity_observation', this.newActivityObservationData);
      console.log('Activity observation created successfully');
      this.$emit('create-success');
      // Reset the form after successful creation
      this.newActivityObservationData = {};
    } catch (error) {
      console.error('Error creating new activity observation:', error);
      this.$emit('create-error', error);
    }
  },
  closeForm() {
    console.log('Closing form');
    this.$emit('close');
    this.newActivityObservationData = {};
  }
},
    mounted() {
      // Any additional code needed when the component is mounted
    }
  };
  </script>
  
  <style>
  /* Your CSS code if needed */
  </style>
  