<template>
  <div v-if="props.observation" class="p-4 space-y-2">
    <h3 v-if="!isSavePreview && props.observation.educator" 
      class="font-bold text-xl ">
      {{ findValueByName(userDetails?.school_info.educators, props.observation.educator, 'educator_name') }}
    </h3>
    <h3 v-if="isSavePreview && props.observation.educator_name" 
      class="font-bold text-xl ">
      {{ props.observation.educator_name }}
    </h3>
    <!-- <div v-if="!isSavePreview && props.observation.educator_name" class="space-y-2">
      <h4 class="font-semibold">
        Educator:
      </h4>
      <p class="text-gray-500">
        {{ findValueByName(userDetails?.school_info.educators, props.observation.educator_name, 'educator_name') }}
      </p>
    </div>
    <div v-if="isSavePreview && props.observation.educator_id" class="space-y-2">
      <h4 class="font-semibold">
        Educator:
      </h4>
      <p class="text-gray-500">
        {{ props.observation.educator_name }}
      </p>
    </div> -->
    <div v-if="props.observation.about" class="space-y-2">
      <h4 class="font-semibold">
        <span v-if="props.observation.about !== 'Week'">Who </span>
        <span v-if="props.observation.about === 'Week'">What </span>
        is this observation about:
      </h4>
      <p class="text-gray-500">
        {{ props.observation.about }}
      </p>
      <div v-if="!isSavePreview && props.observation.about === 'Students'" class="flex flex-wrap gap-2">
        <div v-for="student in props.observation.students"   
          class="bg-[#FDD835] px-2 rounded">
          {{ student.student_name }}
        </div>
      </div>
      <div v-if="isSavePreview && props.observation.about === 'Students'" class="flex flex-wrap gap-2">
        <div v-for="student in props.observation.students"   
          class="bg-[#FDD835] px-2 rounded">
          {{ findValueByName(userDetails?.school_info.students, student, 'student_name') }}
        </div>
      </div>
      <div v-if="props.observation.about === 'Cohort'" class="flex flex-wrap gap-2">
        <div class="bg-[#FDD835] px-2 rounded">
          {{ findValueByName(userDetails?.school_info.cohorts, props.observation.cohort, 'cohort_name') }}
        </div>
      </div>
    </div>
    <div v-if="props.observation.date" class="space-y-2">
      <h4 class="font-semibold">
        Date:
      </h4>
      <p class="text-gray-500">
        {{ observation.date }}
      </p>
    </div>
    <div v-if="props.observation.feedback" class="space-y-2">
      <h4 class="font-semibold">
        Observation:
      </h4>
      <div class="text-gray-500">
        {{ props.observation.feedback }}
      </div>
    </div>
    <div v-if="props.observation.feedback_text" class="space-y-2">
      <h4 class="font-semibold">
        Observation:
      </h4>
      <div class="text-gray-500">
        {{ props.observation.feedback_text }}
      </div>
    </div>
    <!-- <div v-if="!isSavePreview && props.observation.competencies" class="space-y-2">
      <h4 class="font-semibold">
        Competencies anecdote is linked to:
      </h4>
      <div class="flex flex-wrap gap-2">
        <div v-for="competency in props.observation.competencies"   
          class="bg-[#FDD835] px-2 rounded">
          {{ competency.competency_name }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { findValueByName } from '@/lib/utils'
import { inject } from 'vue';

const props = defineProps(['observation', 'isSavePreview'])

const userDetails = inject('userDetails')
</script>