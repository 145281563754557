<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
    props.pageNum !== 2 && `hidden`)">
    <div class="space-y-4" @keydown.stop>
      <label class="font-medium text-sm md:text-base">
        1. Type out the feedback
      </label>
      <textarea id="hs-autoheight-textarea-1" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" rows="10"
        placeholder="Enter feedback here..." name="feedback_text" v-model="feedbackText" ref="feedbackInputRef"
        @input="textareaAutoHeight"></textarea>
    </div>
    <div class="space-y-4">
      <p class="font-medium text-sm md:text-base">
        or record audio and get transcript
      </p>
      <div class="flex gap-4">
        <button type="button" :class="cn(`py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold 
          rounded-lg border border-gray-300 text-gray-500 hover:border-blue-600 hover:text-blue-600 
          disabled:opacity-50 disabled:pointer-events-none dark:border-gray-700 dark:text-gray-400 
          dark:hover:text-blue-500 dark:hover:border-blue-600 dark:focus:outline-none dark:focus:ring-1 
          dark:focus:ring-gray-600`, is_recording && 'bg-red-400 text-white')" @click="toggleMic">
          <span v-if="!is_recording || is_paused">Record</span>
          <span v-if="is_recording && !is_paused">Pause</span>
        </button>
        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold 
          rounded-lg border border-transparent bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 
          disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          :disabled="!is_recording" @click="stopRecording">
          Stop
        </button>
        <p :class="cn(`font-semibold text-gray-400 text-sm`, !is_paused && 'hidden')">
          Recording Paused
        </p>
      </div>
      <audio id="unstruct-audio-player" :src="audioURL" controls :class="cn(!audioURL && 'hidden')"></audio>
      <button type="button" :class="cn(`py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold 
        rounded-lg border border-transparent bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 
        disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`,
        !audioURL && 'hidden')" @click="removeAudio">
        Remove
      </button>
      <button type="button" :class="cn(`py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold 
        rounded-lg border border-gray-300 text-gray-500 hover:border-blue-600 hover:text-blue-600 
        disabled:opacity-50 disabled:pointer-events-none dark:border-gray-700 dark:text-gray-400 
        dark:hover:text-blue-500 dark:hover:border-blue-600 dark:focus:outline-none dark:focus:ring-1 
        dark:focus:ring-gray-600`, !audioURL && 'hidden')" @click="getTranscript">
        Get Transcript
      </button>
    </div>
    <div class="text-sm font-semibold text-gray-600">{{ statusText }}</div>
    <input type="text" class="hidden" name="file_name" v-model="audioFileName">
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref } from 'vue';

const props = defineProps(['pageNum', 'userData', 'observation'])
const emit = defineEmits(['audioRecorded', 'audioDeleted', 'transcriptGenerated'])

const feedbackInputRef = ref(null)

const feedbackText = ref(props.observation?.feedback || '')
const statusText = ref('')
const audioURL = ref('')
const audioFileName = ref('')

const can_record = ref(false)
const is_recording = ref(false)
const is_paused = ref(false)

let recorder = null
let chunks = []

let blob = null

const createAudioUrl = () => {
  blob = new Blob(chunks, { type: "audio/wav" })
  console.log(blob)
  emit('audioRecorded', blob)
  chunks = []
  audioURL.value = window.URL.createObjectURL(blob)
}

function setupStream(stream) {
  console.log(stream)
  recorder = new MediaRecorder(stream)
  recorder.ondataavailable = (e) => {
    chunks.push(e.data)
  }

  recorder.onstop = (e) => {
    if (is_paused.value) {
      // console.log(`returning without saving the data as pause is ${is_paused.value}`)
      return
    }
    // console.log(`saving the data as pause is ${is_paused.value}`)
    createAudioUrl();
    stream.getAudioTracks()[0].stop()
  }

  can_record.value = true
  recorder.start()
}

const pauseRecording = () => {
  is_paused.value = true
  recorder.stop()
}

const resumeRecording = () => {
  is_paused.value = false
  recorder.start()
}

function setupAudio() {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then(setupStream)
      .catch(err => {
        console.error(err)
      })
  }
}

const startRecording = () => {
  audioURL.value = ''
  setupAudio()
  is_recording.value = true
  if (is_paused.value) {
    is_paused.value = false
  }
}

const toggleMic = () => {
  if (is_recording.value) {
    if (is_paused.value) 
      resumeRecording()
    else 
      pauseRecording()
  } else {
    startRecording()
  }
}

const stopRecording = () => {
  if (!is_recording.value) {
    return
  }
  if (is_paused.value) {
    is_paused.value = false
    createAudioUrl();
    is_recording.value = false
    return
  }
  recorder.stop()
  
  is_recording.value = false
}

const removeAudio = () => {
  blob = null
  audioURL.value = ''
  emit('audioDeleted')
}

const getTranscript = async () => {
  const startTime = new Date().getTime()
  const educatorID = props.userData.educator_id
  const fileName = `${educatorID}_${Date.now()}.wav`
  const audio_file = new File([blob], fileName, { type: "audio/wav" })

  statusText.value = "Generating Transcript..."

  const formData = new FormData()
  formData.append('audio_file', audio_file)
  try {
    const res = await fetch('/api/method/curriculum_planner.curriculum_planner.doctype.unstructured_feedback.api.get_transcription', {
      method: 'POST',
      headers: {
        'X-Frappe-Csrf-Token': window.csrf_token
      },
      body: formData
    })
    const res_data = await res.json()
    const endTime = new Date().getTime()
    const elapsedTime = endTime - startTime
    if (res_data.message.status === 'success') {
      feedbackText.value = res_data.message.transcript
      audioFileName.value = fileName
      statusText.value = `Transcript generated in ${elapsedTime} milliseconds.`
      emit('transcriptGenerated')
    } else if (res_data.message.status == 'fail') {
      alert("Transcript generation failed!")
    }
  } catch (error) {
    statusText.value = "Transcript generation failed! Please try again."
    console.log('Transcription generation failed')
    console.error(error)
  }
}

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  feedbackInputRef.value.style.height = 'auto'
  let calculatedHeight = feedbackInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight / 2.5)
    calculatedHeight = windowHeight / 2.5
  feedbackInputRef.value.style.height = `${calculatedHeight}px`
}
</script>