<template>
 
 <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "closeForm">
   <div class="bg-white rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97vh] overflow-auto">
     
    
  <!-- Cross button -->
  <div class="sticky top-2 mr-2 flex justify-end z-10">
       <button @click="closeForm" class="border-black">
         <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>
       </button>
  </div>
  <!-- Form container -->
  <div class="event-form relative px-4 text-xs md:text-sm">
    <form @submit.prevent="createEvent" class="flex flex-col gap-1.5">

                
  <div>
    <!-- Activity text input --> 
    <input v-model="newEventData.activity_title" id="activitiesSelect" type="text" class="text-2xl text- font-semibold leading-9 tracking-wide text-left text-black border:white" placeholder="Activity name">
      
    <!-- Input for Activity Description -->
    <div class="flex flex-row items-center justify-between mb-1.5">
        <label for="description" class="font-medium text- text-black mr-2">Description:</label>
        <div class="w-[15rem] md:w-[80%]">
          <textarea v-model="newEventData.activity_description" id="description" placeholder="Activity Description"
            class="w-[15rem] md:w-[100%] h-25 border border-gray-300 rounded text-sm md:text-sm align-middle"> 
          </textarea>
        </div>
    </div>

    <!-- Session Select -->
    <div class="flex flex-row items-center justify-between mb-1.5">
    <label for="sessionSelect" class="font-medium text- text-black mr-2">Session</label>
    
      <!-- Select -->
      <div class="relative border md:w-[80%] w-[15rem]">
          <select id="sessionSelect" ref="sessionSelect" data-hs-select='{
            "placeholder": "Select options...",
            "toggleTag": "<button type=\"button\"></button>",
            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
            "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
          }' class="hidden">
            <option v-for="session in sessionOptions" :key="session" :value="session">{{ session}}</option>
          </select>
            <div class="absolute top-1/2 end-3 -translate-y-1/2">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
            </div>           
      </div>
    </div> 
      <!-- End Select -->
    
      <!-- Input for Start Time -->
      <div class="flex flex-row items-center justify-between mb-1.5">
      <label for="start" class="font-medium text- text-black mr-2">Date:</label>
        <div class="border w-[15rem] md:w-[80%]">
              <input class="text-xs md:text-sm" step="any" v-model="newEventData.start" type="date" id="start">
        </div>
      </div>
      
      <!-- Competencies Select -->
      <div class="flex flex-row items-center justify-between mb-1.5">
        <label for="competenciesSelect" class="font-medium text- text-black mr-2">Competencies:</label> 
              
        <!-- Select -->
        <div class="relative border w-[14rem] md:w-[80%]">
        <select id="competenciesSelect" ref="competenciesSelect" multiple data-hs-select='{
          "hasSearch": true,
              "placeholder": "Select...",
              "toggleTag": "<button type=\"button\"></button>",
              "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-xs md:text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
              "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
              "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
              "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
          }' class="hidden">
          <option v-for="competency in competenciesOptions" :key="competency.name" :value="competency.name">{{ competency.competency_name }}</option>
        </select>
        <div class="absolute top-1/2 end-3 -translate-y-1/2">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
          </div>
        </div>
        <!-- End Select -->
      </div>

     
      <!-- Educators/Led by Select -->
      <div class="flex flex-row items-center justify-between mb-1.5">
        <label for="educatorsSelect" class="font-medium text- text-black mr-2">Led by:</label>
        <!-- Select -->
        <div class="relative w-[15rem] md:w-[80%]">
          <select id="educatorsSelect" ref="educatorsSelect" multiple data-hs-select='{
            "placeholder": "Select option...",
            "toggleTag": "<button type=\"button\"></button>",
            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
            "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
            "mode": "tags",
            "wrapperClasses": "relative ps-0.5 pe-9 min-h-[46px] flex items-center flex-wrap text-nowrap w-full border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
            "tagsItemTemplate": "<div class=\"flex flex-nowrap items-center relative z-10 bg-white border border-gray-200 rounded-full p-1 m-1 dark:bg-neutral-900 dark:border-neutral-700\"><div class=\"size-6 me-1\" data-icon></div><div class=\"whitespace-nowrap text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"inline-flex flex-shrink-0 justify-center items-center size-5 ms-2 rounded-full text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm dark:bg-neutral-700/50 dark:hover:bg-neutral-700 dark:text-neutral-400 cursor-pointer\" data-remove><svg class=\"flex-shrink-0 size-3\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M18 6 6 18\"/><path d=\"m6 6 12 12\"/></svg></div></div>",
            "tagsInputClasses": "py-3 px-2 rounded-lg order-1 text-sm outline-none dark:bg-neutral-900 dark:placeholder-neutral-500 dark:text-neutral-400",
            "optionTemplate": "<div class=\"flex items-center\"><div class=\"size-8 me-2\" data-icon></div><div><div class=\"text-sm font-semibold text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"text-xs text-gray-500 dark:text-neutral-500\" data-description></div></div><div class=\"ms-auto\"><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-4 text-blue-600\" xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" viewBox=\"0 0 16 16\"><path d=\"M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z\"/></svg></span></div></div>",
            "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
          }' class="hidden">
          <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.name">{{ educator.educator_name }}</option>
        </select>
          <div class="absolute top-1/2 end-3 -translate-y-1/2">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
          </div>
        </div>
      </div>  
      <!-- End Select -->
   
    <!-- Location Select -->
    <div class="flex flex-row items-center justify-between mb-1.5">
        <label for="locationSelect" class="font-medium text- text-black mr-2">Room:</label>
                
      <!-- Select -->
      <div class="relative w-[15rem] md:w-[80%]">
          <select id="locationSelect" ref="locationSelect" data-hs-select='{
            "hasSearch": true,
                  "placeholder": "Select...",
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                }' class="hidden">
            <option v-for="location in locationOptions" :key="location.name" :value="location.name">{{ location.location }}</option>
          </select>
            <div class="absolute top-1/2 end-3 -translate-y-1/2">
              <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
            </div>
      </div>

    </div>  
      <!-- End Select -->


    <!-- Cohorts Select -->
    <div class="flex flex-row items-center justify-between mb-1.5">
        <label for="cohortsSelect" class="font-medium text- text-black mr-2">Cohort:</label>
               
      <!-- Select -->
      <div class="relative w-[15rem] md:w-[80%]">
      
      <select v-model="selectedCohorts" id="cohortsSelect" ref="cohortsSelect" multiple data-hs-select='{        
              "placeholder": "Select option...",
              "toggleTag": "<button type=\"button\"></button>",
              "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
              "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
              "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
              "mode": "tags",
              "wrapperClasses": "relative ps-0.5 pe-9 min-h-[46px] flex items-center flex-wrap text-nowrap w-full border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
              "tagsItemTemplate": "<div class=\"flex flex-nowrap items-center relative z-10 bg-white border border-gray-200 rounded-full p-1 m-1 dark:bg-neutral-900 dark:border-neutral-700\"><div class=\"size-6 me-1\" data-icon></div><div class=\"whitespace-nowrap text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"inline-flex flex-shrink-0 justify-center items-center size-5 ms-2 rounded-full text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm dark:bg-neutral-700/50 dark:hover:bg-neutral-700 dark:text-neutral-400 cursor-pointer\" data-remove><svg class=\"flex-shrink-0 size-3\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M18 6 6 18\"/><path d=\"m6 6 12 12\"/></svg></div></div>",
              "tagsInputClasses": "py-3 px-2 rounded-lg order-1 text-sm outline-none dark:bg-neutral-900 dark:placeholder-neutral-500 dark:text-neutral-400",
              "optionTemplate": "<div class=\"flex items-center\"><div class=\"size-8 me-2\" data-icon></div><div><div class=\"text-sm font-semibold text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"text-xs text-gray-500 dark:text-neutral-500\" data-description></div></div><div class=\"ms-auto\"><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-4 text-blue-600\" xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" viewBox=\"0 0 16 16\"><path d=\"M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z\"/></svg></span></div></div>",
              "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
            }' class="hidden">
        <option v-for="cohort in cohortsOptions" :key="cohort.name" :value="cohort.name">{{ cohort.cohort_name }}</option>
      </select>
        <div class="absolute top-1/2 end-3 -translate-y-1/2">
          <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
        </div>
      </div>
     </div> 
      <!-- End Select -->  

    <!-- Input for Activity link -->
    <div class="flex flex-row items-center justify-between mb-1.5">
      <label for="activity_link" class="font-medium text- text-black mr-2">Activity Link:</label>
      <div class="border w-[15rem] md:w-[80%]">
        <input v-model="newEventData.activity_link" id="activity_link" type="text" placeholder="url"
         class="w-full border border-gray-300 rounded px-4 text-xs md:text-sm align-middle">
      </div>      
    </div>  

  
      <!-- Students/Learners Participating Select -->
      <div class="flex flex-row items-center justify-between mb-1.5">
        <label for="studentsSelect" class="font-medium text- text-black mr-2">Learners Participating:</label>        
        <!-- Select -->
        <div class="relative w-[15rem] md:w-[80%]">
        <select id="studentsSelect" ref="studentsSelect" multiple data-hs-select='{
          
                "placeholder": "Select option...",
                "toggleTag": "<button type=\"button\"></button>",
                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
                "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
                "mode": "tags",
                "wrapperClasses": "relative ps-0.5 pe-9 min-h-[46px] flex items-center flex-wrap text-nowrap w-full border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                "tagsItemTemplate": "<div class=\"flex flex-nowrap items-center relative z-10 bg-white border border-gray-200 rounded-full p-1 m-1 dark:bg-neutral-900 dark:border-neutral-700\"><div class=\"size-6 me-1\" data-icon></div><div class=\"whitespace-nowrap text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"inline-flex flex-shrink-0 justify-center items-center size-5 ms-2 rounded-full text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm dark:bg-neutral-700/50 dark:hover:bg-neutral-700 dark:text-neutral-400 cursor-pointer\" data-remove><svg class=\"flex-shrink-0 size-3\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M18 6 6 18\"/><path d=\"m6 6 12 12\"/></svg></div></div>",
                "tagsInputClasses": "py-3 px-2 rounded-lg order-1 text-sm outline-none dark:bg-neutral-900 dark:placeholder-neutral-500 dark:text-neutral-400",
                "optionTemplate": "<div class=\"flex items-center\"><div class=\"size-8 me-2\" data-icon></div><div><div class=\"text-sm font-semibold text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"text-xs text-gray-500 dark:text-neutral-500\" data-description></div></div><div class=\"ms-auto\"><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-4 text-blue-600\" xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" viewBox=\"0 0 16 16\"><path d=\"M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z\"/></svg></span></div></div>",
                "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
              }' class="hidden">
          <option v-for="student in studentsOptions" :key="student.name" :value="student.name">{{ student.student_name }}</option>
        </select>
          <div class="absolute top-1/2 end-3 -translate-y-1/2">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
          </div>
        </div>
       </div> 
      <!-- End Select -->


       <!-- Preline Accordian -->
       <div class="hs-accordion-group">
                <div class="hs-accordion" id="hs-basic-heading-three">
                  <button
                    type="button" 
                    class="hs-accordion-toggle hs-accordion-active: py-3 inline-flex items-center gap-x-1.5 w-full font-medium focus:outline-none focus:ring-2 focus:ring-transparent text-start rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active: dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:text-gray-400"
                    aria-controls="hs-basic-collapse-three">
                    Additional Details
                    <svg class="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <path d="M5 12h14" />
                      <path d="M12 5v14" />
                    </svg>
                    <svg class="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <path d="M5 12h14" />
                    </svg>
                    
                  </button>
                  <div id="hs-basic-collapse-three"
                    class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300 mb-2"
                    aria-labelledby="hs-basic-heading-three">
                    <p class="text-gray-800 dark:text-gray-200">
                     
                       <!-- Input for Visitors -->
                      <div class="flex flex-row items-center justify-between mb-2">
                          <label for="visitors" class="font-medium text- text-black mr-2">Visitors</label>
                          <div class="border w-[15rem] md:w-[80%]">
                            <input v-model="newEventData.visitors" id="visitors" type="text" placeholder="Visitors"
                            class="w-[15rem] md:w-[80%] border border-gray-300 rounded px-4 text-xs md:text-sm align-middle">
                        </div>
                        </div> 

                      <!-- Input for Additional Comments -->
                      <div class="flex flex-row items-center justify-between">
                        <label for="additional_comments" class="font-medium text-black ">Additional Notes:</label>
                        <div class="w-[15rem] md:w-[80%]">
                          <textarea v-model="newEventData.additional_comments" id="additional_comments" placeholder="Additional Comments"
                          class="w-[15rem] md:w-[100%] h-25 border border-gray-300 rounded text-xs md:text-sm align-middle"></textarea>
                        </div>
                      </div>
                     
                    </p>
                  </div>
                </div>
        </div>

  </div>
          <!-- Submit Button -->
          <div class="flex flex-row mb-5">
            <button @click="closeForm" class="bg-customNavbar py-2 md:py-2 px-2 w-1/2 md:w-1/4 text-black rounded-md cursor-pointer hover:bg-yellow-400 block ml-2 mr-2">Close</button>
            <button type="submit" class="py-2 md:py-2 px-2 w-1/2 md:w-1/4 bg-black text-white rounded-md cursor-pointer hover:bg-gray-800 block ml-2 mr-2">Create Event</button>
          </div>  
      </form>
    </div>  

    </div>
  </div>
</template>
  
  <script>

import { onMounted } from 'vue';
  
export default {
    // setup() {
    //   onMounted(() => {
    //     // console.log(" onMounted called in modal form");
    //     setTimeout(() => {
    //   window.HSStaticMethods.autoInit();
	  // console.log("Initialised Preline inside New Event Modal form after delay");
    // }, 100)
    //   });
    // },
    inject: ["$call"], //function from doppio library
    props: {
    optionsResponse: {
      type: Object,
      required: true,
    },
    emptyCellData: { // This is the prop to accept the full event data
        type: Object,
        required: false, 
      },

    allCohortsList: {
      type: Array,
      default: () => []
    },      
  },
    data() {
      return {
        newEventData: {
          activity_description: '',
          additional_comments: '',
          visitors: '',
          start: this.emptyCellData.start,
          end: ''
        },
        competenciesOptions: [],
        selectedCompetencies: [],
        
        selectedCohorts:[],

        activitiesOptions: [],
        cohortsOptions: [],
        educatorsOptions: [],
        studentsOptions: [],
        locationOptions: {},
        sessionOptions:{}
        
      };
    },
    
    methods: {
      preselectMultiSelectFieldsOfEmptyCells() { 
            
        console.log ("Emptycell Data recieved in New events form",this.emptyCellData)
        
        if(this.emptyCellData && Object.keys(this.emptyCellData).length > 0){
          const preselectedCohorts = [];
          this.selectedSession = this.emptyCellData.session
          
              if (this.emptyCellData.cohort == "All"){
              console.log("This is the all cohortlist in New-Event-Form **************************",this.allCohortsList)
              this.allCohortsList.forEach(element => {
                preselectedCohorts.push(element.name)
                this.selectedCohorts = preselectedCohorts
              });
            } 
              
            else {
              preselectedCohorts.push(this.emptyCellData.cohort.name)
              this.selectedCohorts = preselectedCohorts
              }
              console.log("This is preselected cohort array",preselectedCohorts) 
              console.log("this is preselected cohort",this.selectedCohorts)
            }  
    },
      //Backend call to create a new document/ event in the curriculum-event doctype
      async createEvent() {

      // const selectedActivities = this.getSelectedValues('activitiesSelect');
      const selectedCompetencies = this.getSelectedValues('competenciesSelect');
      const selectedCohorts = this.getSelectedValues('cohortsSelect');
      const selectedEducators = this.getSelectedValues('educatorsSelect');
      const selectedStudents = this.getSelectedValues('studentsSelect');
      const selectedLocation = this.getSelectedValues('locationSelect');
      const selectedSession = this.getSelectedValues('sessionSelect');

     
      const eventData = {
        // ...this.newEventData,   // this line seems unnecessary 
        title: this.newEventData.activity_title, // Assuming 'title' is a data property in your Vue component
        activity_name:this.newEventData.activity_title,
        custom_competency_list: selectedCompetencies,
        activity_description: this.newEventData.activity_description,
        
        event_location: selectedLocation,
        session:selectedSession,
        cohort_list: selectedCohorts,
        led_by_list: selectedEducators,
        additional_comments: this.newEventData.additional_comments, // Assuming 'additionalComments' is a data property
        visitors: this.newEventData.visitors, // Assuming 'visitors' is a data property
        start: this.newEventData.start,
        end: this.newEventData.end,
        students_participating_list: selectedStudents,
        activity_link: this.newEventData.activity_link
      };
      // Handle the selected values as needed
        try {
          console.log("This is data before creating a new event ###################",eventData)
          const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.create_new_event', eventData);
         
          this.$emit('create-success', response.document_name); // Emit the event_name as part of the success event
           console.log("This is the data fetched in new event form for options list")
          // Reset the form after successful creation
          this.newEventData = {};
          
        } 
        
        catch (error) {
          console.error('Error creating new event:', error);
          this.$emit('create-error', error);
        }
      },
      closeForm() {
        this.$emit('close');
        this.newEventData = {};
      },
      async assignOptionsData() {
      try {
        console.log("assign options data called in new Event form");
        console.log("This is the options list data before assigning values in new event form", this.optionsResponse);
    
          // Directly assign fetched data for all fields
          this.activities = this.optionsResponse.activities;
          this.locationOptions = this.optionsResponse.location;
          this.sessionOptions = this.optionsResponse.session
          this.competenciesOptions = this.optionsResponse.competencies;
          // this.cohortsOptions = this.optionsResponse.cohorts;
          // this.educatorsOptions = this.optionsResponse.educators;
          this.studentsOptions = this.optionsResponse.students;

          const allowedCohorts = ['Bombils','Batatas-1', 'Batatas-2', 'Bimblis', 'BigKids'];
          this.cohortsOptions = this.optionsResponse.cohorts
            .filter(cohort => allowedCohorts.includes(cohort.cohort_name))
            .sort((a, b) => allowedCohorts.indexOf(a.cohort_name) - allowedCohorts.indexOf(b.cohort_name));


          const allowedNames = ['Hetvi','Kshama','Neha', 'Nikita','Priyanka','Anjali', 'Ruchika', 'Sai'];
          this.educatorsOptions = this.optionsResponse.educators
            .filter(educator => allowedNames.includes(educator.educator_name))
            .sort((a, b) => allowedNames.indexOf(a.educator_name) - allowedNames.indexOf(b.educator_name));

          setTimeout(() => {
            window.HSStaticMethods.autoInit(); //Preline is initialised
          console.log("Preline Initialise inside New-Events form after 100ms delay");
          }, 100)
        
      } 
      catch (error) {
        console.error('Error assigning data:', error);
      }
    },
  getSelectedValues(ref) {
    
  const selectElement = this.$refs[ref];
  if (selectElement.multiple) {
    // Handle multi-select
    const selectedOptions = selectElement.selectedOptions;
    return Array.from(selectedOptions).map(option => option.value);
  } else {
    // Handle single-select
    console.log ("Returning value for #####",selectElement)
    return selectElement.value;
  }
},

    },mounted() {
    console.log('OptionsResponse prop in new event form:', this.optionsResponse);
    this.assignOptionsData()
    this.preselectMultiSelectFieldsOfEmptyCells();
     
    },
  };
  </script>
  

<style scoped>
.new-event-form form input {
  border: 0px solid #ddd;
  border-radius: 4px;
  max-width: 100%;
}
/* .new-event-form form input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  max-width: 100%;
  margin-left: 0.4em;
} */


</style>