<template>
  <Card @click="viewObservation" class="transition-all cursor-pointer hover:brightness-95 rounded">
    <template #title>
      <!-- {{ findValueByName(userDetails?.school_info.students, props.observation.child_link, 'student_name') }} -->
      {{ props.observation?.children?.map((child) => child.child_name).join(', ') }}
      <span v-if="props.observation.is_archived" class="text-sm text-gray-600"> (archived)</span>
    </template>
    <div class="flex flex-col gap-2">
      <div class="flex justify-between">
        <div class="text-sm text-gray-600 flex gap-1 items-center">
          <span class="font-medium">{{ props.observation.date }}</span>
          <!-- <span class="text-lg">|</span> -->
          <!-- <span class="font-medium">
            {{ findValueByName(userDetails?.school_info.educators, props.observation.educator_name, 'educator_name') }}
          </span> -->
        </div>
        <div v-if="props.observation.is_imported" class="text-xs text-gray-600">
          imported
        </div>
      </div>
      <div class="flex gap-2 flex-wrap">
        <div v-for="competency in props.observation?.competencies?.slice(0, 3)"   
          class="bg-[#FDD835] px-2 rounded flex items-center justify-center py-0">
          {{ competency.competency_name }}
        </div>
        <div v-if="props.observation?.competencies.length > 3" class="text-[#FDD835] font-bold text-3xl h-8">
          ...
        </div>
      </div>
      <p class="text-ellipsis line-clamp-2 font-light text-gray-700 mt-2">
        {{ props.observation.observation }}
      </p>
    </div>
  </Card>
</template>

<script setup>
import Card from '@/components/ui/Card.vue'
import { findValueByName } from '@/lib/utils'
import { HSOverlay } from 'preline/preline';
import { inject } from 'vue';

const props = defineProps(['observation', 'selectObservation', 'version'])

const userDetails = inject('userDetails')

const viewObservation = () => {
  props.selectObservation(props.observation, props.version)
  HSOverlay.open('#hs-observation-edit-modal-all')
}
</script>