<template>
  <div :class="cn('p-4 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
    props.pageNum !== props.componentPageNum && 'hidden')" >
    <div class="space-y-4">
      <label class="text-xs font-semibold">
        Paste your text below and tap on the Generate button to automatically create your Activity using Zippie AI.
      </label>
      <textarea id="hs-autoheight-textarea-daily-interests" class="py-3 px-4 block w-full border-gray-200 rounded-lg md:text-sm
        text-xs
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        rows="20" placeholder="Paste Activity Here..." name="raw_activity_text" v-model="activityText" ref="activityPasteRef"
        @input="textareaAutoHeight"></textarea>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref } from 'vue';

const props = defineProps(['componentPageNum', 'pageNum'])

const activityText = ref('')
const activityPasteRef = ref(null)

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  activityPasteRef.value.style.height = 'auto'
  let calculatedHeight = activityPasteRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  activityPasteRef.value.style.height = `${calculatedHeight}px`
}
</script>