<template>
  <div class="px-4 flex items-center gap-2">
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5937 2.49766H11.0579V1.38141C11.0579 1.17558 10.8758 0.977665 10.662 0.985581C10.4483 
        0.993498 10.2662 1.15975 10.2662 1.38141V2.49766H4.72453V1.38141C4.72453 1.17558 4.54245 0.977665 
        4.3287 0.985581C4.11495 0.993498 3.93286 1.15975 3.93286 1.38141V2.49766H2.40495C1.88004 2.49766 
        1.37663 2.70618 1.00547 3.07735C0.6343 3.44851 0.425781 3.95192 0.425781 4.47683V13.1852C0.425781 
        13.7101 0.6343 14.2135 1.00547 14.5846C1.37663 14.9558 1.88004 15.1643 2.40495 15.1643H12.5937C13.1186 
        15.1643 13.622 14.9558 13.9932 14.5846C14.3643 14.2135 14.5729 13.7101 14.5729 13.1852V4.47683C14.5729 
        3.95192 14.3643 3.44851 13.9932 3.07735C13.622 2.70618 13.1186 2.49766 12.5937 2.49766ZM13.7812 
        13.1852C13.7812 13.8422 13.2508 14.3727 12.5937 14.3727H2.40495C1.74786 14.3727 1.21745 13.8422 
        1.21745 13.1852V6.51933H13.7812V13.1852ZM13.7812 5.72766H1.21745V4.47683C1.21745 3.81975 1.74786 
        3.28933 2.40495 3.28933H3.93286V3.75641C3.93286 3.96225 4.11495 4.16016 4.3287 4.15225C4.54245 4.14433 
        4.72453 3.97808 4.72453 3.75641V3.28933H10.2662V3.75641C10.2662 3.96225 10.4483 4.16016 10.662 
        4.15225C10.8758 4.14433 11.0579 3.97808 11.0579 3.75641V3.28933H12.5937C13.2508 3.28933 13.7812 
        3.81975 13.7812 4.47683V5.72766Z" fill="#6B7280"/>
    </svg>

    <span class="font-normal text-sm md:text-base text-gray-500">
      For 
      {{ formattedDate }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { weekdayFormatDate2 } from '@/lib/utils.js'

const props = defineProps(['date'])

// Function to format the date in "Mon, Mar 11th" format
function formatDate(date) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const suffixes = ['st', 'nd', 'rd', 'th'];

    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayNum = date.getDate();
    
    // Determine the suffix for the day
    let suffix = suffixes[(day - 1) % 10] || suffixes[3];

    return `${day}, ${month} ${dayNum}${suffix}`;
}

const formattedDate = computed(() => {
  if (!props.date) {
    return weekdayFormatDate2(new Date());
  }
  return weekdayFormatDate2(new Date(props.date))
})
</script>