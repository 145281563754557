<template>
    <div class="flex flex-col gap-8">
      <div class="flex">
        <router-link class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          to="/front-portal/observations/activity-extract">
          Extract Student Mentions
        </router-link>
      </div>
      <div class="flex flex-wrap gap-2 justify-end">
        <div class="flex gap-2">
          <button type="button" @click="selectAllReports"
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
            :disabled="!selectedReports || isPublishing">
            Select All
          </button>
          <button type="button" @click="selectedReports = []"
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
            :disabled="!selectedReports || isPublishing">
            Unselect All
          </button>
        </div>
        <div class="flex gap-2">
          <button type="button" @click="publishSelected"
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
            :disabled="!selectedReports || isPublishing">
            Publish Selected
          </button>
          <button type="button" @click="unpublishSelected"
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
            :disabled="!selectedReports || isPublishing">
            Unpublish selected
          </button>
        </div>
      </div>
      <div class="font-medium text-gray-600">
        <span v-if="isPublishing">Processing request, please wait...</span>
        <span v-if="failedStudentsList.length">
          Failed publishing/unpublishing reports for: {{ failedStudentsList.join(', ') }}
        </span>
      </div>
        <div class="flex justify-evenly w-full flex-col md:flex-row flex-wrap gap-4 md:gap-0">
      <div v-for="cohort in props.cohortStudents" class="flex flex-col gap-4">
        <h3 class="font-semibold text-lg text-gray-800">
          {{ cohort.cohort_name }}
        </h3>
        <div class="flex flex-col gap-2">
            <div v-for="student in cohort.students" class="border rounded-md p-4 flex flex-col gap-1">

              <div class="flex gap-4">
                <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
                  focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 
                  dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 
                  dark:focus:ring-offset-gray-800" :id="`reportCheckbox-${student.name}`"
                  :value="studentFinalReports[student.name]?.name" v-model="selectedReports"
                  :disabled="!studentFinalReports[student.name] || isPublishing">
                <label :for="`reportCheckbox-${student.name}`">
                  <h4 class="font-semibold">{{ student.student_name }}</h4>
                </label>
              </div>
              <div class="flex flex-col gap-1 text-sm">
                <div class="bg-[#FFFAE6] p-2 rounded flex flex-col gap-2">
                  <div class="flex gap-4">
                    <span class="text-gray-700">Final Report:</span>
                    <span class="text-gray-800 font-medium" v-if="!studentFinalReports[student.name]">Not Generated</span>
                    <span class="text-gray-800 font-medium" v-else-if="studentFinalReports[student.name].is_published">Published</span>
                    <span class="text-gray-800 font-medium" v-else>Not Published</span>
                  </div>
                  <router-link class="cursor-pointer text-sm text-gray-600"
                    :to="`/front-portal/observations/report/${student.name}`">
                    View Final Report
                  </router-link>
                </div>
                <router-link class="cursor-pointer text-sm text-gray-600"
                  :to="`/front-portal/observations/assessment/student/${student.name}`">
                  View Assessment Report
                </router-link>
                <div class="flex justify-between items-center gap-4 text-sm">
                  <router-link class="cursor-pointer"
                    :to="`/front-portal/observations/summary-individual/report?student=${student.name}`">
                    View Reports
                  </router-link>
                  <router-link class="cursor-pointer"
                    :to="`/front-portal/observations/summary-individual?student=${student.name}`">
                    Generate
                  </router-link>
                </div>
              </div>
            </div>
        </div>
      </div>
        </div>
    </div>
</template>

<script setup>
import { createResource } from 'frappe-ui';
import { computed, ref } from 'vue'

const props = defineProps(['cohortStudents'])

console.log(props.cohortStudents)

const loadReportsResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.individual_observation_reports.api.get_report_list_all_students',
  auto: true
})

const publishedReports = ref([])
const unpublishedReports = ref([])

const studentFinalReports = computed(() => {
  if (!loadReportsResource.data?.reports) return {}
  const reportsObj = {}
  Object.entries(loadReportsResource.data.reports).forEach(([studentId, report]) => {
    if (publishedReports.value.includes(report.name)) {
      reportsObj[studentId] = { ...report, is_published: true };
    } else if (unpublishedReports.value.includes(report.name)) {
      reportsObj[studentId] = { ...report, is_published: false };
    } else {
      reportsObj[studentId] = report;
    }
  })

  return reportsObj;
})

const selectedReports = ref([])
console.log({selectedReports})

console.log("LOAD REPORTS ALL", studentFinalReports)

const isPublishing = ref(false)
const publishFailList = ref([])

const publishReportResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.publish_or_unpublish_reports',
})

const publishSelected = async () => {
  isPublishing.value = true
  try {
    const reportArray =  selectedReports.value
    await publishReportResource.submit({
      report_ids: reportArray,
      publish_status: true,
    })
    if (publishReportResource.data.status === 'success') {
      publishedReports.value = [...new Set([...publishReportResource.data.success_list, ...publishedReports.value])]
      unpublishedReports.value = unpublishedReports.value.filter((unReport) => {
        return !publishReportResource.data.success_list.includes(unReport)
      })
      publishFailList.value = publishReportResource.data.fail_list
      console.log("Report published successfully")
    } else {
      console.log("Report was not published")
    }
  } catch (error) {
    console.error("Failed to make publish request")
    console.error(error)
  }
  isPublishing.value = false
}

const unpublishSelected = async () => {
  isPublishing.value = true
  try {
    const reportArray = selectedReports.value
    await publishReportResource.submit({
      report_ids: reportArray,
      publish_status: false,
    })
    if (publishReportResource.data.status === 'success') {
      unpublishedReports.value = [...new Set([...publishReportResource.data.success_list, ...unpublishedReports.value])]
      publishedReports.value = publishedReports.value.filter((pubReport) => {
        return !publishReportResource.data.success_list.includes(pubReport)
      })
      publishFailList.value = publishReportResource.data.fail_list
      console.log("Report unpublished successfully")
    } else {
      console.log("Report was not unpublished")
    }
  } catch (error) {
    console.error("Failed to make unpublish request")
    console.error(error)
  }
  isPublishing.value = false
}

const failedStudentsList = computed(() => {
  if (!publishFailList.value.length) return []
  const student_list = []
  Object.entries(studentFinalReports.value).forEach(([student, report]) => {
    if (publishFailList.value.includes(report.name))
      student_list.push(student)
  })

  return student_list
})

const selectAllReports = () => {
  const allReports = [];
  Object.entries(studentFinalReports.value).forEach(([studentId, report]) => {
    allReports.push(report.name)
  })

  selectedReports.value = allReports;
}
</script>