<template>
  <slot name="trigger" />

  <div id="hs-assess-confirm-modal" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] 
      overflow-x-hidden overflow-y-auto pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 
      opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 
        dark:border-gray-700 dark:shadow-slate-700/[.7]">
        <div class="flex justify-between items-start py-3 px-4 dark:border-gray-700 relative">
          <div class="flex flex-col gap-4 justify-center items-center flex-auto pt-12">
            <!-- <img :src="formConfirmIcon" alt="" class="h-10 w-10 md:h-16 md:w-16" /> -->
            <div class="h-32 w-32 rounded-full bg-[#FAEDB1] flex justify-center items-center">
              <div class="h-24 w-24 rounded-full bg-white flex justify-center items-center">
                <div class="text-4xl font-bold">{{ props.score.toFixed(0) }}%</div>
              </div>
            </div>
            <h3 class="font-bold text-gray-800 dark:text-white text:lg md:text-xl text-center">
              Nice, that's pretty good for a first attempt!
            </h3>
          </div>
          <button type="button" class="hs-dropup-toggle absolute top-4 right-4 flex justify-center items-center size-7 text-sm 
            font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 
            disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 
            dark:focus:ring-gray-600" :data-hs-overlay="`#hs-assess-confirm-modal`">
            <span class="sr-only">Close</span>
            <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M18 6 6 18"/><path d="m6 6 12 12"/>
              </svg>
          </button>
        </div>
      <div class="p-4 overflow-y-auto text-sm text-center px-4 md:px-16">
          <p class="mt-1 text-[#6B7280] dark:text-gray-400">
            Some text summarizing the topic in not more that 2 sentences.
          </p>
      </div>
      <div class="flex justify-center items-center gap-x-2 py-3 px-4 pb-12 dark:border-gray-700">
          <button type="button" class="hs-dropup-toggle py-2 px-3 inline-flex items-center gap-x-2 text-sm 
            font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
            disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 
            dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 
            dark:focus:ring-gray-600" :data-hs-overlay="`#hs-assess-confirm-modal`">
              I'm done
          </button>
          <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm 
            font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
            disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 
            dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 
            dark:focus:ring-gray-600" @click="() => { props.createAnotherFn() }" disabled>
              Next Quiz
          </button>
      </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import formConfirmIcon from '@/assets/save_confirm_icon.png'

const props = defineProps(['createAnotherFn', 'modalNum', 'score'])
</script>