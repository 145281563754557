<template>
  <div class="flex flex-col h-[94vh] md:h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <div class="flex h-full">
      <div class="flex-auto h-full flex flex-col">
        <div class="flex flex-col gap-4 lg:gap-8">
          <h2 class="text-3xl">Sandbox</h2>
        </div>
        <div class="relative p-8 pb-4 pr-0 flex flex-col flex-auto">
          <div
              class="flex-auto flex flex-col gap-8 overflow-y-auto pr-[24rem]">
            <div v-if="promptText || isGenerating" class="flex flex-col">
              <div class="self-end bg-gray-100 p-4 rounded-md">
                <span class="font-bold">Prompt:</span>
                <br />
                {{ promptText }}
              </div>
              <div class="flex flex-col gap-2">
                <span class="font-bold" v-if="imageUrl">Response:</span>
                <br />
                <!-- {{ respText }}
                <div v-if="isGenerating" class="animate-spin inline-block size-6 border-[3px] border-current
                  border-t-transparent text-blue-600 rounded-full h-4 w-4" role="status" aria-label="loading">
                  <span class="sr-only">Loading...</span>
                </div> -->
                <div v-if="imageUrl">
                  <div class="p-4 hover:bg-gray-200 w-fit rounded-md cursor-pointer space-y-4">
                    <img :src="imageUrl" alt="Generated Image." class="h-96">
                    <div class="flex justify-end">
                      <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm 
                        font-medium rounded-lg border border-gray-200 bg-white text-yellow-500 shadow-sm 
                        hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 
                        dark:border-neutral-700 dark:hover:bg-neutral-800" @click="downloadImage">
                        Download
                      </button>
                    </div>
                  </div>
                  <!-- <p>{{ imageUrl }}</p> -->
                </div>
              </div>
            </div>
          </div>
          <div>
            <p v-if="isGenerating" class="">Generating Image...
              <div v-if="isGenerating" class="animate-spin inline-block size-6 border-[3px] border-current
                border-t-transparent text-blue-600 rounded-full h-4 w-4" role="status" aria-label="loading">
                <span class="sr-only">Loading...</span>
              </div>
            </p>
            <p v-else-if="apiTime" class="">Image generated in {{ apiTime.toFixed(3) }} seconds.</p>
          </div>
          <div class="relative">
            <!-- <PlayInput :action="getCost" @prompt="setPromptText" ref="playInput" /> -->
            <PromptInput :action="generateImage" ref="promptInputRef" />
          </div>
        </div>
      </div>
      <!-- <SummaryOptions :changeDate="changeDate" :changeType="changeObservationType" 
        :fullPromptFn="getFullPrompt" :savePromptFn="savePrompt" :changePrompt="switchPrompt" /> -->
      <SandboxOptions :changeType="changeGenerationType" :changeResolution="changeImageResoultion" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { createResource } from 'frappe-ui'
import PromptInput from '@/components/planWeek/PromptInput.vue';
// import SummaryOptions from '../components/planWeek/summary/SummaryOptions.vue';
import { HSOverlay } from 'preline/preline';
import SandboxOptions from '../components/sandbox/SandboxOptions.vue';

const promptText = ref('')
const apiTime = ref(0)
const isGenerating = ref(false)
const generationType = ref('image')
const imageResolution = ref('square')
const imageUrl = ref('')

const promptInputRef = ref(null)

const generateImageResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.sandbox.api.generate_image',
})

const generateImage = async (prompt = "") => {
  imageUrl.value = ''
  promptText.value = prompt
  isGenerating.value = true
  apiTime.value = 0
  try {
    await generateImageResource.submit({
      prompt: prompt,
      resolution: imageResolution.value,
    })
    isGenerating.value = false
    if (generateImageResource.data?.status === 'success') {
      // respText.value = observationResource.data.completion
      imageUrl.value = generateImageResource.data.image_url
      apiTime.value = generateImageResource.data.duration.total
      console.log({generationDuration: generateImageResource.data.duration})
    }
    console.log(generateImageResource)
  } catch (error) {
    console.error("Failed generating summary", error)
    isGenerating.value = false
  }
}

// const modalPrompt = ref('')

// const savePrompt = async () => {
//   const prompt = promptInputRef.value.getPrompt()
//   modalPrompt.value = prompt
//   console.log({prompt})
//   HSOverlay.open('#hs-saveObservPromptModal')
// }

// const switchPrompt = (prompt) => {
//   promptInputRef.value.changePrompt(prompt)
// }

const changeGenerationType = (newType) => {
  generationType.value = newType
}

const changeImageResoultion = (newRes) => {
  imageResolution.value = newRes 
}

const downloadImage = async () => {
  if (!imageUrl.value) return
  const filename = 'generated-image.png';
  try {
    const response = await fetch(imageUrl.value);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('There was an error downloading the image:', error);
  }
}
</script>