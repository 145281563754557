<template>
    <!-- <div class=" bg-customNavbar min-h-screen flex"> -->
      <div class=" bg-customNavbar pt-0 md:pt-10 pb-20 md:pb-0 mb-8">
          <!-- <img src="@/assets/currie_login_logo.png" alt="Currie Login Logo" width="172.85"     height="49.12"> -->
          <img src="@/assets/zippie_brand_logo.png" alt="Currie Login Logo"
            class="absolute top-[5%] left-[5%] w-[122px] h-[52px] md:w-[179.00px] md:h-[76.00px]">
      
    <div class="min-h-screen flex flex-col justify-center items-center md:flex-row md:p-8">
      <!-- Left Section for Logo and Description -->
      <!-- <div class="w-full md:w-1/2 flex flex-col pl-5 md:pl-20 mb-6 md:mb-0">
     
      </div> -->
  
      <div class="w-full flex flex-col justify-center items-center mt-10 md:mt-0 md:max-w-[70%]">
        <!-- <div class="w-full max-w-lg bg-customFormBackground p-6 rounded-md mx-auto"> -->
        <!-- <div class="w-full max-w-lg md:p-10 p-4 rounded-md mx-8 md:mx-auto"> -->
          <!-- Description with partially colored text -->
          <div class="flex flex-col justify-center items-center h-full w-[100%] mb-20 ">
              <p class="text-black text-3xl md:text-5xl font-medium leading-tight md:leading-tight text-center md:text-left">
                Make learning playful and purposeful
                <!-- <br>
                <span class="md:text-white">playful</span>
                and purposeful -->
              </p>
          </div>
          <!-- <button style="color: #6B7280;"
            class="w-[70%] md:w-[35%] px-4 md:px-15 py-2 md:py-3 rounded-md bg-white mx-auto text-base md:text-lg"
            v-if="googleAuthUrl && iAmAnEducator" @click="redirectToGoogleOAuth(googleAuthUrl)">
            <img src="@/assets/google_login_logo.png" alt="upload_photos_logo" height="38" width="38" style="max-width: none; display: inline;">       
            Sign in with Google
          </button> -->
  
  
          <!-- Button for selecting Educator or Parent Login flow -->
          <!-- <div v-if="!iAmAnEducator && !iAmAParent" class="flex flex-col md:flex-row w-full gap-6 justify-center items-center">
            <button @click="iAmAnEducatorClicked()"
              class="w-[70%] md:w-[30%] px-4 py-3 md:py-5 rounded-md bg-black text-white text-base md:text-xl"
              type="submit">
              I'm an Educator 
            </button>
            <button @click="iAmAParentClicked()"
              class="w-[70%] md:w-[30%] px-4 py-3 md:py-5 rounded-md bg-white text-black text-base md:text-xl"
              type="submit">
              I'm a Parent 
            </button>
          </div> -->
  
      </div>  
          <form @submit.prevent="login" class="space-y-8 font-medium text-lg">
            <input type="text" v-model="email" placeholder="Email or Username"
              class="w-full px-4 md:px-15 py-2 md:py-3 rounded-md bg-white text-black mx-auto text-base md:text-lg text-center" /> 
  
            <!-- Password Input with Placeholder -->
            <input type="password" v-model="password" placeholder="Password"
              class="w-full px-4 md:px-15 py-2 md:py-3 rounded-md bg-white text-black mx-auto text-base md:text-lg text-center" />
  
            <!-- Button Container -->
            <!-- <div class="flex flex-col items-center space-y-6"> -->
  
              <button
                class="w-[50%] md:w-[50%] px-4 md:px-15 py-2 md:py-3 rounded-md bg-black text-white mx-auto text-base md:text-lg"
                type="submit">
                Sign in
              </button>
  
              
            <!-- </div> -->
            <!-- <div  style="margin-top: 1.4rem;" class="text-center">
              New user? <a href="" class="hover:text-grey-800 underline">Contact us</a>
            </div> -->
           </form>
  
           <!-- Login option for parents -->
           <!-- <div v-if="iAmAParent" class="w-[86%] md:w-[30%] p-4 pb-6 pt-6 flex flex-col items-center bg-customFormBackground rounded-md"> -->
              <!-- </div>  -->
              <!-- <PhoneAuth></PhoneAuth> 
              <div class="font-medium text-sm mt-8 mb-2 md:mb-3"> Or continue with </div>
              <button style="color: #6B7280;"
                  class="w-full md:max-w-[360px] px-4 md:px-15 py-2 rounded-md bg-white mx-auto text-base md:text-lg"
                  v-if="googleAuthUrl" @click="redirectToGoogleOAuth(googleAuthUrl)">
                  <img src="@/assets/google_login_logo.png" alt="upload_photos_logo" height="26" width="26" style="max-width: none; display: inline;" class="mr-2.5">       
                   Google
              </button> -->
           <!-- s</div> -->
    </div>
  </div>
  </template>
  <script>
  
  import PhoneAuth from '../components/PhoneAuth.vue';
  
  export default {
    inject: {
      $call: '$call', // Injecting the $call property
      $auth: '$auth', // Injecting the $auth property
      userDetails: {
        from: 'userDetails',
        default: () => ({}) // Provide a default value if userDetails is not provided
      }
    },
    components:{
      PhoneAuth
    },
    data() {
      return {
        email: null,
        password: null,
        googleAuthUrl: "", // URL for Google OAuth
        iAmAnEducator: false,
        iAmAParent: false,
      };
    },
    async mounted() {
      if (this.$route?.query?.route) {
        this.redirect_route = this.$route.query.route;
        this.$router.replace({ query: null });
      }
      
      // Call fetchOAuthUrls when the component is mounted (page loads)
      this.fetchOAuthUrls();
    },
    methods: {
      async login() {
        if (this.email && this.password) {
          let res = await this.$auth.login(this.email, this.password);
      
          if (res) {
  
            if(window.localStorage.getItem('redirect_after_login')){
              var urlToRedirect = window.localStorage.getItem('redirect_after_login')
              window.localStorage.removeItem("redirect_after_login");
              window.location.href = urlToRedirect;
              console.log('redirecting to url',urlToRedirect)
              return;
            }
  
            this.$router.push({ name: 'Home' }).then(() => {
            window.location.href = this.$router.resolve({ name: 'Home' }).href;
            console.log("Page reloaded after login")
          });
          }
        }
      },
      async fetchOAuthUrls() {
       
        try {
          const response = await this.$call(
            'curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_social_logins_with_redirect',
            { redirect_to: '/api/method/frappe.integrations.oauth2_logins.login_via_google' }
  
          );
          if (response) {
            this.googleAuthUrl = response;
            // console.log("OAuth URLs Response:", response);
          } else {
            console.error("Failed to get OAuth URLs");
          }
        } catch (error) {
          console.error("Error while fetching OAuth URLs:", error);
        }
      },
      redirectToGoogleOAuth(response) {
       
        const googleOAuthUrl = response[0].auth_url;
        // console.log("before click link", googleOAuthUrl)
        window.location.href = googleOAuthUrl;
      },
      iAmAnEducatorClicked(){
        this.iAmAnEducator = true;
      },
      iAmAParentClicked(){
        this.iAmAParent = true;
      }
     
    },
  };
  </script>
  
  <style>
  input::placeholder {
    color: rgba(102, 102, 102, 1);
    opacity: 1;
    /* Required for some browsers to show placeholder in the same color */
  }
  </style>