<template>
  <div class="flex justify-between">
    <button @click="goToPrevPage" :disabled="props.paginationDetails.page === 1">
      Previous
    </button>
    <p>Page 
      <span class="font-semibold">{{ props.paginationDetails.page }}</span>
       of 
       <span class="font-semibold">{{ props.paginationDetails.total_pages }}</span>
       .
    </p>
    <button @click="goToNextPage" :disabled="props.paginationDetails.page === props.paginationDetails.total_pages">
      Next
    </button>
  </div>
</template>

<script setup>
const props = defineProps(['paginationDetails'])

const emit = defineEmits(['navigate-to'])

const goToNextPage = () => {
  emit('navigate-to', props.paginationDetails.page + 1)
}

const goToPrevPage = () => {
  emit('navigate-to', props.paginationDetails.page - 1)
}
</script>