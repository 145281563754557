<template>
  <div :class="cn(`flex flex-col gap-4`, props.pageNum !== props.qNum && 'hidden')">
    <div class="flex flex-col gap-1">
      <p class="font-semibold" >Scenario {{ props.qNum }}:</p>
      <p>{{ props.questionData.scenario }}</p>
    </div>
    <div class="flex flex-col gap-1">
      <p class="font-semibold" >Question:</p>
      <p>{{ props.questionData.question }}</p>
    </div>
    <div class="flex flex-col gap-4" >
      <ScenarioOption v-for="option in props.questionData.options" :optionData="option" :key="option.name"
        :questionID="props.questionData.name" :formStatus="props.formStatus" />
      <div class="text-sm text-gray-600 font-semibold text-end">
        No. of attempts: {{ props.formStatus[`num-${props.questionData.name}`] }}
      </div>
    </div>
  </div>
  </template>
  
<script setup>
import { cn } from '../ui/utils';
import ScenarioOption from './ScenarioOption.vue';

const props = defineProps([
  'questionData', 'qNum', 'pageNum',
  'formStatus'
])
</script>