<template>
    <div class="calendar-widget-container"> <!-- Outer container -->
      <div class="flex justify-center items-center">
        <!-- Wrapper for the calendar to control size and styling -->
        <div class="w-[333px] h-[184px] rounded-lg class= unique-calendar-container">
         <!-- <FullCalendar :options="calendarOptions" /> -->
          <FullCalendar ref="fullCalendar" :options="calendarOptions" />
          <router-link to="/front-portal/calendar" class="text-center mt-2 block">
             <h3>Open Calendar</h3> <!-- Centered heading -->
          </router-link>
        </div>
         <!-- Dropdown for selecting views -->
         <div v-if="showDropdown" class="calendar-widget-dropdown">
          <button @click="changeView('dayGridDay')">Day</button>
          <button @click="changeView('dayGridWeek')">Week</button>
          <button @click="changeView('dayGridMonth')">Month</button>
        </div>
      </div>
    </div>
    </template>
    <script>
   
    import FullCalendar from '@fullcalendar/vue3';
    import timeGridPlugin from '@fullcalendar/timegrid'
    import dayGridPlugin from '@fullcalendar/daygrid';
    
    export default {
      inject: ["$call"], 
      components: {
        FullCalendar
      },
      mounted() {
        // Call the method right after the component has been mounted
        this.applyViewSpecificStyles(this.currentView);
      },
      data() {
          return {
            currentView: 'dayGridWeek', // Default view
            calendarOptions: {
                timeZone: 'UTC',
                customButtons: {
                viewSelect: {
                  text: 'Select View',
                  click: this.toggleDropdown
                }
              },
              plugins: [dayGridPlugin,timeGridPlugin],
              initialView: 'dayGridWeek',
              headerToolbar: {
                        left: 'title',
                        center: 'prev,next',
                        right: 'viewSelect', // user can switch between the two
                        
                       },
                       dayHeaderContent: (arg) =>{
                        return arg.date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' });
                      
                    },
              },
              
              showDropdown: false,
          };
      },
      watch: {
        currentView(newValue) {
          this.applyViewSpecificStyles(newValue);
        }
      },
      methods: {
        toggleDropdown() {
          this.showDropdown = !this.showDropdown;
        },
        
        changeView(viewName) {
          this.$refs.fullCalendar.getApi().changeView(viewName);
          this.showDropdown = false;
          console.log("current view before click",viewName)
          this.currentView = viewName; // Update the current view
        },
        applyViewSpecificStyles(viewName) {
          const container = document.querySelector('.calendar-widget-container');
          const viewHarness = document.querySelector('.fc-view-harness');
    
          if (!container) return;
    
          if (viewName === 'dayGridMonth') {
            container.style.height = '390px';
          } else {
            container.style.height = ''; // Reset to default when not in 'month' view
          }
          if (viewHarness) {
           
            if (viewName === 'dayGridMonth') {
            viewHarness.style.height = '360px'; // Height for month view
          } else {
            viewHarness.style.height = '100px'; // Default height for other views
          }
        }
        }
      },
      
      // Removed the data and methods related to fetching user data
    };
    </script>
    
    <style>
   .calendar-widget-container .fc-day {
    background: rgba(254, 243, 194, 1) !important;
    }
    .calendar-widget-container .fc-day-today {
      background: rgba(253, 216, 53, 1) !important;
    }
    .unique-calendar-container .fc-header-toolbar {
      background-color: white; /* Example background color */
      margin-bottom: 0em !important ;
      margin-top: 0.3em !important;
      /* other styles */
    }
    
    .unique-calendar-container .fc-button-group .fc-button {
      background-color: white; /* Example button color */
      
      font-size: 12px; /* Smaller font size */
      padding: 5px 10px; /* Smaller padding */
      height: auto; /* Adjust height as needed */
      border: none;
      color: black;
      /* Add any other styles you wish to customize */
    }
    .unique-calendar-container .fc-scrollgrid.fc-scrollgrid-liquid {
      padding-top: 20px; 
    }
    
    .unique-calendar-container .fc-viewSelect-button {
      background-color: white;
      color: black;
      border: none;
    }
    
    .unique-calendar-container .fc-toolbar-title {
      color: #1f2937; /* Example title color */
      
    }
    
    .unique-calendar-container .fc-button-primary:not(.fc-button-active):hover {
      background-color: #dbc93e; /* Darker shade for hover state */
    }
    .unique-calendar-container .fc-toolbar-title {
      font-size: 12px; /* Adjust font size as desired */
      font-weight: bold;
      /* Add other styling properties if needed */
    }
    .unique-calendar-container .fc-view-harness {
      height: 100px /* important was needed as we are overriding inline css here////////////////////*/
      /* 306px */
    }
    .unique-calendar-container .fc-daygrid-day, 
    .unique-calendar-container .fc-col-header-cell {
      border: none ; /* Remove borders */
    }
    </style>
    <style>
    /* ...existing styles... */
    
    .calendar-widget-dropdown {
      position: absolute;
      background-color: #f9f9f9;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      padding: 12px 16px;
      z-index: 1;
    }
    .calendar-widget-container {
      width: 318px;
      height: 186px;
      /* 390px */
      
      border-radius: 16px;
      border: 1px solid #ccc; /* Example border color */
      overflow: hidden; /* Ensures content does not overflow */
      
    }
    </style>
    