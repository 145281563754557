<template>
  <div class="w-full h-full">
    <div class="text-lg sm:text-xl md:text-3xl mt-5 md:mt-8 mb-4 mx-3 md:ml-12"> Curriculum Landscape - Activities</div>
     <!-- Preline accordian For Filters and Search bar in Mobile screens ****************************************************************** -->
     <div class=" hs-accordion-group md:hidden px-3">
      <div class="hs-accordion" id="hs-basic-with-arrow-heading-two">
        
         <div class="flex w-full justify-between"> 
            <!-- Go To Map button -->
            <button class=" self-center text-white truncate bg-black rounded text-center text-sm py-3 px-3" @click="navigateBackToMap">Go To Map</button>
            
            <!-- View filters buttons -->
            <button class="hs-accordion-toggle hs-accordion-active:text-black py-3 inline-flex items-center gap-x-3 w-auto font-medium text-start text-black hover:text-gray-500 focus:outline-none focus:text-black rounded-lg disabled:opacity-50 disabled:pointer-events-none" aria-expanded="false" aria-controls="hs-basic-with-arrow-collapse-two">
              
              <div class="hs-accordion-active:hidden block">View Filters</div>
              <div class="hs-accordion-active:block hidden">Close Filters</div>
              <svg class="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="m6 9 6 6 6-6"></path>
              </svg>
              <svg class="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="m18 15-6-6-6 6"></path>
              </svg>
            </button>   
         </div>   
       
         <div id="hs-basic-with-arrow-collapse-one" class="hs-accordion-content hidden w-full overflow-hidden hs-accordion-active:overflow-visible transition-[height] duration-300" role="region" aria-labelledby="hs-basic-with-arrow-heading-two">
          <p class="text-gray-800 dark:text-neutral-200">
            <!-- Body of the dropdown -->
            
            <!-- Filter and search inside accordian for Mobile screen -->
            <div class="flex flex-col w-full md:flex-row mt-4 md:mt-5 md:ml-12 mr-5 md:mr-0 md:mb-6 justify-center md:justify-start">
                          
                <!-- Filters container -->
                <div class="flex flex-wrap items-start w-full md:w-[100%] lg:w-[70%] mb-4 md:mb-0">
        
                  <!-- Domain Filter-->
                  <template v-if="domains && domains.length>0">
                    <div class="w-[32%] md:w-[18%] lg:w-[20%] self-start mt-2 md:mt-0 md:mx-6"> 
                      <div class="relative">
                        <select id="domainSelect" ref="domainSelect" data-hs-select='{
                        "placeholder": "Domain",
                        "hasSearch": true,
                        "toggleTag": "<button type=\"button\"></button>",
                        "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-5 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-[4px] text-start text-xs focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                        "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                        "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                      }' class="hidden">
                          <option value="">Select an age group</option>
                          <option v-for="domain in domains" :key="domain.domain_id" :value="domain.domain_id">
                                  {{ domain.domain_name}}
                          </option>
                        </select>
                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                          <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path d="m7 15 5 5 5-5" />
                            <path d="m7 9 5-5 5 5" />
                          </svg>
                        </div>
                      </div>
                      <!-- End Select -->
                    </div> 
                  </template>
        
                  <!-- Temporary Subdomain Filter placeholder-->
                  <template v-if="selectedDomain==null || subdomains.length===0">
                    <div class="w-[32%] md:w-[18%] lg:w-[20%] hs-tooltip [--trigger:click] [--placement:right] self-start ml-1.5 mt-2 md:mt-0 md:mx-6"> 
                          <div class="relative hs-tooltip-toggle">
                            <select id="" ref="" multiple data-hs-select='{
                            "placeholder": "Subdomain",
                            "toggleTag": "<button type=\"button\"></button>",
                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-5 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-[4px] text-start text-xs focus:border-blue-500 focus:ring-blue-500 focus:outline-none before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                            "drpdwnCls": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                          }' class="hidden">
                            </select>
                            <div class="absolute top-1/2 end-3 -translate-y-1/2">
                              <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5" />
                                <path d="m7 9 5-5 5 5" />
                              </svg>
                            </div>
                          </div>
                          <!-- End Select -->
                          <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
                            Please select Domain 
                          </span>
                    </div>
                  </template>
                  <!-- Subdomain Filter-->
                  <template v-if="subdomains && subdomains.length>0 && selectedDomain !=null && renderSubdomain">
                    <div class="w-[32%] md:w-[18%] lg:w-[20%] self-start ml-1.5 mt-2 md:mt-0 md:mx-6"> 
                      <div class="relative">
                        <select id="subdomainSelect" ref="subdomainSelect" data-hs-select='{
                        "placeholder": "Subdomain",
                        "hasSearch": true,
                        "toggleTag": "<button type=\"button\"></button>",
                        "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-5 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-[4px] text-start text-xs focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                        "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                        "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                      }' class="hidden">
                          <option value="">Select a sub domain</option>
                          <option v-for="subdomain in subdomains" :key="subdomain.subdomain_id" :value="subdomain.subdomain_id">
                                  {{ subdomain.subdomain_name}}
                          </option>
                        </select>
                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                          <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path d="m7 15 5 5 5-5" />
                            <path d="m7 9 5-5 5 5" />
                          </svg>
                        </div>
                      </div>
                      <!-- End Select -->
                    </div> 
                  </template>
        
        
                  <!-- Temporary Age group/ DevStage Filter placeholder-->
                  <template v-if="selectedSubdomain==null || devStages.length===0 ">
                    <div class="w-[32%] md:w-[18%] lg:w-[20%] rounded-[8px] hs-tooltip [--trigger:click] [--placement:right] self-start ml-1.5 mt-2 md:mt-0 md:mx-6"> 
                          <div class="relative hs-tooltip-toggle">
                            <select id="" ref="" multiple data-hs-select='{
                            "placeholder": "Age group",
                            "toggleTag": "<button type=\"button\"></button>",
                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-5 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-[4px] text-start text-xs focus:border-blue-500 focus:ring-blue-500 focus:outline-none before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                            "drpdwnCls": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                          }' class="hidden">
                            </select>
                            <div class="absolute top-1/2 end-3 -translate-y-1/2">
                              <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5" />
                                <path d="m7 9 5-5 5 5" />
                              </svg>
                            </div>
                          </div>
                          <!-- End Select -->
                          <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
                            Please select Subdomain 
                          </span>
                    </div>
                  </template>
                  <!-- Age group/ DevStage Filter-->
                  <template v-if="devStages && devStages.length>0 && selectedSubdomain !=null && renderAgeGroup">
                    <div class="w-[32%] md:w-[18%] lg:w-[20%] rounded-[8px] self-start ml-1.5 mt-2 md:mt-0 md:mx-6"> 
                      <div class="relative">
                        <select id="devStageSelect" ref="devStageSelect" data-hs-select='{
                        "placeholder": "Age group",
                        "hasSearch": true,
                        "toggleTag": "<button type=\"button\"></button>",
                        "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-5 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-[4px] text-start text-xs focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                        "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                        "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                      }' class="hidden">
                          <option value="">Select an age group</option>
                          <option v-for="ageGroup in devStages" :key="ageGroup.dev_stage_id" :value="ageGroup.dev_stage_id">
                                  {{ ageGroup.dev_stage_name}}
                          </option>
                        </select>
                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                          <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path d="m7 15 5 5 5-5" />
                            <path d="m7 9 5-5 5 5" />
                          </svg>
                        </div>
                      </div>
                      <!-- End Select -->
                    </div> 
                  </template>
        
        
                  <!-- Temporary Focus Area filter placeholder-->
                  <template v-if="selectedDevStage==null || focusAreas.length===0">
                    <div class="w-[32%] md:w-[18%] lg:w-[20%] rounded-[8px] hs-tooltip [--trigger:click] [--placement:right] self-start mt-2 md:mt-0 md:mx-0"> 
                          <div class="relative hs-tooltip-toggle">
                            <select id="" ref="" multiple data-hs-select='{
                            "placeholder": "Focus Area",
                            "toggleTag": "<button type=\"button\"></button>",
                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-5 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-[4px] text-start text-xs focus:border-blue-500 focus:ring-blue-500 focus:outline-none before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                            "drpdwnCls": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                          }' class="hidden">
                            </select>
                            <div class="absolute top-1/2 end-3 -translate-y-1/2">
                              <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5" />
                                <path d="m7 9 5-5 5 5" />
                              </svg>
                            </div>
                          </div>
                          <!-- End Select -->
                          <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
                            Please select Age group 
                          </span>
                    </div>
                  </template>
                  <!-- Focus Area Filter-->
                  <template v-if="focusAreas && focusAreas.length>0 && selectedDevStage !=null && renderFocusArea"> 
                    <div  class="w-[32%] md:w-[18%] lg:w-[20%] rounded-[8px] self-start mt-2 md:mt-0 md:mx-0"> 
                      <div class="relative">
                        <select id="focusAreaSelect" ref="focusAreaSelect" data-hs-select='{
                        "placeholder": "Focus Area",
                        "hasSearch": true,
                        "toggleTag": "<button type=\"button\"></button>",
                        "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-5 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-[4px] text-start text-xs focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                        "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                        "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                      }' class="hidden">
                          <option value="">Select a Focus Area</option>
                          <option v-for="focusArea in focusAreas" :key="focusArea.focus_area_id" :value="focusArea.focus_area_id">
                                  {{ focusArea.focus_area_name}}
                          </option>
                        </select>
                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                          <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path d="m7 15 5 5 5-5" />
                            <path d="m7 9 5-5 5 5" />
                          </svg>
                        </div>
                      </div>
                      <!-- End Select -->
                    </div> 
                  </template>
                  
                  <!-- Search -->
                  <div class="mt-3 ml-2 w-[62.5%]">
                    <input class="w-full border border-slate-300 rounded-full px-3 py-2 text-xs md:text-sm align-middle" type="text" placeholder="Search">
                  </div> 
                  
                </div>
        
            </div> 
          </p>
         </div>
      </div>
      </div>
      <!-- ********************************************************************************** -->
  
     
      <!-- Filters and Search bar For desktop screen -->
      <div class="hidden md:flex flex-col md:flex-row mt-4 md:mt-5 ml-5 md:ml-12 mr-5 md:mr-0 md:mb-6 justify-center md:justify-start">
            
          <!-- Search -->
          <div class="flex flex-row items-center mb-4 md:mb-0">
            <input class="w-full border border-slate-300 rounded-full px-4 py-3 text-xs md:text-sm align-middle" type="text" placeholder="Search">
          </div> 

          <!-- Filters container -->
          <div class="flex flex-wrap items-start w-full md:w-[100%] lg:w-[70%] mb-4 md:mb-0">

            <!-- Domain Filter-->
            <template v-if="domains && domains.length>0">
              <div class="border w-[30%] md:w-[18%] lg:w-[20%] rounded-[8px] self-start ml-1.5 mt-2 md:mt-0 md:mx-6" style="border-color: rgba(212, 215, 221, 1);"> 
                <div class="relative">
                  <select id="domainSelect_desktop" ref="domainSelect_desktop" data-hs-select='{
                  "placeholder": "Domain",
                  "hasSearch": true,
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                }' class="hidden">
                    <option value="">Select an age group</option>
                    <option v-for="domain in domains" :key="domain.domain_id" :value="domain.domain_id">
                            {{ domain.domain_name}}
                    </option>
                  </select>
                  <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
                <!-- End Select -->
              </div> 
            </template>

            <!-- Temporary Subdomain Filter placeholder-->
            <template v-if="selectedDomain==null || subdomains.length===0">
              <div class="border w-[30%] md:w-[18%] lg:w-[20%] rounded-[8px] hs-tooltip [--trigger:click] [--placement:right] self-start ml-1.5 mt-2 md:mt-0 md:mx-6" style="border-color: rgba(212, 215, 221, 1);"> 
                    <div class="relative hs-tooltip-toggle">
                      <select id="" ref="" multiple data-hs-select='{
                      "placeholder": "Subdomain",
                      "toggleTag": "<button type=\"button\"></button>",
                      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-none before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                      "drpdwnCls": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                      "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                    }' class="hidden">
                      </select>
                      <div class="absolute top-1/2 end-3 -translate-y-1/2">
                        <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round">
                          <path d="m7 15 5 5 5-5" />
                          <path d="m7 9 5-5 5 5" />
                        </svg>
                      </div>
                    </div>
                    <!-- End Select -->
                    <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
                      Please select Domain 
                    </span>
              </div>
            </template>
            <!-- Subdomain Filter-->
            <template v-if="subdomains && subdomains.length>0 && selectedDomain !=null && renderSubdomain">
              <div class="border w-[30%] md:w-[18%] lg:w-[20%] rounded-[8px] self-start ml-1.5 mt-2 md:mt-0 md:mx-6" style="border-color: rgba(212, 215, 221, 1);"> 
                <div class="relative">
                  <select id="subdomainSelectDesktop" ref="subdomainSelectDesktop" data-hs-select='{
                  "placeholder": "Subdomain",
                  "hasSearch": true,
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                }' class="hidden">
                    <option value="">Select a sub domain</option>
                    <option v-for="subdomain in subdomains" :key="subdomain.subdomain_id" :value="subdomain.subdomain_id">
                            {{ subdomain.subdomain_name}}
                    </option>
                  </select>
                  <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
                <!-- End Select -->
              </div> 
            </template>


            <!-- Temporary Age group/ DevStage Filter placeholder-->
            <template v-if="selectedSubdomain==null || devStages.length===0">
              <div class="border w-[30%] md:w-[18%] lg:w-[20%] rounded-[8px] hs-tooltip [--trigger:click] [--placement:right] self-start ml-1.5 mt-2 md:mt-0 md:mx-6" style="border-color: rgba(212, 215, 221, 1);"> 
                    <div class="relative hs-tooltip-toggle">
                      <select id="" ref="" multiple data-hs-select='{
                      "placeholder": "Age group",
                      "toggleTag": "<button type=\"button\"></button>",
                      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-none before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                      "drpdwnCls": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                      "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                    }' class="hidden">
                      </select>
                      <div class="absolute top-1/2 end-3 -translate-y-1/2">
                        <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round">
                          <path d="m7 15 5 5 5-5" />
                          <path d="m7 9 5-5 5 5" />
                        </svg>
                      </div>
                    </div>
                    <!-- End Select -->
                    <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
                      Please select Subdomain 
                    </span>
              </div>
            </template>
            <!-- Age group/ DevStage Filter-->
            <template v-if="devStages && devStages.length>0 && selectedSubdomain !=null && renderAgeGroup">
              <div class="border w-[30%] md:w-[18%] lg:w-[20%] rounded-[8px] self-start ml-1.5 mt-2 md:mt-0 md:mx-6" style="border-color: rgba(212, 215, 221, 1);"> 
                <div class="relative">
                  <select id="devStageSelectDesktop" ref="devStageSelectDesktop" data-hs-select='{
                  "placeholder": "Age group",
                  "hasSearch": true,
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                }' class="hidden">
                    <option value="">Select an age group</option>
                    <option v-for="ageGroup in devStages" :key="ageGroup.dev_stage_id" :value="ageGroup.dev_stage_id">
                            {{ ageGroup.dev_stage_name}}
                    </option>
                  </select>
                  <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
                <!-- End Select -->
              </div> 
            </template>


            <!-- Temporary Focus Area filter placeholder-->
            <template v-if="selectedDevStage==null || focusAreas.length===0">
              <div class="border w-[30%] md:w-[18%] lg:w-[20%] rounded-[8px] hs-tooltip [--trigger:click] [--placement:right] self-start ml-1.5 mt-2 md:mt-0 md:mx-0" style="border-color: rgba(212, 215, 221, 1);"> 
                    <div class="relative hs-tooltip-toggle">
                      <select id="" ref="" multiple data-hs-select='{
                      "placeholder": "Focus Area",
                      "toggleTag": "<button type=\"button\"></button>",
                      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-none before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                      "drpdwnCls": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                      "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                    }' class="hidden">
                      </select>
                      <div class="absolute top-1/2 end-3 -translate-y-1/2">
                        <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round">
                          <path d="m7 15 5 5 5-5" />
                          <path d="m7 9 5-5 5 5" />
                        </svg>
                      </div>
                    </div>
                    <!-- End Select -->
                    <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
                      Please select Age group 
                    </span>
              </div>
            </template>
            <!-- Focus Area Filter-->
            <template v-if="focusAreas && focusAreas.length>0 && selectedDevStage !=null && renderFocusArea"> 
              <div  class="border w-[30%] md:w-[18%] lg:w-[20%] rounded-[8px] self-start ml-1.5 mt-2 md:mt-0 md:mx-0" style="border-color: rgba(212, 215, 221, 1);"> 
                <div class="relative">
                  <select id="focusAreaSelectDesktop" ref="focusAreaSelectDesktop" data-hs-select='{
                  "placeholder": "Focus Area",
                  "hasSearch": true,
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                }' class="hidden">
                    <option value="">Select a Focus Area</option>
                    <option v-for="focusArea in focusAreas" :key="focusArea.focus_area_id" :value="focusArea.focus_area_id">
                            {{ focusArea.focus_area_name}}
                    </option>
                  </select>
                  <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
                <!-- End Select -->
              </div> 
            </template> 
            
          </div>

          <!-- Go To Map button -->
      <button class="hidden md:flex self-center text-white truncate bg-black rounded text-center text-sm py-3 px-3" @click="navigateBackToMap">Go To Map</button>
          

      </div> 

      <!-- Rendering Table -->                                                                                                                <!-- height was h-[64.8%] without dropdown -->                                                                                                                 
      <div v-if="allData" class="landscape-table relative flex flex-row md:flex-col mt-2 md:mt-6 ml-3 md:ml-12 md:max-w-[90%] border border-gray-200 rounded-md h-[58%] md:h-[72%] w-full overflow-auto">
          
          <!-- Table header -->
          <div class="sticky md:relative left-0 md:relative flex flex-col md:flex-row font-medium text-sm md:min-w-[150px] md:min-width-auto">
              <div v-for="header in headers" :key="header" class="flex flex-col px-2 py-2 lg:py-4 h-auto bg-[#FFFAE6] max-h-[70px] min-h-[65px] md:min-h-auto border border-gray-200 overflow-hidden text-wrap text-gray-500 lg:w-[180px]">
                
                <div v-if="header === 'Domain'">
                  Domain/
                  <br>
                  Competency
                </div>
                
                <div v-else>
                  {{ header }}
                </div>
                
              </div>
          </div>

          <!-- Table Body -->
                                                                                  <!--Conditional styling class checks for row to highligh when navigating from curriculum landscape Map page  -->
          <div v-for="(row, index) in allData" :key="index" class="flex flex-col md:flex-row text-sm min-w-[165px] md:min-width-auto" :class="{ 'bg-[#FEF3C2]': highlightLearningSequence && row.learning_sequence.learning_sequence_id == highlightLearningSequence }">
            <div v-for="header in headers" :key="header" class="flex flex-col px-2 py-3 md:pb-6 border border-gray-200 min-h-[65px] md:min-h-auto overflow-hidden text-wrap lg:w-[180px]">
              
              <!-- For rendering cambridge alignment stage number above the data -->
              <template v-if="header === 'Cambridge Alignment' && row.learning_sequence.cambridge_alignment_stage">
                <div class="cambridge-alignment">{{ row.learning_sequence.cambridge_alignment_stage }}:</div>
              </template>
              
              <!-- For rendering CC alignment grade number above the data -->
              <template v-if="header === 'CC Alignment' && row.learning_sequence.cc_grade_alignment ">
                <div class="cc-alignment">{{ row.learning_sequence.cc_grade_alignment }}:</div>
              </template>

              
              <!-- For Competnecy/subdomain in the same column as domain-->
              <template v-if="header === 'Domain' && row.subdomain.subdomain_name ">
                <div class="domain">{{  `${getFieldValue(row, header)}/` }}</div>
                <div class="competency">{{ row.subdomain.subdomain_name }}</div>
              </template>
              
              <template v-else>
                <!-- Render the data -->
                {{ getFieldValue(row, header) }}
              </template>

            </div>
          </div>
      </div>

      <!-- Pagination Controls -->
      <div class="pagination-controls flex justify-end md:justify-center my-2 mr-2">
          <button @click="prevPage" :disabled="pageNumber === 1" class="px-[15px] py-0 bg-[#FFD700] border rounded-full">
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.35156 1L1.35156 6L6.35156 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </button>
          
          <span v-if="totalCount" class="px-2 py-2">{{ pageNumber+'/'+ Math.ceil(totalSequenceCount/pageSize) }}</span> <!-- Divided by 4 becuase of pagination, that is only 4 docs are being fetched per page-->
          
          <button @click="nextPage" :disabled="!hasMoreData" class="px-[15px] py-0 bg-[#FFD700] border rounded-full">
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.07813 1L6.07812 6L1.07813 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </button>
      </div>
      
     
  </div>
</template>
  
  <script setup>
  import { ref, onMounted, inject, watch, computed, resolveDirective } from 'vue'
  import { useRoute, useRouter } from 'vue-router';
  
  const route = useRoute();
  const router = useRouter();

  const props = defineProps({
    domain: String,
    subdomain: String,
    devStage: String,
    focusArea: String,
    learningSequence: String
  })


 // Inject the $call method
 const $call = inject('$call');

// 
  const headers = ref([
    'Domain',
    'Development Stage',
    'Focus Area',
    'Sequence',
    'Cambridge Alignment',
    'CC Alignment',
    'Example',
    'Activities'
  ])
  
  const tableData = ref([])
  const allData = ref([])
  
  const domains = ref([])
  const selectedDomain = ref();

  const subdomains = ref([])
  const selectedSubdomain = ref()

  const devStages = ref([])
  const selectedDevStage = ref()

  const focusAreas = ref([])
  const selectedFocusArea = ref()

  // variable to force re render of the filters s
  const renderSubdomain = ref(true)
  const renderAgeGroup = ref(true)
  const renderFocusArea = ref(true)

  const learningSequences = ref([])
  
  const highlightLearningSequence = ref([])
  
  //For using as filter when navigating from Curriculum Map
  const selectedLearningSequence = ref();

// pagination variables
const pageNumber = ref(1);
const pageSize = ref(5);
const hasMoreData = ref(true);
const totalCount = ref(0)
const totalSequenceCount = ref(0)
  

  const fetchAllData = async() =>{
    try {
        console.log("fetch All data called")
        const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_sequences_with_details_for_display_with_filters',{
          domain:selectedDomain.value,
          subdomain: selectedSubdomain.value,
          dev_stage: selectedDevStage.value,
          focus_area:selectedFocusArea.value,
          learning_sequence:selectedLearningSequence.value,
          // learning_sequence: selectedLearningSequence.value !== undefined ? selectedLearningSequence.value : null,
          page_number: pageNumber.value,
          page_size:pageSize.value
        });
        console.log("This is fetched data response", response);
        // If response.status is success
        allData.value=response.data
        totalCount.value = response.total_count;
        totalSequenceCount.value = response.total_sequence_count;
        hasMoreData.value = response.data.length === pageSize.value;

    } catch (error) {
        console.error('Error fetching All the data:', error);
    }
    
  }

  const routedFromLandscapeMap = ()=>{
    console.log("routedFromLandscapeMap called")
    console.log("This is the prop data recieved in Table Page",props)
    selectedDomain.value= props.domain
    selectedSubdomain.value = props.subdomain
    selectedDevStage.value = props.devStage
    selectedFocusArea.value = props.focusArea
    selectedLearningSequence.value = props.learningSequence

    highlightLearningSequence.value = selectedLearningSequence.value
    fetchAllData();
  }

  const navigateBackToMap = () => {
    router.push({
      name: 'CurriculumLandscapeMap', // Adjust this to your actual route name for the Map
      query: {
        domain: selectedDomain.value,
        subdomain: selectedSubdomain.value,
        devStage: selectedDevStage.value,
        focusArea: selectedFocusArea.value,
        learningSequence: selectedLearningSequence.value
      }
    });
  };

  const getFieldValue = (row, header) => {
  switch(header) {
    case 'Domain': return row.domain.domain_name
    case 'Development Stage': return row.development_stage.development_stage
    case 'Focus Area': return row.focus_area.title
    case 'Sequence': return row.learning_sequence.learning_sequence_name
    case 'Cambridge Alignment':return row.learning_sequence.cambridge_stage_alignment_data
    case 'CC Alignment':return row.learning_sequence.cc_grade_alignment_data
    case 'Example':return row.learning_sequence.example
    default: return ''
  }
}

const nextPage = () => {
    pageNumber.value += 1;
   
    fetchAllData()
    };

    const prevPage = () => {
    if (pageNumber.value > 1) {
        pageNumber.value -= 1;
      fetchAllData()
    }
    };

  // Funtion to fetch development stages/age groups for filter options
  const fetchDomains = async() => {
    try {
        console.log("fetch domains called")
        const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_domains');
        // If response.status is success
        domains.value = response.data
        console.log("This is fetched domains data", response);
        setTimeout(() => {
            window.HSStaticMethods.autoInit();
            console.log("Preline initialised in curriculum landscape table.vue after fetching domains");
             // Initialize the select element for Domains
             const SelectElement = window.HSSelect.getInstance('#domainSelect');
             const SelectElementDesktop = window.HSSelect.getInstance('#domainSelect_desktop');
             
            if (SelectElement) {
              SelectElement.on('change', (value) => {
                console.log('Selected domain value changed:', value);
                selectedDomain.value = value
                // fetch the data again but with filter applied
                // fetch the option list data of child/next filter
                fetchSubdomains();
                fetchAllData();
            });
            } 
            if (SelectElementDesktop) {
              SelectElementDesktop.on('change', (value) => {
                console.log('Selected domain value changed:', value);
                selectedDomain.value = value
                // fetch the data again but with filter applied
                // fetch the option list data of child/next filter
                fetchSubdomains();
                fetchAllData();
            });
            } 
            else {
            console.log('AdvancedSelect Domain instance not found');
            }
        },100)
    } catch (error) {
        console.error('Error fetching age domains data:', error);
    }
  }

  const reinitializePrelineSelect = async(targetElement)=> {
      const selectEl = this.$refs.targetElement;
      if (selectEl) {
        const selectInstance = window.HSSelect.getInstance(selectEl);
        if (selectInstance && selectInstance.destroy) {
          selectInstance.destroy();
          console.log("HSSelect instance destroyed");
        }
      }
    }

   // Funtion to fetch development stages/age groups for filter options
   const fetchSubdomains = async() => {
    try {
        renderSubdomain.value=false
        console.log("fetch Subdomains called")
        const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_subdomains',{
        domain:selectedDomain.value
        });
        // If response.status is success
        console.log("This is fetched subdomains data", response);
        console.log("This is subdomain value before assigning",{...subdomains.value})
        // if subdomains.value is not already null/empty
        // destroy the selectElement
        
        subdomains.value = response.data
        console.log("This is subdomain value after assigning",{...subdomains.value})
        renderSubdomain.value=true
        
        setTimeout(() => {
            window.HSStaticMethods.autoInit();
            console.log("Preline initialised in curriculum landscape table.vue after fetching subdomains");
             // Initialize the select element for Subdomains
             const SelectElement = window.HSSelect.getInstance('#subdomainSelect');
             const SelectElementDesktop = window.HSSelect.getInstance('#subdomainSelectDesktop');
             
            if (SelectElement) {
                SelectElement.on('change', (value) => {
                console.log('Selected Subdomain value changed:', value);
                selectedSubdomain.value = value

                SelectElement.recalculateDirection();
               
                // fetch the data again but with filter applied
                // fetch the option list data of child/next filter
                fetchDevStages();
                fetchAllData();
            });
            } 
            if (SelectElementDesktop) {
                SelectElementDesktop.on('change', (value) => {
                console.log('Selected Subdomain value changed:', value);
                selectedSubdomain.value = value
                // fetch the data again but with filter applied
                // fetch the option list data of child/next filter
                fetchDevStages();
                fetchAllData();
            });
            } else {
            console.log('AdvancedSelect subdomain instance not found');
            }
        },100)
    } catch (error) {
        console.error('Error fetching age subdomains data:', error);
    }
  }


  // Funtion to fetch development stages/age groups for filter options
  const fetchDevStages = async() => {
    try {
        renderAgeGroup.value = false;
        console.log("fetch dev stages/ age group called")
        const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_development_stages',{
        subdomain: selectedSubdomain.value
        });
        // If response.status is success
        devStages.value = response.data
        renderAgeGroup.value = true;
        console.log("This is fetched age-group/dev stages data", response);
        setTimeout(() => {
            window.HSStaticMethods.autoInit();
            console.log("Preline initialised in curriculum landscape table.vue after fetching dev stages/age group");
            // Initialize the select element for Subdomains
            const SelectElement = window.HSSelect.getInstance('#devStageSelect');
            const SelectElementDesktop = window.HSSelect.getInstance('#devStageSelectDesktop');
            if (SelectElement) {
                SelectElement.on('change', (value) => {
                console.log('Selected dev Stage value changed:', value);
                selectedDevStage.value = value
                // fetch the data again but with filter applied
                fetchFocusAreas();
                fetchAllData();
               
            });
            }
            if (SelectElementDesktop) {
                SelectElementDesktop.on('change', (value) => {
                console.log('Selected dev Stage value changed:', value);
                selectedDevStage.value = value
                // fetch the data again but with filter applied
                fetchFocusAreas();
                fetchAllData();
               
            });
            } else {
            console.log('AdvancedSelect dev stage instance not found');
            }
        },100)
    } catch (error) {
        console.error('Error fetching age groups/ dev stages data:', error);
    }
  }
  
  // Funtion to fetch Focus areas of Selected age group/dev stages for filter options
  const fetchFocusAreas = async() => {
    
      try {
          renderFocusArea.value = false;
          console.log("fetch focus areas called")
          const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_focus_area',{
            dev_stage: selectedDevStage.value
          });
          // If response.status is success
          focusAreas.value = response.data
          renderFocusArea.value=true;
          console.log("This is fetched Focus areas data", response);
          setTimeout(() => {
              window.HSStaticMethods.autoInit();
              console.log("Preline initialised in curriculum landscape table.vue after fetching focus area");

              // Initialize the select element for Subdomains
              const SelectElement = window.HSSelect.getInstance('#focusAreaSelect');
              const SelectElementDesktop = window.HSSelect.getInstance('#focusAreaSelectDesktop');
              if (SelectElementDesktop) {
                  SelectElementDesktop.on('change', (value) => {
                  console.log('Selected focus Area value changed:', value);
                  selectedFocusArea.value = value
                  // fetch the data again but with filter applied
                  fetchLearningSequences();
                  fetchAllData();
              });
              } else {
              console.log('AdvancedSelect focus area instance not found');
              }
          },100)
      } catch (error) {
          console.error('Error fetching Focus area data:', error);
      }
   }
   

  // Function to fetch learning sequences
  const fetchLearningSequences = async() => {
    try {
        console.log("fetch Sequences called")
        const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_learning_sequences_for_activity_table',{
          focus_area:selectedFocusArea
        });
        // If response.status is success
        learningSequences.value = response.data
        console.log("This is fetched sequences data", response);
    } catch (error) {
        console.error('Error fetching sequences data:', error);
    }
  }

  
  onMounted(async () => {
    fetchDomains();

    if(props.domain){
      routedFromLandscapeMap()
    }
    else{
      fetchAllData();
    }

    setTimeout(()=>{
      // Initialise preline for placeholder filters
      window.HSStaticMethods.autoInit();
    },100)
  })

  </script>

<style scoped>
/* .hs-select button{
  font-size: 12px;
} */
</style>