<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              {{ props.activityData.activity_name }} <span v-if="mode===2" class="text-sm">(edited)</span>
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col" ref="formRef">
          <div>
            <ActivityCardContent v-if="mode === 0" :activityDetails="props.activityData" />
            <ActivityDetails v-if="mode === 1" :pageNum="pageNum" :componentPageNum="1" 
              :detailsData="detailsData" />
            <ActivityDescription v-if="mode === 1" :pageNum="pageNum" :componentPageNum="2" 
              :descData="descData" />
            <ActivityCardContent v-if="mode === 2" :activityDetails="previewData" />
          </div>
          <div class="flex flex-col md:flex-row gap-4 md:items-center md:justify-between">
            <div class="flex justify-center md:justify-end items-center gap-4 py-3 px-4 flex-wrap">
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden')" 
                :data-hs-overlay="`#hs-${props.modalName}`" :disabled="mode !== 0">
                Close
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden')"  
                :disabled="mode !== 0 || true" @click="mode = 1">
                Edit
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none`, mode === 0 && 'hidden')" 
                @click="goToPrevPage" :disabled="(mode !== 1 && mode !== 2) || pageNum === 1">
                Back
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 1 && 'hidden', pageNum !== 1 && 'hidden')" 
                @click="goToNextPage" :disabled="mode !== 1 ||pageNum >= 2">
                Next
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 1 && 'hidden', pageNum !== 2 && 'hidden')" 
                @click="showPreview" :disabled="mode !== 1 || pageNum != 2">
                Preview
              </button>
              <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 2 && 'hidden')" 
                :disabled="mode !== 2">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline';
import ActivityCardContent from './ActivityCardContent.vue';
import ActivityDetails from '../creation/ActivityDetails.vue';
import ActivityDescription from '../creation/ActivityDescription.vue';
import { createResource } from 'frappe-ui';


const props = defineProps(['modalName', 'activityData'])

const detailsData = computed(() => {
  if (!props.activityData) return {}
  return {
    structured_data: {
      title: props.activityData.activity_name,
      domain: props.activityData.domain,
      competencies: props.activityData.competencies.map(comp => comp.competency_name).join(', '),
      summary: props.activityData.summary
    }
  }
})

const descData = computed(() => {
  if (!props.activityData) return {}
  return {
    structured_data: {
      description: props.activityData.activity_description,
      observaton_guide: props.activityData.observaton_guide
    }
  }
})

const mode = ref(0)  // 0 - view, 1 - edit, 2 - preview
const pageNum = ref(1)
const formRef = ref(null)
const previewData = ref(null)

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const goToNextPage = () => {
  if (pageNum.value !== 1) return
  pageNum.value++
}

const goToPrevPage = () => {
  if (pageNum.value <= 1) return
  pageNum.value--
}

const showPreview = () => {
  const formData = new FormData(formRef.value)
  const formDataObj = {}
  
  formData.forEach((value, key) => {
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  if (!Array.isArray(formDataObj.competencies)) {
    formDataObj.competencies = formDataObj.competencies.split(', ').map(comp => {
      return comp
    })
  }
  previewData.value = formDataObj
  mode.value = 2
}

const activitySaveResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity.api.edit_activity'
})
const isSaving = ref(false)

const handleSubmit = async (e) => {
  const formDataObj = previewData.value
  formDataObj.is_domain_ids = true
  formDataObj.id = props.activityData.name

  console.log(formDataObj)
  activitySaveResource.update({
    params: {
      activity_data: JSON.stringify(formDataObj),
  }})
  try {
    isSaving.value = true
    await activitySaveResource.submit()
    isSaving.value = false
    if (activitySaveResource.data.status === 'success') {
      HSOverlay.close(`#hs-${props.modalName}`)
      pageNum.value = 1
      // HSOverlay.open('#hs-form-confirm-modal-2')
    } else if (activitySaveResource.data.status === 'fail') {
      alert("Form Submission Failed. Make sure you have filled everything correctly.")
    }
  } catch (error) {
    console.error(error)
  }
}
</script>