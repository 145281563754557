<template>
  <td class="items-center whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
    <label class="flex justify-center h-full w-full">
      <input type="radio" :name="`engage-${props.studentID}`" class="shrink-0 mt-0.5 border-gray-200
        rounded-full text-blue-600 disabled:opacity-50
        disabled:pointer-events-none" :value="props.inputValue" 
        :checked="isInputChecked">
    </label>
  </td>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps(['studentID', 'inputValue', 'defaultAnswer'])

const isInputChecked = computed(() => {
  if (!props.studentID || !props.defaultAnswer) return false
  else return props.defaultAnswer === props.inputValue
})
</script>