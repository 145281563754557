<template>
  <div class="w-[20rem]">
    <div class="border-b border-gray-200 dark:border-neutral-700">
      <nav class="flex space-x-1" aria-label="Tabs" role="tablist">
        <button type="button" class="hs-tab-active:font-semibold hs-tab-active:border-blue-600 
          hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent 
          text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 
          disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500 
          active" id="summary-options-tab-item-1" data-hs-tab="#summary-options-tab-1" 
          aria-controls="summary-options-tab-1" role="tab">
          Options
        </button>
        <button type="button" class="hs-tab-active:font-semibold hs-tab-active:border-blue-600 
          hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent 
          text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 
          disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500" 
          id="summary-options-tab-item-2" data-hs-tab="#summary-options-tab-2" 
          aria-controls="summary-options-tab-2" role="tab">
          Prompts
        </button>
      </nav>
    </div>

    <div class="mt-3">
      <div id="summary-options-tab-1" role="tabpanel" aria-labelledby="summary-options-tab-item-1">
        <form @submit.prevent="() => {}" ref="fromref" class="flex flex-col gap-4">
          <div>Observation Type:</div>
          <div class="flex flex-col md:flex-row gap-4">
            <div class="flex">
              <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4 cursor-pointer">
                <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200 
                  rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
                  disabled:pointer-events-none" id="hs-radio-un-about-group-1" value="child"
                  v-model="pickedType">
                  Child
              </label>
            </div>

            <div class="flex">
              <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4 cursor-pointer">
                <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200 
                  rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                  id="hs-radio-un-about-group-2" value="activity"
                  v-model="pickedType">
                  Group
              </label>
            </div>

            <div class="flex">
              <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4 cursor-pointer">
                <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200 
                  rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                  id="hs-radio-un-about-group-2" value="weekly"
                  v-model="pickedType">
                  Weekly
              </label>
            </div>
          </div>
          <label>
            <p>Start Date:</p>
            <input type="date" id="summaryStartDate" name="start-date" v-model="startDate" min="2018-01-01" 
              :max="today" />
          </label>
          <label>
            <p>End Date:</p>
              <input type="date" id="summaryEndDate" name="end-date" v-model="endDate" min="2018-01-01"
                :max="today" />
          </label>
          <!-- dropdown for model select-->
          Model:
          <div class="hs-dropdown relative inline-flex">
            <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex 
              items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white 
              text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none 
              dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
              {{ MODELMAP[pickedModel] }}
              <svg class="hs-dropdown-open:rotate-180 size-4" xmlns="http://www.w3.org/2000/svg" width="24" 
                height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
            </button>

            <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 
              opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-neutral-800 
              dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute 
              after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 
              before:start-0 before:w-full" aria-labelledby="hs-dropdown-default">
              <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 
                hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 
                dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                @click="() => pickNewModel('gpt-3.5-turbo-0125')">
                GPT 3.5 Turbo
              </a>
              <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 
                hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 
                dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                @click="() => pickNewModel('gpt-4')">
                GPT 4
              </a>
              <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 
                hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 
                dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                @click="() => pickNewModel('gpt-4-32k')">
                GPT 4 32K
              </a>
              <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 
                hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 
                dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                @click="() => pickNewModel('gpt-4-turbo')">
                GPT 4 Turbo
              </a>
              <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 
                hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 
                dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                @click="() => pickNewModel('gpt-4o')">
                GPT 4o
              </a>
            </div>
          </div>
          <!-- dropdown for model select ends-->
          <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit" 
            @click="props.fullPromptFn">Show full prompt</button>
        </form>
      </div>
      <div id="summary-options-tab-2" class="hidden" role="tabpanel" aria-labelledby="summary-options-tab-item-2">
        <p class="text-gray-500 dark:text-neutral-400 flex flex-col gap-4">
          <button type="button" @click="props.savePromptFn"
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
            Save Prompt
          </button>
          <div v-if="promptResource.data?.status === 'success'" 
            class="flex flex-col gap-2">
            <div v-for="prompt in promptResource.data.prompts"
              class="border rounded-md p-2">
              <h5 class="font-semibold text-gray-700">
                {{ prompt.prompt_name }}
              </h5>
              <p class="text-ellipsis line-clamp-2 font-light text-gray-600">
                {{ prompt.prompt }}
              </p>
              <div class="flex justify-end">
                <button  class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
                  border border-gray-200 bg-white text-yellow-500 shadow-sm hover:bg-gray-50
                  disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
                  dark:hover:bg-neutral-800"
                @click="() => changePrompt(prompt.prompt)">
                  Use this prompt
                </button>
              </div>
            </div>
          </div>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { getTodayDateJSFormat } from '../../../lib/utils';
import { createResource } from 'frappe-ui'

const props = defineProps([
  'changeDate', 'changeType', 'fullPromptFn',
  'savePromptFn', 'changePrompt', 'changeModel'
])

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const today = getTodayDateJSFormat();

const pickedType = ref('child');
const startDate = ref("2024-05-10");
const endDate = ref(today);
const pickedModel = ref('gpt-3.5-turbo-0125')

watch([pickedType, startDate, endDate], (newValues, oldValues) => {
  if (newValues[0] !== oldValues[0] && props.changeType)
    props.changeType(newValues[0])
  if (newValues[1] !== oldValues[1] && props.changeDate)
    props.changeDate('start', newValues[1])
  if (newValues[2] !== oldValues[2] && props.changeDate)
    props.changeDate('end', newValues[2])
})

const MODELMAP = {
  'gpt-3.5-turbo-0125': 'GPT-3.5 Turbo',
  'gpt-4': 'GPT-4',
  'gpt-4-32k': 'GPT-4 32K',
  'gpt-4-turbo': 'GPT-4 Turbo',
  'gpt-4o': 'GPT-4o'
}

const pickNewModel = (newModel) => {
  pickedModel.value = newModel
  props.changeModel(newModel)
}

const promptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompt.api.get_all_prompts',
  auto: true
})
</script>