<template>
    <div class="wrapper">
      
      <!-- <h1>Calendar page</h1> -->
      <!-- <div class="calendar-container"> -->
        <CurriculumEvents :options-response="optionsResponse"></CurriculumEvents>
      <!-- </div> -->
      
    </div>
  </template>


<script>
import CurriculumEvents from '../components/CurriculumEvents.vue';

export default {
	components: {
    CurriculumEvents
  },
  props: {
    optionsResponse: {
      type: Object,
      required: true,
    },
  },
	
};
</script>