<template>
  <div class="flex flex-col h-full max-h-[94vh] md:max-h-[91.5vh] pt-6 px-2 lg:pt-8 lg:pl-8 gap-4 lg:gap-8 
    lg:pr-4 overflow-y-auto">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8">
        <h2 class="text-3xl">User Management</h2>
      </div>
    </div>
    <!-- <div class="flex flex-col lg:flex-row gap-8 lg:basis-96">
    </div> -->
    <Tabs>
    <div class="flex flex-col gap-4">
        <div class="flex justify-between md:px-4 flex-wrap">
          <TabsList>
            <TabsTrigger segNum="1">Educators</TabsTrigger>
            <TabsTrigger segNum="2">Students</TabsTrigger>
            <TabsTrigger segNum="3">Parents</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent segNum="1">
          <EducatorsTab v-if="manDataResource.data?.status === 'success'"
            :educatorList="manDataResource.data.educator_list" :reloadFn="reloadManData" />
        </TabsContent>
        <TabsContent segNum="2">
          <StudentsTab v-if="manDataResource.data?.status === 'success'" 
            :studentList="manDataResource.data.student_list" />
        </TabsContent>
        <TabsContent segNum="3">
          Parents Tab
        </TabsContent>
      </div>
    </Tabs>
  </div>
</template>

<script setup>
import { createListResource, createResource } from 'frappe-ui'
import { useRouter } from 'vue-router';
import { inject, provide } from 'vue';
import { TabsList, Tabs, TabsTrigger, TabsContent } from '@/components/ui/Tabs'
import StudentsTab from '../components/userManagement/StudentsTab.vue';
import EducatorsTab from '../components/userManagement/EducatorsTab.vue';

// const router = useRouter()

// const searchForActivity = (key) => {
//   router.push(`/front-portal/activities/search/${key}`)
// }

const userDetails = inject('userDetails')

const manDataResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.user_details.api.get_user_man_data',
  auto: true
})

const reloadManData = () => {
  manDataResource.reload()
}

console.log(manDataResource)
</script>