<template>
	
 <div>
	<h1 class="font-sans text-2xl md:text-3xl mt-2 ml-2">
    Hey there! Let's make learning playful and purposeful
   </h1>
	
		<!-- Fetch the resource on click -->
	<!-- <button @click="$resources.ping.fetch()">Ping</button> -->
 </div>
</template>

<script>

import EventForm from '../components/EventForm.vue';
import NewEventForm from '../components/NewEventForm.vue';
// Import other plugins as needed

export default {
  components: {
      EventForm,
	  NewEventForm
    },
  resources: {
	ping() {
	  return {
		method: "frappe.ping", // Method to call on backend
		onSuccess(d) {
		  alert(d);
		},
	  };
	},
	
},
  
};
</script>
