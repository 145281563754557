<template>
  <div :class="cn('p-8 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-6">
      <p class="font-semibold">
        5. Is there evidence of creativity or innovation during the activity?
      </p>
      <div class="flex flex-col gap-y-4">
        <div class="flex">
          <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4">
          <input type="radio" name="creativity_evidence" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
            disabled:pointer-events-none" id="creativity-radio-group-1" value="Yes, spontaneous creativity"
            v-model="picked" >
            Yes, spontaneous creativity (e.g., a child painting a picture in a unique style)
          </label>
        </div>

        <div class="flex">
          <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4">
          <input type="radio" name="creativity_evidence" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            id="creativity-radio-group-2" value="Yes, innovation within constraints"
            v-model="picked" >
            Yes, innovation within constraints (e.g., a child devising a new method to complete a familiar activity)
          </label>
        </div>

        <div class="flex">
          <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4">
          <input type="radio" name="creativity_evidence" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            id="creativity-radio-group-3" value="No obvious creativity or innovation"
            v-model="picked" >
            No obvious creativity or innovation
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref } from 'vue'

const props = defineProps(['pageNum', 'componentPageNum', 'observation'])

const picked = ref(props.observation?.creativity_evidence || '')
</script>