<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== 2 && `hidden`)" >
    <div class="space-y-3" @keydown.stop>
      <label class="font-medium text-sm md:text-base">
        1. Anecdotes from the day
      </label>
      <p class="text-xs md:text-sm font-medium">Student<span v-if="props.additionalStudents?.length">s</span>: 
        <span>{{ findValueByName(props.studentsList, props.mainStudent, 'student_name') }}</span>
        <span v-if="props.additionalStudents?.length">
          <span v-for="student in props.additionalStudents">
            , {{ findValueByName(props.studentsList, student, 'student_name') }}
          </span>
        </span>
      </p>
      <textarea id="hs-autoheight-textarea-1" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        rows="10" placeholder="Enter anecdotes here..." name="anecdotes" v-model="anecdotes"
        ref="anecdoteInputRef" @input="textareaAutoHeight"></textarea>
    </div>
    <div>
      <AudioRecorder :userData="props.userData" observationType="daily" 
        @transcriptGenerated="appendAnecdotes" @transcriptStatus="handleTranscriptStatus" />
      <div v-if="transcriptStatusText"
        :class="cn(`text-sm font-semibold text-gray-600 p-3 text-center`)">
        {{ transcriptStatusText }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
// import SelectSearch from '../../../ui/SelectSearch.vue';
// import { createListResource } from 'frappe-ui'
import { ref } from 'vue';
import { findValueByName } from '@/lib/utils.js'
import AudioRecorder from '../AudioRecorder.vue'

const props = defineProps([
  'pageNum', 'observation', 'mainStudent', 
  'additionalStudents', 'studentsList', 'userData'
])

const anecdotes = ref(props.observation?.anecdotes || '')
// const selectedCompRef = ref(null)

const appendAnecdotes = (newText) => {
  anecdotes.value = anecdotes.value + '\n\n' + newText
}

const areAllInputsFilled = () => {
  // const pickedComps = selectedCompRef.value.getSelectedItems()
  // return !!anecdotes.value && !!pickedComps
  return !!anecdotes.value
}
defineExpose({areAllInputsFilled})

const anecdoteInputRef = ref(null)

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  anecdoteInputRef.value.style.height = 'auto'
  let calculatedHeight = anecdoteInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  anecdoteInputRef.value.style.height = `${calculatedHeight}px`
}

const transcriptStatusText = ref('')

const handleTranscriptStatus = (statusObj) => {
  if (statusObj.status === 'start') {
    transcriptStatusText.value = "Generating transcript, please wait..."
  } else if (statusObj.status === 'fail') {
    transcriptStatusText.value = 'Transcript generation failed, please try again...'
    setTimeout(() => {
      transcriptStatusText.value = ''
    }, 3500)
  } else if (statusObj.status === 'success') {
    transcriptStatusText.value = ''
  }
}
</script>