<template>
  <div class="flex flex-col gap-2">
    <div v-if="audioURL"class="flex gap-4 items-center">
      <audio :src="audioURL" controls :class="cn(!audioURL && 'hidden')"></audio>
      <button type="button" class="text-black underline font-medium text-xs bg-inherit"
        :disabled="!audioURL" @click="getTranscript">
        Get Transcript
      </button>
    </div>
    <div v-if="is_recording" class="text-gray-500 text-xs">
      <span v-if="!is_paused">Recording...</span>
      <span v-if="is_paused">Recording Paused...</span>
    </div>
    <div class="flex gap-2 md:gap-4 items-center">
      <button type="button" class="bg-[#FDD835] h-8 w-8 rounded-full flex justify-center items-center shrink-0"
        @click="startOrStopRecording">
        <span v-if="!is_recording">
          <RecordIcon />
        </span>
        <!-- <span v-if="is_recording && !is_paused" class="inline-flex justify-center items-center gap-2">
        </span> -->
        <span v-if="is_recording" class="h-[10px] w-[10px] bg-black">
        </span>
      </button>
      <p class="font-normal text-xs md:text-sm text-gray-600">
        Or Record Audio and get Transcript
      </p>
      <div v-if="is_recording || audioURL" class="flex gap-2 items-center">
        <button type="button" class="py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700
          dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1
          dark:focus:ring-gray-600 text-xs"
          @click="toggleMic" :disabled="!is_recording">
          <span v-if="!is_paused">Pause</span>
          <span v-if="is_paused">Resume</span>
        </button>
        <!-- <button type="button" class="py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700
          dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1
          dark:focus:ring-gray-600 text-xs"
          @click="toggleMic" :disabled="!is_recording">
          clear
        </button> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import RecordIcon from '@/components/icons/RecordIcon.vue';
import { ref } from 'vue';
import { cn } from '@/components/ui/utils.js';

const props = defineProps(['userData', 'observationType'])
const emit = defineEmits(['audioRecorded', 'audioDeleted', 'transcriptGenerated', 'transcriptStatus'])

const audioURL = ref('');
const audioFileName = ref('');

const can_record = ref(false)
const is_recording = ref(false)
const is_paused = ref(false)

let recorder = null
let chunks = []

let blob = null

const createAudioUrl = () => {
  blob = new Blob(chunks, { type: "audio/wav" })
  console.log(blob)
  emit('audioRecorded', blob)
  chunks = []
  audioURL.value = window.URL.createObjectURL(blob)
}

const stopRecording = () => {
  if (!is_recording.value) {
    return
  }
  if (is_paused.value) {
    is_paused.value = false
    createAudioUrl();
    is_recording.value = false
    return
  }
  recorder.stop()
  
  is_recording.value = false
}

function setupStream(stream) {
  console.log(stream)
  recorder = new MediaRecorder(stream)
  recorder.ondataavailable = (e) => {
    chunks.push(e.data)
  }

  recorder.onstop = (e) => {
    if (is_paused.value) {
      // console.log(`returning without saving the data as pause is ${is_paused.value}`)
      return
    }
    // console.log(`saving the data as pause is ${is_paused.value}`)
    createAudioUrl();
    stream.getAudioTracks()[0].stop()
  }

  can_record.value = true
  recorder.start()
}

const pauseRecording = () => {
  is_paused.value = true
  recorder.stop()
}

const resumeRecording = () => {
  is_paused.value = false
  recorder.start()
}

function setupAudio() {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then(setupStream)
      .catch(err => {
        console.error(err)
      })
  }
}

const startRecording = () => {
  audioURL.value = ''
  setupAudio()
  is_recording.value = true
  if (is_paused.value) {
    is_paused.value = false
  }
}

const startOrStopRecording = () => {
  if (is_recording.value)
    stopRecording();
  else
    startRecording();
}

const toggleMic = () => {
  if (is_recording.value) {
    if (is_paused.value) 
      resumeRecording()
    else 
      pauseRecording()
  } else {
    startRecording()
  }
}

const getTranscript = async () => {
  emit('transcriptStatus', { status: 'start' })
  const startTime = new Date().getTime();
  console.log('UserData', props.userData);
  if (!props.userData?.educator_id) {
    console.error("Educator ID could not be found!");
    emit('transcriptStatus', { status: 'fail'})
    return;
  }
  const educatorID = props.userData.educator_id
  const fileName = `${educatorID}_${Date.now()}.wav`
  const audio_file = new File([blob], fileName, { type: "audio/wav" })

  // statusText.value = "Generating Transcript..."

  const formData = new FormData()
  formData.append('audio_file', audio_file)
  formData.append('observation_type', props.observationType)
  try {
    const res = await fetch('/api/method/curriculum_planner.curriculum_planner.common.observations.api.get_transcription', {
      method: 'POST',
      headers: {
        'X-Frappe-Csrf-Token': window.csrf_token
      },
      body: formData
    })
    const res_data = await res.json()
    const endTime = new Date().getTime()
    const elapsedTime = endTime - startTime
    if (res_data.message.status === 'success') {
      // feedbackText.value = res_data.message.transcript
      audioFileName.value = fileName
      console.log('Transcript generated', res_data.message)
      // statusText.value = `Transcript generated in ${elapsedTime} milliseconds.`
      emit('transcriptGenerated', res_data.message.transcript)
      emit('transcriptStatus', { status: 'success' })
    } else if (res_data.message.status == 'fail') {
      alert("Transcript generation failed!")
      emit('transcriptStatus', { status: 'fail' })
    }
  } catch (error) {
    // statusText.value = "Transcript generation failed! Please try again."
    console.log('Transcription generation failed')
    console.error(error)
    emit('transcriptStatus', { status: 'fail' })
  }
}
</script>