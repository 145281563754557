<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full lg:max-h-[90vh]">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Student Observation Extract
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>

        <div v-if="props.extract" class="p-4 flex flex-col gap-4">
          <h3 v-if="props.extract.student" 
            class="font-bold text-xl ">
            {{ findValueByName(userDetails?.school_info.students, props.extract.student, 'student_name') }}
          </h3>
          <div v-else class="flex flex-col gap-4">
            <h3 class="font-bold text-xl ">
              Unlinked Extract
            </h3>
            <p>
              This extract belongs to child who couldn't be matched to our students.
            </p>
          </div>
          <div v-if="props.extract.educator" class="space-y-2">
            <h4 class="font-semibold">
              Educator:
            </h4>
            <p class="text-gray-500">
              {{ findValueByName(userDetails?.school_info.educators, props.extract.educator, 'educator_name') }}
            </p>
          </div>
          <div v-if="props.extract.extract" class="space-y-2">
            <h4 class="font-semibold">
              Extract:
            </h4>
            <p class="text-gray-500">
              {{ props.extract.extract }}
            </p>
          </div>
        </div>

        <div class="flex justify-end pb-2 px-4">
          <button type="button" @click="deleteExtract" 
            :disabled="isDeleting"
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
            Delete Extract
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { findValueByName } from '@/lib/utils';
import { HSOverlay } from 'preline/preline';
// import { HSOverlay } from 'preline/preline';

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const props = defineProps([
  'modalName', 'observation', 'extract',
  'deleteFn'
])

const isDeleting = ref(false)

const userDetails = inject('userDetails')

const deleteExtract = async () => {
  console.log("Deleting extract")
  isDeleting.value = true
  await props.deleteFn(props.extract.name)
  isDeleting.value = false
  HSOverlay.close(`#hs-${props.modalName}`)
}
</script>