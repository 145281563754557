<template>
  <div class="flex flex-col gap-4 pt-4 w-full">
    <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
      <div class="text-center">
        Month: <span class="font-semibold">{{ getMonthName(props.monthlyStats[props.currentMonth].start_date) }}</span>
      </div>
      <div class="border rounded-md w-2/5 md:w-1/4 self-end">
        <AdvancedSelect v-if="props.cohorts" :dataList="props.cohorts"
          dispName="cohort_name" inputName="cohort" selectId="monthStatCohort" @valueSelected="valueSelected"
          default="all" />
      </div>
    </div>
    <div class="flex justify-between w-full">
      <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
        border border-gray-200 bg-white text-yellow-500 shadow-sm hover:bg-gray-50 disabled:opacity-50
        disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:hover:bg-neutral-800"
        @click="props.goToPrevMonth" :disabled="!monthlyStats[props.currentMonth + 1]">
        < Previous
      </button>
      <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
        border border-gray-200 bg-white text-yellow-500 shadow-sm hover:bg-gray-50 disabled:opacity-50
        disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:hover:bg-neutral-800"
        @click="props.goToNextMonth" :disabled="props.currentMonth === 0">
        Next >
      </button>
    </div>
    <div class="h-[50vh] w-full">
      <BarChart :categories="names" :values="observations" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import BarChart from '../../charts/BarChart.vue';
import AdvancedSelect from '@/components/ui/AdvancedSelect.vue';

const props = defineProps([
  'monthlyStats', 'cohorts', 'currentMonth',
  'goToNextMonth', 'goToPrevMonth'
])

const cohort = ref('all')

const names = computed(() => {
  if (cohort.value === 'all')
    return props.monthlyStats[props.currentMonth].count_list.map((monthStat) => monthStat.student_name)
  return props.monthlyStats[props.currentMonth].count_list.filter((monthStat) => monthStat.cohort === cohort.value)
    .map((monthStat) => monthStat.student_name)
})
const observations = computed(() => {
  if (cohort.value === 'all')
    return props.monthlyStats[props.currentMonth].count_list.map((monthStat) => monthStat.count)
  return props.monthlyStats[props.currentMonth].count_list.filter((monthStat) => monthStat.cohort === cohort.value)
    .map((monthStat) => monthStat.count)
})

const valueSelected = (value) => {
  cohort.value = value
}

const getMonthName = (dateString) => {
  const date = new Date(dateString);

  // Get the month name
  return date.toLocaleDateString('en-US', { month: 'long' });
}
</script>