<template>
    <div>
      <div v-if="isModalOpen" @click.self="closeUploadPhotosModal" class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto">
        <!-- Form container with white background -->
        <div class="bg-white rounded-lg shadow-md w-11/12 md:w-auto h-auto max-h-[97vh] overflow-auto flex md:flex-row flex-col relative">      
          
          <!-- Closing cross for large screens -->
          <div class="hidden md:block absolute top-0 right-0 md:mt-2 md:mr-2" @click="closeUploadPhotosModal">
            <svg class="w-[25px]" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.3542 7.54102L8.4375 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.4375 7.54102L23.3542 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
  
          <!--Buttons/tabs and closing cross  -->
          <div class="flex flex-col w-full">
            <div class="flex flex-row mr-4 mt-4 ml-5">
              
              <!-- Show create-new-album card button -->
              <div class="flex flex-row justify-end mr-4 border border-slate-200">
                <button @click="toggleNewAlbumForm" :class="[showNewAlbumForm ? 'bg-black text-white' : 'bg-white text-black', 'text-xs font-bold rounded py-3 px-6']">
                  Create New
                </button>
              </div>
  
              <!-- Show Add-to-existing-album card button -->
              <div class="flex flex-row justify-end border border-slate-200 mr-4">
                <button @click="toggleExistingAlbumForm" :class="[showExistingAlbumForm ? 'bg-black text-white' : 'bg-white text-black', 'text-xs font-bold py-3 px-6']">
                  Add to Existing
                </button>
              </div>
  
              <!-- Closing cross for phone screens -->
              <div class="md:hidden mb-2 ml-2" @click="closeUploadPhotosModal">
                <svg class="w-[22px] md:w-[25px]" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.3542 7.54102L8.4375 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.4375 7.54102L23.3542 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
  
            <!-- Content based on selected tab -->
            <div class="p-4">

              <div v-if="showNewAlbumForm">
                <h2 class="text-xl font-bold mb-4">Create New Album</h2>
                <!-- Form for creating a new album -->
                
                <!-- Create-New-Album card -->
                <form :class="[showNewAlbumForm ? 'block' : 'hidden', 'md:block']" @submit.prevent="handleSubmit">
                    <div class="flex flex-row p-5 gap-4 mt-4">
                        
                        <div class="flex flex-col gap-2 border border-slate-200 p-2 rounded max-w-[100%]">
                            <span class="text-black font-semibold">Create a New Album</span>
                            
                                <UppyUploader :startUpload="shouldStartUpload" :formData="formData"  @uploadStarted="handleUploadStarted" @uploadComplete="handleUploadComplete" @uploadError="handleUploadError"></UppyUploader>
                             
                            <!-- Autocomplete Labels Drop drown -->
                            <AutoCompleteDropDown @update-tags="handleUpdateTags"></AutoCompleteDropDown>
                
                            <!-- Description -->
                            <div class="border border-slate-200 rounded-md">
                                <textarea v-model="description" style="border: 0px" class="w-full text-xs md:text-sm p-2 h-20" id="description" placeholder="Description"></textarea>
                            </div>

                            <!-- Manual date -->
                            <div class="border border-slate-200 rounded-md">
                                <input type="date" step="any" v-model="manualDate" style="border: 0px" class="w-full text-xs md:text-sm p-2" id="date" placeholder="Date"></input>
                            </div>

                        
                            <!-- Facilitator -->
                            <!-- Educators Select -->
                            <div class="flex flex-row items-center justify-between mb-1.5 border border-slate-200 rounded-lg">        
                                <!-- Select -->
                                <div class="relative w-[100%] md:w-[100%]">
                                    <select v-model="selectedEducator" id="educatorsSelect" ref="educatorsSelect" data-hs-select='{
                                            "placeholder": "Facilitator",
                                            "toggleTag": "<button type=\"button\"></button>",
                                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm font-thin text-slate-500 focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                                            "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                                        }' class="hidden">
                                    <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.name">{{ educator.educator_name }}</option>
                                    </select>
                                    <div class="absolute top-1/2 end-3 -translate-y-1/2">
                                        <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                                    </div>
                                </div>

                            </div>  
                            <!-- End Select -->
                            
                            <!-- Loading message -->
                            <div v-if="isLoading" class="text-green-500">Please wait until this is done</div>
                            <div v-if="backgroundProcessStarted" class="text-green-500">Great! you may close this page</div>
                            
                            <!-- Response message -->
                            <div v-if="responseMessage" :class="responseMessage.includes('Photos uploaded successfully!') ? 'text-green-500' : 'text-red-500'">
                            {{ responseMessage }}
                            </div>
                            <!-- Submit button -->
                            <div class="flex felx-row justify-end">
                                <button class="w-1/4 text-xs md:text-s font-bold rounded py-2.5 px-2 text-white bg-black">
                                    {{ isLoading ? 'Processing...' : 'Submit' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>     

              </div>

              <div v-if="showExistingAlbumForm">
                <h2 class="text-xl font-bold mb-4">Add to Existing Album</h2>
              </div>

              
            </div>


          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
 
  import { ref, onMounted, inject, computed, watch } from 'vue';
  import { defineEmits } from 'vue'
  import AutoCompleteDropDown from '../photoEssays/AutoCompleteDropDown.vue';
  import UppyUploader from './uppyUploader.vue';

  const props = defineProps({
    optionsResponse: Object,
  });

  

  const emit = defineEmits(['close-modal'])
  
  const isModalOpen = ref(true);
  const showNewAlbumForm = ref(true);
  const showExistingAlbumForm = ref(false);

  const educatorsOptions = ref([])
  const shouldStartUpload = ref(false);


  const description = ref('');
  const manualDate = ref('');
  const educatorsSelect = ref('');

  const formData = ref({});
  

  onMounted(()=>{
    assignOptionsData();
 })

  const assignOptionsData = ()=>{
    if (!props.optionsResponse || !props.optionsResponse.educators) {
        console.error("optionsResponse or educators property is not defined");
        return;
      }
    
    try {
          console.log("assign options data called in new Event form");
          const allowedNames = ['Hetvi','Kshama','Neha', 'Nikita','Priyanka', 'Ruchika', 'Sai'];
          educatorsOptions.value = props.optionsResponse.educators
            .filter(educator => allowedNames.includes(educator.educator_name))
            .sort((a, b) => allowedNames.indexOf(a.educator_name) - allowedNames.indexOf(b.educator_name));

          setTimeout(() => {
            window.HSStaticMethods.autoInit(); //Preline is initialised
          console.log("Preline Initialise inside upload videos to new album form after 100ms delay");
          }, 100)
        
    } 
    catch (error) {
        console.error('Error assigning data:', error);
      }
    }
  
  const toggleNewAlbumForm = () => {
    showNewAlbumForm.value = true;
    showExistingAlbumForm.value = false;
    setTimeout(() => {
            window.HSStaticMethods.autoInit(); //Preline is initialised
          console.log("Preline Initialise inside upload videos after button toggle");
          }, 100)
  };
  
  const toggleExistingAlbumForm = () => {
    showExistingAlbumForm.value = true;
    showNewAlbumForm.value = false;
  };
  
  const closeUploadPhotosModal = () => {
    isModalOpen.value = false;
    console.log('Closing upload photos modal');
    emit('close-modal')
  };
  const triggerUpload = () => {

    console.log("Updating formdata in Parent component")
    console.log("description--",description.value)
    console.log("manual date--",manualDate.value)
    console.log("educator--",educatorsSelect.value.value)
     formData.value = {
        description: description.value,
        manualDate: manualDate.value,
        selectedEducator: educatorsSelect.value.value,
        // Other fields if needed
    };
    
    // Add values for rest of the fields here and pass as props
    shouldStartUpload.value = true;
  };

const handleSubmit = () => {
    triggerUpload();
}


const handleUploadStarted = () => {
  console.log('Upload started');
  // Any logic you want to run when upload starts
};

const handleUploadComplete = ({ file, response }) => {
  alert('Upload completed', file, response);
  console.log("Upload completed event recieved in paren ===========================================================================================")
  // Any logic you want to run when upload completes
};

const handleUploadError = ({ file, error, response }) => {
  console.error('Upload error', file, error, response);
  // Any error handling logic
};
  </script>