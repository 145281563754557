<template>
  <div class="flex flex-col gap-4">
    <NewUserModal userType="Educator" modalName="new-educator-modal" :reloadFn="props.reloadFn">
      <template #trigger>
        <button data-hs-overlay="#hs-new-educator-modal" class="py-3 px-4 inline-flex items-center gap-x-2 
          text-sm font-semibold rounded-lg border border-transparent bg-[#FDD835] text-white 
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48">
          Add New Educator
        </button>
      </template>
    </NewUserModal>
    <div class="flex flex-col md:flex-row md:flex-wrap gap-4">
      <Card v-for="educator in props.educatorList" class="md:max-w-96 md:w-96" :key="educator.name" >
        <p class="font-semibold text-lg">
          {{ educator.educator_name }}
        </p>
        <p>Email: {{ educator.user_link }}</p>
      </Card>
        </div>
    </div>
</template>

<script setup>
import Card from '@/components/ui/Card.vue'
import NewUserModal from './NewUserModal.vue';

const props = defineProps(['educatorList', 'reloadFn'])
</script>