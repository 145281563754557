<template>

   <div class="h-full w-full overflow-auto">

        <div class="md:ml-14 mt-8 md:mr-16 px-5 md:px-0 text-[13.5px] md:text-sm">
            <h2 class="text-xl font-medium md:text-3xl">Resources</h2>

            <div class="mt-3 md:text-normal">We also make games and learning experiences to enable playful learning. Instead of limiting teaching to classroom settings, we should explore how to playfully approach any topic while allowing for practice.</div> <div class="mt-2">Each experience is carefully crafted to allow for open-ended exploration and understanding of the "big picture." This approach is what makes learning meaningful.</div>

            <!-- Giffie resource card -->
            <div class="border border-slate-200 rounded-xl mt-6 flex py-5 px-4 w-full flex-col md:flex-row">
            
                <img src="@/assets/resources-page-assets/giffie-gif.gif" width="200" height="" class="hidden md:flex rounded-lg w-[] object-contain"></img>
                
                <!-- Text content -->
                <div class="md:ml-4 flex flex-col">
                    <div class="order-1 font-semibold">Giffie</div>
                    

                    <img src="@/assets/resources-page-assets/giffie-gif.gif" width="" height="" class="order-3 md:hidden rounded-lg w-[340px]"></img>
                
                    <!-- description -->
                    <div class="order-4 md:order-2 mt-5 md:mt-3 w-[95%]">Learning to read English can be frustrating because of its seemingly mysterious letter-sound correspondences. Giffie is an AI-powered tutor who helps you learn to read the right way. Giffie helps make this practice effortless, while introducing phonics in a systematic manner. </div>
                    
                    <!-- Tags -->
                    <div class="order-2 md:order-3 mt-3 mb-1.5 md:mb-0">
                        <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                            Literacy

                        </span>
                        <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                            Learning to Read
                        </span>
                        <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                            Interactive
                        </span>
                        <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                            iOS
                        </span>
                        <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">  
                            Mobile
                        </span>
                        <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                            Desktop
                        </span>
                    </div>

                    
                </div>  

                <!-- Learn more button -->
                <button class="text-white font-semibold bg-black rounded-md text-center mr-3 h-[100%] self-center p-3 ml-2 mt-4 md:mt-0"> 
                <a href="https://www.heygiffie.com/" target="_blank">
                        <div class="min-w-[80px] text-white">Learn More</div>
                    </a>
                </button>

            </div>

            <!--Duckblob resource card -->
            <div class="border border-slate-200 rounded-xl mt-6 flex py-5 px-4 w-full flex-col md:flex-row">
            
            <img src="@/assets/resources-page-assets/DuckBlob-gif.gif" width="200" height="" class="hidden md:flex rounded-lg w-[] object-contain"></img>
            
            <!-- Text content -->
            <div class="md:ml-4 flex flex-col">
                <div class="order-1 font-semibold">DuckBlob</div>
                

                <img src="@/assets/resources-page-assets/DuckBlob-gif.gif" width="" height="" class="order-3 md:hidden rounded-lg w-[340px]"></img>
            
                <!-- description -->
                <div class="order-4 md:order-2 mt-5 md:mt-3 max-w-[95%]">
                    DuckBlob allows you to test your knowledge in any domain using multiple choice questions. Explanations are offered for each one to help players understand rather than simply remember.
                </div>
   
                <!-- Tags -->
                <div class="order-2 md:order-3 mt-3 mb-1.5 md:mb-0">
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        General Knowledge
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Practice
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Interactive
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Desktop
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Mobile
                    </span>
                </div>

                
            </div>  

            <!-- Learn more button -->
            <button class="text-white font-semibold bg-black rounded-md text-center mr-3 h-[100%] self-center p-3 ml-2 mt-4 md:mt-0"> 
                <a href="https://comini-duckblob.web.app/" target="_blank">
                    <div class="min-w-[80px] text-white">Try it out</div>
                </a>
            </button>

            </div>

            <!--Story Snitch resource card -->
            <div class="border border-slate-200 rounded-xl mt-6 flex py-5 px-4 w-full flex-col md:flex-row">
            
            <img src="@/assets/resources-page-assets/StorySnitch-gif.gif" width="200" height="" class="hidden md:flex rounded-lg w-[] object-contain"></img>
            
            <!-- Text content -->
            <div class="md:ml-4 flex flex-col">
                <div class="order-1 font-semibold">Story Snitch</div>
                

                <img src="@/assets/resources-page-assets/StorySnitch-gif.gif" width="" height="" class="order-3 md:hidden rounded-lg w-[340px]"></img>
            
                <!-- description -->
                <div class="order-4 md:order-2 mt-5 md:mt-3 max-w-[95%]">
                    We learn to read and write mainly to tell stories. About the world, ourselves, and the things we do. Story Snitch uses storytelling to offer reading practice.
                    <a href="https://play.comini.in/storysnitch" target="_blank" class="text-blue-500 hover:text-blue-400 cursor-pointer">We also have an offline card-game </a>
                </div>
                
            
                <!-- Tags -->
                <div class="order-2 md:order-3 mt-3 mb-1.5 md:mb-0">
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Literacy
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Storytelling
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Desktop
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Mobile
                    </span>
                </div>

                
            </div>  

            <!-- Learn more button -->
            <button class="text-white font-semibold bg-black rounded-md text-center mr-3 h-[100%] self-center p-3 ml-2 mt-4 md:mt-0"> 
              <a href="https://storysnitch.comini.in/" target="_blank">   
                <div class="min-w-[80px] text-white">Try it out</div>
              </a>  
            </button>

            </div>

            <!--IWTLNOW resource card -->
            <div class="border border-slate-200 rounded-xl mt-6 flex py-5 px-4 w-full flex-col md:flex-row">
            
            <img src="@/assets/resources-page-assets/iwtlnow-gif.gif" width="200" height="" class="hidden md:flex rounded-lg w-[] object-contain"></img>
            
            <!-- Text content -->
            <div class="md:ml-4 flex flex-col">
                <div class="order-1 font-semibold">IWTLNOW</div>
                

                <img src="@/assets/resources-page-assets/iwtlnow-gif.gif" width="" height="" class="order-3 md:hidden rounded-lg w-[340px]"></img>
            
                <!-- description -->
                <div class="order-4 md:order-2 mt-5 md:mt-3 max-w-[95%]">
                    Learning from scratch can be daunting, with questions about where to start and which direction to explore first. While AI models have made it easier to ask questions and learn, chat interfaces are often too linear for complex concepts. IWTLNOW is a hybrid mind-map tool and learning map creator, offering a rich interface to help learners discover directions, explore topics at different levels, access resources, and test their understanding in one place.
                </div>


                <!-- Tags -->
                <div class="order-2 md:order-3 mt-3 mb-1.5 md:mb-0">
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Mind Maps
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Quizzes
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Interactive
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Desktop
                    </span>
                    <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-sm px-1.5 py-1 font-medium text-black" style="background-color: #FDD835;">
                        Mobile
                    </span>
                </div>

                
            </div>  

            <!-- Learn more button -->
            <button class="text-white font-semibold bg-black rounded-md text-center mr-3 h-[100%] self-center p-3 ml-2 mt-4 md:mt-0"> 
              <a href="https://iwtlnow.microschools.in" target="_blank">   
                <div class="min-w-[80px] text-white">Try it out</div>
              </a>  
            </button>

            </div>
        </div>

    </div>


</template>

<script setup>

</script>