<template>
  <div class="flex flex-col gap-4 pb-8">
    <h2 class="font-semibold text-xl">
      All Activities
    </h2>
    <div class="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 lg:gap-8 lg:mr-[168px] items-stretch">
      <div v-for="activity in activities" :class="cn('basis-1/3 flex flex-col justify-center min-h-80',
        !activity.summary && 'hidden'
      )">
        <ActivitySearchCard
          :activity="activity" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { createResource } from 'frappe-ui'
import { computed, watch } from 'vue';
import ActivitySearchCard from './view/ActivitySearchCard.vue';
import { cn } from '../ui/utils';

const route = useRoute()

const activityResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity.api.get_search_results',
 params: {
  search_key: '',
 },
 auto: true
})
console.log("Imported Activities", activityResource)

watch(route, () => {
  activityResource.reset()
  activityResource.update({
    params: {
      search_key: route.params.searchKey,
    }
  })
  activityResource.reload()
  console.log(activityResource)
})

const loadedWOFail = computed(() => {
  if (!activityResource.data) 
    return false
  else if (activityResource.data.status === 'fail')
    return false
  else
    return true
})

const activities = computed(() => {
  if (!activityResource.data)
    return []
  else if (activityResource.data.status === 'fail')
    return []
  else
    return activityResource.data.activities
})
</script>