<template>
  <div class="flex flex-col h-[94vh] md:h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-8
    gap-4 lg:gap-8 overflow-y-auto">
    <div class="flex">
      <div class="flex-auto flex flex-col gap-8">
        <div v-if="studentObj" class="font-semibold text-xl">
          {{ studentObj.student_name }}
        </div>
        <div class="bg-[#FFFAE6] p-4 rounded-md">
          <div v-if="observationResource.data?.competency_scores" class="h-[50vh] w-full">
            <BarChartColour :scoreAmountProductArray="countScoreComp" :scoreAmountProductsNames="barChartDimNames"
              :mapText="barChartScoreText" :maxScore="roundUpToNearestFive(maxCount)" />
          </div>
          <div class="flex justify-end items-center p-8">
            Graph Observation threshold:
            <div class="relative flex items-center">
              <input type="number" class="peer py-0 pe-0 ps-2 block bg-transparent border-t-transparent 
                border-b-2 border-x-transparent border-b-gray-200 text-sm focus:border-t-transparent 
                focus:border-x-transparent focus:border-b-blue-500 focus:ring-0 disabled:opacity-50 
                disabled:pointer-events-none dark:border-b-neutral-700 dark:text-neutral-400 w-12
                dark:placeholder-neutral-500 dark:focus:ring-neutral-600 dark:focus:border-b-neutral-600"
                v-model="graphThreshold" min="0">
            </div>
          </div>
        </div>
        <div>
          <div v-if="selectedReport" class="border rounded p-2 cursor-pointer">
            <div class="flex justify-between">
              <div>Start date: {{ selectedReport.start_date }}</div>
              <div>End date: {{ selectedReport.end_date }}</div>
              <div>Model: {{ selectedReport.model }}</div>
            </div>
            <h5 class="font-semibold text-gray-700">
              Prompt:
            </h5>
            <p class="text-gray-600 font-semibold">
              {{ selectedReport.prompt_link ? selectedReport.prompt_link.prompt_name : "Untitled Prompt" }}
            </p>
            <p :class="cn(`text-ellipsis font-light text-gray-600`)">
              {{ selectedReport.prompt }}
            </p>
            <h5 class="font-semibold text-gray-700">
              Report:
            </h5>
            <div v-html="mdToHtml(selectedReport.report)" :class="cn(`text-ellipsis font-light 
              text-gray-600 custom-html-class`)">
            </div>
          </div>
        </div>

      </div>
      <div class="basis-96 shrink-0 p-4 ">
        <h4>Generated Reports</h4>
        <ReportList v-if="reportListResource.data?.status === 'success'" :selectedReport="selectedReport"
          :reportList="reportListResource.data.reports" @selected:report="(report) => selectedReport = report" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, computed, inject, ref } from 'vue';
import { cn } from '@/components/ui/utils.js'
import BarChartColour from '../../../charts/BarChartColour.vue';
import { useRoute } from 'vue-router';
import { getDateFromPreviousMonth, getTodayDateJSFormat } from '../../../../lib/utils';
import { createResource } from 'frappe-ui';
import ReportList from './ReportList.vue';
import { marked } from 'marked'

const route = useRoute()
const studentId = ref(route.query.student || '')

const userDetails = inject('userDetails')
const studentObj = computed(() => {
  if (!studentId.value) return {}
  if (!userDetails.value?.school_info) return {}
  const student = userDetails.value.school_info.students.find((obj) => obj.name === studentId.value)
  return student || {}
})

const today = getTodayDateJSFormat()
const endDate = ref(today)
const startDate = ref(getDateFromPreviousMonth(today))
const shouldLoadAllObservations = ref(true)


const observationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_individual_observations',
})

const reloadObservations = () => {
  const params = { 
    student: route.query.student,
    start_date: startDate.value,
    end_date: endDate.value,
    load_all: shouldLoadAllObservations.value,
  }
  observationResource.submit(params)
  console.log({ params })
  console.log(observationResource)
}

const competencyScores = computed(() => {
  if (!observationResource.data?.status === 'success')
    return null
  return observationResource.data.competency_scores
})

const barChartDimNames = ['count', 'engagement score', 'competency']
const maxCount = ref(5)
const graphThreshold = ref(0)

const countScoreComp = computed(() => {
  if (!competencyScores.value) return [];
  return competencyScores.value
    .filter((scoreObj) => scoreObj.count >= graphThreshold.value)
    .map((scoreObj) => { 
      if (scoreObj.count > maxCount.value) {
        maxCount.value = scoreObj.count;
      }
      return [scoreObj.count, scoreObj.average_engagement, scoreObj.competency]
    })
})

const barChartScoreText = ['Many Observations', 'Few Observations']

function roundUpToNearestFive(number) {
  return Math.ceil(number / 5) * 5;
}

const selectedReport = ref(null)

const reportListResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_saved_reports',
})

const checkForExistingReports = async () => {
  try {
    await reportListResource.submit({ student: studentId.value })
    if (reportListResource.data.status === 'success') {
      console.log("Fetched Report LIst!", reportListResource.data)
      if (reportListResource.data.reports.length)
        selectedReport.value = reportListResource.data.reports[0]
    } else {
      console.error('Server call failed')
      console.log(reportListResource)
    }
  } catch (error) {
    console.error(`Failed to make report list request: ${error}`)
  }
}

if (studentId) {
  observationResource.submit({ 
    student: studentId.value, 
    start_date: startDate.value,
    end_date: endDate.value,
    load_all: shouldLoadAllObservations.value,
  })
  checkForExistingReports()
  console.log({observationResource, reportListResource})
}

watch(() => route.query.student, () => {
  reloadObservations()
  checkForExistingReports()
})

const mdToHtml = (mdText) => {
  console.log("MDOTOGML", marked(mdText))
  return marked(mdText)
}
</script>

<style>
.custom-html-class, .custom-html-class *{
  white-space: pre-wrap;
}

.custom-html-class p{
  margin-bottom: 10px;
}

.custom-html-class ul{
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 8px;
}

.custom-html-class ul li{
  margin-bottom: 2px;
}

.custom-html-class ol {
  list-style-type: decimal;
  margin-left: 40px;
  margin-bottom: 8px;
}

.custom-html-class ol li {
  margin-bottom: 2px;
}

.custom-html-class h1 {
  font-size: 2rem;
  margin: 0.67rem 0;
}

.custom-html-class h2 {
  font-size: 1.5rem;
  margin: 0.83rem 0;
}

.custom-html-class h3 {
  font-size: 1.17em;
  margin: 0.83em 0;
}

.custom-html-class h4 {
  font-size: 1em;
  margin: 1.12em 0;
}

.custom-html-class h5 {
  font-size: 0.83em;
  margin: 1.5em 0;
}

.custom-html-class h6 {
  font-size: 0.75em;
  margin: 1.67em 0;
}

.custom-html-class b, strong {
  font-weight: bold;
}

.custom-html-class em, i {
  font-style: italic;
}
</style>