<template>

  <!-- Content container -->
  <div v-if="learnerAndUserProfileDetails && learnerAndUserProfileDetails.learners && learnerAndUserProfileDetails.learners.length > 0"
    class="px-4 py-8 overflow-x-auto" style="height: 100%;">

    <div class="text-sm md:text-xl font-normal mb-3 md:mb-6 md:ml-10">Hello! {{ learnerAndUserProfileDetails.user_details.first_name }}, what would
      you like to see today ? </div>

    <!-- Widgets -->
    <div class="flex flex-wrap justify-center md:justify-start md:ml-10 gap-x-4  md:gap-y-4 lg:gap-x-8">

      <!-- First column -->
      <div class="w-full md:w-auto">
        
        <!-- New Schedule Widget -->
        <div class="relative md:w-[370px] max-h-[270px] md:max-h-none md:h-auto md:pb-3 border border-slate-200 rounded-xl mb-6 md:mb-7 overflow-auto md:overflow-hidden">
          
          <!-- heading -->
          <div class="flex justify-between items-center py-2 px-5 mt-2.5">
            <div class="font-medium">
              Learners Schedule
            </div>
          </div>
          
          <!-- date, toggle and view all button -->
          <div class="flex items-center mb-3 justify-between px-5">   
              <div class="flex">
                <div class="font-medium">{{ moment().format('MMMM YYYY') }}</div>         
              
                <!-- Toggle calendar widget arrows -->
                <div class="flex flex-col gap-1 ml-5">
                  <!-- Go to next -->
                  <svg @click="scrollUp" width="8" height="12" transform="rotate(90)" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6.93494 0.440056C6.97569 0.480696 7.00801 0.528974 7.03007 0.582126C7.05212 0.635278 7.06348 0.692259 7.06348 0.749806C7.06348 0.807352 7.05212 0.864333 7.03007 0.917485C7.00801 0.970637 6.97569 1.01892 6.93494 1.05956L1.99382 5.99981L6.93494 10.9401C6.97562 10.9807 7.00789 11.029 7.0299 11.0822C7.05192 11.1353 7.06325 11.1923 7.06325 11.2498C7.06325 11.3073 7.05192 11.3643 7.0299 11.4174C7.00789 11.4706 6.97562 11.5189 6.93494 11.5596C6.89427 11.6002 6.84598 11.6325 6.79283 11.6545C6.73968 11.6765 6.68272 11.6879 6.62519 11.6879C6.56767 11.6879 6.51071 11.6765 6.45756 11.6545C6.40441 11.6325 6.35612 11.6002 6.31544 11.5596L1.06544 6.30956C1.0247 6.26892 0.992377 6.22064 0.970321 6.16748C0.948266 6.11433 0.936913 6.05735 0.936913 5.99981C0.936913 5.94226 0.948266 5.88528 0.970321 5.83213C0.992377 5.77897 1.0247 5.7307 1.06544 5.69006L6.31544 0.440056C6.35608 0.399313 6.40436 0.366988 6.45752 0.344932C6.51067 0.322876 6.56765 0.311523 6.62519 0.311523C6.68274 0.311523 6.73972 0.322876 6.79287 0.344932C6.84603 0.366988 6.8943 0.399313 6.93494 0.440056Z"
                      fill="#1F2937" stroke="black" stroke-width="0.5" />
                  </svg>

                  <!-- Go to previous -->
                    <svg @click="scrollDown" width="8" height="12" transform="rotate(90)" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.06506 11.5599C1.02431 11.5193 0.991987 11.471 0.969932 11.4179C0.947876 11.3647 0.936523 11.3077 0.936523 11.2502C0.936523 11.1926 0.947876 11.1357 0.969932 11.0825C0.991987 11.0294 1.02431 10.9811 1.06506 10.9404L6.00618 6.00019L1.06506 1.05995C1.02438 1.01927 0.992112 0.970978 0.970097 0.91783C0.948083 0.864683 0.936752 0.80772 0.936752 0.750195C0.936752 0.692669 0.948083 0.635706 0.970097 0.582559C0.992112 0.529412 1.02438 0.481121 1.06506 0.440445C1.10573 0.399768 1.15402 0.367501 1.20717 0.345487C1.26032 0.323472 1.31728 0.312142 1.37481 0.312142C1.43233 0.312142 1.48929 0.323472 1.54244 0.345487C1.59559 0.367501 1.64388 0.399768 1.68456 0.440445L6.93456 5.69044C6.9753 5.73108 7.00762 5.77936 7.02968 5.83252C7.05173 5.88567 7.06309 5.94265 7.06309 6.00019C7.06309 6.05774 7.05173 6.11472 7.02968 6.16787C7.00762 6.22103 6.9753 6.2693 6.93456 6.30994L1.68456 11.5599C1.64392 11.6007 1.59564 11.633 1.54248 11.6551C1.48933 11.6771 1.43235 11.6885 1.37481 11.6885C1.31726 11.6885 1.26028 11.6771 1.20713 11.6551C1.15397 11.633 1.1057 11.6007 1.06506 11.5599Z"
                        fill="#1F2937" stroke="black" stroke-width="0.5" />
                    </svg>                                   
                </div>  
              </div>  

              <router-link to="/front-portal/calendar" class="text-white bg-black rounded text-center text-xs py-2 px-3">View All</router-link>
          </div>    

          <!-- Rendering schedule events/activities -->
          <div ref="scheduleContainer" class="h-auto overflow-hidden">
            <div v-if="Object.keys(groupedEvents).length > 0">
              <div v-for="(dateEvents, date) in groupedEvents" :key="date" class="flex py-1.5 justify-center">
                
                <!-- Date/Day -->
                <div class="w-[10%] border rounded-lg text-center justify-center mr-1 flex flex-col bg-[#FFFAE6]"
                  :class="{ 'bg-[#fdd835]': isToday(date), 'bg-[#FFFAE6]': !isToday(date) }">
                    <div class="text-xs font-medium"> {{ moment(date).format('ddd') }}</div>
                    <div class="font-semibold text-xl">  {{ moment(date).format('DD') }}</div>
                </div>

                <!-- Activities on that day -->
                <div class="border border-slate-200 rounded-lg p-2 w-[80%] overflow-hidden"  :class="{ 'bg-[#FFFAE6]': isToday(date), 'bg-[]': !isToday(date) }">
                  
                  <div v-if="Object.keys(dateEvents).length > 0" class="">
                
                    <div v-for="(cohortEvents, cohort) in dateEvents" :key="cohort" class="md:w-[270px] min-h-[31px]">
                          <div v-for="session in ['Morning', 'Afternoon']" :key="session" class="flex">
                            
                              <img v-if="session == 'Morning' && cohortEvents[session].length>0" src="@/assets/morning_logo.png" alt="Morning logo" height="20"
                              width="20" style="max-width: none;">
                            
                              <img v-if="session =='Afternoon' && cohortEvents[session].length>0" src="@/assets/afternoon_logo.png" alt="Morning logo" height="20"
                              width="20" style="max-width: none;">
                            
                              <div v-for="event in cohortEvents[session]" :key="event.name" class="text-sm ml-1 truncate md:max-w-[180px]">
                              {{ event.activity_title || event.activity_name }} |
                              </div>
                              
                              <h3 v-if="cohortEvents[session].length>0" class="text-sm ml-1">{{ cohort }}</h3>
                            
                          
                          </div>
                        </div>

                    </div>  


                    <div v-else class="w-[270px] h-[60px] flex items-center">
                        <div class="text-sm ml-4 truncate max-w-[180px] text-slate-500">No events for the day</div>
                    </div>
                  
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- Micro Learnings Card-->
        <div v-if="sortedQuizList.length>0" class="md:w-[370px] h-auto border border-slate-200 rounded-xl overflow-hidden px-4">

          <!--Header row  -->
          <div class="flex justify-between mt-4 mb-3">
            <div class="font-medium mr-2">
              Micro Learnings
            </div>
            <router-link to="/front-portal/educators"                         
            class="text-white bg-black rounded self-center text-center w-[22%] text-xs py-1.5"> 
              View All
            </router-link>
          </div>

          <!-- Render list of quetionnairs -->
          <div v-if="sortedQuizList" class="flex flex-col md:flex-row md:flex-wrap">
            <LearningCard v-for="quiz in sortedQuizList.slice(0, 2)" :key="quiz.name" :title="quiz.title"
              :description="quiz.description" :qID="quiz.name" :updateScore="setLastScore" class="mb-4 text-sm overflow-ao learning-card-wrapper"/>
          </div>

          <!-- <CompletionModal :score="lastScore" /> -->


        </div>

      </div>

      <!-- Second column -->
      <div class="w-full md:w-auto mt-5 md:mt-0">

        <!-- Observations Card -->  <!--Temporary absolute width for md and lg screens to help style the layout-->
        <!-- <div class=" md:w-[400px] h-[350px] border border-slate-200 rounded-xl mb-6">
          
          <div class="flex">
            <div class="font-medium mr-2 mt-4 ml-4">
              {{ learnerAndUserProfileDetails.learners[0].student_name }} &
              {{ learnerAndUserProfileDetails.learners[1].student_name }}'s Observations
            </div>

            <router-link to="/front-portal/observations/view/child/all"                         
              class="text-white bg-black rounded self-center text-center w-[22%] text-xs py-1.5 mr-4"> View All</router-link>
          </div> -->


          <!-- Daily Observations widgets -->
          <!-- <div v-if="filteredObservations.length > 0">
            <div v-for="(observation, index) in filteredObservations" :key="observation.name" class="p-3 border m-5 rounded-lg">
              <div class="font-medium mb-1"> {{ observation.student_name }}</div>
                <div class="flex gap-2">
                  <div class="text-slate-500 font-medium text-sm mt-1">{{ moment(observation.creation).format('DD/MM/YY |') }}</div>
                  <div class="text-slate-500 font-medium text-medium">{{ observation.educator_name1 }}</div>
                </div>
                <div class="">{{ observation.anecdotes }}</div>
            </div>
          </div>

        </div> -->

        <!--Reports Card -->
         <div v-if="learnerAndUserProfileDetails.learners.length>0 && learnerAndUserProfileDetails.learners[0].report_status.is_published === 1" class="md:w-[300px] border border-slate-200 rounded-xl px-4 pb-6">
           
          <!-- Header row -->
          <div class="flex mt-3 justify-between">
            <div class="font-medium">
              Learners Reports
            </div>
            <router-link to="/front-portal/observations/report"                         
              class="text-white bg-black rounded text-center w-[22%] text-xs py-2"> 
                View All
            </router-link>
           </div>

           <!-- Reports button -->
            <div v-for="(learner, index) in learnerAndUserProfileDetails.learners">
               <template v-if="learner.report_status.is_published === 1"> 
                <router-link :to="{ name: 'StudentReport', params: { student: learner.name } }" class="flex gap-2 border border-slate-200 pl-4 rounded-lg  py-5 mt-4">
                  <div class="font-medium mr-2 text-sm"> {{ learner.student_name }}'s Report</div>
                </router-link>
              </template> 
            </div>

        </div> 

        <!-- Worksheet widget -->
        <div v-if="worksheetTitles.length>0" class="border border-slate-200 rounded-xl overflow-auto px-4 pb-3.5 mt-5">
          
          <!--Header row  -->
          <div class="flex justify-between mt-4">
            <div class="font-medium mr-2">
              Worksheets
            </div>
            <router-link to="/front-portal/worksheets"                         
             class="text-white bg-black rounded self-center text-center w-[22%] text-xs py-2"> 
              View All
            </router-link>
          </div>

          <!-- Worksheet titles -->
          <!-- <template v-if="worksheetTitles"> -->
            <div v-for="(worksheet, index) in worksheetTitles" :key="index">
              <router-link :to="{ name: 'WorksheetDetails', params: { worksheetName: worksheet.worksheet_id } }" class="flex gap-2 border border-slate-200 pl-4 rounded-lg  py-5 mt-4">
              <!-- Worksheet title-->
              <div class="font-medium mr-2 text-sm">{{ worksheet.title }}</div>
              </router-link>
            </div>
          <!-- </template>    -->

        </div>  

      </div>

      <!-- Third column -->
      <div class="w-full md:w-auto mt-5 md:mt-0">

        <!-- Learner profiles cards -->
        <div class="w-full md:w-[370px] h-auto max-h-[300px] overflow-auto border border-slate-200 rounded-xl px-4 pb-6">

          <div class="font-medium mb-4 mt-4">Learners Profile</div>

          <!-- Learner -->
          <div v-for="(learner, index) in learnerAndUserProfileDetails.learners" :key="index">
            <router-link :to="{ path: '/front-portal/learners', query: { learner: learner.name}}"  class="flex gap-2 border border-slate-200 pl-4 rounded-lg pt-3 pb-3 mt-4">                          
                <!-- pfp -->
                <img :src=learner.presigned_s3_url class="rounded-full w-16 h-16" alt="Profile Picture">
                <!-- Info -->
                <div>
                  <!-- Name -->
                  <span class="font-medium mr-2 text-sm">{{ learner.student_name }}</span>

                  <!-- Age -->
                  <div>
                    <span class="font-medium mr-2 text-sm">Age:</span>
                    <span class="text-slate-500 text-sm">{{ calculateAge(learner.date_of_birth)}}</span>
                  </div>

                  <!-- Cohort -->
                  <div>
                    <span class="font-medium mr-2 text-sm">Cohort:</span>
                    <span class="text-slate-500 text-sm">{{ learner.cohort_name }}</span>
                  </div>
                </div>
            </router-link>
          </div>

        </div>

        <!-- Photo essays Widget -->
        <div v-if="weeklyImagesData && weeklyImagesData.length > 0" class="flex flex-col border border-slate-200 p-2 md:w-[380px] md:max-h-[20rem] rounded-3xl mt-4 mb-6">

          <div class="flex mt-1.5 md:mt-3 justify-between">
            <!-- Name heading -->
            <div class="font-medium mr-8 ml-2 text-normal">
              Photo Essays
            </div>

            <router-link 
                :to="{ 
                  path: '/front-portal/photo-essays', 
                  query: { learners: getLearnerNames() }
                }" 
                class="text-white bg-black rounded md:self-center text-xs py-1.5 px-2 mr-2 min-w-[65px]">
                View All
            </router-link>
          </div>

          <!-- Tags -->
          <div v-if="uniqueTags" class="tags-container flex mt-2">
            <div v-for="(tag, tagIndex) in uniqueTags" :key="`tag-${tagIndex}`">
              <span class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-md px-1.5 py-1 text-xs font-medium text-black"
                style="background-color: #FDD835;">
                {{ tag }}
              </span>

            </div>
          </div>

          <!-- Images -->
          <div class="flex flex-col border-slate-200 rounded-md items-center md:pl-[25px] md:p-2 cursor-pointer">
            <div class="flex flex-wrap relative min-h-[10rem] min-w-[16rem] md:min-w-[18rem] items-center">
              <!-- Render the images if present -->
              <img v-if="weeklyImagesData && weeklyImagesData.length > 0 && weeklyImagesData[0].thumbnail_url"
                :src="weeklyImagesData[0].thumbnail_url" class="rounded-3xl absolute left-[0px] md:left-[0px]"
                style="z-index: 1; height: 125px !important;" width="125" />

              <img v-if="weeklyImagesData[1] && weeklyImagesData.length > 1 && weeklyImagesData[1].thumbnail_url"
                :src="weeklyImagesData[1].thumbnail_url" class="rounded-3xl absolute left-[25%] md:left-[85px]"
                style="z-index: 2; outline: 8px solid white; height: 125px !important;" width="125" />

              <img v-if="weeklyImagesData[2] && weeklyImagesData.length > 2 && weeklyImagesData[2].thumbnail_url"
                :src="weeklyImagesData[2].thumbnail_url" class="rounded-3xl absolute left-[50%] md:left-[170px]"
                style="z-index: 3; outline: 8px solid white; height: 125px !important;" width="125" />

              <!-- Semi-transparent black cover for the 3rd image -->
              <div v-if="weeklyImagesData.length > 3"
                class="rounded-3xl absolute bg-black bg-opacity-50 left-[50%] md:left-[170px]"
                style="z-index: 4; height: 125px; width: 125px;"></div>

              <!-- Image count on top of black cover  -->
              <div v-if="weeklyImagesData.length > 3" class="absolute z-10 text-white text-5xl"
                style="left: calc(180px + 70px - 50px); top: 57px;">
                +{{ weeklyImagesData.length - 3 }}
              </div>
            </div>

            <!-- Description -->
            <!-- <div class="text-slate-600">{{ weeklyImagesData[0] && weeklyImagesData[0].description }}</div> -->
          </div>

        </div>

      </div>

    </div>

  </div>


</template>

<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import moment from 'moment';
import LearningCard from '../components/educators/LearningCard.vue';
import CompletionModal from '../components/educators/CompletionModal.vue';
import LearningCardAttempted from '../components/educators/LearningCardAttempted.vue';
import { createResource } from 'frappe-ui'
import { cn } from '@/components/ui/utils.js';



    const weeklyImagesData = ref([]);
    const uniqueTags = ref([]);
    const learnerAndUserProfileDetails = ref([]);
    const dailyObservations = ref([]);
    const fetchedCalendarWidgetEvents = ref([]);
    const worksheetTitles = ref([])
    
    // Inject the $call method
    const $call = inject('$call');

    const scheduleContainer = ref(null);

    const scrollUp = () => {
      if (scheduleContainer.value) {
        scheduleContainer.value.scrollBy({
          top: -50, // Adjust the value for how much you want to scroll up
          behavior: 'smooth',
        });
      }
    };

    const scrollDown = () => {
      if (scheduleContainer.value) {
        scheduleContainer.value.scrollBy({
          top: 50, // Adjust the value for how much you want to scroll down
          behavior: 'smooth',
        });
      }
    };


    // funtion to fetch images for photo essays widget
    const fetchRecentImages = async () => {
      try {
        const response = await $call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.get_recent_images_for_parent_dashboard');
        weeklyImagesData.value = response;
        collectUniqueTags();
        console.log("Image data fetched in parent view", response);
      } catch (error) {
        console.error('Error fetching recent images:', error);
      }
    };

    // Function to collect unqiue tags for photo-essays widget
    const collectUniqueTags = () => {
      const tagsSet = new Set();
      for (let i = 0; i < 3; i++) {

        if (weeklyImagesData.value[i] && weeklyImagesData.value[i].tags.length > 0) {

          for (let tag of weeklyImagesData.value[i].tags) {

            if (!tagsSet.has(tag) && tagsSet.size < 3) {
              tagsSet.add(tag);
            }

          }

        }
      }
      uniqueTags.value = Array.from(tagsSet);
      console.log("These are unique tags -----------", uniqueTags.value)
    };

    // funtion to fetch user details
    const fetchProfileDetails = async () => {
      try {
        const response = await $call('curriculum_planner.curriculum_planner.common.user_details.api.fetch_learner_and_user__profile_details');
        learnerAndUserProfileDetails.value = response;

        console.log("This is response of learner and user profile", learnerAndUserProfileDetails.value)

      } catch (error) {
        console.error('Error fetching recent images:', error);
      }
    };

    // funtion to fetch worksheet titles for worksheets widget
    const fetchWorksheetTitles = async () => {
      try {
        const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet.api.fetch_worksheet_titles_for_parent_dashboard');
        worksheetTitles.value = response;

        console.log("This is response of fetching worksheet titles in parent dashboard", response)

      } catch (error) {
        console.error('Error fetching worksheet titles in parent dashboard:', error);
      }
    };

    

      // Funtion for Obervations widget
      const filteredObservations = computed(() => {
        if (dailyObservations.value.length === 1) {
          const learner = dailyObservations.value[0];
          return learner.observations.slice(0, 2);
        } else {
          return dailyObservations.value.map(learner => learner.observations[0]);
        }
      });

      // Funtion to fetch curriculum events data for widgets
      const fetchCalendarWidgetData = async (forDate) => {
        const startDate = moment(forDate).startOf('week').format("YYYY-MM-DD");
        const endDate = moment(forDate).endOf('week').format("YYYY-MM-DD");

        console.log("Fetching data between", startDate, "and", endDate);

        const response = await $call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_events_based_on_role', {
          start_date: startDate,
          end_date: endDate
        });
        fetchedCalendarWidgetEvents.value = response;
      };

      // Funtion to group events together
      const groupedEvents = computed(() => {
      if (fetchedCalendarWidgetEvents.value && fetchedCalendarWidgetEvents.value.enriched_events) {
       
        const parentCohorts = new Set(fetchedCalendarWidgetEvents.value.parents_cohorts || []);

        const startOfWeek = moment().startOf('week');
        const endOfWeek = moment().endOf('week');

        const grouped = fetchedCalendarWidgetEvents.value.enriched_events.reduce((acc, event) => {
          const date = event.start.split(" ")[0];
          if (!acc[date]) {
            acc[date] = {};
          }

          event.cohorts.forEach(cohort => {
            if (parentCohorts.has(cohort) || event.cohorts.length === 1) {
              if (!acc[date][cohort]) {
                acc[date][cohort] = {
                  Morning: [],
                  Afternoon: [],
                };
              }
              acc[date][cohort][event.session].push(event);
            }
          });

          return acc;
        }, {});

          // Ensure all weekdays (Monday to Friday) of the current week are included
          for (let day = startOfWeek; day.isBefore(endOfWeek); day.add(1, 'day')) {
            const dateString = day.format('YYYY-MM-DD');
           
            // Skip weekends
            if (day.isoWeekday() <= 5 && !grouped[dateString]) {  
              grouped[dateString] = {}; // Include empty weekday
            }
          }
          // Convert the grouped object to an array of key-value pairs
          const groupedArray = Object.entries(grouped);

          // Sort the array based on the date in ascending order
          groupedArray.sort(([date1], [date2]) => new Date(date1) - new Date(date2));

          // Convert the sorted array back to an object
          const sortedGrouped = Object.fromEntries(groupedArray);

          return sortedGrouped;
        }
        return {};
      });

 
      // For highlighting the dates in calendar widget
      const isToday = (date) => {
        return moment(date).isSame(moment(), 'day');
      };

      
      // Fetching quicklearning widget data here
      const quizListResource = createResource({
        url: '/api/method/curriculum_planner.curriculum_planner.doctype.facilitator_questionnaire.api.get_questionnaires',
        auto: true
      })
      
      const sortedQuizList = computed(() => {
        if (!quizListResource.data?.quiz_list) return []
        return quizListResource.data.quiz_list.toSorted((a, b) => {
          if (a.is_attempted && b.is_attempted) return 0
          else if (a.is_attempted) return 1
          else return -1
        })
      })

      const sortedAttemptQuiz = computed(() => {
        if (!quizListResource.data?.attempted_list) return []
        return quizListResource.data.attempted_list
      })

      const isNotEducator = computed(() => {
        if (quizListResource.data && quizListResource.data.status === 'fail')
          return true
        return false
      })

      const calculateAge = (dateOfBirth) => {
        const now = new Date()
        const dob = new Date(dateOfBirth)
        const yearsDiff = now.getFullYear() - dob.getFullYear()
        const monthsDiff = now.getMonth() - dob.getMonth()
        
        let years = yearsDiff
        let months = monthsDiff

        if (monthsDiff < 0 || (monthsDiff === 0 && now.getDate() < dob.getDate())) {
          years--
          months = 12 + monthsDiff
        }

        if (years === 0) {
          return `${months} month${months !== 1 ? 's' : ''}`
        } else if (months === 0) {
          return `${years} year${years !== 1 ? 's' : ''}`
        } else {
          return `${years} year${years !== 1 ? 's' : ''} ${months} month${months !== 1 ? 's' : ''}`
        }
      }

      const getLearnerNames = () => {
        const result = learnerAndUserProfileDetails.value.learners.map(learner => learner.name).join('-');
        return result;
      }

      const lastScore = ref(0)

      const setLastScore = (score) => {
        lastScore.value = score
      }

      // Add a watcher on quizListResource.data
      watch(
        () => quizListResource.data,
        (newData) => {
          console.log('Quiz List Response in parent dashboard=====================================', newData);
        }
      );


    onMounted(() => {
      fetchRecentImages();
      fetchProfileDetails();
      fetchWorksheetTitles();
      // fetchDailyObservations();
      fetchCalendarWidgetData(moment().format("YYYY-MM-DD")) // Date format should be YYYY-MM-DD
    });

  

</script>

<style scoped>

.learning-card-wrapper{
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.learning-card-wrapper ::v-deep .start-learning-btn {
  font-size: 11.5px;
  padding: 7px;
  background-color:rgba(253, 216, 53, 1);
  color: black;
  min-width: 100px;
  margin-bottom: auto;
  /* Add more custom styles as needed */
}
.learning-card-wrapper ::v-deep(.custom-title) {
  font-size: 14px;
  line-height: 23px;
}
.learning-card-wrapper ::v-deep(.custom-description-and-btn-container) {
  display: flex;
  flex-direction: row;
  flex-direction: row-reverse;
 
}
.learning-card-wrapper ::v-deep(.custom-description-container) {
  display: none; 
}

@media screen and (max-width: 769px) {
  
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}

</style>