<template>
  <div class="bg-[#FFFAE6] p-4 rounded-md">
    <div class="h-[50vh] w-full">
      <BarChartColour :scoreAmountProductArray="countScoreComp" :scoreAmountProductsNames="barChartDimNames"
        :mapText="barChartScoreText" :maxScore="roundUpToNearestFive(maxCount)" />
    </div>
    <div class="flex justify-end items-center p-8">
      Graph Observation threshold:
      <div class="relative flex items-center">
        <input type="number" class="peer py-0 pe-0 ps-2 block bg-transparent border-t-transparent 
          border-b-2 border-x-transparent border-b-gray-200 text-sm focus:border-t-transparent 
          focus:border-x-transparent focus:border-b-blue-500 focus:ring-0 disabled:opacity-50 
          disabled:pointer-events-none dark:border-b-neutral-700 dark:text-neutral-400 w-12
          dark:placeholder-neutral-500 dark:focus:ring-neutral-600 dark:focus:border-b-neutral-600"
          v-model="graphThreshold" min="0">
      </div>
    </div>
    <div class="flex justify-end gap-4 items-end" v-if="summary">
      <span class="text-sm font-semibold text-gray-600">Render Markdown as html</span>
      <input type="checkbox" id="hs-basic-usage" class="relative w-[3.25rem] h-7 p-px bg-gray-100
        border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out
        duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none
        checked:text-[#fdd835] checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800
        dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600
        before:inline-block before:size-6 before:bg-white checked:before:bg-white before:translate-x-0
        checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0
        before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400
        dark:checked:before:bg-blue-200" v-model="markDownToggle">
      <label for="hs-basic-usage" class="sr-only">switch</label>
    </div>
    <div v-if="props.prompt?.name">
      <p class="font-semibold">
        Prompt Name: 
      </p>
      <p>
        {{ props.prompt.prompt_name }}
        <span v-if="props.prompt?.is_default">(default prompt)</span>
      </p>
    </div>
    <div v-if="props.prompt?.name">
      <p class="font-semibold">
        Prompt: 
      </p>
      <p>{{ props.prompt?.prompt }}</p>
    </div>
    <div v-if="props.summary || props.isGenerating">
      <p class="font-semibold">
        Report: 
      </p>
      <p v-if="props.summary !== '' && !markDownToggle">
        {{ props.summary }}
      </p>
      <div v-if="props.summary !== '' && markDownToggle" 
        v-html="mdToHtml(props.summary)">
      </div>
      <p v-if="props.isGenerating">
        Generating report... Please wait...
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import BarChartColour from '../../../charts/BarChartColour.vue';
import { marked } from 'marked';

const props = defineProps([
  'prompt', 'isGenerating', 'summary',
  'competencyScores'
])

const barChartDimNames = ['count', 'engagement score', 'competency']
const maxCount = ref(5)
const graphThreshold = ref(0)

const countScoreComp = computed(() => {
  if (!props.competencyScores) return [];
  return props.competencyScores
    .filter((scoreObj) => scoreObj.count >= graphThreshold.value)
    .map((scoreObj) => { 
      if (scoreObj.count > maxCount.value) {
        maxCount.value = scoreObj.count;
      }
      return [scoreObj.count, scoreObj.average_engagement, scoreObj.competency]
    })
})

const barChartScoreText = ['Many Observations', 'Few Observations']

function roundUpToNearestFive(number) {
  return Math.ceil(number / 5) * 5;
}

const markDownToggle = ref(false)

const mdToHtml = (mdText) => {
  return marked(mdText)
}
</script>