<template>
  <div class="flex flex-wrap gap-2 md:gap-4 justify-between px-2">
    <ActivityIcon v-for="domain in fileteredDomains" :iconSrc="domain.icon" :domainName="domain.domain_name"
      :domainId="domain.id" />
  </div>
</template>

<script setup>
import ArtCraftIcon from '@/assets/activity-icons/art_craft.png'
import CriticalIcon from '@/assets/activity-icons/critical_thinking.png'
import FineMotorIcon from '@/assets/activity-icons/fine_motor_skills.png'
import LiteracyIcon from '@/assets/activity-icons/literacy.png'
import MovementIcon from '@/assets/activity-icons/movement.png'
import MusicIcon from '@/assets/activity-icons/music.png'
import NumeracyIcon from '@/assets/activity-icons/numeracy.png'
import SELIcon from '@/assets/activity-icons/SEL.png'
import SensorialIcon from '@/assets/activity-icons/sensorial.png'
import StemIcon from '@/assets/activity-icons/stem.png'
import WorldIcon from '@/assets/activity-icons/world_knowledge.png'
import ActivityIcon from './ActivityIcon.vue'
import { computed } from 'vue'

const props = defineProps(['domainList'])

const domains = [
  { name: 'Art & Craft', icon: ArtCraftIcon },
  { name: 'Critical Thinking', icon: CriticalIcon },
  { name: 'Fine Motor Skills', icon: FineMotorIcon },
  { name: 'Literacy', icon: LiteracyIcon },
  { name: 'Movement', icon: MovementIcon },
  { name: 'Music', icon: MusicIcon },
  { name: 'Numeracy', icon: NumeracyIcon },
  { name: 'SEL', icon: SELIcon },
  { name: 'Sensorial', icon: SensorialIcon },
  { name: 'STEM', icon: StemIcon },
  { name: 'World Knowledge', icon: WorldIcon },
]

const fileteredDomains = computed(() => {
  if (!props.domainList) 
    return []
  return props.domainList.map((domain) => {
    const matchingDomain = domains.find(d => d.name === domain.domain_name)
    if (!matchingDomain) 
      return null
    return {
      id: domain.name,
      domain_name: domain.domain_name,
      description: domain.description,
      icon: matchingDomain.icon
    }
  }).filter((domain) => domain !== null)
})

console.log(fileteredDomains.value)
</script>