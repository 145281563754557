<template>
  <div :class="cn(`flex flex-col bg-white border shadow-sm rounded-xl p-4 md:p-5`, 
    props.class)">
    <h3 class="text-lg font-bold text-gray-800">
      <slot name="title" />
    </h3>
    <p class="mt-1 text-xs font-medium uppercase text-gray-500">
      <slot name="subtitle" />
    </p>
    <slot />
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'

const props = defineProps(['class'])
</script>