<template>
  <div :class="cn('p-4 overflow-hidden flex-auto max-h-full flex flex-col gap-4 text-gray-800 dark:text-gray-400 font-normal text-base',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <!-- <div class="space-y-4" @keydown.stop>
      <h4 class="font-semibold">
        Link Activity
      </h4>
    </div> -->
    <div v-if="props.observation" class="bg-[#FFFAE6] p-2 rounded-md flex flex-col gap-2 text-xs sm:text-sm">
      <p class="font-medium text-sm sm:text-base">Observation Details:</p>
      <!-- <div class="flex flex-col md:flex-row md:gap-16">
        <div class="flex gap-2">
          <p>Date:</p>
          <p>{{ props.observation.date }}</p>
        </div>
      </div> -->
      <div class="flex gap-2">
        <p class="font-medium">Session:</p>
        <p>{{ props.observation.activity_time }}</p>
      </div>
      <div class="flex gap-2">
        <p class="font-medium">Cohorts:</p>
        <div class="flex flex-wrap gap-2">
          <div v-for="cohort in props.observation.cohorts"
            class="bg-[#FDD835] px-2 rounded" v-if="userDetails">
            <!-- {{ cohort.cohort_name }}
            {{ cohort.cohort_name }} -->
            {{ findValueByName(userDetails?.school_info.cohorts, cohort, 'cohort_name') }}
          </div>
        </div>
      </div>
      <!-- <div class="flex gap-2">
        <p>Educator:</p>
        <p v-if="userDetails">
          {{ findValueByName(userDetails?.school_info.educators, props.observation.educator_name, 'educator_name') }}
        </p>
      </div> -->
    </div>

    <div class="flex flex-col gap-2 overflow-y-auto">
    <!-- <div class="flex flex-col gap-2 max-h-[40vh] md:max-h-[45vh] overflow-y-auto"> -->
      <div class="space-y-1">
        <p class="font-medium text-sm md:text-base">
          Select activity to link to the observation:
        </p>
        <p class="text-gray-500 font-medium text-xs">
          Don't see an activity here? Check if the date is right?
        </p>
      </div>
      <div v-if="!props.events">
        No activities found with the same date as the observations.
      </div>
      <div v-for="event in filteredEvents"
        v-if="filteredEvents.length"
        :class="cn('border rounded-lg shadow-sm p-2 cursor-pointer text-xs sm:text-sm flex flex-col gap-2',
        selectedEvent === event.name && 'border-[#FDD835] border-2',
        props.observation.event?.name === event.name && 'border-[#FDD835] border-2 border-dashed')"
        @click="() => selectEvent(event.name)">
        <!-- {{ event }} -->
        <div class="flex gap-2">
          <p class="font-medium">Activity Title:</p>
          <p v-if="event.linked_activity_name">{{ event.linked_activity_name }}</p>
          <p v-else-if="event.activity_name">{{ event.activity_name }}</p>
          <p v-else>Unnamed Activity</p>
        </div>
        <div class="flex gap-2">
          <p class="font-medium">Date:</p>
          <p>{{ event.date }}</p>
        </div>
        <div class="flex gap-2">
          <p class="font-medium">Session:</p>
          <p>{{ event.session }}</p>
        </div>
        <div class="flex gap-2">
          <p class="font-medium">Cohorts:</p>
          <div class="flex flex-wrap gap-2">
            <div v-for="cohort in event.cohorts"
              class="bg-[#FDD835] px-2 rounded">
              {{ cohort.cohort_name }}
            </div>
          </div>
        </div>
        <div class="flex gap-2">
          <p class="font-medium">Led by:</p>
          <div class="flex flex-wrap gap-2">
            <div v-for="educator, index in event.led_by" >
              <!-- class="bg-[#FDD835] px-2 rounded"> -->
              <span v-if="index !== 0">, </span>
              {{ educator.educator_name }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="font-medium text-gray-600 pl-4">
        No activities found for the same session.
      </div>
    </div>

  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { inject, ref, computed } from 'vue';
import { findValueByName } from '@/lib/utils';

const props = defineProps([
  'pageNum', 'componentPageNum', 'observation',
  'events'
])

const filteredEvents = computed(() => {
  if (!props.events || !props.observation) return []
  return props.events.filter((event) => {
    return event.session.toLowerCase() === props.observation.activity_time.split(' ')[0].toLowerCase()
  })
})

const emit = defineEmits(['change:value'])

const selectedEvent = ref('')

const userDetails = inject('userDetails')

const selectEvent = (eventId) => {
  if (selectedEvent.value === eventId) {
    selectedEvent.value = ''
    emit('change:value','')
  } else {
    selectedEvent.value = eventId
    emit('change:value', eventId)
  }
}
</script>