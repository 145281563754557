<template>
  <div :class="cn('p-4 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== 1 && `hidden`)" >
    <div class="space-y-3">
      <label class="font-medium text-sm md:text-base">Your name:</label>
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none hidden" 
        id="activity-observ-form-edu-id" name="educator_id"
        :value="educator" readonly />
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        id="activity-observ-form-edu-name" name="educator_name" 
        :readonly="props.userData.is_educator" autocomplete="off"
        :value="findValueByName(props.userData?.school_info.educators, educator, 'educator_name')">
    </div>
    <div>
      <label class="font-medium text-sm md:text-base">
        Date of observation:
        <input type="date" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
          font-normal" @click="openDatePicker"
          name="date" v-model="dateValue" :max="formattedDate" 
          @change="emitDate">
      </label>
    </div>
    <div class="space-y-3">
      <p class="font-medium text-sm md:text-base">
        Time of activity:
      </p>
      <div class="flex gap-x-6 text-xs md:text-sm font-medium">
        <div class="flex gap-0">
          <label class="text-gray-700 ms-2 flex gap-4 items-center">
            <input type="radio" name="activity_time" class="shrink-0 mt-0.5 border-gray-200 rounded-full 
              text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-activity-session-group-1" value="Morning session" v-model="activityTime" >
            Morning Session
          </label>
        </div>

        <div class="flex">
          <label class="text-gray-700 ms-2 flex gap-4 items-center">
            <input type="radio" name="activity_time" class="shrink-0 mt-0.5 border-gray-200 rounded-full 
              text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-activity-session-group-2" value="Afternoon session" v-model="activityTime" >
            Afternoon Session
          </label>
        </div>

        <div class="flex">
          <label class="text-gray-700 ms-2 flex gap-4 items-center">
            <input type="radio" name="activity_time" class="shrink-0 mt-0.5 border-gray-200 rounded-full 
              text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-activity-session-group-3" value="Invitation to Play" v-model="activityTime" >
              Invitation to Play
          </label>
        </div>
      </div>
    </div>
    <div class="space-y-3">
      <p class="font-medium text-sm md:text-base">
        Activity conducted for:
      </p>
      <!-- below was a flex row of 2 flex columns originally - convert to grid if needed -->
      <div class="flex gap-x-10 md:gap-x-14 font-medium text-xs">
        <div class="grid grid-cols-2 sm:grid-cols-3 gap-y-2 gap-x-6" v-if="props.userData">
          <div v-for="(cohort, index) in filteredCohorts" class="flex">
            <label class="text-gray-700 ms-3 flex gap-4">
              <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600
                focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                :id="`hs-cohort-checkbox-group-${index}`"
                :value="cohort.name" name="cohorts" v-model="checkedCohorts">
              <!-- Bombils (2/3)s -->
              {{ cohort.cohort_name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { findValueByName } from '@/lib/utils'
import { computed, ref, watch } from 'vue';
import { openDatePicker } from '@/lib/utils';

const props = defineProps(['pageNum', 'userData', 'observation'])
const emit = defineEmits(['update:cohort'])

console.log(props.observation)

const cohortList = ['Bombils', 'Batatas-1', 'Batatas-2', 'Bimblis', 'BigKids']

const filteredCohorts = computed(() => {
  if (props.userData) {
    const filteredSortedArray = props.userData.school_info.cohorts.filter((cohort) => {
      return cohortList.includes(cohort.cohort_name)
    }).sort((cohortA, cohortB) => {
      return cohortList.indexOf(cohortA.cohort_name) - cohortList.indexOf(cohortB.cohort_name)
    })
    return filteredSortedArray
  }
  return []
})

const checkedCohorts = ref(props.observation.cohorts.map(cohort => {
  return cohort.name
}) || [])
const activityTime = ref(props.observation.activity_time || '')

watch(checkedCohorts, (newCohorts) =>{
  // console.log(newCohorts)
  emit('update:cohort', newCohorts)
})

// const isNameFilled = computed(() => {
//   if (props.userData.is_educator) {
//     return true
//   }
//   return false
// })

// const areAllInputsFilled = () => {
//   return isNameFilled.value && !!checkedCohorts.value && !!activityTime.value
// }
// defineExpose({areAllInputsFilled})

const today = new Date();
// Format today's date in YYYY-MM-DD format (required for input type date)
const formattedDate = today.toISOString().split('T')[0];

const educator = ref(props.observation?.educator_name)
console.log({educator})
const dateValue = ref(props.observation.date)

const emitDate = () => {
  emit('date:selected', dateValue.value)
}
</script>