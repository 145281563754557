<template>
  <div class="flex gap-2">
    <div class="h-8 w-8 bg-red-400 rounded-md flex justify-center items-center">
      {{ findValueByName(userDetails?.school_info.educators, props.comment.educator, 'educator_name')[0] }}
    </div>
    <div class="flex flex-col gap-1">
      <div class="text-xs font-semibold">
        {{ findValueByName(userDetails?.school_info.educators, props.comment.educator, 'educator_name') }}
      </div>
      <div class="text-gray-600 text-sm">
        {{ props.comment.comment }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { findValueByName, getFormattedDate } from '@/lib/utils'
import { computed, inject } from 'vue';

const props = defineProps(['comment'])
const userDetails = inject('userDetails')
</script>