<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-4" @keydown.stop>
      <label class="font-medium text-sm md:text-base">
        1. What recent achievements, interests, or challenges have you observed at home that you'd like to share with us?
      </label>
      <textarea id="hs-autoheight-textarea-1" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        rows="10" placeholder="Enter observations here..." name="observation" v-model="observationText"
        ref="observationInputRef" @input="textareaAutoHeight"></textarea>
    </div>
    <!-- <div>
      <AudioRecorder :userData="userDetails" observationType="daily" 
        @transcriptGenerated="appendAnecdotes" @transcriptStatus="handleTranscriptStatus" />
      <div v-if="transcriptStatusText"
        :class="cn(`text-sm font-semibold text-gray-600 p-3 text-center`)">
        {{ transcriptStatusText }}
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
// import SelectSearch from '../../../ui/SelectSearch.vue';
// import { createListResource } from 'frappe-ui'
import { ref, inject } from 'vue';
import { findValueByName } from '@/lib/utils.js'
import AudioRecorder from '../AudioRecorder.vue'

const props = defineProps([
  'pageNum', 'observation', 'componentPageNum'
])

const userDetails = inject('userDetails')

const observationText = ref(props.observation?.observation || '')

// const appendAnecdotes = (newText) => {
//   anecdotes.value = anecdotes.value + '\n\n' + newText
// }

const observationInputRef = ref(null)

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  observationInputRef.value.style.height = 'auto'
  let calculatedHeight = observationInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  observationInputRef.value.style.height = `${calculatedHeight}px`
}

// const transcriptStatusText = ref('')

// const handleTranscriptStatus = (statusObj) => {
//   if (statusObj.status === 'start') {
//     transcriptStatusText.value = "Generating transcript, please wait..."
//   } else if (statusObj.status === 'fail') {
//     transcriptStatusText.value = 'Transcript generation failed, please try again...'
//     setTimeout(() => {
//       transcriptStatusText.value = ''
//     }, 3500)
//   } else if (statusObj.status === 'success') {
//     transcriptStatusText.value = ''
//   }
// }
</script>