<template>
      
   <button @click="toggleWeekends" class="p-1 mb-2">{{ showWeekends ? 'Hide Weekends' : 'Show Weekends' }}</button>
   
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
 </template>
 
 <script>
 import FullCalendar from '@fullcalendar/vue3';
 import dayGridPlugin from '@fullcalendar/daygrid';
 import timeGridPlugin from '@fullcalendar/timegrid';
 
 export default {
   inject: ["$call"],
   components: {
     FullCalendar
   },
   props: {
     events: Array
   },
   data() {
     return {
       calendarOptions: {
         timeZone: 'UTC',
         slotDuration: '00:20:00',
         scrollTime:'08:00:00',
         eventBackgroundColor: '#FFA6A6', 
         eventBorderColor:'#FDD835',
         eventTextColor: '#000000',
         contentHeight: '60vh',
         weekends: false, // Set based on your requirement
         initialView: 'timeGridWeek',
         eventDidMount: function(info) {
                // Find the time element within the event and hide it
                const timeElement = info.el.querySelector('.fc-event-time');
                if (timeElement) {
                    timeElement.style.display = 'none';
                }
            },
         headerToolbar: {
                    left: 'prev,next,today',
                    center: 'title',
                    right: 'timeGridWeek,timeGridDay,dayGridMonth' // user can switch between the three
                },
         plugins: [dayGridPlugin, timeGridPlugin],
         events: this.events, // Bind events to FullCalendar
         editable: false, // No drag-and-drop
         eventStartEditable: false,
         eventDurationEditable: false,
         eventResizableFromStart: false,
       },
       showWeekends: false,
     };
   },
   mounted() {
     
   },
   methods: {
    toggleWeekends() {
        // Toggle the value of showWeekends
        this.showWeekends = !this.showWeekends;

        // Update the calendar's weekends option based on the state
        this.calendarOptions.weekends = this.showWeekends;

        // Update the calendar view
        const calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.render();
    },
   }
 };
 </script>
 