<template>
  <div class="flex flex-col h-[94vh] md:h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8 w-full">
        <h2 class="text-3xl">Activity Observation Links</h2>
        <div class="bg-[#FFFAE6] rounded-md p-4 ">
          <div></div>
          <button type="button" @click="linkAllObservations" 
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
            Link Unlinked Observations
          </button>
          <div v-if="isLinkingAll">
            Linking Unlinked Observations. Please wait...
          </div>
          <div v-if="autoLinkResource.data?.status === 'success'">
            Linked {{ linkCount }} observation documents.
          </div>
        </div>
        <div v-if="observationResource.data?.status === 'success'" class="flex flex-col gap-4">
          <div v-for="observation, index in observationResource.data.observations"
            class="flex gap-4">
            <div class="w-10">{{ index + 1 }}. </div>
            <ActivityObserViewCard :observation="observation" class="basis-96" modalName="linkObservViewModal"
              :selectObservation="selectObservation" />
            <EventLinkCard class="basis-96" :observation="observation" :openModalFn="openLinkModal"
              :event="observation.event_link ? observation.event : null" />
          </div>
          <EventLinkModal modalName="eventLinkModal" :observation="selectedObservation" 
            :events="observationResource.data.curriculum_events" :linkActivityFn="linkActivity" />
        </div>
      </div>
    </div>
    <ActivityObservEditModal modalName="linkObservViewModal" :observation="selectedObservation" 
      :version="2" :selectedSignal="observationSignal" />
  </div>
</template>

<script setup>
import { createListResource, createResource } from 'frappe-ui';
import { computed, inject, provide, ref, watch, readonly } from 'vue';
import ActivityObserViewCard from '@/components/observations/view/ActivityObservViewCard.vue'
import EventLinkCard from '../components/observations/EventLinkCard.vue';
import EventLinkModal from '../components/observations/EventLinkModal.vue';
import { HSOverlay } from 'preline/preline';
import ActivityObservEditModal from '../components/observations/edit/Activity/ActivityObservEditModal.vue';


// line 52 to 65 - workaround to fix observationCompetencies not found error in ActivityObservEditModal
// should find a better way to provied competencylist later
const competencies = ref(null)
provide('observationCompetencies', readonly(competencies))
const competencyResource = createListResource({
  doctype: 'Competency',
  fields: ['name', 'competency_name', 'description'],
  orderBy: 'competency_name asc',
  auto: true,
})

watch(() => competencyResource.data, () => {
  if (competencyResource.data)
    competencies.value = competencyResource.data
})


const observationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.link.api.get_activity_observ_events',
 auto: true
})

const linkResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.link.api.link_activity',
})

const autoLinkResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.link.api.link_unlinked_observations',
})

console.log(observationResource)

const selectedObservation = ref(null)

const openLinkModal = (observation) => {
  selectedObservation.value = observation
  HSOverlay.open('#hs-eventLinkModal')
}

const linkActivity = async (observation_id, event_id) => {
  try {
    await linkResource.submit({
      observation_id,
      event_id,
    })
    console.log(linkResource)
    if (linkResource.data.status === 'success') {
      HSOverlay.close('#hs-eventLinkModal')
      observationResource.reload()
    } else {
      console.error('Linking failed')
    }
  } catch (error) {
    console.log(`Error making link request: ${error}`)
  }
}

const isLinkingAll = ref(false)
const linkCount = ref(0)

const linkAllObservations = async () => {
  isLinkingAll.value = true
  try {
    await autoLinkResource.submit()
    console.log(autoLinkResource)
    if (autoLinkResource.data.status === 'success') {
      linkCount.value = autoLinkResource.data.link_count
      observationResource.reload()
    } else {
      console.error('Linking failed')
    }
  } catch (error) {
    console.log(`Error making link request: ${error}`)
  }
  isLinkingAll.value = false
}

const observationSignal = ref(false)
const selectObservation = (observation) => {
  selectedObservation.value = observation
  observationSignal.value = !observationSignal.value
}
</script>