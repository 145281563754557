<template>
  <div class="flex flex-col max-h-[94vh] md:max-h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <h2 class="text-3xl">Observation Statistics</h2>
    <div class="flex flex-col lg:flex-row gap-4">
      <div class="flex-auto basis-1/2">
        <h3>Weekly Statistics</h3>
        <WeeklyStats v-if="weekDataArray.length && filteredCohorts.length"
          :weeklyStats="weekDataArray" :cohorts="filteredCohorts" :currentWeek="currentWeek"
          :goToPrevWeek="goToPrevWeek" :goToNextWeek="goToNextWeek" />
      </div>
      <div class="flex-auto basis-1/2">
        <h3>Monthly Statistics</h3>
        <MonthlyStats v-if="monthDataArray.length && filteredCohorts.length"
          :monthlyStats="monthDataArray" :cohorts="filteredCohorts" :currentMonth="currentMonth"
          :goToNextMonth="goToNextMonth" :goToPrevMonth="goToPrevMonth" />
      </div>
    </div>
    <div class="bg-[#FEF3C2] rounded p-4 flex flex-col gap-4" v-if="weekDataArray.length">
      Students without observations For the week
      <div class="flex flex-col md:flex-row gap-4 justify-between">
        <div v-for="cohort in filteredCohorts.slice(1,)">
          <h3 class="font-semibold">
            {{ cohort.cohort_name }}
          </h3>
          <div v-for="student in weekDataArray[currentWeek].count_list">
            <router-link :to="`/front-portal/observations/view/child/all?student=${student.student_id}`"
              class="cursor-pointer" v-if="(student.cohort === cohort.name) && (student.count === 0)">
              <p>
                {{ student.student_name }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { createResource } from 'frappe-ui';
import WeeklyStats from './WeeklyStats.vue';
import MonthlyStats from './MonthlyStats.vue';
import { inject, computed, ref, onMounted } from 'vue';
import { 
  getTodayDateJSFormat, goBackTwoDays, 
  getDateFromPreviousMonth, atLeastTwoMondaysAndFridaysPassed
} from '@/lib/utils'

const today = getTodayDateJSFormat()
const hasTwoWeeksPast = atLeastTwoMondaysAndFridaysPassed(today)

const weeklyResource = createResource({
  'url': 'curriculum_planner.curriculum_planner.common.observations.stats.count.api.get_weekly_stats',
  params: {
    date: today,
  },
  // auto: true,
})

const monthlyResource = createResource({
  'url': 'curriculum_planner.curriculum_planner.common.observations.stats.count.api.get_monthly_stats',
  params: {
    date: today,
  },
  // auto: true,
})

// console.log(weeklyResource)

const weekDataArray = ref([])
const monthDataArray = ref([])

onMounted(async () => {
  await weeklyResource.fetch()
  await monthlyResource.fetch()

  if (weeklyResource.data?.status === 'success')
    weekDataArray.value = weeklyResource.data.week_list
  if (monthlyResource.data?.status === 'success')
    monthDataArray.value = monthlyResource.data.month_list
})

const currentWeek = ref(1)
const currentMonth = ref(hasTwoWeeksPast ? 0 : 1)

const goToPrevWeek = async () => {
  if (!weekDataArray.value[currentWeek.value+2]) {
    const endWeek = weekDataArray.value[currentWeek.value+1]
    const nextDate = goBackTwoDays(endWeek.start_date)
    await weeklyResource.submit({ date: nextDate})
    if (weeklyResource.data?.status === 'success') {
      weeklyResource.data.week_list.forEach((weekData) => {
        weekDataArray.value.push(weekData)
      })
    }
  }
  currentWeek.value++
}

const goToNextWeek = () => {
  currentWeek.value--
}

const goToPrevMonth = async () => {
  if (!monthDataArray.value[currentMonth.value+2]) {
    const endMonth = monthDataArray.value[currentMonth.value+1]
    const startDate = endMonth.start_date
    const nextDate = getDateFromPreviousMonth(startDate)
    await monthlyResource.submit({ date: nextDate})
    if (monthlyResource.data?.status === 'success') {
      monthlyResource.data.month_list.forEach((monthData) => {
        monthDataArray.value.push(monthData)
      })
    }
  }
  currentMonth.value++
}

const goToNextMonth = () => {
  currentMonth.value--
}

const userDetails = inject('userDetails')

const cohortList = ['Bombils', 'Batatas-1', 'Batatas-2', 'Bimblis', 'BigKids']

const filteredCohorts = computed(() => {
  if (userDetails.value?.school_info.cohorts) {
    const filteredSortedArray = userDetails.value.school_info.cohorts.filter((cohort) => {
      return cohortList.includes(cohort.cohort_name)
    }).sort((cohortA, cohortB) => {
      return cohortList.indexOf(cohortA.cohort_name) - cohortList.indexOf(cohortB.cohort_name)
    })
    filteredSortedArray.unshift({ name: "all", cohort_name: "All Students" })
    return filteredSortedArray
  }
  return []
})
</script>