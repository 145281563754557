<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full px-4
        max-h-[80vh] overflow-y-auto gap-4">
        <div class="flex flex-col gap-2">
          <div class="flex justify-between items-center pt-4">
            <h3 class="font-bold text-xl md:text-xl mt-6">
              {{ props.title }}
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
          <p :class="cn(`text-sm text-gray-500`, pageNum !== 1 && 'hidden')">
            This should take under 3 minutes and is designed to get you thinking about {{ props.title.toLowerCase() }}. 
            What that means and does not mean and how one might use in daily practice. 
            <span class="font-semibold">Assume that all scenarios involve a class of 5-7 year olds</span>
          </p>
        </div>
        <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col gap-4" ref="formRef">
          <div v-if="questionsResource.data">
            <ScenarioQuestion v-for="(question, index) in questionsResource.data.questions" 
              :questionData="question" :qNum="index + 1" :key="question.name" :pageNum="pageNum"
              :formStatus="formStatus" />
          </div>
          <div class="flex flex-col md:flex-row gap-4 md:items-center md:justify-between">
            <FormPageIndicator :pageNum="pageNum" :totalPages="totalPages" />
            <div :class="cn(`flex justify-center md:justify-end items-center gap-4 py-3 px-4 basis-[5.5rem]`,
              pageQuestionStatus && 'flex-wrap')">
              <div class="flex gap-4">
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                  rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                  disabled:opacity-50 disabled:pointer-events-none`)"
                  @click="goToPrevPage">
                  Back
                </button>
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                  rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                  disabled:opacity-50 disabled:pointer-events-none`, pageNum === totalPages && 'hidden',
                  !pageQuestionStatus && 'hidden')"
                  @click="goToNextPage" :disabled="(pageNum === totalPages)">
                  Next
                </button>
              </div>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, pageQuestionStatus && 'hidden')" 
                @click="checkAnswer" :disabled="pageQuestionStatus">
                Check Answer
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, !pageQuestionStatus && 'hidden')" 
                @click="tryAgain" :disabled="!pageQuestionStatus">
                Try Again
              </button>
              <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, pageNum !== totalPages && 'hidden',
                !pageQuestionStatus && 'hidden')" 
                :disabled="(pageNum !== totalPages)||(!pageQuestionStatus)">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline'
import { createResource } from 'frappe-ui'
import ScenarioQuestion from './ScenarioQuestion.vue'
import FormPageIndicator from '@/components/ui/FormPageIndicator.vue'


const props = defineProps(['modalName', "qID", "title", "updateScore"])

const pageNum = ref(1)
const formRef = ref(null)
const isDataLoaded = ref(false)

const formStatus = ref({})

const questionsResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.facilitator_questionnaire.api.get_questions',
})

const totalPages = computed(() => {
  if (!questionsResource.data) return 0
  if (!questionsResource.data.status === 'fail') return 0
  return questionsResource.data.questions.length
})

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)

  questionsResource.submit({ q_id: props.qID }).then(res => {
    if (res.status === 'success') {
      isDataLoaded.value = true
    }
  })
});

watch(isDataLoaded, (newLoadedState) => {
  if (newLoadedState === true) {
    formStatus.value = questionsResource.data.questions.reduce((acc, val) => {
      acc[val.name] = false
      acc[`num-${val.name}`] = 0
      return acc
    }, {})
  }
})

const goToNextPage = () => {
  if (pageNum.value >= totalPages.value) return
  pageNum.value++
}

const goToPrevPage = () => {
  if (pageNum.value === 1) {
    HSOverlay.close(`#hs-${props.modalName}`)
    return
  }
  if (pageNum.value < 1) return
  pageNum.value--
}

// to check whether the question has been answered
const pageQuestionStatus = ref(false)
watch(pageNum, (newPageNum) => {
  const ques_id = questionsResource.data.questions[newPageNum - 1].name
  pageQuestionStatus.value = formStatus.value[ques_id]
})

const checkAnswer = () => {
  const ques_id = questionsResource.data.questions[pageNum.value - 1].name
  const formData = new FormData(formRef.value)

  const selectValues = []
  formData.forEach((value, key) => {
    if (key === ques_id) {
      selectValues.push(value)
    }
  })

  formStatus.value[`num-${ques_id}`] += 1
  formStatus.value[ques_id] = true
  pageQuestionStatus.value = true
  formStatus.value[`select-${ques_id}`] = selectValues
  console.log(formStatus)
}

const tryAgain = () => {
  const ques_id = questionsResource.data.questions[pageNum.value - 1].name
  if (formStatus.value[`num-${ques_id}`] === 3) return

  formStatus.value[ques_id] = false
  pageQuestionStatus.value = false
}

const resultsResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.facilitator_learning_answers.api.record_assessment',
})

const handleSubmit = async (e) => {
  console.log(formStatus)
  const apiData = {}
  apiData.answers = {}
  apiData.attempts = {}
  for (const key in formStatus.value) {
    if (key.startsWith(`select-`)) {
      const id = key.substring(7)

      apiData.answers[id] = formStatus.value[key]
    } else if (key.startsWith('num-')) {
      const id = key.substring(4)
      apiData.attempts[id] = formStatus.value[key]
    }
  }
  apiData.questionnaire = props.qID
  await resultsResource.submit({
    assessment_data: apiData
  })

  console.log(resultsResource)
  if (resultsResource.data.status === 'success') {
    HSOverlay.close(`#hs-${props.modalName}`)
    pageNum.value = 1
    props.updateScore(resultsResource.data.assessment_score)
    HSOverlay.open('#hs-assess-confirm-modal')
  } else if (resultsResource.data.status === 'fail') {
    alert(`Form Submission Failed.\n${resultsResource.data.message}`)
  }
}
</script>