<template>
  <div class="w-[20rem]">
    <div class="border-b border-gray-200 dark:border-neutral-700">
      <nav class="flex space-x-1" aria-label="Tabs" role="tablist">
        <button type="button" class="hs-tab-active:font-semibold hs-tab-active:border-blue-600 
          hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent 
          text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 
          disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500 
          active" id="sandbox-options-tab-item-1" data-hs-tab="#sandbox-options-tab-1" 
          aria-controls="sandbox-options-tab-1" role="tab">
          Options
        </button>
        <!-- <button type="button" class="hs-tab-active:font-semibold hs-tab-active:border-blue-600 
          hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent 
          text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 
          disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500" 
          id="summary-options-tab-item-2" data-hs-tab="#summary-options-tab-2" 
          aria-controls="summary-options-tab-2" role="tab">
          Prompts
        </button> -->
      </nav>
    </div>

    <div class="mt-3">
      <div id="sandbox-options-tab-1" role="tabpanel" aria-labelledby="sandbox-options-tab-item-1">
        <form @submit.prevent="() => {}" ref="fromref" class="flex flex-col gap-4">
          <div>
            <div>Generation Type:(disabled)</div>
            <div class="flex flex-col md:flex-row gap-4">
              <div class="flex">
                <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4 cursor-pointer">
                  <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200
                    rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50
                    disabled:pointer-events-none" id="hs-radio-un-about-group-1" value="text"
                    v-model="pickedType" disabled>
                    Text
                </label>
              </div>
              <div class="flex">
                <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4 cursor-pointer">
                  <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200
                    rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    id="hs-radio-un-about-group-2" value="image"
                    v-model="pickedType" disabled>
                    Image
                </label>
              </div>
            </div>
          </div>
          <div v-if="pickedType === 'image'">
            <div>Image Resolution:</div>
            <div class="flex flex-col md:flex-row gap-4">
              <div class="flex">
                <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4 cursor-pointer">
                  <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200
                    rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50
                    disabled:pointer-events-none" value="square"
                    v-model="resolution">
                    1024x1024
                </label>
              </div>
              <div class="flex">
                <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4 cursor-pointer">
                  <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200
                    rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    value="portrait"
                    v-model="resolution">
                    1024x1792
                </label>
              </div>
              <div class="flex">
                <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4 cursor-pointer">
                  <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200
                    rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    value="landscape"
                    v-model="resolution">
                    1792x1024
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- <div id="summary-options-tab-2" class="hidden" role="tabpanel" aria-labelledby="summary-options-tab-item-2">
        <p class="text-gray-500 dark:text-neutral-400">
          This is the <em class="font-semibold text-gray-800 dark:text-neutral-200">second</em> item's tab body.
          <button type="button" @click="props.savePromptFn">Save Prompt</button>
          <div v-if="promptResource.data?.status === 'success'" 
            class="flex flex-col gap-2">
            <div v-for="prompt in promptResource.data.prompts"
              class="border rounded p-2">
              <h5>{{ prompt.prompt_name }}</h5>
              <p>{{ prompt.prompt }}</p>
              <button @click="() => changePrompt(prompt.prompt)">
                Use this prompt
              </button>
            </div>
          </div>
        </p>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { createResource } from 'frappe-ui'

const props = defineProps([
  'changeType', 'changeResolution'
  // 'savePromptFn', 'changePrompt'
])

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const pickedType = ref('image');
const resolution = ref('square')

watch(pickedType, (newType) => {
    props.changeType(newType)
})

watch(resolution, (newRes) => {
    props.changeResolution(newRes)
})

// const promptResource = createResource({
//   url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompt.api.get_all_prompts',
//   auto: true
// })
</script>