<template>
  <div class="flex flex-col gap-4">
    <StudentModal userType="Student" modalName="student-modal" :reloadFn="() => {}" 
      :editMode="modalEditMode" :selectedStudent="selectedStudent">
      <template #trigger>
        <button @click="openNewStudentModal" data-hs-overlay="#hs-student-modal" class="py-3 px-4 inline-flex items-center gap-x-2 
          text-sm font-semibold rounded-lg border border-transparent bg-[#FDD835] text-white 
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48">
          Add New Student
        </button>
      </template>
    </StudentModal>
    <div :class="cn(`flex flex-col md:flex-row md:flex-wrap gap-4`)">
      <Card v-for="student in props.studentList" class="md:max-w-96 md:w-96 hover:bg-gray-200 cursor-pointer" 
        :key="student.name" @click="() => { editStudent(student) }">
        <p class="font-semibold text-lg">
          {{ student.student_name }}
        </p>
        <p>{{ findValueByName(userDetails?.school_info.cohorts || [], student.cohort, 'cohort_name') }}</p>
      </Card>
    </div>
  </div>
</template>

<script setup>
import { findValueByName } from '@/lib/utils'
import { cn } from '@/components/ui/utils'
import { inject, ref } from 'vue';
import Card from '@/components/ui/Card.vue'
import StudentModal from '@/components/userManagement/StudentModal.vue'
import { HSOverlay } from 'preline/preline';

const props = defineProps(['studentList'])
const userDetails = inject('userDetails')

const modalEditMode = ref(false)
const selectedStudent = ref(null)

const editStudent = (student) => {
  modalEditMode.value = true
  selectedStudent.value = (student)
  console.log({student})
  HSOverlay.open('#hs-student-modal')
}

const openNewStudentModal = () => {
  modalEditMode.value = false;
  selectedStudent.value = null;
};

</script>