<template>
    <div class="mt-6 ml-4">


      <!-- Rendering breadcrumb -->
      <template v-for="(item, index) in breadcrumb" :key="index">
          <span class="font-medium" :style="index === breadcrumb.length - 1 ? { color: '#EAB308' } : {}">
            {{ formatBreadcrumbValue(item) }}
          </span>
          <span v-if="index < breadcrumb.length - 1"> / </span>
      </template>
    </div>

    <template v-if="clickedLearningSequenceDetails && showLearningSequenceDetailsModal">
      <LearningSequenceDetailsModal :clickedLearningSequenceDetails="clickedLearningSequenceDetails" :selectedNodesDetailed="selectedNodesDetailed" @close="showLearningSequenceDetailsModal= false"></LearningSequenceDetailsModal>
    </template>
    
      <VueFlow :nodes="nodes" :edges="edges" :nodeTypes="nodeTypes" :pan-on-scroll="true" 
        :zoom-on-scroll="false" 
        :pan-on-scroll-speed="0.5" 
        :pan-on-scroll-mode="'Free'"
        :pinch-to-zoom="true" @node-click="handleNodeClick"
        @navigate="handleNavigate">
      
        <Controls>
          <ControlButton @click="zoomIn">
            <i class="fa fa-plus"></i> <!-- Zoom In Icon -->
          </ControlButton>
          <ControlButton @click="zoomOut">
            <i class="fa fa-minus"></i> <!-- Zoom Out Icon -->
          </ControlButton>
          <ControlButton @click="fitView">
            <i class="fa fa-arrows-alt"></i> <!-- Fit View Icon -->
          </ControlButton>
        </Controls>

      </VueFlow>
            
    </template>
    
    
    <script setup>
    import { ref,onMounted, inject,computed, onUnmounted } from 'vue'
    import { VueFlow, Position } from '@vue-flow/core'
    
    import {useVueFlow, MarkerType, PanOnScrollMode } from '@vue-flow/core'
    const { setCenter, setViewport, project, getViewport } = useVueFlow();
    import { useRoute, useRouter } from 'vue-router';
    import ActivitiesIconUnselected from '@/assets/activities_unselected.png';
    import quick_learnings_unselected from '@/assets/quick_learnings_unselected.png'; 
    
    import CustomButtonGroupNode from '../components/curriculum_landscape/CustomButtonGroupNode.vue';
    import CustomEdgeLabel from '../components/curriculum_landscape/CustomEdgeLabel.vue';
    import LearningSequenceDetailsModal from '../components/curriculum_landscape/LearningSequenceDetailsModal.vue';

    import { Controls, ControlButton } from '@vue-flow/controls';
    import '@vue-flow/core/dist/style.css';
    import '@vue-flow/controls/dist/style.css';

    const nodeTypes = {
      // ... other node types
      buttonGroup: CustomButtonGroupNode,
      edgeLabel: CustomEdgeLabel
    };
        
    // Inject the $call method
    const $call = inject('$call');
    
    const handleNavigate = (data) => {
      console.log('Navigate event received in parent with data:',data);
      // Handle the navigate event here
    };
    // these are our edges
    const edges = ref([])
    
    const domainData = ref([]); 
    const competencyData = ref([]);
    const devStagesData = ref([]);
    const focusAreaData = ref([]);
    const learningSequenceData = ref([]);
    
    const breadcrumb = ref([]);
    const selectedNodes = ref([]);
    // New valribale to hold value of lables or title field too, without changing the previous list and related code
    const selectedNodesDetailed = ref([]);

    
    const showNavigationPopup = ref(false);
    const mouseX = ref(0);
    const mouseY = ref(0);

    const updateMousePosition = (event) => {
      // mouseX.value = event.clientX;
      // mouseY.value = event.clientY;
    };
    
    const expandedNodes = ref(new Set());

    const clickedLearningSequenceDetails = ref([])
    const showLearningSequenceDetailsModal = ref(false); 

    
    // these are our nodes
    const nodes = ref([
      // an input node, specified by using `type: 'input'`
      { 
        id: '1',
        type: 'input', 
        position: { x: 115, y: 300 },
        // all nodes can have a data object containing any data you want to pass to the node
        // a label property can be used for default nodes
        data: { label: 'Curriculum Landscape',type:"curriculumLandscape" ,doc_id:'1' },
        sourcePosition: Position.Right,  // Position for the source handle
        targetPosition: Position.Left, 
      },
    
    ])
    
    onMounted(async () => {
      if(route.query.domain){
        await extractQueryParamsAndExpandNodes();
      }
      window.addEventListener('click', updateMousePosition);
    })  

    
    
  // let nodeCounter = 2 // Start from 5 since we already have nodes 1-4
    
    
  const handleNodeClick = async (event) => {
      
    if(event.node.type === "edgeLabel"){  console.log("egde label clicked")} //Ignoe the click for edge label type node
    
    else if(event.node.type === "buttonGroup"){
      console.log("Navigate popup clicked")
      navigateToLandscapeTable()
    } // Click will be handle by emmit listener 
    else { 
      const clickedNode = event.node
      // Center the clicked node
      setCenter(clickedNode.position.x+165, clickedNode.position.y+50, { duration: 800, zoom: 1 });
      
      // Find the parent edge of the clicked node
      const parentEdge = edges.value.find(edge => edge.target === clickedNode.id);
      
      if (parentEdge) {
        // Find all sibling nodes (nodes with the same parent)
        const siblingNodes = nodes.value.filter(node => 
          edges.value.some(edge => edge.source === parentEdge.source && edge.target === node.id && edge.target !== clickedNode.id)
        );
        
        const getAllDescendants = (nodeId) => {
          let descendants = [];
          const childEdges = edges.value.filter(edge => edge.source === nodeId);
          for (let edge of childEdges) {
            descendants.push(edge.target);
            descendants = descendants.concat(getAllDescendants(edge.target));
          }
          return descendants;
        };
    
        const allDescendantsOfSiblings = siblingNodes.flatMap(node => getAllDescendants(node.id));
        
        nodes.value = nodes.value.filter(node => !allDescendantsOfSiblings.includes(node.id));
        
        edges.value = edges.value.filter(edge => 
          !allDescendantsOfSiblings.includes(edge.source) && !allDescendantsOfSiblings.includes(edge.target)
        );
    
        // Change color of sibling nodes to grey
        nodes.value = nodes.value.map(node => {
          if (siblingNodes.includes(node)) {
            return {
              ...node,
              style: {
                ...node.style,
                backgroundColor: '#ffffff', // Change to white
                color: '#000000' // Change text color to black
              }
            };
          }
          return node;
        });
      }
        
      const clickedNodeId = event.node.id;
      console.log("this is clicked node data",event.node.data)
        
    
      // If the clicked node is already expanded
      if (Object.values(selectedNodes.value).includes(event.node.data.doc_id)) {
        console.log("selectedNodes has clicked Node Id, so proceeding to remove it")
        
        const test = Object.values(selectedNodes.value).includes(event.node.data.doc_id)
        console.log ("This is test var",test)
        console.log("this is clicked node id",event.node.data.doc_id)
        
        console.log("this is selected nodes list",{...selectedNodes.value}) 

        

        // Node is already expanded, so collapse it
        collapseNode(clickedNodeId,event.node.data);

        // Selected nodes for passing data to table view page
        removeFromSelectedNodes(event.node.data.type, event.node.data);
         
       
        // Change the color of the clicked node
          nodes.value = nodes.value.map(node => {
            if (node.id === clickedNodeId) {
              return {
                ...node,
                style: {
                  ...node.style,
                  backgroundColor: '#ffffff', // Change to comini-yellow
                  color: '#000000' // Change text color to white for visibility
                }
              };
            }
            return node;
          });
    
          // Remove the unselected route from breadcrumb
          const index = breadcrumb.value.indexOf(event.node.data.type);
          if(event.node.id == 1){
            breadcrumb.value = [];
          }
          else if (index !== -1) {
            breadcrumb.value = breadcrumb.value.slice(0, index+1);
          }
          // console.log("This is the value of bread crumb after clicking the node:",breadcrumb.value)
          
         
          
        } else {
        console.log("On click id not found in existing list, so Else block triggered calling expandNodes() with click/event.node data")
        // Node is not expanded, so expand it
        await expandNode(event.node);
        expandedNodes.value.add(clickedNodeId);
       
        // Change the color of the clicked node
          nodes.value = nodes.value.map(node => {
            if (node.id === clickedNodeId) {
              return {
                ...node,
                style: {
                  ...node.style,
                  backgroundColor: '#FDD835', // Change to black
                  color: '#000000' // Change text color to white for visibility
                }
              };
            }
            return node;
          });
          
      }
    }   
     
   }
    
    
  const collapseNode = (nodeId,data) => {
      var edgeNodeToRemove;
      console.log("This is the data type recieved in collapse node",data)
      // Remove all descendant nodes and edges
      nodes.value = nodes.value.filter(node => !isDescendant(nodeId, node.id));
      edges.value = edges.value.filter(edge => !isDescendant(nodeId, edge.target));

      const hierarchyOrder = ["curriculumLandscape", "domain","competency", "devStage", "focusArea", "learningSequence" ];

      const edgeLabels = ["domain_edge_label","competency_edge_label","devStage_edge_label","focus_areas_edge_label","learning_sequences_edge_label", "popup_button_group"];

        const startIndex = hierarchyOrder.indexOf(data.type);
        if (startIndex !== -1) {
          const labelsToRemove = edgeLabels.slice(startIndex);
          nodes.value = nodes.value.filter(node => !labelsToRemove.includes(node.id));
        }
    }
    
  const isDescendant = (parentId, childId) => {
      if (parentId === childId) return false;
      let currentNode = edges.value.find(edge => edge.target === childId);
      while (currentNode) {
        if (currentNode.source === parentId) return true;
        currentNode = edges.value.find(edge => edge.target === currentNode.source);
      }
      return false;
    }
    
  const expandNode = async (node) => {

    let nodeCounter = 2
      const newNodes = []
      const newEdges = []
    
      if (node.id === '1') {
        // Handle click on the root node (Domains)
        await fetchDomains()
        
        domainData.value.forEach((domain, index) => {
          const newNode = {
            id: `domain_${nodeCounter}`,
            position: { 
              x: node.position.x + 250, 
              y: node.position.y - 150 + index * 100
            },
            data: { label: domain.domain_name, type: 'domain',doc_id:domain.domain_id },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
          }
          newNodes.push(newNode)
          newEdges.push({
            id: `e1->domain_${nodeCounter}`,
            source: '1',
            target: `domain_${nodeCounter}`,
            type: 'smoothstep',
            // label: `Label for edge 1`,
          })
          nodeCounter++
        })

        const labelNode = {
            id: `domain_edge_label`,
            type: 'edgeLabel',
            position: { x: node.position.x, y: node.position.y },
            data: { label: 'Domains' },
          };

         //To push the Edge Label node
         newNodes.push(labelNode);
   
        // Add non duplicate value to breadcrumb
        addToBreadcrumb('domain');

        // Add the clicked node to selectedNodes
        addToSelectedNodes(node.data.type, node.data.doc_id,node.data.label);
    
      } else if (node.data.type === 'domain') {
        // Handle click on a domain node
        await fetchCompetencies(node.data.doc_id) // Pass the selected domain id
        
        competencyData.value.forEach((competency, index) => {
          const newNode = {
            id: `competency_${nodeCounter}`,
            position: { 
              x: node.position.x + 240, 
              y: node.position.y - 100 + index * 70
            },
            data: { label: competency.subdomain_name, type: 'competency', doc_id:competency.subdomain_id },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
          }
          newNodes.push(newNode)
          newEdges.push({
            id: `e${node.id}->competency_${nodeCounter}`,
            source: node.id,
            target: `competency_${nodeCounter}`,
            type: 'smoothstep',
            
          })
          nodeCounter++
        })

        const labelNode = {
            id: `competency_edge_label`,
            type: 'edgeLabel',
            position: { x: node.position.x+200, y: node.position.y },
            data: { label: 'Competencies' },
          };

         //To push the Edge Label node
         newNodes.push(labelNode);

        // Add the clicked node to selectedNodes
        addToSelectedNodes(node.data.type, node.data.doc_id,node.data.label);
        addToBreadcrumb('competency')
      }
      else if (node.data.type === 'competency') {
        // Handle click on a competency nodes
        await fetchDevelopmentStages(node.data.doc_id) //pass the selected subdomain id
        
        devStagesData.value.forEach((devStage, index) => {
          const newNode = {
            id: `devStage_${nodeCounter}`,
            position: { 
              x: node.position.x + 250, 
              y: node.position.y - 100 + index * 70
            },
            data: { label: devStage.dev_stage_name, type: 'devStage', doc_id: devStage.dev_stage_id },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
          }
          newNodes.push(newNode)
          newEdges.push({
            id: `e${node.id}->devStage_${nodeCounter}`,
            source: node.id,
            target: `devStage_${nodeCounter}`,
            type: 'smoothstep',
          })
         
          nodeCounter++
        })
       
        const labelNode = {
            id: `devStage_edge_label`,
            type: 'edgeLabel',
            position: { x: node.position.x+200, y: node.position.y },
            data: { label: 'Development Stages' },
          };

         //To push the Edge Label node
         newNodes.push(labelNode);

         // Add the clicked node to selectedNodes
         addToSelectedNodes(node.data.type, node.data.doc_id,node.data.label);
         addToBreadcrumb('devStage')
      }
      else if (node.data.type === 'devStage') {
        // Handle click on a Development Stage node
        await fetchFocusAreas(node.data.doc_id) // Pass the selected dev stage id
    
        focusAreaData.value.forEach((focusArea, index) => {
          const newNode = {
            id: `focus_area_${nodeCounter}`,
            position: { 
              x: node.position.x + 240, 
              y: node.position.y - 100 + index * 70
            },
            data: { 
              label: focusArea.focus_area_name, 
              type: 'focusArea', 
              doc_id: focusArea.focus_area_id 
            },
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            
          }
          newNodes.push(newNode)
          newEdges.push({
            id: `e${node.id}->focus_area_${nodeCounter}`,
            source: node.id,
            target: `focus_area_${nodeCounter}`,
            type: 'smoothstep',
          })
          nodeCounter++
        })
        
        const labelNode = {
            id: `focus_areas_edge_label`,
            type: 'edgeLabel',
            position: { x: node.position.x+200, y: node.position.y },
            data: { label: 'Focus Areas' },
          };

         //To push the Edge Label node
         newNodes.push(labelNode);

         // Add the clicked node to selectedNodes
         addToSelectedNodes(node.data.type, node.data.doc_id,node.data.label);
         addToBreadcrumb('focusArea')
      }
      else if (node.data.type === 'focusArea') {
      // Handle click on a Focus Area node
      await fetchLearningSequences(node.data.doc_id) // Pass the selected focus area id
    
      learningSequenceData.value.forEach((sequence, index) => {
        const newNode = {
          id: `learning_sequence_${nodeCounter}`,
          position: { 
            x: node.position.x + 250, 
            y: node.position.y - 100 + index*70
          },
          data: { 
            label: sequence.learning_sequence_name, 
            type: 'learningSequence', 
            doc_id: sequence.learning_sequence_id 
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        }
        newNodes.push(newNode)
        newEdges.push({
          id: `e${node.id}->learning_sequence_${nodeCounter}`,
          source: node.id,
          target: `learning_sequence_${nodeCounter}`,
          type: 'smoothstep',
        })
        nodeCounter++
      }) 

      const labelNode = {
            id: `learning_sequences_edge_label`,
            type: 'edgeLabel',
            position: { x: node.position.x+200, y: node.position.y },
            data: { label: 'Learning Sequences' },
          };

         //To push the Edge Label node
         newNodes.push(labelNode);

     // Add the clicked node to selectedNodes
     addToSelectedNodes(node.data.type, node.data.doc_id,node.data.label);
     addToBreadcrumb('learningSequence')
    }
    else if (node.data.type === 'learningSequence') {
          
          // Add the clicked node to selectedNodes
          addToSelectedNodes(node.data.type, node.data.doc_id,node.data.label);
          // addToBreadcrumb(node.data.LearningSequence_id)
          showNavigationPopup.value = true

          console.log("Learninig sequence node clicked,this is the node deata=================>",node)

          // Fetch rest of the details of the sequence for modal
          fetchLearningSequenceDetailsForModal(node.data.doc_id)
         
          
          // Create a new node for the button group -- button for redirecting to activity page
          // const buttonGroupNode = {
          //   id: `popup_button_group`,
          //   type: 'buttonGroup',
          //   position: { 
          //     x: node.position.x + 200, 
          //     y: node.position.y-20
          //   },
          //   data: { 
          //     quizIcon: quick_learnings_unselected,
          //     activityIcon: ActivitiesIconUnselected
          //   },
          //   sourcePosition: Position.Right,
          //   targetPosition: Position.Left,
          // };
          // newNodes.push(buttonGroupNode);


  nodeCounter++;

    }

      // Append the new nodes and edges
      nodes.value = [...nodes.value, ...newNodes]
      edges.value = [...edges.value, ...newEdges]

    }


    
  // Function to add types of selected nodes to breadcrumbs
  const addToBreadcrumb = (item) => {
    if (!breadcrumb.value.includes(item)) {
        breadcrumb.value = [...breadcrumb.value, item];
      }
    };

  //Funtions to add type and Id of selected node 
  const addToSelectedNodes = (dataType, nodeId, nodeLabel) => {
    console.log("add to selected nodes function called")
    console.log("List before adding selected nodes", {...selectedNodes.value})
    console.log("List before adding selected NODE DETAILED", {...selectedNodesDetailed.value})
    
    // Directly assign the nodeId to the dataType key
    selectedNodes.value[dataType] = nodeId;
    console.log("list after adding selected nodes", {...selectedNodes.value})
    
    // Update the detailed structure
    const existingIndex = selectedNodesDetailed.value.findIndex(node => node.type === dataType);
    if (existingIndex !== -1) {
      selectedNodesDetailed.value[existingIndex] = { type: dataType, id: nodeId, label: nodeLabel };
    } else {
      selectedNodesDetailed.value.push({ type: dataType, id: nodeId, label: nodeLabel });
    }
    console.log("list after adding selected NODES DETAILED", {...selectedNodesDetailed.value})

    
    };
   
  const removeFromSelectedNodes = (dataType,data) => {
    console.log("Remove from selected nodes called with dataType:===================================================", dataType);
    showNavigationPopup.value=false
      console.log("Value of selected nodes before removing:", {...selectedNodes.value});

      const keys = Object.keys(selectedNodes.value);
      const index = keys.indexOf(dataType);

      if (index !== -1) {
        // Remove the current dataType and all types that come after it
        keys.slice(index).forEach(key => {
          delete selectedNodes.value[key];
          selectedNodesDetailed.value = selectedNodesDetailed.value.filter(node => !keys.slice(index).includes(node.type));
        });

      }

      console.log("Value of selected nodes after removing==========================================================",{...selectedNodes.value});
     
    };

    
  const formatBreadcrumbValue = computed(() => {
     return (item) => {
       
        if (item == 'devStage'){
          return 'Development Stages'
        } 
        else if (item === 'focusArea'){
          return 'Focus Areas'
        }
        else if (item === 'learningSequence'){
          return 'Learning Sequences'
        }
        else {
          return item.charAt(0).toUpperCase() + item.slice(1)+'s';
        }
      }
    });
    
  const fetchDomains = async() => {
      try {
            console.log("fetch Domains called")
            const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_domains');
            // If response.status is success
            domainData.value = response.data
            
        } catch (error) {
            console.error('Error fetching Competencies data:', error);
        }
      };
    
      const fetchCompetencies = async(doc_id) => {
        try {
            console.log("fetch Competencies called")
            const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_subdomains',{
             domain: doc_id
            });
            competencyData.value = response.data; 
        } catch (error) {
            console.error('Error fetching Competencies data:', error);
        }
      };
    
     const fetchDevelopmentStages = async(doc_id) => {
        try {
            console.log("fetch development stages called")
            const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_development_stages',{
             subdomain: doc_id
            });
            devStagesData.value = response.data;
           
        } catch (error) {
            console.error('Error fetching Competencies data:', error);
        }
      };
    
      const fetchFocusAreas = async(doc_id) => {
        try {
            console.log("fetch focus area called")
            const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_focus_area',{
             dev_stage: doc_id
            });
            // if response.success
            focusAreaData.value = response.data
           
        } catch (error) {
            console.error('Error fetching focus areaa data:', error);
        }
      };
    
      const fetchLearningSequences = async(doc_id) => {
        try {
            console.log("fetch Learning Sequence called")
            const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_learning_sequence',{
             focus_area: doc_id
            });
            learningSequenceData.value = response.data
           
        } catch (error) {
            console.error('Error fetching Learning Sequence data:', error);
        }
      };

      const fetchLearningSequenceDetailsForModal = async(doc_id) => {
       
        try {
            console.log("fetch Learning Sequence Details called")
            const response = await $call('curriculum_planner.curriculum_planner.doctype.activity_domain.api.fetch_learning_sequence_details',{
             sequence_id: doc_id
            });

            if (response.status === "success"){
              clickedLearningSequenceDetails.value = response.data;
              showLearningSequenceDetailsModal.value = true;
            }
            
            console.log("This is the fetched Learning sequence details Response ===============>>",response)
        } catch (error) {
            console.error('Error fetching Learning Sequence data:', error);
        }
      } 

    const router = useRouter();
    const route = useRoute();

    // Funtion to send selected node's ids when navigating to table view
    const navigateToLandscapeTable = () => {
    console.log("this is selected nodes value before navigating",selectedNodes.value)  
    router.push({
        name: 'CurriculumLandscapeTable',
        params: {
          domain: selectedNodes.value.domain,
          subdomain: selectedNodes.value.competency,
          devStage: selectedNodes.value.devStage,
          focusArea: selectedNodes.value.focusArea,
          learningSequence: selectedNodes.value.learningSequence
        }
    });
    };

   
    const extractQueryParamsAndExpandNodes = async () => {
      console.log("Extract query parameter on mounted called");
      showNavigationPopup.value = false

      const { domain, subdomain, devStage, focusArea, learningSequence } = route.query;

    
      // let nodeCounter = 1;
      
      const nodesToExpand = [{
          id: '1',
          type: 'input',
          position: { x: 115, y: 300 }, // Starting position
          data: { label: 'Curriculum Landscape' },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        }];

        let lastPosition = { x: 115, y: 300 }; // Initialize with the starting position

        const addNode = (type, id, xOffset, yOffset) => {
          const newPosition = {
            x: lastPosition.x + xOffset,
            y: lastPosition.y + yOffset
          };
          // const newNodeId = `${type}_${nodeCounter}`;
          // nodeCounter++;

          
          nodesToExpand.push({
            // id: newNodeId,
            position: newPosition,
            data: { type,[`${type}_id`]: id }
          });

          
          lastPosition = newPosition; // Update lastPosition for the next node
        };

        if (domain) {
          selectedNodes.value.domain = domain;
          addNode('domain', domain, 200, -30);
        }

        if (subdomain) {
          selectedNodes.value.competency = subdomain;
          addNode('subdomain', subdomain, 200, 70);
        }

        if (devStage) {
          selectedNodes.value.devStage = devStage;
          addNode('devStage', devStage, 200, 70);
        }

        if (focusArea) {
          selectedNodes.value.focusArea = focusArea;
          addNode('focusArea', focusArea, 200, 70);
        }

      console.log("This is extracted query value", selectedNodes.value);

      // Expand nodes in sequence
      for (const nodeInfo of nodesToExpand) {
        
        if (nodeInfo) {
          await expandNode(nodeInfo);
          expandedNodes.value.add(nodeInfo.id);
          updateNodeColor(nodeInfo.id);
        }
      }
    };

    const updateNodeColor = (nodeId) => {
      nodes.value = nodes.value.map(node => {
        if (node.id === nodeId) {
          return {
            ...node,
            style: {
              ...node.style,
              backgroundColor: '#FDD835',
              color: '#000000'
            }
          };
        }
        return node;
      });
    };
    </script>
    
    
    
    <style>
    /* import the necessary styles for Vue Flow to work */
    @import '@vue-flow/core/dist/style.css';
    
    /* import the default theme, this is optional but generally recommended */
    @import '@vue-flow/core/dist/theme-default.css';
    
    .vue-flow__node {
      width: auto;
      max-width: 185px;
      min-width: 110px;
      border-width: 2px;
      font-weight:600;
      border-radius: 6px;
      border-color: #000 !important;
    }

    /* #popup_button-group {
      border: 0px !important;
    } */
    .vue-flow__node-buttonGroup{
      border: 0px !important;
    }

    /* To remove outer border */
   .vue-flow__node-edgeLabel{
      border: 0px !important;
    }

    
    #domain_edge_label{
      position: relative;
      border: 0px solid #008000 !important;
      left: 135px;
      top:-100px;
      transform: rotate(-90deg) !important;
      background-color: white !important;
      box-shadow: none;
    }

    #competency_edge_label{
      position: relative;
      border: 0px solid green !important;
      left: -90px;
      top:-70px;
      transform: rotate(-90deg) !important;
      box-shadow: none;
    }

    #devStage_edge_label{
      position: relative;
      border: 0px solid green !important;
      left: -130px;
      top:-80px;
      transform: rotate(-90deg) !important;
      box-shadow: none;
    }
    #focus_areas_edge_label{
      position: relative;
      border: 0px solid green !important;
      left: -85px;
      top:-80px;
      transform: rotate(-90deg) !important;
      box-shadow: none;
    }

    #learning_sequences_edge_label{
      position: relative;
      border: 0px solid green !important;
      left: -65px;
      top:-100px;
      transform: rotate(-90deg) !important;
      box-shadow: none;
    }
    
    .vue-flow__edge path {
      stroke: #000000;
      stroke-width: 1px; /* Increase the edge thickness */
    }
    
    .vue-flow__handle{
      width: 10px; 
      height: 10px; 
      background-color: #fff !important; 
      border: 3.5px solid #000;
      border-radius: 50%;
    }
    
    .vue-flow__handle-right{
      background-color: transparent !important; 
      border: 0px solid #000;
    }
    
    .vue-flow__edge-textwrapper {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      border: 2px solid red;
      /* transform: rotate(9deg) translateY(0%); */
    }
    
    
    </style>