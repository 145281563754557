<template>
  <ActivityFormSecondaryPage :pageNum="props.pageNum" :componentPageNum="props.componentPageNum"
    :competencyList="props.competencyList" :observation="props.observation"
    :defaultCompetencies="defaultSelectedComps" :defaultChecked="defaultCheckComps" />
</template>

<script setup>
import { computed } from 'vue';
import ActivityFormSecondaryPage from '../../forms/ActivityForm/ActivityFormSecondaryPage.vue';

const props = defineProps([
    'pageNum', 'componentPageNum', 'observation', 
    'competencyList'
])

const compItems = [
  "Emotional Intelligence",
  "Reading & Writing",
  "Math & Numeracy",
  "Science Exploration",
  "Physical Wellness",
  "Music & Rhythm",
  "Critical Thinking",
  "Storytelling",
  "Visual Arts"
]

const defaultCheckComps = computed(() => {
  if (!props.observation?.secondary_competencies) return []
  const checkedComps = props.observation.secondary_competencies.filter((comp) => compItems.includes(comp.competency_name))
    .map((comp) => comp.competency_name)

  if (checkedComps.length !== props.observation.secondary_competencies.length) {
    checkedComps.push("Other")
  }
  return checkedComps
})

const defaultSelectedComps = computed(() => {
  if (!props.observation?.secondary_competencies) return []
  return props.observation.secondary_competencies.map((comp) => comp.name)
})
</script>