<template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup>
import { computed } from 'vue'
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'

const props = defineProps({
  categories: {
    type: Array
  },
  values: {
    type: Array
  }
})

use([GridComponent, BarChart, CanvasRenderer, TooltipComponent])

const option = computed(() => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  xAxis: {
    type: 'value'
  },
  yAxis: {
    type: 'category',
    //data: ['Last Month', 'Current Month']
    data: props.categories
  },
  series: [
    {
      //data: [120, 200],
      data: props.values,
      type: 'bar'
    }
  ],
  color: ['#FDD835']
}))
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
}
</style>