<template>
  <div v-if="props.observationType === 'child'" class="flex-auto flex flex-col gap-4 lg:grid
    xl:grid-cols-3 lg:grid-cols-2 lg:auto-rows-max">
    <DailyObservViewCard v-for="observation in observations.daily_v2" :observation="observation" 
      :selectObservation="selectObservation" :version="2" />
    <DailyObservViewCard v-for="observation in observations.daily" :observation="observation" 
      :selectObservation="selectObservation" :version="1" />
    <ObservEditModal modalName="observation-edit-modal-all" :version="selectedObservationVersion"
      :observation="selectedObservation" :selectedSignal="selectedSignal" :scrollSignal="scrollSignal" />
  </div>
  <div v-if="props.observationType === 'activity'" class="flex-auto flex flex-col gap-4 lg:grid
    xl:grid-cols-3 lg:grid-cols-2 lg:auto-rows-max">
    <ActivityObservViewCard v-for="observation in observations.activity_v3" :observation="observation"
      :selectObservation="selectActivityObservation" :version="3"/>
    <ActivityObservViewCard v-for="observation in observations.activity_v2" :observation="observation"
      :selectObservation="selectActivityObservation" :version="2"/>
    <ActivityObservViewCard v-for="observation in observations.activity" :observation="observation"
      :selectObservation="selectActivityObservation" :version="1" />
    <ActivityObservEditModal modalName="activity-observ-edit-modal-all" :observation="selectedObservation"
      :selectedSignal="selectedSignal" :version="selectedObservationVersion" :scrollSignal="scrollSignal" />
    <ActivityObservV3EditModal  modalName="activity-observ-v3-edit-modal-all" :observation="selectedObservation"
      :selectedSignal="selectedSignal" :version="selectedObservationVersion" :scrollSignal="scrollSignal" />
  </div>
  <div v-if="props.observationType === 'unstructured'" class="flex-auto flex flex-col gap-4 lg:grid
    xl:grid-cols-3 lg:grid-cols-2 lg:auto-rows-max">
    <UnstructViewCard v-for="observation in observations" :observation="observation"
      :selectObservation="selectUnstructured" />
    <UnstructEditModal modalName="unstruct-edit-modal-all" :observation="selectedObservation"
      :selectedSignal="selectedSignal" :scrollSignal="scrollSignal" />
  </div>
  <div v-if="props.observationType === 'parent'" class="flex-auto flex flex-col gap-4 lg:grid
    xl:grid-cols-3 lg:grid-cols-2 lg:auto-rows-max">
    <ParentObservViewCard v-for="observation in observations.parent_observ" :observation="observation"
      :selectObservation="selectParentObserv" />
    <ParentObservEditModal modalName="parent-observ-edit-modal-all" :version="selectedObservationVersion"
      :observation="selectedObservation" :selectedSignal="selectedSignal" />
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import ActivityObservViewCard from './ActivityObservViewCard.vue';
import DailyObservViewCard from './DailyObservViewCard.vue';
import UnstructViewCard from './UnstructViewCard.vue';
import ObservEditModal from '../edit/ObservEditModal.vue';
import { HSOverlay } from 'preline/preline';
import UnstructEditModal from '../edit/Unstructured/UnstructEditModal.vue';
import ActivityObservEditModal from '../edit/Activity/ActivityObservEditModal.vue'
import ActivityObservV3EditModal from '../edit/Activity/ActivityObservV3EditModal.vue'
import ParentObservViewCard from '@/components/observations/view/ParentObservViewCard.vue'
import ParentObservEditModal from '@/components/observations/edit/Parent/ParentObservEditModal.vue';
import { createResource } from 'frappe-ui';

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const props = defineProps(['observationType', 'observations'])
const route = useRoute()

const selectedObservation = ref(null)
const selectedObservationVersion = ref(2)

const selectedSignal = ref(false)

const selectObservation = (observationData, version) => {
  selectedObservation.value = observationData
  selectedObservationVersion.value = version
  selectedSignal.value = !selectedSignal.value
  HSOverlay.open('#hs-observation-edit-modal-all')
}

const selectActivityObservation = (observationData, version) => {
  selectedObservation.value = observationData
  selectedObservationVersion.value = version
  selectedSignal.value = !selectedSignal.value
  if (version === 3) {
    HSOverlay.open('#hs-activity-observ-v3-edit-modal-all')
  } else {
    HSOverlay.open('#hs-activity-observ-edit-modal-all')
  }
}

const selectUnstructured = (observationData) => {
  selectedObservation.value = observationData
  selectedSignal.value = !selectedSignal.value
  HSOverlay.open('#hs-unstruct-edit-modal-all')
}

const selectParentObserv = (observationData) => {
  selectedObservation.value = observationData
  selectedSignal.value = !selectedSignal.value
  HSOverlay.open('#hs-parent-observ-edit-modal-all')
}

const queryObservationResource = createResource({
  'url': 'curriculum_planner.curriculum_planner.common.observations.api.get_observation',
})

const scrollSignal = ref(true)

const openQueryObserv = async (id) => {
  try {
    const version = props.observationType === 'child' ? 2 : (
      props.observationType === 'activity' ? 3 : 1
    )
    await queryObservationResource.submit({
      type: props.observationType === 'child' ? 'daily' : props.observationType,
      version: version,
      name: id,
    })
    console.log(queryObservationResource)
    if (queryObservationResource?.data?.status === 'success') {
      if (props.observationType === 'child')
        setTimeout(() => {
          selectObservation(queryObservationResource?.data?.observation, version)
        }, 200)
      else if (props.observationType === 'activity')
        setTimeout(() => {
          selectActivityObservation(queryObservationResource?.data?.observation, version)
        }, 200)
      else if (props.observationType === 'unstructured') {
        setTimeout(() => {
          selectUnstructured(queryObservationResource?.data?.observation,version)
        }, 200)
      }
      const scrollToComments = route.query.scroll
      console.log(`Sscroll value is: ${scrollToComments}, switching signall`)
      if (scrollToComments == 1)
        scrollSignal.value = !scrollSignal.value
    } else {
      console.error("Failed to fetch observation")
      console.error(queryObservationResource?.data?.message)
    }
  } catch (error) {
    console.error("Failed to make observation request")
  }
}

const observId = route.query.observ || ""
console.log("observ id: ", observId)
if (!!observId && observId != '') {
  // console.log("Id exists, fetching observation")
  openQueryObserv(observId)
}

watch(route, () => {
  const newObservId = route.query.observ || ""
  // console.log("observ id changed", newObservId)
  if (!!newObservId && newObservId != '') {
    // console.log("Id exists, fetching observation")
    openQueryObserv(newObservId)
  }
})
</script>