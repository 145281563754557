<template>
  <div :class="cn('p-8 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div :class="cn(`space-y-3`, fetchedCompDecisionMade && 'hidden', !fetchedCompsExists && 'hidden')">
      <div class="space-y-6">
        <p class="font-medium text-sm md:text-base">The following competencies have been detected from the observation:</p>
        <div class="flex gap-2 gap-y-5 font-normal text-base flex-wrap">
          <div v-for="comp in filteredFetchedComps"
            class="bg-[#FDD835] pl-2 rounded items-center justify-center py-0 flex gap-2 relative text-xs md:text-sm">
            <div :class="cn(`absolute -top-4 left-0 bg-[#FEF3C2] rounded-t-lg px-1 text-xs font-medium`,
              (fetchedPrimary !== comp) && 'hidden'
            )">
              primary
            </div>
            <span class="cursor-pointer" @click="() => { changeFetchedPrimary(comp) }">
              {{ comp }}
            </span>
            <div class="p-1 px-2 cursor-pointer hover:bg-[#efc51f]"
              @click="() => { removeFetchedComp(comp)}">
              X
            </div>
          </div>
        </div>
      </div>
      <div class="text-xs md:text-sm font-normal text-gray-600">
        The competency marked as primary will be selected as the primary competency and the rest will be added to secondary competencies.
        You can modify this later.
      </div>
      <div class="flex gap-2">
        <button type="button" class="py-2 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700
          dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1
          dark:focus:ring-gray-600 flex-auto md:flex-initial md:px-10 basis-1/2 justify-center" @click="dontAddFetchComps">
          Add manually
        </button>
        <button type="button" :class="cn(`py-2 inline-flex items-center gap-x-2 text-sm font-semibold
          rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
          disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
          dark:focus:ring-gray-600 flex-auto md:flex-initial md:px-10 basis-1/2 justify-center`)" @click="fetchedCompDecisionMade = true">
          Confirm
        </button>
      </div>
    </div>
    <div :class="cn(`space-y-3`, fetchedCompsExists && !fetchedCompDecisionMade && 'hidden')">
      <p class="font-medium text-sm md:text-base">
        4. What is the primary competency being explored in the activity?
      </p>
      <div :class="cn('flex flex-col gap-y-2 md:gap-y-4', textMode && 'hidden')">
        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-7" value="Critical Thinking"
              v-model="picked">
            Critical Thinking
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
              disabled:pointer-events-none" id="hs-radio-act-primary-group-1" value="Emotional Intelligence"
              v-model="picked">
            Emotional Intelligence
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
              disabled:pointer-events-none" id="hs-radio-act-primary-group-1" value="Fine Motor Skills"
              v-model="picked">
              Fine Motor Skills
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-3" value="Math & Numeracy"
              v-model="picked">
            Math & Numeracy
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-6" value="Music & Rhythm"
              v-model="picked">
              Music & Rhythm
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-5" value="Physical Wellness"
              v-model="picked">
            Physical Wellness
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-2" value="Reading & Writing"
              v-model="picked">
            Reading & Writing
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-4" value="Science Exploration"
              v-model="picked">
            Science Exploration
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-4" value="Storytelling"
              v-model="picked">
              Storytelling
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-4" value="Visual Arts"
              v-model="picked">
              Visual Arts
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="primary_competency" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-8" value="Other"
              v-model="picked">
            Other
          </label>
        </div>

        <div :class="cn(picked !== 'Other' && 'hidden')" @keydown.stop>
          <SelectSearchSingle v-if="renderSelect" placeholder="Select competency..." :dataList="filteredCompetencyList"
            inputName="other_primary" dispName="competency_name" :hasSearch="true" :defaultValue="selectInputDefaultComp" />
        </div>
      </div>

      <div class="flex flex-col gap-1 md:gap-2 rounded p-2 bg-[#FEF3C2]">
        <div class="text-sm font-normal pl-4">
          Cannot find what you are looking for?
        </div>
        <div class="flex">
          <label class="text-sm text-gray-800 ms-4 font-semibold flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value=""
              id="subcomp-checkbox-group-8" v-model="textMode" name="text_mode_primary">
              Enter as text
          </label>
        </div>
      </div>
      <div :class="cn(!textMode && 'hidden')" @keydown.stop>
        <textarea id="hs-autoheight-textarea-custom-comp-act" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
          rows="10" placeholder="Enter competencies..." name="custom_primary_competency" v-model="compText" ref="compTextRef"
          @input="textareaAutoHeight"></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { findValueByName, findNameByValue } from '@/lib/utils.js'
import { computed, inject, ref, watch } from 'vue'
import SelectSearchSingle from '../../../ui/SelectSearchSingle.vue';

const compItems = [
  "Emotional Intelligence",
  "Reading & Writing",
  "Math & Numeracy",
  "Science Exploration",
  "Physical Wellness",
  "Critical Thinking",
  "Music & Rhythm",
  "Critical Thinking",
  "Storytelling",
  "Visual Arts"
]

const props = defineProps([
  'pageNum', 'componentPageNum', 'observation',
  'competencyList', 'version', 'fetchedComps'
])
const emit = defineEmits([
  'changed:textMode', 'decision:fetchedComps', 'selected:secondary'
])
// const competencyList = inject('observationCompetencies')

const textMode = ref(false)

const compText = ref('')
const compTextRef = ref(null)
const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  compTextRef.value.style.height = 'auto'
  let calculatedHeight = compTextRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  compTextRef.value.style.height = `${calculatedHeight}px`
}

const fetchedPrimary = ref('')
const fetchedCompDecisionMade = ref(false)
const fetchedCompsExists = computed(() => {
  if (props.fetchedComps?.length) return true
  return false
})

const filteredFetchedComps = ref(props.fetchedComps || [])
watch(() => props.fetchedComps, () => {
  if (Array.isArray(props.fetchedComps))
  {
    filteredFetchedComps.value = props.fetchedComps
    fetchedCompDecisionMade.value = false
    fetchedPrimary.value = props.fetchedComps[0]
  }
})

const changeFetchedPrimary = (comp) => {
  if (fetchedPrimary.value === comp)
    fetchedPrimary.value = ''
  else
    fetchedPrimary.value = comp
}

const removeFetchedComp = (comp) => {
  filteredFetchedComps.value = filteredFetchedComps.value.filter((fetchComp) => fetchComp !== comp)
}

const dontAddFetchComps = () => {
  filteredFetchedComps.value = []
  fetchedPrimary.value = ''
  fetchedCompDecisionMade.value = true
}

const renderSelect = ref(true)
const selectInputId = ref('')

watch(fetchedCompDecisionMade, (newDecision) => {
  emit('decision:fetchedComps', newDecision)
  if (!newDecision) return

  if (fetchedPrimary.value && compItems.includes(fetchedPrimary.value)) {
    picked.value = fetchedPrimary.value
  } else if (fetchedPrimary.value) {
    picked.value = 'Other'
    const compId = findNameByValue(props.competencyList, fetchedPrimary.value, 'competency_name')
    selectInputId.value = compId
    renderSelect.value = false
    setTimeout(() => {
      renderSelect.value = true
    }, 100)
  }

  const secondaryComps = []
  filteredFetchedComps.value.forEach(comp => {
    if (picked.value !== comp) {
      secondaryComps.push(comp)
    }
  })
  emit('selected:secondary', secondaryComps)
})

watch(textMode, () => {
  if (!textMode.value) compText.value = ''
  emit('changed:textMode', textMode.value)
})

const filteredCompetencyList = computed(() => {
  if (!props.competencyList) return []
  // if (!Array.isArray(competencyList.value)) return []
  return props.competencyList.filter((comp) => {
    return !compItems.some(str => str === comp.competency_name)
  })
})

const selectDefault = ref('')

const getPickedComp = () => {
  if (props.version >= 2) {
    if (!props.observation) return ''
    const compName = findValueByName(props.competencyList, props.observation?.primary_competency, 'competency_name')
    if (compItems.includes(compName)) return compName
    selectDefault.value = props.observation?.primary_competency || ''
    return 'Other'
  } else if (props.version === 1) {
    return props.observation?.primary_competency || ''
  }
}

const picked = ref(getPickedComp())

const selectInputDefaultComp = computed(() => {
  if (!selectDefault.value && !selectInputId.value)
    return ''
  return selectInputId.value || selectDefault.value
})
</script>