<template>
  <div class="flex flex-col h-[94vh] md:h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <div class="flex h-full">
      <div class="flex-auto h-full flex flex-col">
        <div class="flex flex-col gap-4 lg:gap-8">
          <h2 class="text-3xl">Observation Summary</h2>
        </div>
        <div class="relative p-8 pb-4 pr-0 flex flex-col flex-auto">
          <div
              class="flex-auto flex flex-col gap-8 overflow-y-auto pr-[24rem]">
            <div v-if="promptText">
              <div>
                <span class="font-bold">Prompt:</span>
                <br />
                {{ promptText }}
              </div>
              <div>
                <span class="font-bold">Response:</span>
                <br />
                {{ respText }}
                <div v-if="isGenerating" class="animate-spin inline-block size-6 border-[3px] border-current
                  border-t-transparent text-blue-600 rounded-full h-4 w-4" role="status" aria-label="loading">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p v-if="isGenerating" class="">Generating Observation Summary...</p>
            <p v-else-if="apiTime" class="">Summary generated in {{ apiTime.toFixed(3) }} seconds.</p>
          </div>
          <div class="relative">
            <!-- <PlayInput :action="getCost" @prompt="setPromptText" ref="playInput" /> -->
            <PromptInput :action="getSummary" ref="promptInputRef" />
          </div>
        </div>
      </div>
      <SummaryOptions :changeDate="changeDate" :changeType="changeObservationType" 
        :fullPromptFn="getFullPrompt" :savePromptFn="savePrompt" :changePrompt="switchPrompt"
        :changeModel="changeModel" />
      <ShowPromptModal modalName="observationPromptModal" :promptString="fullPromptString" />
      <SavePromptModal modalName="saveObservPromptModal" :prompt="modalPrompt" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { createResource } from 'frappe-ui'
import PromptInput from '../components/planWeek/PromptInput.vue';
import SummaryOptions from '../components/planWeek/summary/SummaryOptions.vue';
import ShowPromptModal from '../components/planWeek/summary/ShowPromptModal.vue';
import SavePromptModal from '../components/planWeek/summary/SavePromptModal.vue';
import { HSOverlay } from 'preline/preline';

const promptText = ref('')
const respText = ref('')
const apiTime = ref(0)
const isGenerating = ref(false)
const fullPromptString = ref('')

const promptInputRef = ref(null)

const observationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_observation_summary',
})

const promptResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_full_prompt',
})

const savePromptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompt.api.save_prompt',
})

const observType = ref('child');
const startDate = ref("2024-05-10");
const endDate = ref("2024-05-16");
const pickedModel = ref('gpt-3.5-turbo-0125')

const changeDate = (type, date) => {
  console.log({type, date})
  if (type === 'start')
    startDate.value = date
  else if (type === 'end')
    endDate.value = date
}

const changeObservationType = (newType) => {
  observType.value = newType
  console.log(newType)
}

const changeModel = (newModel) => {
  pickedModel.value = newModel
}

const getSummary = async (prompt = "") => {
  respText.value = ''
  promptText.value = prompt
  isGenerating.value = true
  try {
    await observationResource.submit({
      prompt: prompt,
      start_date: startDate.value,
      end_date: endDate.value,
      observation_type: observType.value,
      model: pickedModel.value
    })
    isGenerating.value = false
    if (observationResource.data?.status === 'success') {
      respText.value = observationResource.data.completion
      apiTime.value = observationResource.data.api_time
    }
    console.log(observationResource)
  } catch (error) {
    console.error("Failed generating summary", error)
    isGenerating.value = false
  }
}

const getFullPrompt = async () => {
  const prompt = promptInputRef.value.getPrompt()
  console.log({prompt})
  try {
    await promptResource.submit({
      prompt: prompt,
      start_date: startDate.value,
      end_date: endDate.value,
      observation_type: observType.value,
    })
    if (promptResource.data?.status === 'success') {
      fullPromptString.value = promptResource.data.prompt
      HSOverlay.open('#hs-observationPromptModal')
    }
    console.log(promptResource)
  } catch (error) {
    console.error(error)
  }
}

const modalPrompt = ref('')

const savePrompt = async () => {
  const prompt = promptInputRef.value.getPrompt()
  modalPrompt.value = prompt
  console.log({prompt})
  HSOverlay.open('#hs-saveObservPromptModal')
}

const switchPrompt = (prompt) => {
  promptInputRef.value.changePrompt(prompt)
}
</script>