<template>
  <div class="py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 
    dark:border-neutral-700" data-hs-input-number='{
      "min": 1
    }'>
    <div class="w-full flex justify-between items-center gap-x-5">
      <div class="grow">
        <!-- <span class="block text-xs text-gray-500 dark:text-neutral-400">
          Select quantity
        </span> -->
        <input class="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white" 
          type="text" value="1" data-hs-input-number-input="" :name="props.inputName" v-model="inputValue">
      </div>
      <div class="flex justify-end items-center gap-x-1.5">
        <button type="button" class="size-6 inline-flex justify-center items-center gap-x-2 text-sm 
          font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
          disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 
          dark:text-white dark:hover:bg-neutral-800" data-hs-input-number-decrement="">
          <svg class="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
            stroke-linejoin="round">
            <path d="M5 12h14"></path>
          </svg>
        </button>
        <button type="button" class="size-6 inline-flex justify-center items-center gap-x-2 text-sm 
          font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm 
          hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 
          dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800" data-hs-input-number-increment="">
          <svg class="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" 
            stroke-linejoin="round">
            <path d="M5 12h14"></path>
            <path d="M12 5v14"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref } from 'vue';

const props = defineProps(['inputName', 'default'])

const inputValue = ref(props.default || 1)

watch(() => props.default, () => {
  if (props.default) {
    inputValue.value = props.default
  }
})
</script>