// src/firebase.js
import { initializeApp } from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAfe9UqF8lgJG2TZhc6dJJZEffQNO9o2uY",
    authDomain: "comini-pod.firebaseapp.com",
    projectId: "comini-pod",
    storageBucket: "comini-pod.appspot.com",
    messagingSenderId: "304973494614",
    appId: "1:304973494614:web:b23702217e27ab2a0a7ef7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log("firebase.js import works")
export { app };