<template>
  <div class="flex flex-col h-[94vh] md:h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 overflow-y-auto">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8 w-full">
        <h2 class="text-3xl">Assessment Report for 
          <span v-if="userDetails?.school_info">
            {{ findValueByName(userDetails.school_info.students, route.params.student, 'student_name') }}
          </span>
        </h2>
        <!-- <div class="p-4" v-if="assessmentResource.data?.status === 'success'" v-html="assessmentResource.data.reports[0].report_draft">
        </div> -->
        <div class="flex gap-4">
          <div class="flex flex-col flex-auto basis-1/2 gap-2">
            <h3>Draft Report</h3>
            <div class="text-sm font-semibold text-gray-600">
              <p v-if="isSavingDraft">Saving Report. Please wait...</p>
            </div>
            <div class="flex justify-end pr-2 gap-2">
              <button type="button" @click="saveEditedDraft" 
                :class="cn(`py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit`,
                !reportId && 'hidden')"
                :disabled="isSavingDraft || !reportId">
                Save Edited Draft
              </button>
              <button type="button" @click="saveDraft" 
                :class="cn(`py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit`,
                reportId && 'hidden')"
                :disabled="isSavingDraft || (reportId !== '')">
                Save Draft Report
              </button>
            </div>
            <QuillEditorMinimal 
              :editorContent="assessmentResource.data?.reports[0]?.report_draft || ''" 
              @change:content="modifyDraft" />
          </div>
          <div class="flex flex-col flex-auto basis-1/2 gap-2">
            <h3>Formatted Report</h3>
            <div class="text-sm font-semibold text-gray-600">
              <p v-if="isGeneratingReport">
                Generating Report. Please wait...
              </p>
              <p v-if="hasCreatedNewDraft">
                Formatted Report will be generated automatically. Please come back or reload after some time.
              </p>
            </div>
            <div class="flex justify-end pr-2 gap-2">
              <button type="button" @click="regenerateReport" 
                :class="cn(`py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit`,
                !reportId && 'hidden')"
                :disabled="isSavingDraft || !reportId || isGeneratingReport">
                Regenerate Report
              </button>
              <button type="button" @click="isReportEditable = true" 
                :class="cn(`py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit`,
                !reportId && 'hidden', isReportEditable && 'hidden', 
                !assessmentResource.data?.reports[0]?.formatted_report && 'hidden')"
                :disabled="isSavingDraft || !reportId || isGeneratingReport">
                Edit Formatted Report
              </button>
              <button type="button" @click="editFormattedReport" 
                :class="cn(`py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit`,
                !reportId && 'hidden', !isReportEditable && 'hidden')"
                :disabled="isSavingDraft || !reportId || isGeneratingReport">
                Save Changes
              </button>
            </div>
            <QuillEditorMinimal v-if="assessmentResource.data?.status === 'success'" 
              :editorContent="assessmentResource.data?.reports[0]?.formatted_report" :readOnly="!isReportEditable"
              @change:content="modifyEditedReport" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { createListResource, createResource } from 'frappe-ui';
import { computed, inject, onMounted, ref, watch, readonly } from 'vue';
import { HSOverlay } from 'preline/preline';
import { findValueByName } from '@/lib/utils';
import QuillEditorMinimal from '@/components/ui/input/QuillEditorMinimal.vue';
import { cn } from '@/components/ui/utils'
import { useRoute } from 'vue-router';

const route = useRoute()

const reportId = ref('')

const assessmentResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.student_assessment_report.api.get_assessment_report',
 params: {
  student_id: route.params.student,
 },
 auto: true
})

const saveDraftResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.student_assessment_report.api.create_assessment_report',
})

const modifyDraftResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.student_assessment_report.api.edit_draft_report',
})

const reformatReportResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.student_assessment_report.api.regenerate_formatted_report',
})

watch(() => assessmentResource.data, (newData) => {
  if (newData.status === 'success' && newData.reports.length) {
    reportId.value = newData.reports[0].name
  }
})


console.log(assessmentResource)

const userDetails = inject('userDetails');

const draftReport = ref('')
const modifyDraft = (newValue) => {
  draftReport.value = newValue
}

const isSavingDraft = ref(false)
const hasCreatedNewDraft = ref(false)
const isGeneratingReport = ref(false)

const saveDraft = async () => {
  console.log("Saving Draft: ", draftReport.value)
  isSavingDraft.value = true
  try {
    await saveDraftResource.submit({ 
      student_id: route.params.student,
      report_draft: draftReport.value,
     })
    if (saveDraftResource.data.status === 'success') {
      console.log("New Draft report saved!")
      hasCreatedNewDraft.value = true
      isGeneratingReport.value = true
    } else {
      console.error(saveDraftResource.data.message || "Failed to create draft")
    }
  } catch (error) {
    console.log(`Error making save request: ${error}`)
  }
  isSavingDraft.value = false
}

const saveEditedDraft = async () => {
  isSavingDraft.value = true
  try {
    await modifyDraftResource.submit({ 
      report_id: reportId.value,
      report_draft: draftReport.value
     })
    if (modifyDraftResource.data.status === 'success') {
      console.log("Edited Draft Saved!")
    } else {
      console.error(modifyDraftResource.data.message || "Failed to edit draft")
    }
  } catch (error) {
    console.log(`Error making edit request: ${error}`)
  }
  isSavingDraft.value = false
}

const regenerateReport = async () => {
  if (!reportId.value)
    return

  isGeneratingReport.value = true
  try {
    await reformatReportResource.submit({ 
      report_id: reportId.value,
     })
    if (reformatReportResource.data.status === 'success') {
      console.log("Generated Formatted Report!")
      assessmentResource.reload({
        student_id: route.params.student,
      })
    } else {
      console.error(reformatReportResource.data.message || "Failed to generate Formatted Report")
    }
  } catch (error) {
    console.log(`Error making edit request: ${error}`)
  }
  isGeneratingReport.value = false
}

const editFormattedResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.student_assessment_report.api.save_new_formatted_report',
})
const isReportEditable = ref(false)
const editedReport = ref('')

const modifyEditedReport = (newValue) => {
  editedReport.value = newValue
}

const editFormattedReport = async () => {
  if (!reportId.value)
    return

  isReportEditable.value = false
  isSavingDraft.value = true
  try {
    await editFormattedResource.submit({ 
      report_id: reportId.value,
      formatted_report: editedReport.value,
     })
    if (editFormattedResource.data.status === 'success') {
      console.log("Saved edited report!")
    } else {
      console.error(editFormattedResource.data.message || "Failed to save edited Formatted Report")
    }
  } catch (error) {
    console.log(`Error making edit request: ${error}`)
  }
  isSavingDraft.value = false
}
</script>