<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full lg:max-h-[90vh]">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Link Curriculum Event
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>

        <div class="overflow-auto">
          <div class="p-4 flex flex-col gap-4">
            <div v-if="props.observation" class="bg-[#FFFAE6] p-4 rounded-md flex flex-col gap-2">
              <p class="font-semibold">Observation Details:</p>
              <div class="flex gap-16">
                <div class="flex gap-2">
                  <p>Session:</p>
                  <p>{{ props.observation.activity_time }}</p>
                </div>
                <div class="flex gap-2">
                  <p>Date:</p>
                  <p>{{ props.observation.date }}</p>
                </div>
              </div>
              <div>
                <p>Cohorts:</p>
                <div class="flex flex-wrap gap-2">
                  <div v-for="cohort in props.observation.cohorts"
                    class="bg-[#FDD835] px-2 rounded">
                    {{ cohort.cohort_name }}
                  </div>
                </div>
              </div>
              <div class="flex gap-2">
                <p>Educator:</p>
                <p v-if="userDetails">
                  {{ findValueByName(userDetails?.school_info.educators, props.observation.educator_name, 'educator_name') }}
                </p>
              </div>
              <div>
                <p>Activity Summary:</p>
                <p>{{ props.observation.activity_summary }}</p>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <p class="font-semibold">
                Activities from the same day:
              </p>
              <div v-if="!props.events || !props.events[props.observation?.date]">
                No activities found with the same date as the observations.
              </div>
              <div v-for="event in props.events[props.observation.date]"
                v-if="props.events && props.events[props.observation?.date]"
                :class="cn('border rounded p-2 cursor-pointer',
                selectedEvent === event.name && 'border-[#FDD835] border-2',
                props.observation.event?.name === event.name && 'border-[#FDD835] border-2 border-dashed')"
                @click="() => selectEvent(event.name)">
                <!-- {{ event }} -->
                <div class="flex gap-2">
                  <p>Activity Title:</p>
                  <p v-if="event.linked_activity_name">{{ event.linked_activity_name }}</p>
                  <p v-else-if="event.activity_name">{{ event.activity_name }}</p>
                  <p v-else>Unnamed Activity</p>
                </div>
                <div class="flex gap-16">
                  <div class="flex gap-2">
                    <p>Session:</p>
                    <p>{{ event.session }}</p>
                  </div>
                  <div class="flex gap-2">
                    <p>Date:</p>
                    <p>{{ event.date }}</p>
                  </div>
                </div>
                <div class="flex gap-2">
                  <p>Cohorts:</p>
                  <div class="flex flex-wrap gap-2">
                    <div v-for="cohort in event.cohorts"
                      class="bg-[#FDD835] px-2 rounded">
                      {{ cohort.cohort_name }}
                    </div>
                  </div>
                </div>
                <div class="flex gap-2">
                  <p>Led by:</p>
                  <div class="flex flex-wrap gap-2">
                    <div v-for="educator, index in event.led_by" >
                      <!-- class="bg-[#FDD835] px-2 rounded"> -->
                      <span v-if="index !== 0">, </span>
                      {{ educator.educator_name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

          <div class="flex justify-end pb-2 px-4">
            <button type="button" @click="linkActivity" 
              :disabled="selectedEvent === '' || isSaving || props.observation?.event?.name === selectedEvent"
              class="py-3 px-4 inline-flex items-center gap-x-2
              text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
              hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
              Link Activity
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref, version, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { findValueByName } from '../../lib/utils';
import { HSOverlay } from 'preline/preline';
import { createResource, createListResource } from 'frappe-ui';

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const props = defineProps([
  'modalName', 'observation', 'events',
  'linkActivityFn'
])

const selectedEvent = ref('')
const isSaving = ref(false)

const userDetails = inject('userDetails')

const selectEvent = (eventId) => {
  if (selectedEvent.value === eventId) {
    selectedEvent.value = ''
  } else {
    selectedEvent.value = eventId
  }
}

const linkActivity = async () => {
  isSaving.value = true
  await props.linkActivityFn(props.observation.name, selectedEvent.value)
  isSaving.value = false
}
</script>