<template>
 
 <h1>Calendar</h1>
     <div class="calendar-inner-container top-inner-shadow absolute md:right-[18vw] right-0 md:top-[2vh] w-full max-w-full md:w-[65vw] h-auto md:h-auto p-2 md:p-5 rounded-md border border-solid overflow-hidden max-h-[74vh] md:max-h-auto" v-if="isDataLoaded">

      <PublicCalendarComponent :events="events"></PublicCalendarComponent>
     
      <!-- <div v-else>Loading...</div> -->
    </div>
  </template>
  
  <script>
  import PublicCalendarComponent from '../components/PublicCalendarComponent.vue';
  
  
  
  
  export default {
    inject: ["$call"],
    components: {
      PublicCalendarComponent
    }, 
    data() {
      return {
        events: [], // Store fetched events here
        isDataLoaded: false, // Flag to check if data is loaded
      };
    },
    async mounted() {
      await this.fetchWeeklyEvents();
    },
    methods: {
      async fetchWeeklyEvents() {
  try {
    const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_weekly_events');
    // Transform data to match the format expected by the calendar
    this.events = response.map(event => ({
      id: event.name, // or any unique identifier
      title: event.name1, // Transforming 'name1' to 'title'
      start: event.start,
      end: event.end
    }));
    this.isDataLoaded = true;
    
  } catch (error) {
    console.error('Error fetching weekly events:', error);
    // Handle errors appropriately
  }
}

    }
  };
  </script>
 