<template>
  <div class="flex gap-2 items-center">
    <div class="px-8 md:px-4 flex gap-1 items-center">
      <div v-for="n in props.totalPages" :class="cn(`h-2 w-3 bg-[#F3F4F6]`, n <= props.pageNum && 'bg-[#FFD700]')">
        <!-- <div :class="cn(`h-full w-full`, n <= pageNum && 'bg-[#FFD700]')"></div> -->
      </div>
    </div>
    <div class="text-sm font-semibold text-gray-600">
      {{ props.pageNum }}/{{ props.totalPages }}
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'

const props = defineProps(['pageNum', 'totalPages'])
</script>