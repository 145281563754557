<template>
  <div class="border p-2 min-h-96">
    <div class="flex gap-2">
      <button class="bg-gray-300 p-1 rounded" @click="quill?.format('bold', true)">Bold</button>
      <button class="bg-gray-300 p-1 rounded" @click="quill?.format('italic', true)">Italic</button>
      <button class="bg-gray-300 p-1 rounded" @click="quill?.format('header', 1)">H1</button>
      <button class="bg-gray-300 p-1 rounded" @click="quill?.format('header', 2)">H2</button>
    </div>
    <QuillyEditor
      ref="editor"
      v-model="model"
      :options="options"
      @update:modelValue="onModelValueChange"
      @text-change="({ delta, oldContent, source }) => console.log('text-change', delta, oldContent, source)"
      @selection-change="({ range, oldRange, source }) => console.log('selection-change', range, oldRange, source)"
      @editor-change="(eventName) => console.log('editor-change', `eventName: ${eventName}`)"
      @focus="(quill) => console.log('focus', quill)"
      @blur="(quill) => console.log('blur', quill)"
      @ready="(quill) => console.log('ready', quill)"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import Quill, { Delta, Range } from 'quill/core' // Core build
// import Quill from 'quill' // Full build
import { QuillyEditor } from 'vue-quilly'
import 'quill/dist/quill.core.css' // Required
import List from 'quill/formats/list'
// import QuillMarkdown from 'quilljs-markdown'
// import 'quilljs-markdown/dist/quilljs-markdown-common-style.css' // recommend import css, @option improve common style
// import 'quill/dist/quill.snow.css'

const props = defineProps([
  'editorContent', 'readOnly'
])
const emit = defineEmits(['change:content'])

const Inline  = Quill.import('blots/inline')
const Block = Quill.import('blots/block')
// const QList = Quill.import('formats/list')

class BoldBlot extends Inline {}
BoldBlot.blotName = 'bold';
BoldBlot.tagName = 'strong';

class ItalicBlot extends Inline { }
ItalicBlot.blotName = 'italic';
ItalicBlot.tagName = 'em';

class HeaderBlot extends Block {
  static formats(node) {
    return HeaderBlot.tagName.indexOf(node.tagName) + 1;
  }
}
HeaderBlot.blotName = 'header';
HeaderBlot.tagName = ['H1', 'H2'];


Quill.register(BoldBlot);
Quill.register(ItalicBlot);
Quill.register(HeaderBlot);
Quill.register(List, true);

const options = {
  placeholder: 'Enter text here...',
  readOnly: props.readOnly || false,
}

const editor = ref()
const model = ref(props.editorContent || '')
const editorDelta = ref()
const editorRange = ref()

// const markdownOptions = {}

// Quill instance
let quill = null
onMounted(() => {
  quill = editor.value?.initialize(Quill)
  // const quillMarkdown = new QuillMarkdown(quill, markdownOptions)
})

watch(() => props.editorContent, (newContent) => {
  model.value = newContent
})

const onModelValueChange = (value) => {
  // (value) => console.log('HTML model updated:', value)
  emit('change:content', value)
}

watch(() => props.readOnly, (newReadOnly) => {
  if (!newReadOnly) {
    quill?.enable(true)
  } else {
    quill?.enable(false)
  }
})
</script>