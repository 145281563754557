<template>
  <div :class="cn('p-8 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-6">
      <p class="font-medium text-sm md:text-base">
        5. What are the secondary competencies being explored in the activity? (optional)
      </p>
      <div :class="cn('flex flex-col gap-y-2 md:gap-y-4', textMode && 'hidden')">
        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Critical thinking" 
              id="subcomp-checkbox-group-7" v-model="checked" name="secondary_competencies">
            Critical thinking
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Emotional Intelligence" 
              id="subcomp-checkbox-group-1" v-model="checked" name="secondary_competencies">
            Emotional Intelligence
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Fine Motor Skills" 
              id="subcomp-checkbox-group-1" v-model="checked" name="secondary_competencies">
              Fine Motor Skills
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Math & Numeracy" 
              id="subcomp-checkbox-group-3" v-model="checked" name="secondary_competencies">
            Math & Numeracy
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Music & Rhythm" 
              id="subcomp-checkbox-group-6" v-model="checked" name="secondary_competencies">
            Music & Rhythm
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Physical Wellness" 
              id="subcomp-checkbox-group-5" v-model="checked" name="secondary_competencies">
            Physical Wellness
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Reading & Writing" 
              id="subcomp-checkbox-group-2" v-model="checked" name="secondary_competencies">
            Reading & Writing
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Science Exploration"
              id="subcomp-checkbox-group-4" v-model="checked" name="secondary_competencies">
            Science Exploration
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Storytelling"
              id="subcomp-checkbox-group-4" v-model="checked" name="secondary_competencies">
              Storytelling
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Visual Arts"
              id="subcomp-checkbox-group-4" v-model="checked" name="secondary_competencies">
              Visual Arts
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Other" 
              id="subcomp-checkbox-group-8" v-model="checked" name="secondary_competencies">
              Other
          </label>
        </div>
        <div :class="cn('relative px-4', !checked.includes('Other') && 'hidden')" @keydown.stop>
          <!-- <input type="text" class="py-3 pe-0 block w-full bg-transparent border-t-transparent 
            border-b-2 border-x-transparent border-b-gray-200 text-sm focus:border-t-transparent 
            focus:border-x-transparent focus:border-b-blue-500 focus:ring-0 disabled:opacity-50 
            disabled:pointer-events-none md:w-2/3" placeholder="Competency Name" name="other_secondary"
            autocomplete="off"> -->
            <SelectSearch v-if="props.competencyList && renderSelect" placeholder="Select Competencies..." 
              :dataList="filteredCompetencyList" inputName="other_secondary" dispName="competency_name"
              :defaultList="defaultSelectInputValues" />
        </div>
      </div>
      <div class="flex flex-col gap-1 md:gap-2 rounded p-2 bg-[#FEF3C2]">
        <div class="text-sm font-normal pl-4">
          Cannot find what you are looking for?
        </div>
        <div class="flex">
          <label class="text-sm text-gray-800 ms-4 font-semibold flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="" 
              id="subcomp-checkbox-group-8" v-model="textMode" name="text_mode_secondary">
              Enter as text
          </label>
        </div>
      </div>
      <p class="font-medium text-xs md:text-sm text-gray-600">This question is optional.</p>
      <div :class="cn(!textMode && 'hidden')" @keydown.stop>
        <textarea id="hs-autoheight-textarea-custom-comp-act" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
          rows="10" placeholder="Enter competencies..." name="custom_secondary_competencies" v-model="compText" ref="compTextRef"
          @input="textareaAutoHeight"></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { findNameByValue } from '@/lib/utils.js'
import { computed, ref, watch } from 'vue'
import SelectSearch from '../../../ui/SelectSearch.vue';

const props = defineProps([
  'pageNum', 'componentPageNum', 'observation', 
  'competencyList', 'defaultCompetencies', 'defaultChecked',
  'fetchedComps'
])

const compItems = [
  "Emotional Intelligence",
  "Reading & Writing",
  "Math & Numeracy",
  "Science Exploration",
  "Physical Wellness",
  "Music & Rhythm",
  "Critical Thinking",
  "Storytelling",
  "Visual Arts"
]

const filteredCompetencyList = computed(() => {
  if (!props.competencyList) return []
  return props.competencyList.filter((comp) => {
    return !compItems.some(str => str === comp.competency_name)
  })
})

const defaultCheckedPlusFetched = computed(() => {
  if (!props.defaultChecked && !props.fetchedComps)
    return []
  if (!props.fetchedComps)
    return props.defaultChecked
  if (!props.defaultChecked)
    return props.fetchedComps
  const arr = [...props.defaultChecked]
  props.fetchedComps.forEach(comp => {
    if (!arr.includes(comp) && compItems.includes(comp))
      arr.push(comp)
    else arr.push('Other')
  });
  return arr
})

const checked = ref(defaultCheckedPlusFetched.value)
const textMode = ref(false)

watch(defaultCheckedPlusFetched, () => {
  if (!Array.isArray(defaultCheckedPlusFetched.value)) return
  checked.value = defaultCheckedPlusFetched.value
})

const renderSelect = ref(true)
const defaultSelectInputValues = computed(() => {
  if (!props.defaultCompetencies && !props.fetchedComps) return []
  if (!props.fetchedComps)
    return props.defaultCompetencies
  const compIdList = props.fetchedComps.filter((comp) => {
    return !compItems.includes(comp)
  }).map((comp) => {
    return findNameByValue(props.competencyList, comp, 'competency_name')
  }).filter((comp) => comp !== '')

  if (!props.defaultCompetencies)
    return compIdList
  return [...new Set([...props.defaultCompetencies, ...compIdList])]
})

watch([() => props.defaultCompetencies, () => props.fetchedComps], () => {
  if (!checked.value.includes('Other'))
    checked.value.push('Other')
})

watch(defaultSelectInputValues, () => {
  renderSelect.value = false
  setTimeout(() => {
    renderSelect.value = true
  }, 100)
})

const compText = ref('')
const compTextRef = ref(null)
const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  compTextRef.value.style.height = 'auto'
  let calculatedHeight = compTextRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  compTextRef.value.style.height = `${calculatedHeight}px`
}

watch(textMode, () => {
  if (!textMode.value) compText.value = ''
})
</script>