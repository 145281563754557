<template>
    <div class="relative">
      <!-- Labels Input -->
      <input
        v-model="currentLabel"
        @input="filterTags"
        @keydown.enter.prevent="addTag(currentLabel)"
        class="border border-slate-200 rounded-md w-full"
        type="text"
        placeholder="Labels"
      >
  
      <!-- Dropdown for tag suggestions -->
      <div v-if="showDropdown" class="absolute border border-slate-200 bg-white w-full z-10">
        <ul>
          <li v-for="(tag, index) in filteredTags" :key="index" @click="addTag(tag)" class="p-2 hover:bg-slate-100 cursor-pointer">
            {{ tag }}
          </li>
          <li v-if="currentLabel" @click="addTag(currentLabel)" class="p-2 hover:bg-slate-100 cursor-pointer">
            Save "{{ currentLabel }}" as a new tag
          </li>
        </ul>
      </div>
  
      <!-- Display Selected Tags -->
      <div class="flex flex-wrap mt-2">
        <span v-for="(label, index) in labels" :key="index" class="bg-[#FDD835] rounded-full px-3 py-1 text-sm font-semibold text-black mr-2 mb-2">
          {{ label }}
          <button @click.prevent="removeTag(index)">x</button>
        </span>
      </div>
    </div>
</template>
  
  <script>
  export default {
    inject: ["$call"], // function provided by doppio library 
    props:{
        initialTags: {
        type: Array,
        default: () => [],
       },
    },
    data() {
      return {
        currentLabel: '',
        labels: [], // Store selected labels as an array
        allTags: [], // Placeholder for all available tags
        filteredTags: [],
        showDropdown: false,
      };
    },
    mounted() {
    this.fetchImageTags();
    // Initialize labels with initialTags
    this.labels = [...this.initialTags];
   },

    methods: {
      filterTags() {
        if (!this.currentLabel) {
          this.showDropdown = false;
          return;
        }
  
        this.filteredTags = this.allTags.filter(tag =>
          tag.toLowerCase().includes(this.currentLabel.toLowerCase()) &&
          !this.labels.includes(tag)
        );
  
        this.showDropdown = true;
      },
      addTag(tag) {
        if (!this.labels.includes(tag)) {
          this.labels.push(tag);
        }
        this.currentLabel = '';
        this.showDropdown = false;

        // updateSelectedTags is called to emit the event
        this.updateSelectedTags();
      },

      removeTag(index) {
        this.labels.splice(index, 1);
         //updateSelectedTags is called to emit the event
         this.updateSelectedTags();
        },

      async fetchImageTags() {
        try {
            // Call the server-side method to get image tags
            const imageTags = await this.$call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.get_image_tags');
            // Clear the existing tags to avoid duplicates
            this.allTags = [];

            // Iterate through the response and assign tag values to allTags
            imageTags.forEach(tagObj => {
            this.allTags.push(tagObj.tag); //the field name is 'tag'
            });
            console.log("These are the fetched Image tags for dropdown==========================",imageTags); // Just for demonstration, replace this with your actual logic
        } catch (error) {
            console.error("Error fetching image tags:", error);
            // Handle the error as appropriate for your application
        }
        
      },
      // This method is called whenever the selected tags change (when a new tag is added or removed)
      updateSelectedTags() {

            this.$emit('update-tags', this.labels);
        },

    },
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  