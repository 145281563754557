<template>
  <Card class="md:max-w-[450px] h-full">
    <template #title>
      {{ props.activity.activity_name }}
    </template>
    <div class="flex flex-col gap-4 flex-auto mt-4">
      <div class="flex flex-col gap-4 flex-auto">
        <div class="flex flex-wrap gap-4">
          <div v-for="comp in props.activity.competencies.slice(0, 3)" class="bg-[#FDD835] py-1 px-2 rounded">
            {{ comp.competency_name }}
          </div>
        </div>
        <p class="text-sm text-gray-500 text-ellipsis line-clamp-3">
          {{ getSummaryText(props.activity.summary) }}
        </p>
      </div>
      <div class="flex gap-4 justify-stretch items-center">
        <ActivityModal :modalName="`activity-search-view-modal-${props.activity.name}`" 
          :activityData="props.activity">
          <template #trigger>
            <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold 
              rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 disabled:opacity-50 
              disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 justify-center
              dark:focus:ring-gray-600 dark:bg-white dark:text-gray-800 flex-auto" 
              :data-hs-overlay="`#hs-activity-search-view-modal-${props.activity.name}`">
              View Details
            </button>
          </template>
        </ActivityModal>
        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium 
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
          disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 
          dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 
          dark:focus:ring-gray-600 justify-center flex-auto" disabled>
          Add to Schedule
        </button>
      </div>
    </div>
  </Card>
</template>

<script setup>
import Card from '../../ui/Card.vue';
import ActivityModal from './ActivityModal.vue'

const props = defineProps(['activity'])
console.log(props.activity)

const getSummaryText = (summary) => {
  const newEl = document.createElement('div')
  newEl.innerHTML = summary
  return newEl.textContent
}
</script>