<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Set Extraction Prompt
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>

        <div class="mt-3">
            <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col gap-4 p-4" ref="formRef">
              <div class="flex flex-col gap-4">
                <div class="flex gap-2 items-center z-50">
                  <div class="font-semibold">Model:</div>
                  <div class="hs-dropdown relative inline-flex">
                    <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex
                      items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white
                      text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none
                      dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                      {{ MODELMAP[pickedModel] }}
                      <svg class="hs-dropdown-open:rotate-180 size-4" xmlns="http://www.w3.org/2000/svg" width="24"
                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
                    </button>
                    <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100
                      opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-neutral-800
                      dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute
                      after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4
                      before:start-0 before:w-full" aria-labelledby="hs-dropdown-default">
                      <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
                        hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
                        dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                        @click="pickedModel = 'gpt-3.5-turbo-0125'">
                        GPT 3.5 Turbo
                      </a>
                      <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
                        hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
                        dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                        @click="pickedModel = 'gpt-4'">
                        GPT 4
                      </a>
                      <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
                        hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
                        dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                        @click="pickedModel = 'gpt-4-32k'">
                        GPT 4 32K
                      </a>
                      <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
                        hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
                        dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                        @click="pickedModel = 'gpt-4-turbo'">
                        GPT 4 Turbo
                      </a>
                      <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
                        hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
                        dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                        @click="pickedModel = 'gpt-4o'">
                        GPT 4o
                      </a>
                      <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
                        hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
                        dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
                        @click="pickedModel = 'claude-3-5-sonnet'">
                        Claude 3.5 Sonnet
                      </a>
                    </div>
                  </div>
                </div>
                <div class="space-y-4">
                  <label class="font-semibold">Prompt: </label>
                  <textarea id="hs-autoheight-textarea-1" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
                    focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" rows="10"
                    placeholder="Enter prompt here..." name="prompt" v-model="prompt" ref="promptInputRef"
                    @input="textareaAutoHeight"></textarea>
                </div>
              </div>
              <div>
                <span v-if="isSubmitting" class="font-semibold text-gray-600">
                  Saving Prompt...
                </span>
                <span v-if="!isSubmitting && isSaved" class="font-semibold text-gray-600">
                  Prompt Saved Successfully!
                </span>
              </div>
              <div class="flex gap-4 justify-end">
                <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                  rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                  dark:focus:ring-gray-600`)" :disabled="isSubmitting">
                  Save
                </button>
              </div>
            </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline';
import { createResource } from 'frappe-ui'

const props = defineProps([
  'modalName', 'param_id', 'prompt',
  'model'
])

const emit = defineEmits([
  'change:params'
])

const MODELMAP = {
  'gpt-3.5-turbo-0125': 'GPT-3.5 Turbo',
  'gpt-4': 'GPT-4',
  'gpt-4-32k': 'GPT-4 32K',
  'gpt-4-turbo': 'GPT-4 Turbo',
  'gpt-4o': 'GPT-4o',
  'claude-3-5-sonnet': 'Claude 3.5 Sonnet'
}

const prompt = ref(props.prompt || '')
const pickedModel = ref(props.model || 'gpt-4-turbo')

watch([() => props.prompt, () => props.model], ([newPrompt, newModel]) => {
  prompt.value = newPrompt;
  pickedModel.value = newModel;
})

const formRef = ref(null)
const promptInputRef = ref(null)

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const savePromptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.generation_parameters.api.save_prompt',
})

const isSubmitting = ref(false)
const isSaved = ref(false)

const handleSubmit = async () => {
  isSaved.value = false

  if (!props.param_id) {
    alert('Cannot save without param Id!')
    return
  }
  if (!prompt) {
    alert('Prompt cannot be empty!')
    return
  }

  isSubmitting.value = true
  try {
    await savePromptResource.submit({
      param_id: props.param_id,
      model: pickedModel.value,
      prompt: prompt.value,
    })
    if (savePromptResource.data?.status === 'success') {
      console.log(savePromptResource.data.message)
      isSaved.value = true
      HSOverlay.close(`#hs-${props.modalName}`)
      emit('change:params', [
        prompt.value, pickedModel.value, props.param_id
      ])
    } else {
      console.error(savePromptResource.data.message)
    }
    console.log(savePromptResource.data.message)
  } catch (error) {
    console.error(error)
  }
  isSubmitting.value = false
}

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  promptInputRef.value.style.height = 'auto'
  let calculatedHeight = promptInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight / 2.5)
    calculatedHeight = windowHeight / 2.5
  promptInputRef.value.style.height = `${calculatedHeight}px`
}
</script>