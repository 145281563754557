<template>
  <DailyFormAnecdoteComp :pageNum="props.pageNum" :componentPageNum="props.componentPageNum"
    :competencyList="filteredCompetencyList" :defaultCheckPairs="compPairs" 
    :defaultCompetencies="defaultSelectedComps" />
</template>

<script setup>
import { createListResource } from 'frappe-ui';
import { computed } from 'vue';
import DailyFormAnecdoteComp from '@/components/observations/forms/DailyForm/DailyFormAnecdoteComp.vue'

const props = defineProps(['pageNum', 'componentPageNum', 'observation' ])

const compItems = [
  "Emotional Intelligence",
  "Reading & Writing",
  "Math & Numeracy",
  "Science Exploration",
  "Physical Wellness",
  "Music & Rhythm",
  "Critical Thinking",
  "Storytelling",
  "Visual Arts"
]

const competencyList = createListResource({
  doctype: 'Competency',
  fields: ['name','competency_name', 'description'],
  orderBy: 'competency_name asc',
  start: 0,
  pageLength: 90,
  auto: true
})

const filteredCompetencyList = computed(() => {
  if (!competencyList.data) return []
  return competencyList.data.filter((comp) => {
    return !compItems.some(str => str === comp.competency_name)
  })
})

const compPairs = computed(() => {
  if (!props.observation?.competencies) return []
  const checkedComps = props.observation.competencies.filter((comp) => compItems.includes(comp.competency_name))
    .map((comp) => comp.competency_name)

  if (checkedComps.length !== props.observation.competencies.length) {
    checkedComps.push("Other")
  }

  return checkedComps
})

const defaultSelectedComps = computed(() => {
  if (!props.observation?.competencies) return []
  return props.observation.competencies.map((comp) => comp.name)
})
</script>