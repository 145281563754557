<template>
 
    <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "closeComponent">
        <div class="bg-white rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97vh] overflow-auto">
        
       
        <!-- Cross button -->
        <div class="sticky top-2 mr-2 flex justify-end z-10">
            <button @click="closeComponent" class="border-black">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        
        <!-- Form container -->
        <div v-if="optionsResponse" class="event-form relative px-4 text-xs md:text-sm">
            <form @submit.prevent="createWorksheet" class="flex flex-col gap-1.5">

                <!-- Heading -->
                <div class="font-semibold text-black text-xl self-center"> Add Worksheet</div>                       
                
                <!-- Input for Worksheet Title-->
                <div class="flex flex-col items-center justify-between mb-1.5">
                    <label for="title" class="font-medium self-start text-black mr-2 mb-4">Worksheet Title:</label>
                    <div class="w-[15rem] md:w-full">
                        <input id="worksheet_title" ref="worksheet_title" placeholder="Enter title here"
                          class="w-[15rem] md:w-[100%] h-25 border border-gray-300 rounded-md text-sm md:text-sm align-middle"> 
                        </input>
                    </div>
                </div>

                <!-- Your name/ Educators Select -->
                <div class="flex flex-col items-center justify-between mb-1.5">
                <label for="educatorsSelect" class="font-medium text-black mr-2 self-start mb-3 mt-3">Your Name:</label>
                <!-- Select -->
                <div class="relative w-[15rem] md:w-full border rounded-md">
                    <select id="educatorsSelect" ref="educatorsSelect" data-hs-select='{
                        "placeholder": "Select...",
                        "toggleTag": "<button type=\"button\"></button>",
                        "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                        "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
                        "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
                        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>",
                        "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
                        }' class="hidden">
                        <option> </option>
                        <option v-for="educator in optionsResponse.educators" :key="educator.name" :value="educator.name">{{ educator.educator_name }}</option>
                    </select>
                    <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                    </div>
                </div>
                </div>  
                <!-- End Select -->
            
                
                <!-- who is this worksheet intended for/ intended Cohort -->
                <div class="font-medium text-black mt-3">Who is this Worksheet intended for?</div>
                <div class="flex flex-wrap w-full">
                    <div v-for="cohort in optionsResponse.cohorts" :key="cohort.name" class="ml-2 mb-2 w-[160px]">  
                            <input type="checkbox" :value="cohort.name" v-model="selectedCohorts" class="shrink-0 mt-0.5 border-gray-200 rounded" id="">
                            <label :for="cohort.name" class="text-sm text-gray-500 ms-3">{{ cohort.cohort_name }}</label>
                    </div>
                </div> 
                           
                
                <!-- Competencies Select -->
                <div class="flex flex-col items-center justify-between mb-1.5">
                <label for="competenciesSelect" class="font-medium text- text-black mr-2 self-start mb-3 mt-3">Competencies:</label> 
                        
                <!-- Select -->
                <div class="relative border rounded-md w-[14rem] md:w-full">
                <select id="competenciesSelect" ref="competenciesSelect" multiple data-hs-select='{
                    "hasSearch": true,
                        "placeholder": "Select...",
                        "toggleTag": "<button type=\"button\"></button>",
                        "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-xs md:text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                        "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                        "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                    }' class="hidden">
                    <option v-for="competency in optionsResponse.competencies" :key="competency.name" :value="competency.name">{{ competency.competency_name }}</option>
                </select>
                <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                    </div>
                </div>
                <!-- End Select -->
                </div>

                                                                           
                <!-- Input for Worksheet link -->
                <div class="flex flex-col items-center justify-between mb-1.5">
                    <label for="worksheet_link" class="font-medium text-black mr-2 self-start mt-3 mb-3">Worksheet Link:</label>
                    <div class="w-[15rem] md:w-full">
                    <input id="worksheet_link" ref="worksheetLink" type="text" placeholder="url"
                        class="w-full border border-gray-300 rounded-md px-4 text-xs md:text-sm align-middle">
                    </div>      
                </div>  

 
                <!-- Input for Summary -->
                 <div class="flex flex-col items-center justify-between mb-1.5">
                    <label for="summary" class="font-medium text- text-black mr-2 self-start mt-3 mb-3">summary:</label>
                    <div class="w-[15rem] md:w-full">
                        <textarea id="summary" ref="summary" placeholder="Activity summary"
                        class="w-[15rem] md:w-[100%] h-20 border border-gray-300 rounded-md text-sm md:text-sm align-middle"></textarea>
                    </div>
                 </div>


                <!-- Upload/choose file -->
                <label for="upload_file" class="font-medium text-black mr-2 self-start mt-3 mb-3">Upload Worksheet:</label>
                    <label for="large-file-input" class="sr-only">Choose file</label>
                    <input type="file" name="large-file-input" id="large-file-input" @change="handleFileUpload" class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400
                    file:bg-gray-50 file:border-0
                    file:me-4
                    file:py-3 file:px-4 file:sm:py-5
                   mb-6">
               
                <!-- Submit Button -->   
                <div class="flex mb-6">
                   <button type="submit" class="py-3 px-2 w-1/2 md:w-[22%] bg-black text-white rounded-md cursor-pointer hover:bg-gray-800 block ml-2 mr-2">Save</button>
                   
                    <button @click="closeComponent" class="bg-white py-2 md:py-2 px-2 w-1/2 md:w-[22%] text-black rounded-md cursor-pointer hover:bg-gray-200 block ml-2 mr-2" style="border: 1px solid #cbd5e1;">Close</button>
                   
                </div>
            </form>

            
            
        </div>  
   
       </div>
     </div>
   </template>
     
<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { toRaw } from 'vue';
import moment from 'moment';
import { defineEmits } from 'vue';

// Inject the $call method
const $call = inject('$call');


const props = defineProps({
  optionsResponse: Object,
});

const emit = defineEmits(['close','worksheet-created', 'worksheet-error']);


const selectedCohorts = ref([]);
const worksheet_title = ref('');
const educatorsSelect = ref('');
const summary = ref('');
const competenciesSelect = ref(null);
const worksheetLink = ref('');



const selectedFile = ref(null); // For uploaded file 



const handleFileUpload = (event) => {
  console.log("Assign selected file funtion called")
  if(event.target.files){
    selectedFile.value = event.target.files;
    console.log("Selected files assigned =============",selectedFile.value)
  }
};

const closeComponent = () => {
        emit('close');
    };  


const createWorksheet = async () => {
    const titleValue = worksheet_title.value.value;
    console.log('Title:', titleValue);

    const educatorsSelectValue = educatorsSelect.value.value;
    console.log('Selected Educator:', educatorsSelectValue);

    const selectedCohortsValue = JSON.parse(JSON.stringify(selectedCohorts.value));
    console.log('Selected Cohorts:', selectedCohortsValue);
 
    const competencies = Array.from(competenciesSelect.value.selectedOptions).map(option => JSON.parse(JSON.stringify(option.value)));
    console.log('Selected Competencies:', competencies);

    const inputSummary = summary.value.value
    console.log('Summary:',inputSummary)

    const worksheetLinkValue = worksheetLink.value.value;
    console.log('Worksheet Link:', worksheetLinkValue);

    const csrfToken = window.csrf_token;

   
    var selectedFileValue = {}
    console.log("This is selected file value =======",selectedFile.value)
    // Ensure the file is properly selected
    if (selectedFile.value == null) {
        console.error("No file selected for upload");        
    }
    
    else {
        selectedFileValue = selectedFile.value[0];
        console.log(' this is Selected File value:======================================', selectedFileValue);
    }

    // Create a new worker
    const worker =  new Worker(new URL('./worksheet_worker.js', import.meta.url));

    // Handle messages from the worker
    worker.addEventListener('message', (e) => {
        const { type, message, error, details } = e.data;
        if (type === 'success') {
        console.log('Worker: Worksheet created successfully:', message);
        // alert(`Worksheet created successfully: ${message.message || JSON.stringify(message)}`);
        } else if (type === 'error') {
        console.error('Worker: Error creating worksheet:', error, details);
        alert(`Error creating worksheet: ${error}\n\nDetails: ${details || 'No additional details'}`);
        }
    });

    // Post data to the worker
    worker.postMessage({
        title: titleValue,
        worksheet_url: worksheetLinkValue,
        summary: inputSummary, 
        competencies,
        cohort: selectedCohortsValue,
        file: selectedFileValue || null,
        csrfToken
    });
    // Handle messages from the worker
    worker.addEventListener('message', (e) => {
        const { type, message, error, details } = e.data;
        if (type === 'success') {
            console.log('Worker: Worksheet created successfully:', message);
            // Emit success event to parent
            console.log("Emmitting successfully created message to parent ##############")
            try {
                emit('worksheet-created', message);
                console.log("successfully emmitted event to parent #####")
            }
            catch{
                console.log("Error emmiting event to parent ###################")
            }

        } else if (type === 'error') {
            console.error('Worker: Error creating worksheet:', error, details);
            // Emit error event to parent
            emit('worksheet-error', { error, details });
        }
    });

    closeComponent();
 };

 onMounted(()=>{
    setTimeout(() => {
        window.HSStaticMethods.autoInit();},100);
 })



watch(
    () => props.optionsResponse,
    (newVal, oldVal) => {
        if (newVal) {
            console.log("THis is options reposnse in create new worksheet modal",props.optionsResponse)
            setTimeout(() => {
                window.HSStaticMethods.autoInit();},100);
        }
    
    }
)

</script>
