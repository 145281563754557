<template>
  <div class="flex flex-col items-center gap-1">
    <router-link :to="`/front-portal/activities/domain/${domainId}`" class="border border-black rounded-full overflow-hidden flex justify-center items-center h-16 w-16 
      md:h-20 md:w-20 lg:h-24 lg:w-24 bg-[#FEF3C2] hover:bg-[#fceaa5] cursor-pointer select-none">
      <img :src="props.iconSrc" class="h-10 md:h-14 lg:h-16" />
    </router-link>
    <p class="text-xs lg:text-sm text-center max-w-12 lg:max-w-20">
      {{ props.domainName }}
    </p>
  </div>
</template>

<script setup>
const props = defineProps(['iconSrc', 'domainName', 'domainId'])
</script>