<template>
  <v-chart class="chart" :option="option" autoresize ref="chartRef" @finished="generateImageUrl" />
</template>

<script setup>
import { computed, ref } from 'vue'
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, VisualMapComponent, DatasetComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'

const props = defineProps({
  scoreAmountProductArray: { type: Array },
  scoreAmountProductsNames: { type: Array },
  mapText: { type: Array },
  maxScore: { type: Number },
})
const emit = defineEmits(['urlGenerated'])

use([
  DatasetComponent, GridComponent, VisualMapComponent,
  BarChart, CanvasRenderer, TooltipComponent
])

const option = computed(() => ({
  dataset: {
    source: [props.scoreAmountProductsNames, ...props.scoreAmountProductArray]
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: { containLabel: true },
  xAxis: { name: props.scoreAmountProductsNames[1] },
  yAxis: { type: 'category' },
  visualMap: {
    orient: 'horizontal',
    left: 'center',
    min: 0,
    max: props.maxScore,
    text: props.mapText,
    // Map the score column to color
    dimension: 0,
    inRange: {
      color: [ '#FD665F', '#FDD835','#65B581']
    }
  },
  series: [
    {
      type: 'bar',
      encode: {
        // Map the "amount" column to X axis.
        x: props.scoreAmountProductsNames[1],
        // Map the "product" column to Y axis
        y: props.scoreAmountProductsNames[2]
      }
    }
  ]
}))

const chartRef = ref(null)

const generateImageUrl = () => {
  const imageURl = chartRef.value.chart.getDataURL({
    type: 'png',
    pixelRatio: 2,
    backgroundColor: '#fff',
  })
  emit('urlGenerated', imageURl)
}
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
}
</style>