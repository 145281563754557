<template>
  <div :class="cn('p-4 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400')" >
    <div class="space-y-1">
      <label class="font-semibold">
        Comment:
      </label>
      <Mentionable
            :keys="['@']"
            :items="taggableUsers"
            offset="6"
            @apply="handleTagApply"
          >
      <textarea id="hs-autoheight-textarea-additional-notes" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        rows="5" placeholder="Enter text here..." name="comment" v-model="comment" ref="commentInputRef"
        @input="textareaAutoHeight" :disabled="props.isSavingComment"></textarea>
      </Mentionable>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref, watch, inject, computed } from 'vue';
import { Mentionable } from 'vue-mention';

const props = defineProps(['isSavingComment', 'commentResponse', 'userList'])
const emit = defineEmits([
  'insert:tag'
])

const comment = ref('')
const commentInputRef = ref(null)

watch(() => props.isSavingComment, () => {
  if (!props.isSavingComment && props.commentResponse === "") {
    comment.value = ''
  }
})

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  commentInputRef.value.style.height = 'auto'
  let calculatedHeight = commentInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/3)
    calculatedHeight = windowHeight/3
  commentInputRef.value.style.height = `${calculatedHeight}px`
}

const taggableUsers = computed(() => {
  return props.userList || []
})

const handleTagApply = (item) => {
  emit('insert:tag', item)
  // console.log("Pushing user to tagged list: ", item)
}
</script>

<style>
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(228, 199, 73);
}
</style>