<template>
  <div class="">
    <Dashboard v-if="uppy" :uppy="uppy" :props="{
        inline: true,
        hideUploadButton: true
      }" />
    
  </div>
</template>

<script setup>
import { ref, onMounted, inject, defineEmits, watch } from 'vue';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { Dashboard } from '@uppy/vue';

import GoldenRetriever from '@uppy/golden-retriever';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

// Inject the $call method
const $call = inject('$call');

const props = defineProps({
  startUpload: Boolean,
  formData: Object
});

// Define emits
const emit = defineEmits(['uploadStarted', 'uploadComplete', 'uploadError']);


const uppy = ref(null);
const filesSelected = ref(false);

const currentObjectKey = ref(null);  // Add this line to store the current object key


onMounted(async () => {
  uppy.value = new Uppy()
    .use(GoldenRetriever, {
      serviceWorker: true,
      indexedDB: {
        maxFileSize: 2 * 1024 * 1024 * 1024, // 2GB
      }
    })

    .use(AwsS3, {
    companionUrl: null,
    createMultipartUpload: async (file) => {
      console.log('createMultipartUpload called for file:', file.name);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.create_multipart_upload');
      console.log('createMultipartUpload response:', response);
      if (!response.success) throw new Error(response.error);
      return { uploadId: response.uploadId, key: response.key };
    },
    listParts: async (file, { uploadId, key }) => {
      console.log('listParts called for file:', file.name, 'uploadId:', uploadId, 'key:', key);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.list_parts', { uploadId, key });
      console.log('listParts response:', response);
      if (!response.success) throw new Error(response.error);
      return response.parts;
    },
    signPart: async (file, { uploadId, key, partNumber, body }) => {
      console.log('signPart called for file:', file.name, 'uploadId:', uploadId, 'key:', key, 'partNumber:', partNumber);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.get_signed_url_for_part', { uploadId, key, partNumber });
      console.log('signPart response:', response);
      if (!response.success) throw new Error(response.error);
      return { url: response.url };
    },
    
    completeMultipartUpload: async (file, { uploadId, key, parts }) => {
      console.log('completeMultipartUpload called for file:', file.name, 'uploadId:', uploadId, 'key:', key);
      
      // Format parts correctly for S3 API
      const formattedParts = parts.map(part => ({
        PartNumber: part.PartNumber,
        ETag: part.ETag || part.etag // Handle both uppercase and lowercase
      }));
      
      console.log('Formatted parts to be sent:', formattedParts);
      
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.complete_multipart_upload', { uploadId, key, parts: JSON.stringify(formattedParts) });
      
      console.log('completeMultipartUpload response:', response);
      if (!response.success) throw new Error(response.error);
      return { location: response.location };
    },

    abortMultipartUpload: async (file, { uploadId, key }) => {
      console.log('abortMultipartUpload called for file:', file.name, 'uploadId:', uploadId, 'key:', key);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.abort_multipart_upload', { uploadId, key });
      console.log('abortMultipartUpload response:', response);
      if (!response.success) throw new Error(response.error);
    },
    getUploadParameters: async (file) => {
      console.log('getUploadParameters called for file:', file.name);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.get_presigned_video_upload_url');
      console.log('getUploadParameters response:', response);

     
      if (!response.success) throw new Error(response.error);

      const { success, presigned_url, object_key } = response 
      currentObjectKey.value = object_key
      
      return {
        method: 'PUT',
        url: response.presigned_url,
        fields: {},
        headers: {
          'Content-Type': file.type,
        },
      };
    },
  });


  uppy.value.on('upload-success', async(file, response) => {
    console.log('File uploaded successfully:', file.name, response);
   
    console.log ("These is the description revieved in props=======================================", props.formData.description)
    console.log ("These is the Educator revieved in props======", props.formData.selectedEducator)
    console.log ("These is the mmanual dateTime revieved in props======", props.formData.manualDate)

    // Trigger transcoding and meta data update
    try {
      const transcodingResponse = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.transcode_uploaded_video', {
        object_key: currentObjectKey.value,  // Assuming the S3 object key is returned in the response
        description: props.formData.description,
        manual_date: props.formData.manualDate,
        selected_educator:props.formData.selectedEducator
      
      });
      
      if (transcodingResponse.success) {
        console.log('Transcoding job created:', transcodingResponse.job_id);
      } else {
        console.error('Failed to create transcoding job:', transcodingResponse.error);
      }
    } catch (error) {
      console.error('Error triggering transcoding:', error);
    }
  });

  uppy.value.on('upload-error', (file, error, response) => {
    console.error('Upload error:', file.name, error, response);
  });

  uppy.value.on('restore:start', () => {
    console.log('Attempting to restore upload...');
  });

  uppy.value.on('restore:success', (restoredFiles) => {
    console.log('Successfully restored', restoredFiles.length, 'files');
  });

  uppy.value.on('restore:error', (error) => {
    console.error('Error restoring uploads:', error);
  });

  uppy.value.on('file-added', () => {
    filesSelected.value = true;
  });

  uppy.value.on('file-removed', () => {
    filesSelected.value = uppy.value.getFiles().length > 0;
  });

  /// Method to start upload

  watch(() => props.startUpload, (newValue) => {
  if (newValue && uppy.value && filesSelected.value) {
    uppy.value.upload();
    emit('uploadStarted');
   }
  });
  

  
  });

</script>

<style>

 .uppy-Dashboard-Item-previewInnerWrap {

  background-color: #FEF3C2 !important;
}
</style>