<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <!-- <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Create New Activity
            </h3> -->
            <div class="inline-flex rounded-lg shadow-sm">
              <button type="button" :class="cn(`py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg 
                first:ms-0 last:rounded-e-lg text-xs md:text-sm font-medium focus:z-10 border border-gray-200 bg-white 
                text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none 
                dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 
                dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`, AIMode && `bg-gray-800 
                text-white hover:bg-gray-900`)" @click="turnAIModeOn" >
                Let AI do the work
              </button>
              <button type="button" :class="cn(`py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg 
                first:ms-0 last:rounded-e-lg text-xs md:text-sm font-medium focus:z-10 border border-gray-200 bg-white 
                text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none 
                dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 
                dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`, !AIMode && `bg-gray-800 
                text-white hover:bg-gray-900`)" @click="turnAIModeOff" >
                Create Manually
              </button>
            </div>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col" ref="formRef">
          <div v-if="AIMode">
            <div :class="cn(previewMode && 'hidden')">
              <PasteActivity :pageNum="pageNum" :componentPageNum="1" />
              <ActivityDetails :pageNum="pageNum" :componentPageNum="2" :detailsData="detailsResource.data" />
              <ActivityDescription :pageNum="pageNum" :componentPageNum="3" :descData="descResource.data"
                :updateIsRequestingFalse="updateIsRequestingFalse" />
            </div>
            <ActivityCardContent v-if="previewMode" :activityDetails="previewData" />
          </div>
          <div v-if="!AIMode">
            <div :class="cn(previewMode && 'hidden')">
              <ActivityDetails :pageNum="pageNum" :componentPageNum="1" />
              <ActivityDescription :pageNum="pageNum" :componentPageNum="2" />
            </div>
            <ActivityCardContent v-if="previewMode" :activityDetails="previewData" />
          </div>
          <div v-if="AIMode" :class="cn(`text-xs font-semibold text-gray-500 p-3 px-8`,
          !isRequesting && 'hidden')">
            Generating content, please wait.
          </div>
          <div v-if="(AIMode && pageNum === 3) || (!AIMode && pageNum === 2)" :class="cn(`text-xs 
          font-semibold text-gray-500 p-3 px-8`, !isSaving && 'hidden')">
            Saving new activity...
          </div>
          <div :class="cn(`text-sm font-semibold text-red-500 p-3 px-8`, 
            hideInputWarning && 'hidden')">
            {{ warnText }}
          </div>
          <div class="flex flex-col md:flex-row gap-4 md:items-center md:justify-between">
            <FormPageIndicator v-if="!(AIMode && pageNum === 1) && !previewMode" :pageNum="AIMode ? pageNum - 1: pageNum" 
              :totalPages="2" />
            <div class="flex justify-center md:justify-end items-center gap-4 py-3 px-4 flex-wrap">
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none`, AIMode && (pageNum === 1) && 'hidden')" 
                @click="goToPrevPage" :disabled="pageNum === 1">
                Back
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, !AIMode && 'hidden', AIMode && pageNum !== 1 && 'hidden')" 
                @click="getDetails" :disabled="!AIMode">
                Generate
              </button>
              <button type="button" :class="cn(`py-2 px-2 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, !AIMode && 'hidden', AIMode && pageNum !== 2 && 'hidden')" 
                @click="getDescription" :disabled="!AIMode">
                Generate Description
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, AIMode && pageNum === 3 && 'hidden', 
                AIMode && !detailsResource.data?.structured_data && (pageNum === 1) && 'hidden', !AIMode && pageNum === 2 && 'hidden')" 
                @click="goToNextPage" :disabled="pageNum === 3">
                Next
              </button>
              <button type="button"  :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, AIMode && pageNum !== 3 && 'hidden',
                !AIMode && pageNum !== 2 && 'hidden', previewMode && 'hidden')" 
                :disabled="(AIMode && pageNum !== 3) || (!AIMode && pageNum !== 2) || previewMode || isRequesting || isSaving" 
                @click="showPreview">
                Preview
              </button>
              <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, AIMode && pageNum !== 3 && 'hidden',
                !AIMode && pageNum !== 2 && 'hidden')" 
                :disabled="(AIMode && pageNum !== 3) || (!AIMode && pageNum !== 2) || isRequesting || isSaving">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { createResource } from 'frappe-ui'
import { HSOverlay } from 'preline/preline';
import PasteActivity from '@/components/activities/PasteActivity.vue'
import ActivityDetails from '@/components/activities/creation/ActivityDetails.vue'
import ActivityDescription from '@/components/activities/creation/ActivityDescription.vue';
import FormPageIndicator from '@/components/ui/FormPageIndicator.vue';
import ActivityCardContent from './view/ActivityCardContent.vue';


const props = defineProps(['modalName', 'userData'])

const pageNum = ref(1)
const AIMode = ref(true)   // 0 - paste activity, 1 - classic
const formRef = ref(null)
const warnText = ref("Please paste activity text!")
const previewMode = ref(false)
const previewData = ref(null)

const hideInputWarning = ref(true)

const isRequesting = ref(false)
const isSaving = ref(false)

const updateIsRequestingFalse = () => {
  isRequesting.value = false
}

const setHideWarningTime = (time) => {
  setTimeout(() => {
    if (hideInputWarning.value === false)
      hideInputWarning.value = true
  }, time)
}

const turnAIModeOn = () => {
  if (AIMode.value === true) return
  if (previewMode.value) previewMode.value = false
  detailsResource.reset()
  descResource.reset()
  AIMode.value = true
  pageNum.value = 1
}

const turnAIModeOff = () => {
  if (AIMode.value === false) return
  if (previewMode.value) previewMode.value = false
  AIMode.value = false
  pageNum.value = 1
}

const showPreview = () => {
  const formData = new FormData(formRef.value)
  const formDataObj = {}
  
  formData.forEach((value, key) => {
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  if (!Array.isArray(formDataObj.competencies)) {
    formDataObj.competencies = formDataObj.competencies.split(', ').map(comp => {
      return {
        'competency_name': comp
      }
    })
  }
  previewData.value = formDataObj
  previewMode.value = true
}

const detailsResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_creation.api.get_meta_data'
})

const descResource = createResource({
//  url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_creation.api.get_description'
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_creation.api.get_desc_stream'
})

const getDetails = async () => {
  const formData = new FormData(formRef.value)
  const raw_text = formData.get('raw_activity_text')
  if (!raw_text) {
    hideInputWarning.value = false
    setHideWarningTime(1500)
    return
  }
  detailsResource.update({
    params: {
      activity_editor_str: raw_text,
      model: 'claude',
  }})
  try {
    isRequesting.value = true
    await detailsResource.submit()
    console.log("Fetched Details", detailsResource)
    isRequesting.value = false
    if (detailsResource.data.status === "success") {
      goToNextPage()
    } else {
      console.error("Failed to fetch details please try again!")
      console.error(detailsResource.data.message)
    }
  } catch (error) {
    console.error(error)
  }
}

const getDescription = async () => {
  const formData = new FormData(formRef.value)
  const raw_text = formData.get('raw_activity_text')
  if (!raw_text) {
    hideInputWarning.value = false
    setHideWarningTime(1500)
    return
  }
  descResource.update({
    params: {
      activity_editor_str: raw_text,
  }})
  try {
    isRequesting.value = true
    descResource.submit()
    goToNextPage()
    // isRequesting.value = false
  } catch (error) {
    console.error(error)
  }
}

const goToPrevPage = () => {
  if (pageNum.value <= 1) return
  if (previewMode.value) {
    previewMode.value = false
    return
  }
  pageNum.value--
}

const goToNextPage = () => {
  pageNum.value++
}

const activityCreationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_creation.api.save_activity_data'
})

const handleSubmit = async (e) => {
  const formData = new FormData(e.target)

  const formDataObj = {}
  formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  // if (!Array.isArray(formDataObj.competencies)) {
  //   formDataObj.competencies = formDataObj.competencies.split(', ').map(comp => {
  //     return { competency: comp, isGPTGen: true }
  //   })
  // }
  if (!Array.isArray(formDataObj.competencies)) {
    formDataObj.competencies = formDataObj.competencies ? [formDataObj.competencies] : []
  }
  formDataObj.is_domain_ids = true

  console.log(formDataObj)
  activityCreationResource.update({
    params: {
      activity_data: JSON.stringify(formDataObj),
  }})
  try {
    isSaving.value = true
    await activityCreationResource.submit()
    isSaving.value = false
    if (activityCreationResource.data.status === 'success') {
      HSOverlay.close(`#hs-${props.modalName}`)
      pageNum.value = 1
      // HSOverlay.open('#hs-form-confirm-modal-2')
    } else if (activityCreationResource.data.status === 'fail') {
      alert("Form Submission Failed. Make sure you have filled everything correctly.")
    }
  } catch (error) {
    console.error(error)
  }
}
</script>