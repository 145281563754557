<template>
  <div :class="cn('p-4 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-4" @keydown.stop>
      <label class="font-medium text-sm md:text-base">
        8. Additional notes from the activity (optional)
      </label>
      <div class="flex flex-col gap-2">
        <p class="text-sm font-medium">Insert Students: </p>
        <div class="flex gap-4" v-for="cohort in props.selectedCohorts">
          <p v-if="userDetails" class="text-base font-medium">
            {{ findValueByName(userDetails.school_info.cohorts, cohort, 'cohort_name') }}: 
          </p>
          <div v-if="cohortStudents" class="text-sm font-semibold text-gray-700">
            <span v-for="student in cohortStudents[cohort]" class="cursor-pointer hover:bg-gray-300 
              p-1 px-2 rounded relative" @click="() => insertStudent(student.student_name)">
              {{ student.student_name }}
              <span class="h-2 w-2 rounded full bg-[#FDD835] absolute top-1 right-[0.25rem]" 
                v-if="insertedStudents.includes(student.student_name)">
              </span>
            </span>
          </div>
        </div>
        <div class="flex gap-4" v-if="additionalStudentsList.length">
          <p class="text-base font-medium">
            Additional Students:
          </p>
          <div v-if="additionalStudentsList" class="text-sm font-semibold text-gray-700">
            <span v-for="student in additionalStudentsList" class="cursor-pointer hover:bg-gray-300 
              p-1 px-2 rounded relative" @click="() => insertStudent(student.student_name)">
              {{ student.student_name }}
              <span class="h-2 w-2 rounded full bg-[#FDD835] absolute top-1 right-[0.25rem]" 
                v-if="insertedStudents.includes(student.student_name)">
              </span>
            </span>
          </div>
        </div>
      </div>
      <textarea id="hs-autoheight-textarea-additional-notes" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        rows="10" placeholder="Enter text here..." name="additional_notes" v-model="notes" ref="notesInputRef"
        @input="textareaAutoHeight"></textarea>
    </div>
    <div>
      <AudioRecorder :userData="props.userData" observationType="activity" 
        @transcriptGenerated="appendNotes" @transcriptStatus="handleTranscriptStatus" />
      <div v-if="transcriptStatusText"
        :class="cn(`text-sm font-semibold text-gray-600 p-3 text-center`)">
        {{ transcriptStatusText }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref, computed, inject } from 'vue';
import { findValueByName } from '@/lib/utils'
import AudioRecorder from '../AudioRecorder.vue'

const props = defineProps([
  'pageNum', 'componentPageNum', 'observation',
  'userData', 'additionalStudents', 'selectedCohorts',
  'studentsList'
])

const userDetails = inject('userDetails')

const cohortStudents = computed(() => {
  if (!props.studentsList) return null
  if (!props.selectedCohorts) return null
  const cohortsObj = {};
  props.selectedCohorts.forEach((cohort) => {
    cohortsObj[cohort] = [];
  });
  props.studentsList.forEach((student) => {
    if (props.selectedCohorts.includes(student.cohort)) {
      cohortsObj[student.cohort].push(student);
    }
  });

  return cohortsObj;
})

const additionalStudentsList = computed(() => {
  if (!props.studentsList || !props.additionalStudents) return []
  return props.studentsList.filter((student) => {
    return props.additionalStudents.includes(student.name)
  })
})

const notes = ref(props.observation?.additional_notes || '')
const notesInputRef = ref(null)

const appendNotes = (newText) => {
  if (notes.value)
    notes.value = notes.value + '\n\n' + newText
  else
    notes.value = newText
}

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  notesInputRef.value.style.height = 'auto'
  let calculatedHeight = notesInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  notesInputRef.value.style.height = `${calculatedHeight}px`
}

const transcriptStatusText = ref('')

const handleTranscriptStatus = (statusObj) => {
  if (statusObj.status === 'start') {
    transcriptStatusText.value = "Generating transcript, please wait..."
  } else if (statusObj.status === 'fail') {
    transcriptStatusText.value = 'Transcript generation failed, please try again...'
    setTimeout(() => {
      transcriptStatusText.value = ''
    }, 3500)
  } else if (statusObj.status === 'success') {
    transcriptStatusText.value = ''
  }
}

const insertedStudents = ref([])

const insertStudent = (studentName) => {
  insertedStudents.value.push(studentName)
  notes.value = notes.value + `\n ${studentName}:  `
}
</script>