<template>
  <div :class="cn('p-4 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
    props.pageNum !== props.componentPageNum && 'hidden')" >
    <div class="space-y-4">
      <label class="">
        <span class="text-xs font-semibold">Activity Description:</span>
        <textarea id="hs-autoheight-textarea-daily-interests" class="py-2 px-2 block w-full border-gray-200 
          rounded-lg text-xs
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
          rows="10" placeholder="How to perform the activity..." name="activity_description"  
          ref="summaryRef" @input="textareaAutoHeight" v-model="description"></textarea>
      </label>
    </div>
    <div class="space-y-4">
      <label class="">
        <span class="text-xs font-semibold">Observation Guide:</span>
        <textarea id="hs-autoheight-textarea-daily-interests" class="py-2 px-2 block w-full border-gray-200 
          rounded-lg text-xs
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
          rows="10" placeholder="Any notes for the facilitators..." name="observation_guide"  
           v-model="observGuide"></textarea>
      </label>
    </div> 
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref, watch } from 'vue'
import { io } from "socket.io-client"
import config from '../../../../../../../sites/common_site_config.json'

const props = defineProps([
  'componentPageNum', 'pageNum', 
  'descData', 'updateIsRequestingFalse'
])

const summaryRef = ref(null)

const description = ref(props.descData?.structured_data?.description || '')
const observGuide = ref('')

const host = window.location.hostname
const port = window.location.port ? `${config.socketio_port}` : '';
const protocol = port ? 'http' : 'https'
const siteName = window.site_name || host
const url = `${protocol}://${host}:${port}/${siteName}`

// const socket = io("http://localhost:9001/newcurrie.local", {
const socket = io(url, {
  withCredentials: true,
  reconnectionAttempts: 5,
})

const socketEventHandler = (data) => {
  if (data.content === null) {
    props.updateIsRequestingFalse()
    return
  }
  description.value += data.content
}

socket.on("desc_stream", socketEventHandler);

watch(() => props.descData, (newDesc) => {
  if (!newDesc) return
  if (!(newDesc.status === 'fail')) return
  description.value = newDesc.structured_data.description
})

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  if (summaryRef.value) {
    summaryRef.value.style.height = 'auto'
    let calculatedHeight = summaryRef.value?.scrollHeight + offsetTop
    if (calculatedHeight > windowHeight/2.5)
        calculatedHeight = windowHeight/2.5
    summaryRef.value.style.height = `${calculatedHeight}px`
  }
}
</script>