<template>
 
  <div class="">

   
    <button @click="goToActivityObservationsPage" class="border-black mt-5 md:mt-10 md:ml-10 px-4 py-2 hidden">
      New Activity Observation
    </button>
    
    <button @click="goToDailyObservationsPage" class="border-black mt-5 md:ml-10 px-4 py-2 hidden">
      New Daily Observation
    </button>
    <CalendarWidget class="ml-10 md:ml-10 mt-5"></CalendarWidget>
   
   

 </div>

 </template>
 

 <script>

import CalendarWidget from '../components/CalendarWidget.vue'
import { onMounted } from 'vue';

 export default {
  setup() {
      onMounted(() => {
        console.log(" onMounted called in modal form");
        setTimeout(() => {
      window.HSStaticMethods.autoInit();
	  console.log("Initialised Preline inside educator dashboard after delay");
    }, 100)
      });
    },
   components: {
     CalendarWidget,
     
   },
   methods: {
    
    goToDailyObservationsPage() {
      const dailyObservationsUrl = '/app/daily-observations';
      window.location.href = dailyObservationsUrl;
    },
    goToActivityObservationsPage() {
      const activityObservationsUrl = '/app/activity-observations';
      window.location.href = activityObservationsUrl;
    }
  }
};
 </script>
 