<template>
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8973 8.65987C14.8973 8.56585 14.8201 8.48892 14.7258 8.48892H13.4391C13.3448 8.48892 13.2676 
      8.56585 13.2676 8.65987C13.2676 11.6579 10.8293 14.0875 7.82073 14.0875C4.81211 14.0875 2.3739 11.6579 
      2.3739 8.65987C2.3739 8.56585 2.2967 8.48892 2.20235 8.48892H0.915694C0.82134 8.48892 0.744141 8.56585 
      0.744141 8.65987C0.744141 12.2647 3.45898 15.2392 6.96296 15.6602V17.8483H3.84712C3.55333 17.8483 
      3.31745 18.1539 3.31745 18.5321V19.3014C3.31745 19.3954 3.37749 19.4723 3.4504 19.4723H12.1911C12.264 
      19.4723 12.324 19.3954 12.324 19.3014V18.5321C12.324 18.1539 12.0881 17.8483 11.7943 
      17.8483H8.59272V15.6709C12.1374 15.2862 14.8973 12.2946 14.8973 8.65987ZM7.82073 12.2925C9.83434 12.2925 
      11.4662 10.6856 11.4662 8.70261V3.91607C11.4662 1.93308 9.83434 0.326172 7.82073 0.326172C5.80712 
      0.326172 4.17521 1.93308 4.17521 3.91607V8.70261C4.17521 10.6856 5.80712 12.2925 7.82073 
      12.2925ZM5.80497 3.91607C5.80497 2.83483 6.70348 1.95017 7.82073 1.95017C8.93797 1.95017 9.83648 2.83483 
      9.83648 3.91607V8.70261C9.83648 9.78385 8.93797 10.6685 7.82073 10.6685C6.70348 10.6685 5.80497 9.78385 
      5.80497 8.70261V3.91607Z" fill="black"/>
  </svg>
</template>
