<template>
  <div :class="cn('p-8 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-6">
      <p class="font-medium text-sm md:text-base">
        6. Overall how did the activity go? Engagement, Aha moment, fun!
      </p>
      <div class="flex flex-col gap-y-4">
        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" name="activity_rating" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
            disabled:pointer-events-none" id="activity-rating-radio-group-1" value="Excellent" v-model="picked">
            Excellent
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" name="activity_rating" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            id="activity-rating-radio-group-2" value="Good" v-model="picked">
            Good
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" name="activity_rating" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            id="activity-rating-radio-group-3" value="Average" v-model="picked">
            Average
          </label>
        </div>
        
        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" name="activity_rating" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            id="activity-rating-radio-group-4" value="Washout" v-model="picked" >
            Washout
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref } from 'vue'

const props = defineProps(['pageNum', 'componentPageNum', 'observation'])

const picked = ref(props.observation?.activity_rating || '')
</script>