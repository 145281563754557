<template>
    <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "closeComponent">
        <div class="bg-white rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-[40%] overflow-auto">
            
            
            
            <!-- Cross button -->
            <div class="sticky top-2 mr-2 flex justify-end z-10">
                <button @click="closeComponent" class="border-black">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>  

            
            <!-- Heading -->
            <div class="text-lg md:text-[21px] font-semibold ml-3 mb-3"> Curriculum Alignment</div>
            


            <!-- Rendering Table --> 
            <div class="w-full max-h-[490px] px-3 overflow-auto">                                                                                                                                                                                                                               
                <div v-if="props.clickedLearningSequenceDetails" class="landscape-table relative flex flex-row mt-2 mb-3 border border-gray-200 rounded-md">
                    
                    <!-- Table Body -->                                                                      <!--Conditional styling class checks for row to highligh when navigating from curriculum landscape Map page  -->
                    <div class="flex flex-col text-sm min-w-[165px] w-full">
                        <div v-for="header in headers" :key="header" class="flex flex-col border border-gray-200 overflow-hidden text-wrap">
                        

                        <!-- For Competnecy/subdomain in the same column as domain-->
                        <div v-if="header === 'Domain'" class="flex flex-row">
                        <div class="bg-[#FEF3C2] w-1/3 border-r flex items-center pl-2 py-2 font-semibold">{{ header }}</div>
                        <div class="w-2/3 flex items-center px-2 py-2">
                               <div> {{domainLabel}}/
                                {{ competencyLabel}}</div>
                            </div>    
                        </div>

                        <!-- For development stage -->
                        <div v-if="header === 'Development Stage'" class="flex flex-row">
                            <div class="bg-[#FEF3C2] w-1/3 border-r flex items-center pl-2 py-2 font-semibold">{{ header }}</div>
                            <div class="dev-stage w-2/3 flex items-center px-2 py-2">{{ devStageLabel}}</div>
                        </div>

                        <!-- For focus area -->
                        <div v-if="header === 'Focus Area'" class="flex flex-row">
                            <div class="bg-[#FEF3C2] w-1/3 border-r flex items-center pl-2 py-2 font-semibold">{{ header }}</div>
                            <div class="dev-stage w-2/3 flex items-center px-2 py-2">{{ focusAreaLabel }}</div>
                        </div>

                        <!-- For rendering sequence title -->
                        <div v-if="header === 'Sequence' && props.clickedLearningSequenceDetails.title" class="flex flex-row">
                            <div class="bg-[#FEF3C2] w-1/3 border-r flex items-center pl-2 py-2 font-semibold">{{ header }}</div>
                            <div class="sequence w-2/3 flex items-center px-2 py-2">{{ props.clickedLearningSequenceDetails.title}}</div>
                        </div>

                        <!-- For rendering cambridge alignment stage number above the data -->
                        <div v-if="header === 'Cambridge Alignment' && props.clickedLearningSequenceDetails.cambridge_alignment_stage" class="flex flex-row">
                            <div class="bg-[#FEF3C2] w-1/3 border-r flex items-center pl-2 py-2 font-semibold">{{ header }}</div>
                            <div class="cambridge-alignment w-2/3 flex items-center px-2 py-2">{{ props.clickedLearningSequenceDetails.cambridge_alignment_stage }} : {{ props.clickedLearningSequenceDetails.cambridge_stage_alignment_data }}</div>
                        </div>
                        
                        <!-- For rendering CC alignment grade number above the data -->
                        <div v-if="header === 'CC Alignment' && props.clickedLearningSequenceDetails.cc_grade_alignment" class="flex flex-row">
                            <div class="bg-[#FEF3C2] w-1/3 border-r flex items-center pl-2 py-2 font-semibold">{{ header }}</div>
                            <div class="cc-alignment w-2/3 flex items-center px-2 py-2">{{ props.clickedLearningSequenceDetails.cc_grade_alignment }} : {{ props.clickedLearningSequenceDetails.cc_grade_alignment_data}}</div>
                        </div>

                        <!-- For rendering Example -->
                        <div v-if="header === 'Example' && props.clickedLearningSequenceDetails.example" class="flex flex-row">
                            <div class="bg-[#FEF3C2] w-1/3 border-r flex items-center pl-2 py-2 font-semibold">{{ header }}</div>
                            <div class="example w-2/3 flex items-center px-2 py-2">{{ props.clickedLearningSequenceDetails.example}}</div>
                        </div>
                        
                        
                        <!-- <template v-else>

                        </template>  -->

                        </div>
                    </div>
                </div>
            </div>    

        </div>
    </div>    
</template>

<script setup>
import { ref, computed} from 'vue'
import { defineEmits } from 'vue';
  


// Add emit to your component setup
const emit = defineEmits(['close']);



const props = defineProps({
    clickedLearningSequenceDetails: Array,
    selectedNodesDetailed:Array
 });

const headers = ref([
    'Domain',
    'Development Stage',
    'Focus Area',
    'Sequence',
    'Cambridge Alignment',
    'CC Alignment',
    'Example',
  ])

  const findNodeLabel = (type) => {
  const node = props.selectedNodesDetailed.find(node => node.type === type);
  return node ? node.label : `No ${type} selected`;
    };

const domainLabel = computed(() => findNodeLabel('domain'));
const competencyLabel = computed(() => findNodeLabel('competency'));
const devStageLabel = computed(() => findNodeLabel('devStage'));
const focusAreaLabel = computed(() => findNodeLabel('focusArea'));


const closeComponent = () => {
        emit('close');
    };  
</script>

<style scoped>

@media screen and (max-width: 769px) {
  
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}
</style>