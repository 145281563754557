<template>
  <div class="lg:hidden">
    <nav class="flex border rounded-lg overflow-hidden bg-gray-800 h-10">
      <button type="button" :class="cn(`flex-1 bg-white first-rounded-s-lg last:rounded-e-lg
        py-1 px-4 text-gray-800 hover:text-gray-900 text-xs font-medium text-center overflow-hidden
        hover:bg-gray-50 focus:outline-none focus:text-white focus:bg-gray-800`,
        props.observTab === 'child' && `text-white bg-gray-800`)"
        @click="() => props.switchTab('child')">
        Individual
      </button>
      <button type="button" :class="cn(`flex-1 bg-white first-rounded-s-lg last:rounded-e-lg
        py-1 px-4 text-gray-800 hover:text-gray-900 text-xs font-medium text-center overflow-hidden
        hover:bg-gray-50 focus:outline-none focus:text-white focus:bg-gray-800`,
        props.observTab === 'group' && `text-white bg-gray-800`)"
        @click="() => props.switchTab('group')">
        Group/Activity
      </button>
      <button type="button" :class="cn(`flex-1 bg-white first-rounded-s-lg last:rounded-e-lg
        py-1 px-4 text-gray-800 hover:text-gray-900 text-xs font-medium text-center overflow-hidden
        hover:bg-gray-50 focus:outline-none focus:text-white focus:bg-gray-800`,
        props.observTab === 'unstructured' && `text-white bg-gray-800`)" 
        @click="() => props.switchTab('unstructured')">
        Weekly
      </button>
    </nav>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils'

const props = defineProps(['observTab', 'switchTab'])
</script>