<template>
  <div class="flex flex-col max-h-[94dvh] md:max-h-[91.5vh] pt-6 px-2 lg:py-8 md:px-16 pb-20
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto h-[94dvh] md:h-[91.5vh]">
    <h2 class="text-3xl">
      Observation Reports
    </h2>
    <div :class="cn(`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 flex-auto items-start gap-4 xl:gap-8`, 
      !reportsList.length && 'flex justify-center items-center')">
      <div v-if="reportsList.length === 0 && !(userDetails?.is_system_manager)">
        Currently, there are no published reports available for your child. Please check back later for updates.
      </div>
      <Card v-for="report in reportsList" class="">
        <Tooltip>
          <router-link :to="`/front-portal/observations/report/${report.student}`">
            <div class="space-y-4 overflow-x-hidden">
              <div class="flex gap-2 md:gap-4 overflow-x-hidden">
                <!-- Replace with student profile image-->
                 <div class="size-12 bg-[#fdd835] grid place-content-center rounded-full font-bold text-xl shrink-0">
                  {{ findValueByName(userDetails.school_info.students, report.student, 'student_name')[0] }}
                 </div>
                 <div class="flex flex-col">
                  <h3 class="font-semibold text-base md:text-lg line-clamp-1 text-ellipsis">
                    <span>
                      {{ findValueByName(userDetails.school_info.students, report.student, 'student_name') }}
                    </span>
                    <span>'s Yearly Observation Report</span>
                  </h3>
                  <p class="font-medium text-gray-500">
                    {{ formatDateString(report.creation) }}
                  </p>
                 </div>
              </div>
              <div class="space-y-4">
                <h4 class="font-medium text-sm md:text-base">
                  Summary Essay
                </h4>
                <p class="text-ellipsis line-clamp-[7] text-gray-600 text-sm md:text-base font-normal">
                  {{ report.report }}
                </p>
              </div>
            </div>
          </router-link>
          <template #tooltipContent>
            View {{ findValueByName(userDetails.school_info.students, report.student, 'student_name') }}'s report
          </template>
        </Tooltip>
        <!-- {{ report }} -->
      </Card>
    </div>
  </div>
</template>

<script setup>
import { createResource } from 'frappe-ui';
import { cn } from '@/components/ui/utils.js'
import { computed, inject } from 'vue';
import Card from '@/components/ui/Card.vue';
import { findValueByName } from '@/lib/utils'
import Tooltip from '@/components/ui/Tooltip.vue';

const userDetails = inject('userDetails')

const reportsResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.individual_observation_reports.api.get_report_list_parent',
 auto: true
})

const reportsList = computed(() => {
  if (!reportsResource?.data?.reports) return []
  return reportsResource.data.reports
})

console.log("Parent Reports", reportsResource)

function formatDateString(dateString) {
    const date = new Date(dateString);

    // Options for formatting the date
    const options = { month: 'long', year: 'numeric' };

    // Format the date as "Month Year"
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
}
</script>