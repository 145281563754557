<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Save Prompt
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col gap-4 p-4" ref="formRef">
          <div class="flex flex-col gap-4">
            <div>
              <label class="font-semibold">Name: 
                <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
                  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  name="name" v-model="promptName" placeholder="Untiltled Prompt">
              </label>
            </div>
            <div>
              <p class="font-semibold">
                Type of observation
              </p>
              <div class="flex flex-col md:flex-row gap-4 mt-4">
                <div class="flex">
                  <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4">
                    <input type="radio" name="child" class="shrink-0 mt-0.5 border-gray-200 
                      rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
                      disabled:pointer-events-none" value="all" v-model="type">
                      All
                  </label>
                </div>

                <div class="flex">
                  <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4">
                    <input type="radio" name="type" class="shrink-0 mt-0.5 border-gray-200 
                      rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                      value="child" v-model="type">
                      Child
                  </label>
                </div>

                <div class="flex">
                  <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4">
                    <input type="radio" name="type" class="shrink-0 mt-0.5 border-gray-200 
                      rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                      value="activity" v-model="type">
                      Group
                  </label>
                </div>

                <div class="flex">
                  <label class="text-sm text-gray-600 ms-4 font-semibold flex gap-4">
                    <input type="radio" name="type" class="shrink-0 mt-0.5 border-gray-200 
                      rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                      value="weekly" v-model="type">
                    Weekly
                  </label>
                </div>
              </div>
            </div>
            <div class="space-y-4">
              <label class="font-semibold">1. Type out the feedback</label>
              <textarea id="hs-autoheight-textarea-1" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
                focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" rows="10"
                placeholder="Enter prompt here..." name="prompt" v-model="prompt" ref="promptInputRef"
                @input="textareaAutoHeight"></textarea>
            </div>
          </div>
          <div>
            <span v-if="isSubmitting" class="font-semibold text-gray-600">
              Saving Prompt...
            </span>
            <span v-if="!isSubmitting && isSaved" class="font-semibold text-gray-600">
              Prompt Saved Successfully!
            </span>
          </div>
          <div>
            <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
              rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
              disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
              dark:focus:ring-gray-600`)" :disabled="isSubmitting">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, inject } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline';
import { createResource } from 'frappe-ui'

const props = defineProps([
  'modalName', 'prompt'
])

const promptName = ref('')
const type = ref('all')
const prompt = ref(props.prompt || '')

watch(() => props.prompt, (newPrompt) => {
  if (newPrompt)
    prompt.value = newPrompt
})

const formRef = ref(null)
const promptInputRef = ref(null)

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const savePromptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompt.api.save_prompt',
})

const isSubmitting = ref(false)
const isSaved = ref(false)

const handleSubmit = async () => {
  isSaved.value = false
  const formData = new FormData(formRef.value)
  const name = formData.get('name')
  const type = formData.get('type')
  const prompt = formData.get('prompt')

  console.log({name,type, prompt})
  const promptData = {
    prompt_name: name,
    observation_type: type,
    prompt,
  }
  if (!prompt) {
    alert('Prompt cannot be empty!')
    return
  }
  isSubmitting.value = true
  try {
    await savePromptResource.submit({
      prompt_data: promptData,
    })
    if (savePromptResource.data?.status === 'success') {
      console.log(savePromptResource.data.message)
      isSaved.value = true
      HSOverlay.close(`#hs-${props.modalName}`)
    } else {
      console.error(savePromptResource.data.message)
    }
    console.log(savePromptResource.data.message)
  } catch (error) {
    console.error(error)
  }
  isSubmitting.value = false
}

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  promptInputRef.value.style.height = 'auto'
  let calculatedHeight = promptInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight / 2.5)
    calculatedHeight = windowHeight / 2.5
  promptInputRef.value.style.height = `${calculatedHeight}px`
}
</script>