<!-- CustomButtonGroupNode.vue -->
<template>

  <div class="custom-button-group">
    <button @click="handleQuizzClick" class="flex flex-col items-center mx-1 px-2 py-1 text-[10px] bg-slate-200 rounded-md">
      <img :src="props.data.quizIcon" alt="Quizzes Icon" class="h-7 w-8"/>
      <div>Quizzes</div>
    </button>
    <button @click="handleActivityClick" class="flex flex-col items-center text-[10px]">
      <img :src="props.data.activityIcon" alt="Activities Icon" class="h-7 w-7"/>
      <div>Activities</div>
    </button>
  </div>


</template>



<script setup>

import { defineProps, defineEmits } from 'vue';
const props = defineProps(['data']);

const emit = defineEmits(['navigate']);

const handleQuizzClick = () => {
  emit('navigate', 'Quiz-button-clicked');
  console.log("Quiz button clicked")
};
const handleActivityClick = () => {
  emit('navigate', 'Activity-button-clicked');
  console.log("Activity button clicked")
};
</script>



<style scoped>
.custom-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  width: 130px;
  height: 65px;
  border-radius: 6px;
  background-color: white;
}


</style>