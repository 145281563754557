<template>
  <slot name="trigger"/>


  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full px-4
        max-h-[80vh] overflow-y-auto gap-4 overflow-y-visible">
        <div class="flex flex-col gap-2">
          <div class="flex justify-between items-center pt-4">
            <h3 class="font-bold text-xl md:text-xl mt-6">
              Add new {{ props.userType }}
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
       
        
        <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col gap-4 overflow-auto" ref="formRef">
          <div class="flex flex-col gap-4">

            <label class="flex flex-col gap-2">
              <span class="font-semibold">Name</span>
              <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
                focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
                dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" 
                placeholder="Name of the student" name="name" v-model="student_name" >
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Age</span>
                <NumberInput inputName="age" :default="student_age" />
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Cohort</span>
              <SelectSearchSingle v-if="userDetails" placeholder="Select Cohort" :dataList="userDetails.school_info.cohorts"
                inputName="cohort" dispName="cohort_name" :hasSearch="false" :defaultValue="cohort_default" />
            </label>

            <!-- New form fields -->
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Date of Birth</span>
              <input type="date" name="date_of_birth" v-model="date_of_birth">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Parent 1</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="parent_1" v-model="parent_1">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Parent 2</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="parent_2" v-model="parent_2">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Emergency Contact</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="emergency_contact" v-model="emergency_contact">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Allergies</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="allergies" v-model="allergies">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Food Preference</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="food_preference" v-model="food_preference">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Likes</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="likes" v-model="likes">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Dislikes</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="dislikes" v-model="dislikes">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Fun Facts</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="fun_facts" v-model="fun_facts">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Guardian 1</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="guardian_1" v-model="guardian_1">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Guardian 2</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="guardian_2" v-model="guardian_2">
            </label>
            <label class="flex items-center gap-2">
              <span class="font-semibold">Is Enrolled</span>
              <input class="border border-slate-200 rounded-lg" type="checkbox" name="is_enrolled" v-model="is_enrolled">
            </label>
            <label class="flex items-center gap-2">
              <span class="font-semibold">Graduated</span>
              <input class="border border-slate-200 rounded-lg" type="text" name="graduated" v-model="graduated">
            </label>


          </div>
          <div :class="cn(`flex justify-center md:justify-end items-center gap-4 py-3 px-4 basis-[5.5rem]`)">
            <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
              rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
              disabled:opacity-50 disabled:pointer-events-none`)"
              :data-hs-overlay="`#hs-${props.modalName}`">
              Back
            </button>
            <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
              rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
              disabled:opacity-50 disabled:pointer-events-none`)" >
              Submit
            </button>
          </div>
        </form>



      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline'
import { createResource } from 'frappe-ui'
import NumberInput from '../ui/NumberInput.vue';
import SelectSearchSingle from '../ui/SelectSearchSingle.vue';

const props = defineProps([
  'modalName', 'userType', 'reloadFn', 
  'editMode', 'selectedStudent'
])
const userDetails = inject('userDetails') 

const student_name = ref('')
const student_age = ref(1)
const cohort_default = ref('')

const date_of_birth = ref('')
const parent_1 = ref('')
const parent_2 = ref('')
const emergency_contact = ref('')
const allergies = ref('')
const food_preference = ref('')
const likes = ref('')
const dislikes = ref('')
const fun_facts = ref('')
const guardian_1 = ref('')
const guardian_2 = ref('')
const is_enrolled = ref(false)
const graduated = ref(false)

// watch(() => props.selectedStudent, () => {
//   if (props.editMode && props.selectedStudent) {
//     console.log("This is the student data being recieved here====================",props.selectedStudent)
//     student_name.value = props.selectedStudent.student_name
//     cohort_default.value = props.selectedStudent.cohort || ''
//     student_age.value = props.student_age || 1
//   } else {
//     student_name.value = ''
//     cohort_default.value = ''
//     student_age.value = 1
//   }
// })

watch(() => props.selectedStudent, () => {
  if (props.editMode && props.selectedStudent) {
    console.log("This is the student data being recieved here====================",props.selectedStudent)
    student_name.value = props.selectedStudent.student_name
    cohort_default.value = props.selectedStudent.cohort || ''
    student_age.value = props.selectedStudent.age || 1
    date_of_birth.value = props.selectedStudent.date_of_birth || ''
    parent_1.value = props.selectedStudent.parent_1 || ''
    parent_2.value = props.selectedStudent.parent_2 || ''
    emergency_contact.value = props.selectedStudent.emergency_contact || ''
    allergies.value = props.selectedStudent.allergies || ''
    food_preference.value = props.selectedStudent.food_preference || ''
    likes.value = props.selectedStudent.likes || ''
    dislikes.value = props.selectedStudent.dislikes || ''
    fun_facts.value = props.selectedStudent.fun_facts || ''
    guardian_1.value = props.selectedStudent.guardian_1 || ''
    guardian_2.value = props.selectedStudent.guardian_2 || ''
    is_enrolled.value = props.selectedStudent.is_enrolled || false
    graduated.value = props.selectedStudent.graduated || ''
  } else {
     // Clear form fields for new student
     clearFormFields();
  }
})



const clearFormFields = () => {
  console.log("clear form fields called")
  student_name.value = '';
  cohort_default.value = '';
  student_age.value = 1;
  date_of_birth.value = '';
  parent_1.value = '';
  parent_2.value = '';
  emergency_contact.value = '';
  allergies.value = '';
  food_preference.value = '';
  likes.value = '';
  dislikes.value = '';
  fun_facts.value = '';
  guardian_1.value = '';
  guardian_2.value = '';
  is_enrolled.value = false;
  graduated.value = '';
};


onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
})

const formRef = ref(null)

const newStudentResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.student.api.create_student',
})

const editStudentResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.student.api.edit_student',
})

const handleSubmit = async (e) => {
  const formData = new FormData(formRef.value)
  const formDataObj = {}
  formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  console.log(formDataObj)

  if (!props.editMode) {
    await newStudentResource.submit({
      student_data: formDataObj
    })
    console.log(newStudentResource)
    if (newStudentResource.data.status === 'success') {
      HSOverlay.close(`#hs-${props.modalName}`)
      props.reloadFn()
    } else if (newStudentResource.data.status === 'fail') {
      alert(`Form Submission Failed.\n${newStudentResource.data.message}`)
    }
  } else {
    formDataObj.student_id = props.selectedStudent.name
    await editStudentResource.submit({
      student_data: formDataObj
    })
    console.log(editStudentResource)
    if (editStudentResource.data.status === 'success') {
      HSOverlay.close(`#hs-${props.modalName}`)
      props.reloadFn()
    } else if (editStudentResource.data.status === 'fail') {
      alert(`Form Submission Failed.\n${editStudentResource.data.message}`)
    }
  }
}
</script>