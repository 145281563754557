<template>
  <div :class="cn('p-8 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-6">
      <p class="font-medium text-sm md:text-base">
        7. Is this activity worth building on?
      </p>
      <div class="flex flex-col gap-y-4">
        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="worth_building" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              id="hs-radio-act-primary-group-7" value="Yes"
              v-model="pickedBuild">
              Yes
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="worth_building" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
              disabled:pointer-events-none" id="hs-radio-act-primary-group-1" value="Unsure"
              v-model="pickedBuild">
              Unsure
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" name="worth_building" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
              disabled:pointer-events-none" id="hs-radio-act-primary-group-1" value="No"
              v-model="pickedBuild">
              No
          </label>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref } from 'vue'

const props = defineProps([
  'pageNum', 'componentPageNum', 'observation',
])

const pickedBuild = ref(props.observation?.worth_building || "")
</script>