<template>

   <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "closeComponent">
      <div class="bg-white rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97vh] overflow-auto">
        
        <!-- Cross button -->
        <div class="sticky top-2 mr-2 flex justify-end z-10">
            <button @click="closeComponent" class="border-black">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>

        <!-- Form container -->
        <div class="event-form relative px-4 text-xs md:text-sm">
                <form @submit.prevent="createAnswersheet" class="flex flex-col gap-1.5">

                    <!-- Heading -->
                    <div class="font-semibold text-black text-xl self-center"> Add Answersheet</div>                       
                    
                    <!-- Input for Worksheet Title-->
                    <div class="flex flex-col items-center justify-between mb-1.5">
                        <label for="title" class="font-medium self-start text-black mr-2 mb-4">Answersheet Title:</label>
                        <div class="w-[15rem] md:w-full">
                            <input id="answersheet_title" ref="answersheet_title" placeholder="Enter title here"
                            class="w-[15rem] md:w-[100%] h-25 border border-gray-300 rounded-md text-sm md:text-sm align-middle"> 
                            </input>
                        </div>
                    </div>

                                                                            
                    <!-- Input for answersheet doc link -->
                    <div class="flex flex-col items-center justify-between mb-1.5">
                        <label for="answersheetLink" class="font-medium text-black mr-2 self-start mt-3 mb-3">Answersheet Doc Link:</label>
                        <div class="w-[15rem] md:w-full">
                        <input id="answersheetLink" ref="answersheetLink" type="text" placeholder="url"
                            class="w-full border border-gray-300 rounded-md px-4 text-xs md:text-sm align-middle">
                        </div>      
                    </div>  


                    <!-- Upload/choose file -->
                    <label for="upload_file" class="font-medium text-black mr-2 self-start mt-3 mb-3">Upload Answersheet:</label>
                        <label for="large-file-input" class="sr-only">Choose file</label>
                        <input type="file" name="large-file-input" id="large-file-input" @change="handleFileUpload" class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400
                        file:bg-gray-50 file:border-0
                        file:me-4
                        file:py-3 file:px-4 file:sm:py-5
                    mb-6">
                
                    <!-- Submit Button -->   
                    <div class="flex mb-6">
                        <button type="submit" class="py-3 px-2 w-1/2 md:w-[22%] bg-black text-white rounded-md cursor-pointer hover:bg-gray-800 block ml-2 mr-2">Save</button>
                    
                        <button @click="closeComponent" class="bg-white py-2 md:py-2 px-2 w-1/2 md:w-[22%] text-black rounded-md cursor-pointer hover:bg-gray-200 block ml-2 mr-2" style="border: 1px solid #cbd5e1;">Close</button>
                    
                    </div>
                </form>

                
                
        </div> 
        
     </div>
    </div>    
</template>


<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { defineEmits } from 'vue';


const props = defineProps({
      worksheetName: String,
    });



const emit = defineEmits(['close','answersheet-created', 'answersheet-error']);

const selectedFile = ref(null); // For uploaded file 


const answersheet_title = ref('');
const answersheetLink = ref('');


const handleFileUpload = (event) => {
  console.log("Assign selected file funtion called")
  selectedFile.value = event.target.files;
  console.log("Selected files assigned =============",selectedFile.value)
};


const createAnswersheet = async () => {
    const titleValue = answersheet_title.value.value;
    console.log('Answersheet Title:', titleValue);


    const answersheetLinkValue = answersheetLink.value.value;
    console.log('Worksheet Link:', answersheetLinkValue);

    const csrfToken = window.csrf_token;

   
    
    // Ensure the file is properly selected
    if (!selectedFile.value[0]) {
        console.error("No file selected for upload");
        return;
    }
    
    
    const selectedFileValue = selectedFile.value[0];
    console.log(' this is Selected File value:======================================', selectedFileValue);
  

    // Create a new worker
    const worker =  new Worker(new URL('./answersheet_worker.js', import.meta.url));

    // Handle messages from the worker
    worker.addEventListener('message', (e) => {
        const { type, message, error, details } = e.data;
        if (type === 'success') {
        console.log('Worker: Answersheet created successfully:', message);
        // alert(`Answersheet created successfully: ${message.message || JSON.stringify(message)}`);
        } else if (type === 'error') {
        console.error('Worker: Error creating Answersheet:', error, details);
        alert(`Error creating Answersheet: ${error}\n\nDetails: ${details || 'No additional details'}`);
        }
    });

    // Post data to the worker
    worker.postMessage({
        title: titleValue,
        worksheet_id:props.worksheetName,
        answersheet_url: answersheetLinkValue,
        file: selectedFileValue,
        csrfToken
    });
    // Handle messages from the worker
    worker.addEventListener('message', (e) => {
        const { type, message, error, details } = e.data;
        if (type === 'success') {
            console.log('Worker: Answersheetsheet created successfully:', message);
            // Emit success event to parent
            console.log("Emmitting successfully created message to parent ##############")
            try {
                emit('answersheet-created', message);
                console.log("successfully emmitted event to parent #####")
            }
            catch{
                console.log("Error emmiting event to parent ###################")
            }

        } else if (type === 'error') {
            console.error('Worker: Error creating worksheet:', error, details);
            // Emit error event to parent
            emit('answersheet-error', { error, details });
        }
    });

    closeComponent();
 };


const closeComponent = () => {
        emit('close');
    };  



</script>