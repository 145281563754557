import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function removeHTMLContents(markupText) {
  const newEl = document.createElement('div')
  newEl.innerHTML = markupText
  return newEl.textContent
}