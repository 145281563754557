<template>
  <div class="flex flex-col gap-4 pt-4 w-full">
    <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
      <div class="text-center">
        Week: 
        <span class="font-semibold">
          {{ getFormattedDate(props.weeklyStats[props.currentWeek].start_date) }} -
          {{ getFormattedDate(props.weeklyStats[props.currentWeek].end_date) }}
        </span>
      </div>
      <div class="border rounded-md w-2/5 md:w-1/4 self-end">
        <AdvancedSelect v-if="props.cohorts" :dataList="props.cohorts" default="all"
          dispName="cohort_name" inputName="cohort" selectId="weekStatCohort" @valueSelected="valueSelected" />
      </div>
    </div>
    <div class="flex justify-between w-full">
      <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
        border border-gray-200 bg-white text-yellow-500 shadow-sm hover:bg-gray-50 disabled:opacity-50
        disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:hover:bg-neutral-800"
        @click="props.goToPrevWeek" :disabled="!weeklyStats[props.currentWeek + 1]">
        < Previous
      </button>
      <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
        border border-gray-200 bg-white text-yellow-500 shadow-sm hover:bg-gray-50 disabled:opacity-50
        disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:hover:bg-neutral-800"
        @click="props.goToNextWeek" :disabled="props.currentWeek === 0">
        Next >
      </button>
    </div>
    <div class="h-[50vh] w-full">
      <BarChart :categories="names" :values="observations" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import BarChart from '../../charts/BarChart.vue';
import AdvancedSelect from '@/components/ui/AdvancedSelect.vue';
import { getFormattedDate } from '@/lib/utils'

const props = defineProps([
  'weeklyStats', 'cohorts', 'currentWeek',
  'goToNextWeek', 'goToPrevWeek'
])

const cohort = ref('all')

const names = computed(() => {
  if (cohort.value === 'all')
    return props.weeklyStats[props.currentWeek].count_list.map((weeklyStat) => weeklyStat.student_name)
  return props.weeklyStats[props.currentWeek].count_list.filter((weeklyStat) => weeklyStat.cohort === cohort.value)
    .map((weeklyStat) => weeklyStat.student_name)
})
const observations = computed(() => { 
  if (cohort.value === 'all')
    return props.weeklyStats[props.currentWeek].count_list.map((weeklyStat) => weeklyStat.count)
  return props.weeklyStats[props.currentWeek].count_list.filter((weeklyStat) => weeklyStat.cohort === cohort.value)
    .map((weeklyStat) => weeklyStat.count)
})

const valueSelected = (value) => {
  cohort.value = value
}
</script>