<template>
  <div class="flex flex-col h-full max-h-[94vh] md:max-h-[91.5vh] pt-6 px-2 lg:pt-8 lg:pl-8 gap-4 lg:gap-8 
    lg:pr-4 overflow-y-auto">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8">
        <h2 class="text-3xl">Activites</h2>
        <!-- <p class="lg:w-1/2 text-sm">
          Activity Repository description text sample
        </p> -->
        <ActivityDomainSelect v-if="domainResource.data" :domainList="domainResource.data" />
      </div>
    </div>
    <!-- <div class="flex flex-col lg:flex-row gap-8 lg:basis-96">
    </div> -->
    <div class="flex flex-col lg:flex-row gap-4">
      <ActivitySearchBar :searchFn="searchForActivity" />
      <div class="flex justify-centery items-center">
        <NewActivityModal modalName="activity-creation-modal">
          <template #trigger>
            <button type="button" class="py-2 px-4 inline-flex items-center gap-x-2 text-sm
              font-semibold rounded-lg border border-transparent bg-gray-800 text-white
              hover:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none
              dark:focus:ring-1 dark:focus:ring-gray-600 dark:bg-white dark:text-gray-800
              flex-auto flex justify-center items-center w-36" data-hs-overlay="#hs-activity-creation-modal"
              @click="console.log('Button Clicked')">
              Add New
            </button>
          </template>
        </NewActivityModal>
      </div>
      <router-link v-if="route.path !== '/front-portal/activities/imported'" 
        to="/front-portal/activities/imported">
        <button class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          type="button">
          View Imported Activities
        </button>
      </router-link>
      <router-link v-if="route.path === '/front-portal/activities/imported'"  
        to="/front-portal/activities">
        <button class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          type="button">
          View All Activities
        </button>
      </router-link>
    </div>
    <router-view />
  </div>
</template>

<script setup>
import NewActivityModal from '@/components/activities/NewActivityModal.vue'
import ActivityDomainSelect from '../components/activities/view/ActivityDomainSelect.vue';
import { createListResource, createResource } from 'frappe-ui'
import ActivitySearchBar from '../components/activities/search/ActivitySearchBar.vue';
import { useRouter, useRoute } from 'vue-router';
import { provide, ref, watch } from 'vue';

const domainResource = createListResource({
  doctype: 'Activity Domain',
  fields: ['name', 'domain_name', 'description'],
  orderBy: 'domain_name asc',
  start: 0,
  pageLength: 30,
  auto: true
})

const competencyList = ref([])
const competencyListResource = createListResource({
  doctype: 'Competency',
  fields: ['name','competency_name', 'description'],
  orderBy: 'competency_name asc',
  start: 0,
  pageLength: 30,
  auto: true
})
// console.log("Competencies", competencyListResource)

watch(() => competencyListResource.data, () => {
  if (competencyListResource.data) {
    competencyList.value = competencyListResource.data
  }
})

provide('domainResource', domainResource)
provide('competencyList', competencyList)

const router = useRouter()
const route = useRoute()
console.log("ROUTE:", route)

const searchForActivity = (key) => {
  router.push(`/front-portal/activities/search/${key}`)
}
</script>