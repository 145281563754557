<template>
  <div :class="cn('p-4 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-3">
      <p v-if="props.observType === 'activity' && props.choiceInputName === 'keep_replace_primary'" 
        class="font-medium text-sm md:text-base">
        The primary competency you entered is 
        <span v-if="noCompReturned" >not similar to any of the </span>
        <span v-else>similar to already </span>
        existing competencies<span v-if="!noCompReturned">: </span>
      </p>
      <p v-else-if="props.observType === 'activity' && props.choiceInputName === 'keep_replace'" 
        class="font-medium text-sm md:text-base">
        The custom competencies you entered is 
        <span v-if="noCompReturned" >not similar to any of the </span>
        <span v-else>similar to already </span>
        existing competencies<span v-if="!noCompReturned">: </span>
      </p>
      <p v-else class="font-medium text-sm md:text-base">
        The custom competencies you entered is 
        <span v-if="noCompReturned" >not similar to any of the </span>
        <span v-else>similar to already </span>
        existing competencies<span v-if="!noCompReturned">: </span>
      </p>
      <div v-if="!noCompReturned" class="flex gap-4 flex-wrap font-normal text-sm md:text-base">
        <div v-for="comp in filteredComps" class="bg-[#FDD835] px-2 rounded">
          {{ comp.competency_name }}
          <!-- {{ comp }} -->
        </div>
      </div>
      <p class="text-sm md:text-base font-medium">
        Original Text:
      </p>
      <p class="font-medium text-sm text-gray-600">
        {{ props.customText }}
      </p>
    </div>
    <p class="text-sm md:text-base font-medium">
      Do you want to replace the custom text with the suggested competency values?
    </p>
    <div class="flex gap-2 md:gap-8">
      <div class="flex">
        <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" :name="props.choiceInputName" class="shrink-0 mt-0.5 border-gray-200
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
            id="hs-radio-act-primary-group-7" value="replace"
            v-model="picked" :disabled="noCompReturned">
            <span :class="cn(noCompReturned && 'opacity-50')">Accept Suggestion</span>
        </label>
      </div>
      <div class="flex">
        <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" :name="props.choiceInputName" class="shrink-0 mt-0.5 border-gray-200
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50
            disabled:pointer-events-none" id="hs-radio-act-primary-group-1" value="keep"
            v-model="picked">
            Keep Original
        </label>
      </div>
    </div>

  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { computed, ref, watch } from 'vue';

const props = defineProps([
  'pageNum', 'componentPageNum', 'generatedCompList', 
  'customText', 'competencyList', 'choiceInputName',
  'observType'
])
const emit = defineEmits(['compListModified'])

const selectedComps = ref([])

watch(selectedComps, () => {
  emit('compListModified', selectedComps.value)
})

const filteredComps = props.generatedCompList.map((compName) => {
  if (!props.competencyList) return []
  const matchedComp = props.competencyList.find(comp => comp.competency_name === compName)
  if (matchedComp) {
    selectedComps.value = [...selectedComps.value, matchedComp.name]
    return matchedComp
  }
  return null
  // return matchedComp ? matchedComp : null
}).filter(Boolean)

const noCompReturned = computed(() => {
  // return !props.generatedCompList?.length
  return !filteredComps?.length
})

console.log('filtered comps',filteredComps)

const picked = ref('keep')

watch(noCompReturned, () => {
  if (noCompReturned) picked.value = 'keep'
})
</script>