<template>
    <div class="rounded-3xl w-full">
      <video ref="videoPlayer" class="video-js vjs-default-skin" controls preload="auto" width="" height="">
        <source src="https://d20rei050copm9.cloudfront.net/transcoded/555412bb-0d36-4411-9b9c-4bed71f9f4d6/555412bb-0d36-4411-9b9c-4bed71f9f4d6.m3u8" type="application/x-mpegURL">
                      
      </video>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import videojs from 'video.js';
  import 'video.js/dist/video-js.css';


  const props = defineProps({
  videoUrl: String,
});
  
  // Reference to the video element
  const videoPlayer = ref(null);
  let player = null;
  
  onMounted(() => {
    // Initialize Video.js player
    player = videojs(videoPlayer.value, {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true
    });
  
    player.ready(() => {
      console.log('Player is ready');
      // player.play();
    });
  });
  
  onUnmounted(() => {
    if (player) {
      player.dispose();
    }
  });
  </script>
  
  <style scoped>
  /* Add any additional styling here */
  </style>
  