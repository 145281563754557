<template>
  <div class="bg-[#FFFAE6] p-4 rounded-md flex flex-col gap-4">
    <div v-if="props.prompt?.name" class="flex gap-4 text-sm font-semibold text-gray-700 self-end">
      <p>Prompt: </p>
      <p>{{ props.prompt.prompt_name }}
        <span v-if="props.prompt.is_default" class="text-gray-800">(default)</span>
      </p>
    </div>
    <div :class="cn('flex justify-between items-center')">
      <div class="text-gray-700">
        Report Exists for
        <span class="font-semibold">
          {{ completedCount }} / {{ totalStudents }}
        </span>
        students.
      </div>
      <!-- <button v-if="isGenerating" type="button" @click="cancelJobQueue"
        class="py-3 px-4 inline-flex items-center gap-x-2
        text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
        hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
        :disabled="!isGenerating || isCancelling">
        Cancel Generation
      </button> -->
    </div>
    <!-- <div>Job id: {{ props.generateResource.data.job_id }}</div> -->
    <div v-if="isGenerating || props.isGeneratingAll">
      Generating Report for {{ currentStudent }}...
    </div>
    <div v-if="failedStudents.length">
      Generation failed for {{ failedStudents.join(', ') }}
    </div>
    <div v-if="cancelStatus">
      {{ cancelStatus }}
    </div>
  </div>
</template>

<script setup>
import { createResource } from 'frappe-ui';
import { computed, inject, ref, watch } from 'vue';
import config from '../../../../../../../../sites/common_site_config.json'
import { io } from "socket.io-client"
import { cn } from '@/components/ui/utils.js'

const props = defineProps(['generateResource', 'isGeneratingAll', 'prompt', 'defaultFlag'])
console.log(props.generateResource)

const reportResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.individual_observation_reports.api.get_completed_reports',
 auto: true
})

const cancelResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.utils.cancel_background_job',
})

watch([() => props.prompt, () => props.defaultFlag], ([newPrompt, newFlag]) => {
  reportResource.reload()
})

const userDetails = inject('userDetails');

const jobId = computed(() => {
  if (!props.generateResource?.job_id) return null
  return props.generateResource.job_id
})

const completedCount = computed(() => {
  if (!reportResource.data?.reports) return 0;
  const uniqueStudents = new Set();
  reportResource.data.reports.forEach(report => {
    uniqueStudents.add(report.student)
  });

  return uniqueStudents.size + runningCount.value
})

const runningCount = ref(0)

const totalStudents = computed(() => {
  if (!userDetails.value) return 0;
  return (userDetails.value.school_info.students.length)
})

const isGenerating = ref(props.isGeneratingAll || false)
const currentStudent = ref('')
const failedStudents = ref([])

watch(() => props.isGeneratingAll, (newValue) => {
  if (newValue) isGenerating.value = true
})

const host = window.location.hostname
const port = window.location.port ? `${config.socketio_port}` : '';
const protocol = port ? 'http' : 'https'
const siteName = window.site_name || host
const url = `${protocol}://${host}:${port}/${siteName}`

// Replace zippie.local w/ your local testing site
// const socket = io("http://localhost:9001/zippie.local", {
const socket = io(url, {
  withCredentials: true,
  reconnectionAttempts: 5,
})

const generationStartHandler = (data) => {
  if (!isGenerating.value) isGenerating.value = true
  currentStudent.value = data.student;
}

const generationEndHandler = (data) => {
  if (!isGenerating.value) isGenerating.value = true;
  if (data.result === 'success') runningCount.value++;
  failedStudents.value = data.failed_students;
  if (data.completed) isGenerating.value = false;
}

socket.on("report_generate_start", generationStartHandler);
socket.on("report_generate_end", generationEndHandler);

const isCancelling = ref(false)
const cancelStatus = ref('')

const cancelJobQueue = async () => {
  isCancelling.value = true
  cancelStatus.value = "Cancelling background job..."
  if (!props.generateResource?.data?.job_id)
    return
  try {
    await cancelResource.submit({
      job_id: props.generateResource.data?.job_id
    })
    if (cancelResource.data.status === 'success') {
      cancelStatus.value = "Background job has been cancelled successfully.";
    } else {
      cancelStatus.value = "Failed to cancel background job."
    }
  } catch (error) {
    console.error('Failed to make cancel request')
    cancelStatus.value = "Failed to make cancel request"
  }
  isCancelling.value = false
}
</script>