<!--This is the Calendar container or parent component of Calendar and Create and Update event Forms-->
<!--All the data is fetched here and then passed onto calendar and forms-->
<!--EventForm.vue is the Update form and the child of EventModal.vue (Have to rename it)-->


<template>

  <!-- Spinner logo -->
  <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center z-50">
    <!-- Preline spinner -->
    <!-- <div style="color: #FDD835" class="animate-spin inline-block size-12 border-[3px] border-current border-t-transparent text-yellow-600 rounded-full" role="status" aria-label="loading"> 
      <span class="sr-only">Loading...</span>
    </div> -->
    <!-- custom spinner -->
    <div class="relative inline-block w-16 h-16" role="status" aria-label="loading">
        <div class="absolute inset-0 bg-conic-gradient rounded-full animate-spin spinner-mask" style="--spinner-thickness: 8px;"></div>
        <span class="sr-only">Loading...</span>
      </div>
  </div>

  <div class="wrapper">
    <!--After save "Create-new-or-Edit" event modal-->
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50"
      v-if="isFeedbackModalVisible">
      <div class="bg-white p-5 rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-screen overflow-auto">

        <div v-if="feedbackMessage" class="feedback-message">{{ feedbackMessage }}</div>
        <!--New buttons for opening forms-->
        <button @click="showNewEventForm" class="add-event-btn ml-2">Add New Event</button>
        <button @click="handleEditButtonClick" @update-error="handleUpdateError" class="edit-event-btn ml-2">Edit
          Event</button>
        <button @click="closeFeedbackModal" class="bg-customNavbar mt-2 border-black ml-2">Close</button>
      </div>
    </div>

    <!-- Delete-event confirmation modal-->
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50"
      v-if="isDeleteEventConfirmationModalVisible">
        <div class="bg-white p-5 rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-1/3 h-auto max-h-screen overflow-auto">
          Are you sure you want to delete this Event ?
          <div class="flex flew-row mt-2">
            <button @click="closeDeleteConfirmationModal"  class="py-1.5 md:py-2 px-2 w-full md:w-1/4 bg-white text-black rounded-md cursor-pointer hover:bg-gray-200 block ml-2 mr-2">Cancel</button>
            <button @click="deleteEvent"  class="py-1.5 md:py-2 px-2 w-full md:w-1/4 bg-black text-white rounded-md cursor-pointer hover:bg-gray-800 block ml-2 mr-2">Delete</button>
          </div>  
        </div>
    </div>    

    <!--View Event details modal-->
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50"
      v-if="isViewDetailModalVisible" @click.self="closeFeedbackModal">
      <div class="bg-white p-5 rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-1/3 h-auto max-h-screen overflow-auto">
        

        <!-- Cross button at top right -->
        <div class="sticky top-0 flex justify-end z-10">
          <button @click="closeFeedbackModal" class="border-black">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

        <div>
          <!-- Displaying activity name -->
          <div v-if="fetchedSelectedEventData.activity_details"
            class="text-2xl text- font-semibold leading-9 tracking-wide text-left text-black">
            {{ fetchedSelectedEventData.activity_details.name }}
          </div>
          <div v-else class="text-2xl text- font-semibold leading-9 tracking-wide text-left text-black">
            {{ fetchedSelectedEventData.activity_name }}
          </div>

          <div class="flex items-center mb-4 text-black">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path
                d="M3.5 12.877C3.5 14.0588 3.73279 15.2292 4.18508 16.3211C4.63738 17.413 5.30031 18.4052 6.13604 19.2409C6.97177 20.0766 7.96392 20.7396 9.05585 21.1919C10.1478 21.6442 11.3181 21.877 12.5 21.877C13.6819 21.877 14.8522 21.6442 15.9442 21.1919C17.0361 20.7396 18.0282 20.0766 18.864 19.2409C19.6997 18.4052 20.3626 17.413 20.8149 16.3211C21.2672 15.2292 21.5 14.0588 21.5 12.877C21.5 10.49 20.5518 8.20082 18.864 6.51299C17.1761 4.82516 14.8869 3.87695 12.5 3.87695C10.1131 3.87695 7.82387 4.82516 6.13604 6.51299C4.44821 8.20082 3.5 10.49 3.5 12.877Z"
                stroke="#6B7280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.5 7.87695V12.877L15.5 15.877" stroke="#6B7280" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            {{ formatEventTime(fetchedSelectedEventData.start, fetchedSelectedEventData.end) }}
          </div>

          <!--Activity Link-->
          <div v-if="fetchedSelectedEventData.activity_details && fetchedSelectedEventData.activity_details.activity_link" class="mb-4">
            <p class="font-extra-thin text-base leading-6 tracking-tight">
              <span class="font-semibold text-black "></span>
              <a :href="fetchedSelectedEventData.activity_details.activity_link" target="_blank" class="text-black-500 underline hover:text-blue-700">
                  Activity link
                </a>
            </p>
          </div>


          <!--Description-->
          <div v-if="fetchedSelectedEventData.activity_description" class="mb-4">
            <p class="text-base leading-6 tracking-tight">
              <span class="font-semibold text-black ">Description:</span>

              <span class="ml-2">{{ fetchedSelectedEventData.activity_description }}</span>
            </p>
          </div>


          <!-- Displaying competencies -->
          <div v-if="fetchedSelectedEventData.custom_competency && fetchedSelectedEventData.custom_competency.length"
            class="mt-2">
            <p class="font-semibold text-black">Competencies:</p>
            <ul class="list-disc pl-5 ml-2">
              <li v-for="competency in fetchedSelectedEventData.custom_competency" :key="competency.id">
                {{ competency.name }}
              </li>
            </ul>
          </div>


          <!-- Displaying educators (led by) -->
          <div v-if="fetchedSelectedEventData.led_by && fetchedSelectedEventData.led_by.length" class="mt-4">
            <span class="font-semibold text-black">Led By:</span>
            <span class="ml-2">{{ fetchedSelectedEventData.led_by.map(educator => educator.name).join(', ') }}</span>
          </div>
          <div v-else class="mt-2">
            <p class="font-semibold text-black">Led By:</p>
            <p class="text-gray-500">No Facilitator found</p>
          </div>

          <!-- Displaying cohorts -->
          <div v-if="fetchedSelectedEventData.cohorts && fetchedSelectedEventData.cohorts.length" class="mt-4">
            <span class="font-semibold text-black">Cohorts:</span>
            <span class="ml-2">
              {{ fetchedSelectedEventData.cohorts.map(cohort => cohort.name).join(', ') }}
            </span>
          </div>
          <div v-else class="mt-2">
            <span class="font-semibold text-black">Cohorts:</span>
            <span class="text-gray-500 ml-2">No Cohorts found</span>
          </div>


           <!-- Displaying Room/location -->
           <div v-if="fetchedSelectedEventData.event_location && fetchedSelectedEventData.event_location.room" class="mt-4">
            <span class="font-semibold text-black">Room:</span>
            <span class="ml-2">
              {{ fetchedSelectedEventData.event_location.room }}
            </span>
          </div>
          <!-- <div v-else class="mt-2">
            <span class="font-semibold text-black">Room:</span>
            <span class="text-gray-500 ml-2">No location found</span>
          </div> -->


          <!-- Displaying students participating -->
          <div
            v-if="fetchedSelectedEventData.students_participating && fetchedSelectedEventData.students_participating.length"
            class="mt-4">
            <span class="font-semibold text-black">Students Participating:</span>
            <span class="ml-2">{{ fetchedSelectedEventData.students_participating.map(student => student.name).join(',') }}</span>
          </div>

          <!--Visitors  -->
          <div v-if="fetchedSelectedEventData.visitors" class="mt-4">
            <span class="font-semibold text-black">Visitors:</span>
            <span class="ml-2">{{ fetchedSelectedEventData.visitors }}</span>
          </div>

          <!-- Additional Comments -->
          <div class="mt-4" v-if="fetchedSelectedEventData.additional_comments">
            <span class="font-semibold text-black">Additional Comments:</span>
            <span class="ml-2">{{ fetchedSelectedEventData.additional_comments }}</span>
          </div>

        </div>
           
        <!-- Buttons -->
        <div class="mt-2 flex justify-center">
          <button v-if="userRoles.includes('Curriculum Calendar Manager')" @click="handleEditButtonClick" @update-error="handleUpdateError"
            class="ml-2 bg-black text-white py-2 px-16 rounded">Edit</button>
          <button @click="closeFeedbackModal"
            class="ml-2 md:ml-4 mr-2 bg-white text-black py-2 px-16 rounded border border-gray-200">Close</button>
        </div>
      </div>
    </div>

    <!--Table view Scheduler container-->   
    <div class="wrapper flex flex-col md:flex-row justify-center" v-if="isDataLoaded">

      <!--Div to render the Table view-->
      <div
        class="calendar-inner-container w-full max-w-full md:w-full md:mr-0 md:h-auto pr-2 pl-2 pt-6 md:p-0 rounded-md border border-solid overflow-hidden md:overflow-visible h-[100%] max-h-[100%] md:max-h-auto">
       
        <TableView v-if="optionsResponse && currentFilteredEvents" :events="currentFilteredEvents" :filtersApplied = "listOfFilterApplied" :isDataLoading="loading"
          :options-response="optionsResponse" :user-roles="userRoles" :all-cohorts-list="allCohortsList" :parents-child-info="parents_child_info"
          @event-clicked="handleEventClick" @cohorts-select-changed="handleFilterValueChange"
          @educators-select-changed="handleFilterValueChange" @month-changed="handleMonthChange"
          @showNewEventForm="showNewEventForm"
          @empty-event-clicked="handleEmptyEventClick">
        </TableView>

        <!-- Older schedule-x calendar -->
        <!-- <CurriculumCalendar v-if="optionsResponse && curriculumEvents" :events="curriculumEvents" :options-response="optionsResponse" @event-clicked="handleEventClick" @update-error="handleUpdateError" @update-event="handleUpdateEvent"/> -->
       
       
        <!-- New Event Modal Form -->
        <NewEventForm v-if="isNewEventFormVisible && optionsResponse && userRoles.includes('Curriculum Calendar Manager')"
          @create-success="event_name => handleCreateSuccess(event_name)" :options-response="optionsResponse"
          :emptyCellData ="emptyCellData"
          :all-cohorts-list="allCohortsList"
          @create-error="handleCreateError" @close="isNewEventFormVisible = false" />
      </div>
    </div>
    <div v-else></div>

    <!--Edit Event Form Modal-->
    <EventModal v-if="isModalVisible && optionsResponse" :selectedEvent="selectedEvent"
      :options-response="optionsResponse" :fetchedSelectedEventData="fetchedSelectedEventData"
      @close="isModalVisible = false" @modal-update-success="handleUpdateSuccess"
      @modal-update-error="handleUpdateError" @modal-delete-success="handleDeleteSuccess"
      @modal-delete-error="handleDeleteError"
      @delete-event-confirmation="handleDeleteConfirmation"/>
  </div>
</template>

<script>
import CurriculumCalendar from './CurriculumCalendar.vue';
import EventModal from './EventModal.vue';
import NewEventForm from './NewEventForm.vue';
import TableView from './TableView.vue'
import moment from 'moment';


export default {
  inject: ["$call"], // function provided by doppio library 
  components: {
    CurriculumCalendar,
    EventModal,
    NewEventForm,
    TableView
  },
  props: {
    optionsResponse: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userRoles: [],
      curriculumEvents: [], //Array to store only required fields of each event for calendar display.
      isDataLoaded: false,  //flag to wait for data to load before rendering calendar.
      selectedEvent: null,
      isModalVisible: false,
      fullEventData: {}, // Object to store all the fields of each event, to be passed onto the forms.
      allCohortsList: [],
      isNewEventFormVisible: false, // 
      feedbackMessage: '',
      isFeedbackModalVisible: false,
      isViewDetailModalVisible: false,
      isDeleteEventConfirmationModalVisible: false,
      parents_child_info: null,

      fetchedSelectedEventData: {},
      emptyCellData: {},
      deleteEventName:{},

      currentFilteredEvents: [],
      listOfFilterApplied: {},
      loading: false, 
    };
  },
  mounted() {
    const currentDate = moment(); // This gets the current date and time
    this.fetchCurriculumEvents(currentDate.format("YYYY-MM-DD"));
    // this.fetchCurriculumEvents(); //component calls the fuction after mounting 
    this.fetchUserRole();
    console.log('OptionsResponse prop in CurriculumEvents:', this.optionsResponse);
  },
  methods: {
    handleFilterValueChange(selection) {
      this.listOfFilterApplied[selection['type']] = selection['value'];

      console.log('Received new select value in parent:-----', selection);
      
      this.filterCurriculumEvents();
    },


    filterCurriculumEvents() {
      // Start with the full curriculumEvents array
      let filteredEvents = [...this.curriculumEvents];

      var selectedCohorts = this.listOfFilterApplied['cohorts'];
      var selectedEducators = this.listOfFilterApplied['educators'];

      console.log('selected cohorts');
      console.log(selectedCohorts);

      console.log(this.listOfFilterApplied);

      // Filter by selected cohorts if any
      if (selectedCohorts && selectedCohorts.length > 0) {
        filteredEvents = filteredEvents.filter((event) => {
          return event.cohorts.some((cohortName) => {
            return selectedCohorts.includes(cohortName);
          });
        }
        );

        // removing grouped events 
        // grouped events: events having more than 1 cohort


      }
      console.log("after filtering cohorts",)
      console.log(selectedCohorts);
      console.log(filteredEvents);
      // Filter by selected educators if any
      if (selectedEducators && selectedEducators.length > 0) {
        // filteredEvents = filteredEvents.filter(event =>
        // Array.isArray(event.led_by) && event.led_by.some(educator => selectedEducators.includes(educator))
        //   );
        filteredEvents = filteredEvents.filter((event) => {
          return event.led_by.some((ledBy) => {
            return selectedEducators.includes(ledBy);
          });
        }
        );

      }

      console.log("after filtering ledby",)
      console.log(selectedEducators);
      console.log(filteredEvents);

      this.currentFilteredEvents = filteredEvents;
      console.log("These are filtered events being passed down", this.currentFilteredEvents);
    },

    //fetches the curriculum-events doctype data 
    async fetchCurriculumEvents(forDate) {
      this.loading = true;
      try {

        const startDate = moment(forDate).startOf('month').subtract(1, 'week').format("YYYY-MM-DD");
        // Adjust the end date to go one week after the end of the month
        const endDate = moment(forDate).endOf('month').add(1, 'week').format("YYYY-MM-DD");


        console.log("Fetching data between", startDate, "and", endDate)
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_events_based_on_role', { start_date: startDate, end_date: endDate });

        console.log("Curriculum events data fetch response :---", response)
        console.log("Fetched data response lenght", response.enriched_events.length)

        this.fullEventData = {}; // Resetting full event data
        this.allCohortsList = response.all_cohorts
        if (response.parents_cohorts && response.parents_cohorts.length > 0) {
        this.parents_child_info = response.parent_cohorts_info;
        }
        this.curriculumEvents = response.enriched_events.map(event => {
        this.fullEventData[event.name] = event; // Storing full event data

          return {
            id: event.name,
            title: event.activity_title,
            start: moment(event.start).format("YYYY-MM-DD HH:mm"),
            end: moment(event.end).format("YYYY-MM-DD HH:mm"),
            competency: event.custom_competencies,
            led_by: event.led_by,
            activity_name:event.activity_name,
            session: event.session,
            location: event.event_location,
            cohorts: event.cohorts,
            calendarId: event.cohorts.length > 1 ? "groupevent" : (event.cohorts[0] ? event.cohorts[0].toLowerCase().replace(/\s+/g, '') : 'defaultCalendarId'),
            // ... maps only the fields required for calendar display ...
          };
        });
        console.log("This is the length of data being passed to table view", this.curriculumEvents.length)
        // Set currentFilteredEvents to the full set of events by default
        this.currentFilteredEvents = [...this.curriculumEvents];
        this.filterCurriculumEvents();

        this.isDataLoaded = true;
      } catch (error) {
        console.error('Error fetching curriculum events:', error);
        this.isDataLoaded = true;
      }
      this.loading = false;
    },
    async fetchFullEventData() {
      console.log("Fetch full events data called")
      if (this.selectedEvent && this.selectedEvent.name) {
        console.log("This is selected event in fetch full event data call", this.selectedEvent)
        try {
          const fullEventData = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_event_details', {
            event_name: this.selectedEvent.name  // Document id for fetching the details
          });
          console.log("This is the full data fetch response",fullEventData)
          this.fetchedSelectedEventData = fullEventData; // Populate eventData with the fetched full event data
          console.log("Full events data fetched in curriculum events on Click, logging its details -- ", this.fetchedSelectedEventData)

        }
        catch (error) {
          console.error('Error fetching full event data:', error);
        }
      }
    },
    handleMonthChange(newMonthStart) {
      console.log('New month detected, start date:', newMonthStart);
      // Fetch events for the new month
      this.fetchCurriculumEvents(newMonthStart);
    },
    formatEventTime(start, end) {
      const format = "ddd, MMM Do";
      const startTime = moment(start).format(format);
      const endTime = moment(end).format("h:mm A");

      return `${startTime}`;
    },
    async handleUpdateEvent(args) {
      console.log("Updating drag and drop in parent", args)
      try {
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.update_event', args);
        // Handle successful response
        console.log("update event response", response)
      } catch (error) {
        console.error('Error updating event in parent:', error);
        // Handle the error as needed, possibly emit another event or update local state
      }
    },
    // handles click for Update form modal display
    async handleEventClick(clickedEvent) {
      this.selectedEvent = this.fullEventData[clickedEvent.id]; //id property of clickedEvent is used to look up the event in fullEventData object, and event data is assigned to this.selectedEvent. 
      await this.fetchFullEventData()
      this.isViewDetailModalVisible = true;
      console.log("clicked event data recieved in parent", clickedEvent)
    }, 
    async handleEmptyEventClick(clickedEvent) {
      
    console.log("Clicked event recienved in parenty ####",clickedEvent)
    this.emptyCellData = clickedEvent
    },
    async handleEditButtonClick() {
      await this.fetchFullEventData();
      this.isModalVisible = true;
      this.isFeedbackModalVisible = false;
      this.isViewDetailModalVisible = false;

    },
    handleDeleteConfirmation(event_name){
      console.log("Handle delete confirmation called")
      this.isDeleteEventConfirmationModalVisible = true;
      this.deleteEventName= event_name
    },
    async deleteEvent() {
      console.log("delete eventcalled with event name",this.deleteEventName)
        try {
          const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.delete_event', {
            event_name: this.deleteEventName.event_name.event_name
          });
         console.log("This is deleting response",response)
        } catch (error) {
          console.error('Error deleting event:', error);
          
        }
        this.isDeleteEventConfirmationModalVisible = false;
        // clear delete event name object
        this.deleteEventName= {}
      },
    //handles click for closing Update Form modal
    closeModal() {
      this.isModalVisible = false;
      this.selectedEvent = null;
    },
    //handles click for showing NewEventForm modal
    showNewEventForm() {
      this.isNewEventFormVisible = true;
    },
    // Functions for handling feedback
    handleCreateSuccess(event_name) {
      this.feedbackMessage = 'New event created successfully!';
      // clears the message after some time
      this.clearFeedbackMessage();
      this.isNewEventFormVisible = false;
      this.isFeedbackModalVisible = true;
      console.log("This is full event data before create success -- ", this.fetchedSelectedEventData)

      this.selectedEvent = { ...this.selectedEvent, name: event_name };
      console.log("This is full event data after create success -- ", this.fetchedSelectedEventData)

    },
    handleUpdateSuccess() {

      this.isModalVisible = false; // Close the modal
      this.feedbackMessage = 'Event updated successfully!'; // Set feedback message
      this.clearFeedbackMessage();
    },
    handleDeleteSuccess() {
      this.isModalVisible = false; // Close the modal
      this.feedbackMessage = 'Event deleted successfully!'; // Set feedback message
      this.clearFeedbackMessage();
    },
    // methods when operations fail
    handleCreateError(error) {
      console.error('Error while creating new event:', error);
      // this.feedbackMessage = `Error while creating new event: ${error.message}`;
      this.feedbackMessage = `Internal server error while creating new event`;

      // clears the message after some time
      this.clearFeedbackMessage();
    },
    handleUpdateError(error) {
      this.isModalVisible = false; // Close the modal
      // this.feedbackMessage = `Error updating event: ${error.message}`; 
      this.feedbackMessage = `Internal server error while updating the event`;
      this.clearFeedbackMessage(); // Clear the message after some time
    },

    // Method when delete fails
    handleDeleteError(error) {
      this.isModalVisible = false; // Close the modal
      this.feedbackMessage = `Internal server error while deleting the event`;
      this.clearFeedbackMessage(); // Clear the message after some time
    },
    closeFeedbackModal() {
      this.isFeedbackModalVisible = false;
      this.isViewDetailModalVisible = false;
      this.fetchedSelectedEventData = {}
    },
    closeDeleteConfirmationModal(){
      this.isDeleteEventConfirmationModalVisible= false;
    },
    // Method to clear the feedback messages
    clearFeedbackMessage() {
      setTimeout(() => {
        this.feedbackMessage = '';
      }, 3000); // Adjust the timeout as needed
    },
    async fetchUserRole() {
      try {
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_logged_in_user_profile');

        // Assuming the response structure contains the role in response.message.role
        if (response) {

          this.userRoles = response.role;
          console.log("User profile fetch results:", this.userRoles);
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    },
    showNewEventForm() {
      this.isNewEventFormVisible = true; // Show the form
      this.showNewEventButton = false; // Hide the button
    },


  }
};
</script>

<style scoped>

/* Styling for loading spinner */
.bg-conic-gradient {
    background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
   }

  .spinner-mask {
    --spinner-thickness: 3px;
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  }

.font-extra-thin {
  font-weight: 100;
  /* Adjust the value as needed */
}


.calendar-inner-container.top-inner-shadow {
  box-shadow: inset 0 6px 0 rgba(253, 216, 53, 1);
}

.calendar-inner-container {
  border-left: 1px solid #B9B9B9;
  border-right: 1px solid #B9B9B9;
  border-bottom: 1px solid #B9B9B9;
  /* border-top: 1px solid #B9B9B9; */
}

.calendar-inner-container .fc-button {
  background-color: rgba(253, 216, 53, 1);
  border: none;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;

}

.calendar-inner-container .fc-day-today {
  background: rgba(254, 243, 194, 1) !important;
}

.calendar-inner-container .fc .fc-daygrid-day {

  border-color: #B9B9B9;

}

.calendar-inner-container .fc .fc-timegrid-slot {
  border-color: #B9B9B9;
}

.calendar-inner-container .fc .fc-toolbar-title {
  font-weight: 500;
  text-align: center;
}

.calendar-inner-container .fc .fc-scrollgrid {
  border-color: #B9B9B9;
}

.calendar-inner-container .fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: rgb(0, 0, 1);
  /* Replace with your desired color */

}

.calendar-inner-container .fc .fc-button-primary:disabled {
  background-color: rgb(0, 0, 1);
  opacity: 1;

}

.calendar-inner-container .fc-event-title,
.fc-event-content,
fc-event-time {
  font-size: 0.95rem;
  color: rgb(0, 0, 1);
  /* Change the text color */
  font-weight: 400 !important;
  line-height: 1em;
  word-wrap: break-word;

}

@media (max-width: 639px) {

  /* CSS for small devices */
  .calendar-inner-container .fc-button {
    background-color: rgba(253, 216, 53, 1);
    border: none;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
  }

  .calendar-inner-container .fc .fc-toolbar-title {
    font-weight: 500;
  }

  /* Mobile Styles */
  @media (max-width: 767px) {
    .add-event-btn {
      margin-top: 1em;
    }

    .calendar-inner-container .fc-button {
      font-size: 0.7em;
      /* Smaller font size for mobile */
      padding: 8px 12px;
      /* Adjust padding as needed for smaller buttons */
    }

    .calendar-inner-container .fc .fc-toolbar-title {
      text-align: center;
      font-size: 0.9em;
      /* Smaller font size for mobile */
    }

    .calendar-inner-container {
      border: none;
      border-bottom: 1px solid #B9B9B9;
    }

    .calendar-inner-container.top-inner-shadow {
      box-shadow: none;
    }

    .calendar-inner-container .fc-event-title,
    .fc-event-content,
    fc-event-time {
      font-size: 0.75rem;
      word-wrap: break-word;

    }
  }


}
</style>
