<template>
  <v-chart class="h-full w-full" :option="option" autoresize ref="chartRef" @finished="generateImageUrl" />
</template>

<script setup>
import { ref } from 'vue'
import { use } from 'echarts/core'
import { PieChart } from 'echarts/charts'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'

// data should be an array of {value, name} pairs
const props = defineProps(['name', 'chartData'])
const emit = defineEmits(['urlGenerated'])

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

const option = ref({
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: props.name,
      type: 'pie',
      radius: '50%',
      data: props.chartData,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ],
  color: [
    "#FDD835",
    "#FF8B70",
    "#00C49A",
    "#39C7FF",
    "#73E8EB",
    "#1982C4",
    "#B174C3",
    "#FF88C8",
  ],
})

const chartRef = ref(null)

const generateImageUrl = () => {
  const imageURl = chartRef.value.chart.getDataURL({
    type: 'png',
    pixelRatio: 2,
    backgroundColor: '#fff',
  })
  emit('urlGenerated', imageURl)
}
</script>