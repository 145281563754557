<template>
  <div class="flex flex-col h-[94vh] md:h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8 w-full">
        <h2 class="text-3xl">Activity Observation Extracts</h2>
        <div class="bg-[#FFFAE6] rounded-md p-4 space-y-4">
          <div class="flex flex-col gap-2">
            <div>
              <PromptModal modalName="extractPromptModal" :prompt="prompt" :model="model"
                :param_id="paramId" @change:params="(newParams) => changeParams(...newParams)">
                <template #trigger >
                  <button type="button"
                    class="py-3 px-4 inline-flex items-center gap-x-2
                    text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                    hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
                    :disabled="isExtracting" data-hs-overlay="#hs-extractPromptModal">
                    Set Prompt
                  </button>
                </template>
              </PromptModal>
            </div>
            <h4 class="font-semibold">Prompt:</h4>
            <p v-if="prompt">
              {{ prompt }}
            </p>
            <p v-else>No Prompt found. Please set new prompt.</p>
            <div class="flex gap-4">
              <h4 class="font-semibold">Model:</h4>
              <p v-if="model">
                {{ model }}
              </p>
              <p v-else>Model not set.</p>
            </div>
            <div v-if="!paramId">paramId not present</div>
          </div>
          <button type="button" @click="extractFromAll" 
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
            :disabled="isExtracting">
            Extract from all
          </button>
          <div v-if="isExtractingAll">
            Initiated extraction of student mentions in Activity Observations. Please revisit or reload the page after a few minutes.
          </div>
          <!-- <div v-if="autoLinkResource.data?.status === 'success'">
            Linked {{ extractCount }} observation documents.
          </div> -->
        </div>
        <div v-if="observationResource.data?.status === 'success'" class="flex flex-col gap-4">
          <div v-for="observation, index in observationResource.data.observations"
            class="flex gap-8">
            <div class="w-10">{{ index + 1 }}. </div>
            <ActivityObserViewCard :observation="observation" class="basis-96" modalName="extractObservViewModal"
              :selectObservation="selectObservation" />
            <!-- <EventLinkCard class="basis-96" :observation="observation" :openModalFn="openLinkModal"
              :event="observation.event_link ? observation.event : null" /> -->
            <div class="flex justify-center items-center">
              <button type="button" @click="() => generateExtract(observation.name, index)" 
                :disabled="isExtracting"
                class="py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
                Extract
              </button>
            </div>
            <div class="basis-96 flex flex-wrap items-start gap-2">
              <div v-for="extract in realtimeExtracts[index].extracts" 
                v-if="useRealtime && realtimeExtracts[index]" 
                @click="() => openExtractModal(extract)"
                :class="cn(`p-4 bg-[#FFFAE6] cursor-pointer`, deletedExtracts.includes(extract.name) && 'hidden')">
                <span v-if="extract.student">
                  {{ findValueByName(userDetails?.school_info.students, extract.student, 'student_name') }}
                </span>
                <span v-else>Unmatched student: {{ extract.extract.split(':')[0] }}</span>
              </div>
                <!-- v-else-if="!deletedExtracts.includes(extract?.name)" -->
              <div v-for="extract in observation.extracts" @click="() => openExtractModal(extract)"
                :class="cn(`p-4 bg-[#FFFAE6] cursor-pointer gap-2`, 
                deletedExtracts.includes(extract.name) && 'hidden')">
                <span v-if="extract.student">
                  {{ findValueByName(userDetails?.school_info.students, extract.student, 'student_name') }}
                </span>
                <span v-else>Unmatched student: {{ extract.extract.split(':')[0] }}</span>
              </div>
            </div>
            <div v-if="((useRealtime && realtimeExtracts[index]) || observation.observation_summary) 
              && !deletedSummaries.includes(observation.name)"
              class="basis-96 flex flex-col cursor-pointer gap-2 border p-2"
              @click="() => openSummaryModal(observation, index)">
              <div>Observation Summary:</div>
              <p v-if="useRealtime && realtimeExtracts[index]" 
                class="text-sm text-gray-600 line-clamp-5 text-ellipsis">
                {{ realtimeExtracts[index].summary }}
              </p>
              <p v-else class="text-sm text-gray-600 line-clamp-5 text-ellipsis">
                {{ observation.observation_summary }}
              </p>
            </div>
          </div>
          <ExtractModal modalName="activityExtractModal" :extract="selectedExtract"
            :deleteFn="deleteExtract" />
          <SummaryModal modalName="activityExtractSummary" :summary="selectedSummary"
            :deleteFn="deleteSummary" :observation="selectedObservation" />
          <!-- <EventLinkModal modalName="eventLinkModal" :observation="selectedObservation" 
            :events="observationResource.data.curriculum_events" :linkActivityFn="linkActivity" /> -->
        </div>
      </div>
    </div>
    <ActivityObservEditModal modalName="extractObservViewModal" :observation="selectedObservation" 
      :version="2" :selectedSignal="observationSignal" />
  </div>
</template>

<script setup>
import { createListResource, createResource } from 'frappe-ui';
import { computed, inject, provide, ref, watch, readonly } from 'vue';
import ActivityObserViewCard from '@/components/observations/view/ActivityObservViewCard.vue'
import { HSOverlay } from 'preline/preline';
import ActivityObservEditModal from '@/components/observations/edit/Activity/ActivityObservEditModal.vue';
import ExtractModal from '@/components/observations/extract/ExtractModal.vue';
import SummaryModal from '@/components/observations/extract/SummaryModal.vue';
import { findValueByName } from '@/lib/utils';
import { cn } from '@/components/ui/utils'
import PromptModal from '../components/observations/extract/PromptModal.vue';


// line 52 to 65 - workaround to fix observationCompetencies not found error in ActivityObservEditModal
// should find a better way to provied competencylist later
const competencies = ref(null)
provide('observationCompetencies', readonly(competencies))
const competencyResource = createListResource({
  doctype: 'Competency',
  fields: ['name', 'competency_name', 'description'],
  orderBy: 'competency_name asc',
  auto: true,
})

watch(() => competencyResource.data, () => {
  if (competencyResource.data)
    competencies.value = competencyResource.data
})

const prompt = ref('')
const model = ref('')
const paramId = ref('')

const observationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_observation_extracts.api.get_activity_observ_extracts',
 auto: true
})

const changeParams = (newPrompt, newModel, newParamId) => {
  prompt.value = newPrompt;
  model.value = newModel;
  paramId.value = newParamId;
}

watch(() => observationResource.data, () => {
  if (observationResource.data.generation_params) {
    changeParams(observationResource.data.generation_params.prompt, observationResource.data.generation_params.model, observationResource.data.generation_params.name)
  }
})

const extractResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_observation_extracts.api.extract_from_observation',
})

const deleteSummaryResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_observation_extracts.api.delete_summary',
})

const deleteExtractResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_observation_extracts.api.delete_extract',
})

const extractAllResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_observation_extracts.api.extract_from_all_async',
})

console.log(observationResource)

const userDetails = inject('userDetails');

const selectedObservation = ref(null)

const selectedExtract = ref(null)
const openExtractModal = (extract) => {
  selectedExtract.value = extract
  HSOverlay.open('#hs-activityExtractModal')
}

const isExtracting = ref(false)
const realtimeExtracts = ref([])
const useRealtime = ref(false)

const generateExtract = async (observation_id, index) => {
  isExtracting.value = true
  try {
    await extractResource.submit({
      observation_id,
    })
    console.log(extractResource)
    if (extractResource.data.status === 'success') {
      // observationResource.reload()
      realtimeExtracts.value[index] = {
        extracts: extractResource.data.extracts,
        summary: extractResource.data.summary
      };
      if (deletedSummaries.value.includes(observation_id)) {
        const idIndex = deletedSummaries.value.find((summary) => summary === observation_id)
        deletedSummaries.value.splice(idIndex, 1)
      }
      useRealtime.value = true;
    } else {
      console.error('Extraction failed')
    }
  } catch (error) {
    console.log(`Error making link request: ${error}`)
  }
  isExtracting.value = false
}

const isExtractingAll = ref(false)
// const extractCount = ref(0)

const extractFromAll = async () => {
  isExtracting.value = true
  isExtractingAll.value = true
  try {
    await extractAllResource.submit()
    console.log(extractAllResource)
    if (extractAllResource.data.status === 'success') {
      // extractCount.value = autoLinkResource.data.link_count
      // observationResource.reload()
    } else {
      console.error('Extraction failed')
    }
  } catch (error) {
    console.log(`Error making extract request: ${error}`)
  }
  // isExtracting.value = false
}

const observationSignal = ref(false)
const selectObservation = (observation) => {
  selectedObservation.value = observation
  observationSignal.value = !observationSignal.value
}

const selectedSummary = ref(null)
const openSummaryModal = (observation, index) => {
  if (useRealtime.value) {
    selectedSummary.value = realtimeExtracts.value[index].summary
  } else {
    selectedSummary.value = observation.observation_summary
  }
  selectObservation(observation)
  HSOverlay.open('#hs-activityExtractSummary')
}

const deletedSummaries = ref([])
const deletedExtracts = ref([])

const deleteSummary = async (observation_id) => {
  try {
    await deleteSummaryResource.submit({ observation_id })
    if (deleteSummaryResource.data.status === 'success') {
      deletedSummaries.value.push(observation_id)
      console.log("Summary was deleted!")
    } else {
      console.error(deleteSummaryResource.data.message || "Failed to delete Summary")
    }
  } catch (error) {
    console.log(`Error making deletion request: ${error}`)
  }
}

const deleteExtract = async (extract_id) => {
  try {
    await deleteExtractResource.submit({ extract_id })
    if (deleteExtractResource.data.status === 'success') {
      deletedExtracts.value.push(extract_id)
      console.log("Extract was deleted!")
    } else {
      console.error(deleteExtractResource.data.message || "Failed to delete Extract")
    }
  } catch (error) {
    console.log(`Error making deletion request: ${error}`)
  }
}
</script>