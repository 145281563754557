<template>
  <div :class="cn(`flex p-2 rounded-md transition-all duration-[2000ms] border border-transparent`, 
    isCorrectChoice && `border-green-600`, 
    isWrongChoice && `border-red-500`, isQuestionAnswered && `disabled:opacity-50 brightness-90`)">
    <label class="text-sm text-gray-800 ms-3 dark:text-gray-400 flex items-center gap-4">
      <input type="checkbox" :class="cn(`shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 
        disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 
        dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800
        transition-colors duration-[800ms]`,
        isCorrectChoice && `text-green-600`, isWrongChoice && `text-red-500`)" 
        :value="optionData.name" v-model="isChecked" :name="props.questionID" 
        :disabled="isQuestionAnswered">
        <!-- id="hs-default-checkbox"> -->
      <span :class="cn(isQuestionAnswered && `text-gray-500`)">{{ optionData.answer }}</span>
    </label>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { cn } from '../ui/utils';

const props = defineProps(['optionData', 'questionID', 'formStatus'])

const isChecked = ref(false)
const isQuestionAnswered = computed(() => {
  return props.formStatus[props.questionID]
})

const isCorrectChoice = computed(() => {
  if (!props.formStatus[`select-${props.questionID}`]) return false
  const selectChoices = props.formStatus[`select-${props.questionID}`]
  const optionId = props.optionData.name
  const isCorrect = props.optionData.is_correct
  return selectChoices.includes(optionId) && isCorrect
})

const isWrongChoice = computed(() => {
  if (!props.formStatus[`select-${props.questionID}`]) return false
  const selectChoices = props.formStatus[`select-${props.questionID}`]
  const optionId = props.optionData.name
  const isCorrect = props.optionData.is_correct
  return selectChoices.includes(optionId) && !isCorrect
})
</script>