<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== 1 && `hidden`)" >
    <div>
      <p class="font-medium text-sm md:text-base">
        Name of child:
      </p>
      <div class="border rounded-md" @keydown.stop>
        <!-- <StudentSelect :studentsList="props.studentsList" ref="studentSelectRef"
          @valueModified="handleMainStudentChange" selectId="dailyFormMainSelect" /> -->
          <!-- <div class="max-w-sm space-y-3"> -->
        <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 
          opacity-70 pointer-events-none
          dark:focus:ring-neutral-600" placeholder="Student Name..." readonly="" 
          :value="filteredStudentList[0].student_name" name="child_name"
          v-if="filteredStudentList.length === 1">
        <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 
          dark:focus:ring-neutral-600 hidden" placeholder="Student Name..." readonly="" 
          :value="filteredStudentList[0].name" name="children"
          v-if="filteredStudentList.length === 1">
<!-- </div> -->
        <MultiSelectSearch placeholder="Select Student..." v-if="filteredStudentList.length > 1"
          :dataList="filteredStudentList" inputName="children" dispName="student_name"
          selectId="studentSelectParentObserv" :hasSearch="false" :defaultList="[]" />
      </div>
    </div>
    <div>
      <label class="font-medium text-sm md:text-base">
        Date of observation:
      </label>
      <input type="date" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        id="activity-observ-form-date" name="date" v-model="dateValue" :max="formattedDate"
        required @change="emitDate" @click="openDatePicker">
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { computed, ref, inject } from 'vue'
import MultiSelectSearch from '@/components/ui/input/MultiSelectSearch.vue'
import { findValueByName } from '@/lib/utils.js'
import { openDatePicker } from '@/lib/utils';
// import FormFooter from '../FormFooter.vue';

const props = defineProps(['pageNum'])
const emit = defineEmits(['date:selected'])

const userDetails = inject('userDetails')

const filteredStudentList = computed(() => {
  if (!userDetails.value?.school_info) return []
  if (!userDetails.value?.children) return []
  return userDetails.value.school_info.students.filter((child) => userDetails.value.children.includes(child.name));
})

console.log(filteredStudentList)

const isNameFilled = computed(() => {
  if (userDetails.value?.user_roles.inclued('Parent')) {
    return true
  }
  return false
})

const today = new Date();
// Format today's date in YYYY-MM-DD format (required for input type date)
const formattedDate = today.toISOString().split('T')[0];

const dateValue = ref(formattedDate)

const emitDate = () => {
  emit('date:selected', dateValue.value)
}
</script>