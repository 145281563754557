<!--Container of Update form (EventForm)-->
<!--Recieves data of the event clicked on calendar from curriculum events "SelectedEvent", to identify the event/document-->
<template>
   <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50" v-if="isVisible" @click.self="closeModal">
   <div class="bg-white rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97vh] overflow-auto">

    <!-- <EventForm :event="selectedEvent" @close="closeModal" /> -->
      <EventForm
        :event="selectedEvent"
        :options-response="optionsResponse"
        :fetchedSelectedEventData="fetchedSelectedEventData"
        @close="closeModal"
        @update-success="handleUpdateSuccess"
        @update-error="handleUpdateError"
        @delete-success="handleDeleteSuccess"
        @delete-error="handleDeleteError"
        @delete-event-confirmation="handleDeleteConfirmation"
      /> <!--Emitted data gets recieved here-->
      </div>
    </div>
</template>
  
  <script>
  import EventForm from './EventForm.vue';
  
  export default {
    components: {
      EventForm
    },
    props: {
      selectedEvent: Object,
      optionsResponse: {
        type: Object,
        required: true,
      },
      fetchedSelectedEventData: {
        type: Object,
        required: true,
      }
    },
    created() {
   
   },
    data() {
      return {
        isVisible: true // Initially visible when the modal is rendered
      };
    },
    methods: {
      closeModal() {
        this.$emit('close'); // Emit an event to inform the parent component to close the modal
      },
      handleUpdateSuccess(data) {
      this.$emit('modal-update-success', data); // Emit an event to the parent component
      },
      handleUpdateError(error) {
      this.$emit('modal-update-error', error); // Emit an event to the parent component
      },
      handleDeleteSuccess(data) {
      this.$emit('modal-delete-success', data); // Emit an event to the parent component
      },
      handleDeleteError(error) {
      this.$emit('modal-delete-error', error); // Emit an event to the parent component
      },
      handleDeleteConfirmation(event_name){
        console.log("This is event data in modal",event_name)
        this.$emit('delete-event-confirmation', {
          event_name: event_name
        }); // Emit an event to the parent component
      }
    }
  };
  </script>
  