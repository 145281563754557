<template>
  <slot name="trigger" />

  <div :id="`hs-form-confirm-modal-${props.modalNum}`" class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] 
      overflow-x-hidden overflow-y-auto pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 
      opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 
        dark:border-gray-700 dark:shadow-slate-700/[.7]">
        <div class="flex justify-between items-start py-3 px-4 dark:border-gray-700 relative">
          <div class="flex flex-col gap-4 justify-center items-center flex-auto pt-12">
            <img :src="formConfirmIcon" alt="" class="h-10 w-10 md:h-16 md:w-16" />
            <h3 class="font-bold text-gray-800 dark:text-white text:lg md:text-xl text-center">
              Sweet, that took you only a few minutes!
            </h3>
          </div>
          <button type="button" class="hs-dropup-toggle absolute top-4 right-4 flex justify-center items-center size-7 text-sm 
            font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 
            disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 
            dark:focus:ring-gray-600" :data-hs-overlay="`#hs-form-confirm-modal-${props.modalNum}`">
            <span class="sr-only">Close</span>
            <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M18 6 6 18"/><path d="m6 6 12 12"/>
              </svg>
          </button>
        </div>
      <div class="p-4 overflow-y-auto text-sm text-center px-4 md:px-16">
          <p class="mt-1 text-[#6B7280] dark:text-gray-400">
            <span>You can view or edit the observation any time on the </span>
            <span class="text-[#EAB308]" @click="closeModal">
              <router-link v-if="props.formType === 'child' || !props.formType" to="/front-portal/observations/view/child/all"
                class="text-[#EAB308]">
                Learner Observations
              </router-link>
              <router-link v-if="props.formType === 'parent'" to="/front-portal/observations/view/parent/all"
                class="text-[#EAB308]">
                Child Observations
              </router-link>
              <router-link v-if="props.formType === 'group'" to="/front-portal/observations/view/activity/all"
                class="text-[#EAB308]">
                Group/Activity Observations
              </router-link>
              <router-link v-if="props.formType === 'weekly'" to="/front-portal/observations/view/unstructured/all"
                class="text-[#EAB308]">
                Weekly Observations
              </router-link>
            </span>
            <span>page</span>
          </p>
      </div>
      <div class="flex justify-center items-center gap-x-2 py-3 px-4 pb-12 dark:border-gray-700">
          <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm 
            font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
            disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 
            dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 
            dark:focus:ring-gray-600" @click="() => { props.createAnotherFn(props.currentFormNum || '') }">
              Add another
          </button>
          <button type="button" class="hs-dropup-toggle py-2 px-3 inline-flex items-center gap-x-2 text-sm 
            font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
            disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 
            dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 
            dark:focus:ring-gray-600" :data-hs-overlay="`#hs-form-confirm-modal-${props.modalNum}`">
              I'm done
          </button>
      </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import formConfirmIcon from '@/assets/save_confirm_icon.png'
import { HSOverlay } from 'preline'

// formType can be 'parent', 'child', 'group', 'weekly'
const props = defineProps([
  'createAnotherFn', 'modalNum', 'currentFormNum',
  'formType'
])

const closeModal = () => {
  HSOverlay.close(`#hs-form-confirm-modal-${props.modalNum}`);
}
</script>