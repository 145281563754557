<template>
  <div>
    <!-- <label for="hs-search-box-with-loading-5" class="block text-sm font-medium mb-2 dark:text-white">Search</label> -->
    <div class="relative flex rounded-lg shadow-sm">
      <input type="text" id="hs-search-box-with-loading-5" name="hs-search-box-with-loading-5" class="py-3 px-4 ps-11 block w-full border-gray-200 shadow-sm rounded-full text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" placeholder="Search Activity" @keypress="searchActivity" v-model="searchKey">
      <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4">
        <svg class="flex-shrink-0 size-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"/><path d="m21 21-4.3-4.3"/></svg>
      </div>
      <button type="button" class="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent   disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 hidden">
        <span class="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
          <span class="sr-only">Loading...</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const props = defineProps(['searchFn'])
const router = useRouter()
// const route = useRoute()

const searchKey = ref("")

const searchActivity = (e) => {
  if (e.key !== 'Enter') return
  if (searchKey.value === "") {
    router.push('/front-portal/activities/')
    return
  }
  props.searchFn(searchKey.value)
}
</script>