<template>
  <Card @click="() => props.openModalFn(props.observation)" 
    :class="cn('transition-all cursor-pointer hover:brightness-95 rounded', props.class)">
    <template #title>
      <span v-if="props.event" class="flex gap-2 items-center">
        <span v-if="props.event.linked_activity_name">{{ props.event.linked_activity_name }}</span>
        <span v-else-if="props.event.activity_name">{{ props.event.activity_name }}</span>
        <span v-else>Unnamed Activity</span>
      </span>
    </template>
    <div class="flex flex-col gap-4">
      <div v-if="!props.event" class="flex flex-col justify-center items-start">
        <div>No linked Activity!</div>
        <div>Click to view suggestions.</div>
      </div>
    </div>
    <div v-if="props.event" class="flex flex-col gap-4">
      <div class="flex justify-between">
        <div class="text-sm text-gray-600 flex gap-1 items-center">
          <span class="font-medium">{{ props.event.session }} session</span>
          <span>-</span>
          <span>{{ getFormattedDate(props.event.start) }}</span>
        </div>
      </div>
      <div class="flex gap-2 flex-wrap">
        <div v-for="cohort in props.event.cohorts.slice(0, 3)"   
          class="bg-[#FDD835] px-2 rounded items-center justify-center py-0">
          {{ cohort.cohort_name }}
        </div>
      </div>
      <p class="text-ellipsis line-clamp-2 font-light text-gray-700">
        Led by: 
        <span v-for="educator, index in props.event.led_by" >
          <span v-if="index !== 0">, </span>{{ educator.educator_name }}
        </span>
      </p>
      <div>Click to change linked activity.</div>
    </div>
  </Card>
</template>

<script setup>
import Card from '@/components/ui/Card.vue'
import { findValueByName, getFormattedDate } from '@/lib/utils'
import { HSOverlay } from 'preline/preline';
import { cn } from '@/components/ui/utils.js'
import { inject } from 'vue';

const props = defineProps([
  'event', 'samedayEvents', 'class',
  'openModalFn', 'observation'
])

// const userDetails = inject('userDetails')

// const viewObservation = () => {
//   props.selectObservation(props.observation, props.version)
//   HSOverlay.open('#hs-observation-edit-modal-all')
// }
</script>