<script setup>
import { cn } from '@/components/ui/utils'

const props = defineProps(['class', 'segNum'])

const id = `segment-${props.segNum}`
const ariaLabel = `segment-item-${props.segNum}`
</script>

<template>
  <div 
    :id="id" 
    role="tabpanel" 
    :aria-labelledby="ariaLabel"
    :class="cn
      (
        `mt-2 pb-14 lg:pb-6 xl:pb-0`,
        props.segNum !== '1' && 'hidden',
        props.class
      )"
  >
    <slot />
  </div>
</template>