<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full px-4
        max-h-[80vh] overflow-y-auto gap-4">
        <div class="flex flex-col gap-2">
          <div class="flex justify-between items-center pt-4">
            <h3 class="font-bold text-xl md:text-xl mt-6">
              Add new {{ props.userType }}
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col gap-4" ref="formRef">
          <div class="flex flex-col gap-4">
            <label class="flex flex-col gap-2">
              <span class="font-semibold">First Name</span>
              <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
                focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
                dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" 
                placeholder="First Name" name="first_name">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Last Name</span>
              <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
                focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
                dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" 
                placeholder="Last Name" name="last_name">
            </label>
            <label class="flex flex-col gap-2">
              <span class="font-semibold">Email</span>
              <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
                focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
                dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" 
                placeholder="First Name" name="email">
            </label>
          </div>
          <div :class="cn(`flex justify-center md:justify-end items-center gap-4 py-3 px-4 basis-[5.5rem]`)">
            <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
              rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
              disabled:opacity-50 disabled:pointer-events-none`)"
              :data-hs-overlay="`#hs-${props.modalName}`">
              Back
            </button>
            <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
              rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
              disabled:opacity-50 disabled:pointer-events-none`)" >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline'
import { createResource } from 'frappe-ui'

const props = defineProps(['modalName', 'userType', 'reloadFn'])
  
onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
})

const formRef = ref(null)

const newEducatorResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.educator.api.add_new_educator',
})

const newParentResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.parent.api.add_new_parent',
})

const handleSubmit = async (e) => {
  const formData = new FormData(formRef.value)
  const formDataObj = {}
  formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  console.log(formDataObj)

  if (props.userType === 'Educator') {
    await newEducatorResource.submit({
      user_data: formDataObj
    })
    console.log(newEducatorResource)
    if (newEducatorResource.data.status === 'success') {
      HSOverlay.close(`#hs-${props.modalName}`)
      props.reloadFn()
    } else if (newEducatorResource.data.status === 'fail') {
      alert(`Form Submission Failed.\n${newEducatorResource.data.message}`)
    }
  }
  // const apiData = {}
  // apiData.answers = {}
  // apiData.attempts = {}
  // apiData.questionnaire = props.qID
  // await resultsResource.submit({
  //   assessment_data: apiData
  // })

  // console.log(resultsResource)
  // if (resultsResource.data.status === 'success') {
  //   HSOverlay.close(`#hs-${props.modalName}`)
  //   pageNum.value = 1
  //   props.updateScore(resultsResource.data.assessment_score)
  //   HSOverlay.open('#hs-assess-confirm-modal')
  // } else if (resultsResource.data.status === 'fail') {
  //   alert(`Form Submission Failed.\n${resultsResource.data.message}`)
  // }
}
</script>