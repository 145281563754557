<template>
    <div class="h-full overflow-auto px-4 md:px-0"> 
        
        <!-- Title and Assign button -->
        <div class="flex flex-col md:flex-row mt-8 md:ml-16 mb-6 md:mb-12 justify-between"> 
            <span class="md:text-3xl text-xl font-medium md:min-w-[35%]">{{ worksheetDetails.title }}</span>
           
            <!-- Breadcrumb navigation -->
            <nav aria-label="Breadcrumb" class="md:hidden mt-2 mb-6">
                <ol class="flex items-center space-x-1 text-sm text-gray-500">
                    <li>
                    <router-link :to="{ name: 'Home' }" class="hover:text-gray-700">Home</router-link>
                    </li>
                    <li>
                    <span class="">/</span>
                    </li>
                    <li>
                    <router-link :to="{ name: 'Worksheets' }" class="hover:text-gray-700">Worksheets</router-link>
                    </li>
                    <li>
                    <span class="">/</span>
                    </li>
                    <li>
                    <span class="font-medium text-gray-900">{{ worksheetDetails.title }}</span>
                    </li>
                </ol>
            </nav>

            <!-- <button class="bg-[#FDD835] rounded-lg text-center text-black py-3 px-3 w-[50%] md:w-[20%] md:mr-20" @click="showAssignWorksheetModal=true"> Assign Worksheet</button> -->
            <div class="text-sm flex justify-between md:justify-end md:mr-10 md:w-full h-[100%] md:gap-2">
                <button v-if="userDetails && (userDetails.user_roles.includes ('System Manager') || userDetails.user_roles.includes('Educator'))" class="bg-[#FDD835] rounded-lg text-center text-black py-3 px-1 w-[49%] md:w-[15%]" @click="showAssignWorksheetModal=true"> Assign Worksheet</button> 
                <button v-if="userDetails && (userDetails.user_roles.includes ('System Manager') || userDetails.user_roles.includes('Educator'))" class="bg-white rounded-lg text-center text-black py-3 px-1 w-[49%] md:w-[15%]" @click="showAddAnswersheetModal=true" style="border: 1px solid #cbd5e1;"> Add Answer Sheet</button> 
            </div>
        </div>

        <!-- Breadcrumb navigation -->
        <nav aria-label="Breadcrumb" class="hidden md:flex mb-4 md:ml-16">
        <ol class="flex items-center space-x-1 text-sm text-gray-500">
            <li>
            <router-link :to="{ name: 'Home' }" class="hover:text-gray-700">Home</router-link>
            </li>
            <li>
            <span class="">/</span>
            </li>
            <li>
            <router-link :to="{ name: 'Worksheets' }" class="hover:text-gray-700">Worksheets</router-link>
            </li>
            <li>
            <span class="">/</span>
            </li>
            <li>
            <span class="font-medium text-gray-900">{{ worksheetDetails.title }}</span>
            </li>
        </ol>
        </nav>

        <!-- Image and details -->
        <div class="flex flex-col md:flex-row md:ml-16">
            
            <!-- Preview Image -->
            <div v-if="worksheetDetails.preview_image_url" class="w-auto flex justify-center mb-2 md:mb-0" style="border: 2px solid #FDD835;">
                <img :src="worksheetDetails.preview_image_url" class="w-[] h-[540px]"/> 
            </div>

            <div v-else class="w-auto flex justify-center mb-2 md:mb-0 md:mt-6">
                <img src="../assets/worksheet-icons/placeholder-worksheet-icon.png" class="md:h-[220px]"/> 
            </div>
            

            <!-- Worksheet details -->
            <div class="flex flex-col space-y-4 overflow-hidden text-sm md:text-[16px] md:ml-6 md:w-[40%]">
                
                <!-- Buttons for mobile screen-->
                <div class="flex md:hidden gap-2 mt-4">
                    <button class="text-white bg-black rounded-lg text-center text- py-2 w-2/3 " @click="showEmbeddedPdf=true"> Preview</button> <!--worksheetDetails.uploaded_worksheet_file-->

                    <a :href="worksheetDetails.uploaded_worksheet_file" download target="_blank" class="text-black bg-white rounded-lg text-center text- py-2 w-2/3" style="border: 2px solid #cbd5e1;">
                      Download
                    </a>

                    <!-- <button @click="forceDownload"  class="text-black bg-white rounded-lg text-center text- py-2 w-2/3"  style="border: 2px solid #cbd5e1;">
                        Download
                    </button> -->
                 </div>

                <!-- Summary -->
                <div>
                    <div class="font-medium text-lg">Summary</div>
                    <div class="mt-1.5">{{worksheetDetails.summary}}</div>
                </div>

                <!-- workheet url -->
                <div v-if="worksheetDetails.worksheet_url">
                    <div class="font-medium text-lg">Worksheet link</div>
                    <a :href="worksheetDetails.worksheet_url" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        class="text-blue-600 hover:text-blue-800 underline">
                            View Worksheet
                    </a>
                </div>
                
                <!-- Competencies -->
                <div class="">
                    <div class="font-medium text-lg mr-2">Competencies</div>
                    <div class="flex">
                        <div v-if="worksheetDetails && worksheetDetails.competencies" class="self-center mr-2.5 mt-1.5">
                            {{ worksheetDetails.competencies.join(', ') }}
                        </div>
                    </div>
                </div>
                
                <!--For cohorts  -->
                <div v-if="worksheetDetails.for_cohort">
                    <div class="font-medium text-lg">Cohorts</div>
                    {{ worksheetDetails.for_cohort.map(cohort => cohort.cohort_name).join(', ') }}
                </div>

                <!--Link to Answersheet Link -->
                <div v-if="worksheetAnswers.answersheet_url"> 
                    <div class="font-medium text-lg">Answersheet Url:</div>
                    {{ worksheetAnswers.answersheet_url }}
                </div>

                <!--Link to Answersheet pdf Download-->
                <div v-if="worksheetAnswers.answersheet_s3_url"> 
                    <div class="font-medium text-lg">Answersheet Url</div>
                    <a :href="worksheetAnswers.answersheet_s3_url" 
                        target="_blank" 
                        download 
                        rel="noopener noreferrer" 
                        class="text-blue-600 hover:text-blue-800 underline">
                            Download Answersheet
                    </a>
                </div>

                <!-- Date of creation -->
                <div>
                    <div class="font-medium text-lg">Date of creation</div>
                    <div class="mt-1.5">{{moment(worksheetDetails.datetime_of_creation).format('Do MMMM, YYYY')}}</div>
                </div>
                
                <!-- Buttons for desktop screen-->
                 <div class="hidden md:flex">
                    <button v-if="worksheetDetails.uploaded_worksheet_file" class="text-white bg-black rounded-lg text-center text-[16px] py-2 px-3 w-1/5 lg:w-[28%]" @click="showEmbeddedPdf=true"> Preview</button> <!--worksheetDetails.uploaded_worksheet_file-->

                    <template v-if="worksheetDetails.uploaded_worksheet_file">
                        <a :href="worksheetDetails.uploaded_worksheet_file" download class="text-black bg-white rounded-lg text-center text-[16px] py-2 px-3 w-1/5 lg:w-[28%] ml-3" style="border: 2px solid #cbd5e1;">
                        Download
                        </a>
                    </template>    
                 </div>
                
                    
            </div>

        </div>

    </div>
    
   
    <!-- Embeded pdf viewer modal -->
    <div v-if="showEmbeddedPdf" class="new-event-form fixed top-0 left-0 w-full h-full px-4 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "showEmbeddedPdf=false">
        
       <div class="w-full h-auto flex justify-center flex-col md:flex-row ">
           
            <div class="order-2 md:order-1 bg-white rounded-lg shadow-md w-auto md:w-3/5 lg:w-3/5 h-auto max-h-[97vh] overflow-auto">
                <embed
                    :src= "worksheetDetails.uploaded_worksheet_file"
                    type="application/pdf"
                    width="1000px"
                    height="600px"
                />
            </div>

            <!-- cross button -->
            <button class="order-1 md:order-2 text-white  bg-black rounded-lg flex items-center justify-center self-end md:self-start h-[45px] w-[13%] md:w-[3%] ml-4 mb-3 md:mb-0 mr- md:mr-0" @click="showEmbeddedPdf=false">     
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.23417 10.2001L13.147 15.1129C13.4077 15.3736 13.7613 15.5201 14.13 15.5201C14.4987 15.5201 14.8522 15.3736 15.1129 15.1129C15.3736 14.8522 15.5201 14.4987 15.5201 14.13C15.5201 13.7613 15.3736 13.4077 15.1129 13.1471L10.1982 8.23418L15.112 3.32131C15.241 3.19223 15.3434 3.039 15.4132 2.87037C15.483 2.70174 15.5189 2.52101 15.5188 2.3385C15.5188 2.156 15.4828 1.97528 15.4129 1.80669C15.343 1.63809 15.2406 1.48491 15.1115 1.35589C14.9825 1.22686 14.8292 1.12453 14.6606 1.05473C14.492 0.984926 14.3112 0.949021 14.1287 0.949064C13.9462 0.949107 13.7655 0.985097 13.5969 1.05498C13.4283 1.12486 13.2751 1.22727 13.1461 1.35635L8.23417 6.26922L3.3213 1.35635C3.19317 1.22356 3.03988 1.11763 2.87037 1.04472C2.70086 0.971809 2.51853 0.933389 2.33401 0.931699C2.1495 0.930009 1.9665 0.965084 1.79568 1.03488C1.62487 1.10467 1.46966 1.20778 1.33912 1.3382C1.20858 1.46861 1.10533 1.62372 1.03537 1.79447C0.965419 1.96522 0.930172 2.14819 0.931688 2.33271C0.933203 2.51722 0.971451 2.69959 1.0442 2.86916C1.11695 3.03874 1.22274 3.19213 1.35541 3.32039L6.27013 8.23418L1.35633 13.148C1.22367 13.2762 1.11788 13.4296 1.04513 13.5992C0.972378 13.7688 0.93413 13.9511 0.932614 14.1357C0.931098 14.3202 0.966345 14.5031 1.0363 14.6739C1.10625 14.8446 1.20951 14.9998 1.34005 15.1302C1.47059 15.2606 1.62579 15.3637 1.79661 15.4335C1.96742 15.5033 2.15043 15.5384 2.33494 15.5367C2.51946 15.535 2.70179 15.4966 2.87129 15.4236C3.0408 15.3507 3.19409 15.2448 3.32222 15.112L8.23417 10.2001Z" fill="white"/>
                </svg>

            </button>
      </div> 

    </div>  
    
    <!-- Assign worksheet modal -->
    <template v-if="optionsResponse && showAssignWorksheetModal">
     <AssignWorksheetModal :optionsResponse="optionsResponse" :worksheetName="props.worksheetName" @close="showAssignWorksheetModal = false" @assignment-success="handleAssignmentSuccess"
     @assignment-error="handleAssignmentError" ></AssignWorksheetModal>
    </template> 


    <!-- Add worksheet answers modal -->
    <template v-if="showAddAnswersheetModal">
        <AddAnswersheetModal :worksheetName="props.worksheetName" @close="showAddAnswersheetModal = false" @worksheet-created="handleAnswersheetCreated"
        @worksheet-error="handleAnswersheetError"></AddAnswersheetModal> 
    </template>
    
</template>


<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import moment from 'moment';
import AssignWorksheetModal from '../components/worksheets/AssignWorksheetModal.vue'; 
import AddAnswersheetModal from '../components/worksheets/AddAnswersheetModal.vue';
import { useRoute } from 'vue-router'
  
  const route = useRoute()
  const worksheetName = ref(route.params.worksheetName)
  

// Inject the $call method
const $call = inject('$call');


const props = defineProps({
      worksheetName: String,
      optionsResponse: Object,
      userDetails: Object,
    });


const selectedCohorts = ref([]);
const worksheet_title = ref('');
const educatorsSelect = ref('');
const summary = ref('');
const competenciesSelect = ref(null);

const worksheetDetails = ref('');
const worksheetAnswers = ref('');

const showEmbeddedPdf = ref(false)
const showAssignWorksheetModal = ref(false)
const showAddAnswersheetModal = ref(false)


// const imageLoaded = ref(false);

// const onImageLoad = () => {
//     console.log("on image load called===================")
//     imageLoaded.value = true;
// };

onMounted (()=>{
 console.log("Logging on mounted",props.worksheetName)
fetchWorksheetDetails()
fetchAnswersheet()
})


watch(
        () => props.worksheetName,
        (newData) => {
          console.log('watch value logged=====================================');
        }
    );


    const handleAnswersheetCreated = (message) => {
        // Handle successful worksheet creation
        console.log("Answersheet created success recieved in parent######################")
        alert('Answersheet created:', message);
        // Additional logic...
    };

    const handleAnswersheetError = ({ error, details }) => {
        // Handle worksheet creation error
        alert('Error creating Answersheet:', error, details);
        // Additional error handling...
    };    

    const fetchWorksheetDetails = async () => {
            console.log("fetch worksheet details called=============")
            console.log("this is the doc id it is called with",props.worksheetName)
            const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet.api.get_worksheet_details', {
            doc_id: props.worksheetName
            });

            worksheetDetails.value = response
            
            console.log("THis is the response of fetched worksheet details==========",worksheetDetails.value);
            
        }

    const fetchAnswersheet = async () => {
            console.log("fetch answersheet details called###############")
            const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet_answers.api.get_worksheet_answers', {
            worksheet_id: props.worksheetName
            });

            worksheetAnswers.value = response
            
            console.log("THis is the response of fetched worksheet Answers #######",worksheetAnswers.value);
            
        }    

    const handleAssignmentSuccess = (message) => {
        // Handle success (e.g., show a success message)
        console.log('Assignment successful:', message);
        alert('Assignment successful:', message)
        // You might want to update some state or show a notification
    }

    const handleAssignmentError = (error) => {
        // Handle error (e.g., show an error message)
        console.error('Assignment error:', error);
        alert('Assignment error:', error)
        // You might want to show an error notification
    }
        

</script>
  
  