<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Full Prompt
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <!-- <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col" ref="formRef">
        </form> -->
        <div class="flex-auto flex flex-col p-4 max-h-[80vh] overflow-y-auto whitespace-pre-wrap">
          {{ props.promptString }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, inject } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline';

const props = defineProps([
  'modalName', 'promptString'
])

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

</script>