<template>
  <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "closeComponent">
    <div class="bg-white rounded-lg shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-[61%] max-h-[97vh] overflow-auto">
        
        
        
        <!-- Cross button -->
        <div class="sticky top-2 mr-2 flex justify-end z-10">
            <button @click="closeComponent" class="border-black">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>  
        
        <!-- Content wrapper -->
        <div class="flex flex-col py-2 px-6">
            <div v-if="optionsResponse && optionsResponse.students" class="text-black font-semibold text-xl text-center mb-4"> Assign worksheet</div>
            
            <!-- Tabs -->
            <div>
               
                <div class="inline-flex rounded-lg shadow-sm border rounded-md">
                    <div >   
                        <button @click="handleLearnerTabClick" type="button" class="py-3 px-3.5 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200"
                        :class="[learnerTabActive ? 'bg-black text-white' : 'bg-white text-black hover:bg-gray-50']"
                        >
                            Learners
                        </button>
                    </div>     

                    <div @click="handleCohortTabClick" class="">
                        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200"
                        :class="[cohortTabActive ? 'bg-black text-white' : 'bg-white text-black hover:bg-gray-50']">
                            Cohort
                        </button>
                    </div>    
                </div>
                
            </div>


            <!-- Learner Form -->
            <form v-if="learnerTabActive" @submit.prevent="handleLearnerFormSubmit" class="flex flex-col gap-1">
                
                <!-- <div v-if="selectedLearners" class="flex mt-4 gap-2 text-slate-500 font-medium truncate ... w-full">
                    <div v-for="(student, index) in selectedLearnersWithNames">
                        {{ student.name }}
                        <span v-if="index < selectedLearnersWithNames.length - 1">,</span>
                    </div>
                </div> -->

                <!-- Students/Learners worksheet assigned to Select -->
                <div v-if="optionsResponse && optionsResponse.students" class="flex flex-col mb-1.5 mt-2">
                    <label for="learnersSelect" class="font-medium text- text-black mr-2 mb-4 mt-2.5">Who is this worksheet for?</label>        
                    <!-- Select -->
                    <div class="relative w-[15rem] md:w-full border rounded-md">
                    <select v-model="selectedLearners" id="learnersSelect" ref="learnersSelect" multiple="" data-hs-select='{
                        "placeholder": "Select Learners...",
                        "toggleTag": "<button type=\"button\"></button>",
                        "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                        "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
                        "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
                        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>",
                        "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
                        }' class="hidden">
                    <option v-for="student in optionsResponse.students" :key="student.name" :value="student.name">{{ student.student_name }}</option>
                    </select>
                    <div class="absolute top-1/2 end-3 -translate-y-1/2">
                        <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                    </div>
                </div>
                </div> 
                <!-- End Select -->

                
                <!-- Completed assignment student List -->
                <div v-if="worksheetCompletionStatus">
                    <div v-if="previouslyAssignedStudents.length>0" class="flex flex-col mb-1.5">
                        <label for="completeLearnersSelect" class="font-medium text- text-black mr-2 my-4">
                        Learners who have completed the worksheet
                        </label>        
                        <div class="relative w-[15rem] md:w-full border rounded-md">
                        <select v-model="selectedCompletedLearners" id="completeLearnersSelect" ref="completeLearnersSelect" multiple="" data-hs-select='{
                            "placeholder": "Select Learners...",
                            "toggleTag": "<button type=\"button\"></button>",
                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                            "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>",
                            "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
                            }' class="hidden">
                            <option v-for="student in selectedLearnersWithNames" :key="student.id" :value="student.id">
                            {{ student.name }}
                            </option>
                        </select>
                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                        </div>
                        </div>
                </div> 
                </div>


                <!-- Incomplete assignment student list -->
                <div v-if="worksheetCompletionStatus">      
                        <!-- Incomplete Students/Learners Select -->
                        <div v-if="previouslyAssignedStudents.length>0" class="flex flex-col mb-1.5">
                            <label for="incompleteLearnersSelect" class="font-medium text- text-black mr-2 my-4">Learners who have not completed the worksheet</label>        
                            <!-- Select -->
                            <div class="relative w-[15rem] md:w-full border rounded-md">
                            <select v-model="selectedIncompleteLearners" id="incompleteLearnersSelect" ref="incompleteLearnersSelect" multiple="" data-hs-select='{
                                "placeholder": "Select Learners...",
                                "toggleTag": "<button type=\"button\"></button>",
                                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                                "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
                                "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
                                "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>",
                                "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
                                }' class="hidden">
                             <option v-for="student in selectedLearnersWithNames" :key="student.id" :value="student.id"> {{ student.name }}</option>
                            </select>
                            <div class="absolute top-1/2 end-3 -translate-y-1/2">
                                <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                            </div>
                            </div>
                        </div> 
                        <!-- End Select -->  

                </div>


                <button class="bg-black self-end rounded-lg text-center text-white py-2.5 px-3 w-1/5 mt-2" @click=""> Save</button> <!--worksheetDetails.uploaded_worksheet_file-->
        
            </form>

            
            <!-- Cohort Form -->
            <form v-if="cohortTabActive && preselectedCohorts" @submit.prevent="handleCohortFormSubmit" class="flex flex-col">
                <!-- Assign to cohorts -->
                <div class="font-medium text-black mt-8 mb-4">Assign to cohorts </div>
                <div class="flex flex-wrap w-full">
                    <div v-for="cohort in optionsResponse.cohorts" :key="cohort.name" class="ml-2 mb-2 w-[160px]">  
                            <input type="checkbox" :value="cohort.name" v-model="selectedCohorts" class="shrink-0 mt-0.5 border-gray-200 rounded" id="">
                            <label :for="cohort.name" class="text-sm text-gray-500 ms-3">{{ cohort.cohort_name }}</label>
                    </div>
                </div> 

                <button class="bg-black self-end rounded-lg text-center text-white py-2.5 px-3 w-1/5 mt-2 mr-4"> Save</button> <!--worksheetDetails.uploaded_worksheet_file-->
        

            </form>


        </div>

        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import moment from 'moment';
import Worksheets from '../../views/Worksheets.vue';
import { defineEmits } from 'vue';



const props = defineProps({
      worksheetName: String,
      optionsResponse: Object,
    });

// Inject the $call method
const $call = inject('$call');


// Add emit to your component setup
const emit = defineEmits(['close', 'assignment-success', 'assignment-error']);



const selectedLearners = ref([]) 
const previouslyAssignedStudents = ref([])


const selectedIncompleteLearners = ref([])  
const selectedCompletedLearners = ref([])

const previousIncompleteLearners = ref([])
const previousCompleteLearners = ref([])

const worksheetCompletionStatus = ref([])

const preselectedCohorts = ref([])
const selectedCohorts = ref([])


const learnerTabActive = ref(true)
const cohortTabActive = ref(false)


onMounted (()=>{
   
    
})    

//Form submit handler function
const handleLearnerFormSubmit = async () => {
    try {
        const assignResult = await assignWorksheet();
        console.log("THis is assign worksheet response ################",assignResult)
       
        const updateResult = await updateWorksheetStatus();
        console.log("This is updating the worksheet fields response ################",updateResult)
       
        if (assignResult.status === 'success' && updateResult.status === 'info' || updateResult.status=== 'success') {
            emit('assignment-success', 'Learner assignments updated successfully');
        } else {
            emit('assignment-error', 'Error updating learner assignments');
        }
    } catch (error) {
        emit('assignment-error', error.message || 'An error occurred');
    }
    closeComponent();
}

const handleCohortFormSubmit = async()=>{
    try {
        const result = await assignWorksheetToCohorts();
        if (result && result.status === 'success') {
            emit('assignment-success', 'Cohort assignments updated successfully');
        } else {
            emit('assignment-error', 'Error updating cohort assignments');
        }
    } catch (error) {
        emit('assignment-error', error.message || 'An error occurred');
    }
    closeComponent();
}

const assignWorksheet = async () => {
    console.log("Assign worksheet called=============")
    const currentlySelectedStudents = JSON.parse(JSON.stringify(selectedLearners.value))

    // Find newly added students
    const newlyAddedStudents = currentlySelectedStudents.filter(
        student => !previouslyAssignedStudents.value.includes(student)
    )

    // Find removed students
    const removedStudents = previouslyAssignedStudents.value.filter(
        student => !currentlySelectedStudents.includes(student)
    )

    if (newlyAddedStudents.length > 0 || removedStudents.length > 0) {
        console.log("Newly added students:", newlyAddedStudents)
        console.log("Removed students:", removedStudents)

        const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet_assignment.api.assign_worksheet_to_students', {
        worksheet_id: props.worksheetName,
        added_student_ids: newlyAddedStudents,
        removed_student_ids: removedStudents
        })

        // Update the previously assigned students
        previouslyAssignedStudents.value = currentlySelectedStudents
        return response
    } else {
        return { status: 'info', message: "No changes in student assignments" };
    }
  }

    const updateWorksheetStatus = async () => {
        console.log("update worksheet status called")
        
        const newlyCompleted = selectedCompletedLearners.value.filter(
            student => !previousCompleteLearners.value.includes(student)
        )
        console.log("These are new completed learners", newlyCompleted)
        
        const newlyIncomplete = selectedIncompleteLearners.value.filter(
            student => !previousIncompleteLearners.value.includes(student)
        )
        console.log("These are new incomplete students", newlyIncomplete)
        
        // Check for students removed from completed list
        const removedFromCompleted = previousCompleteLearners.value.filter(
            student => !selectedCompletedLearners.value.includes(student)
        )
        console.log("These are students removed from completed list", removedFromCompleted)
        
        // Add students removed from completed to the incomplete list
        const updatedNewlyIncomplete = [...new Set([...newlyIncomplete, ...removedFromCompleted])]
        
        if (newlyCompleted.length > 0 || updatedNewlyIncomplete.length > 0) {
            console.log("These are new completed students", newlyCompleted)
            console.log("These are new incomplete students (including removed from completed)", updatedNewlyIncomplete)
            
            const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet_assignment.api.update_worksheet_completion_status', {
                worksheet_id: props.worksheetName,
                newly_completed_students: newlyCompleted,
                newly_incomplete_students: updatedNewlyIncomplete
            })
            
            if (response.status === 'success') {
                previousCompleteLearners.value = [...selectedCompletedLearners.value]
                previousIncompleteLearners.value = [...selectedIncompleteLearners.value]
            }
            
            return response
        } else {
            return { status: 'info', message: 'No changes in completion status' }
        }
    }

    const fetch_worksheet_completion_status = async () => {
        console.log("fetch_worksheet_completion_status called=============")
        
        const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet_assignment.api.fetch_worksheet_completion_status', {
        worksheet_id: props.worksheetName,
        });

        console.log("These are completed assignment",response)
        worksheetCompletionStatus.value = response;
        
        selectedIncompleteLearners.value = response.incomplete_assignments.map(assignment => assignment.student)
        previousIncompleteLearners.value = response.incomplete_assignments.map(assignment => assignment.student)

        selectedCompletedLearners.value = response.completed_assignments.map(assignment => assignment.student)
        previousCompleteLearners.value = response.completed_assignments.map(assignment => assignment.student)
        
        // For assigning worksheet field
        selectedLearners.value = [
            ...response.incomplete_assignments.map(assignment => assignment.student),
            ...response.completed_assignments.map(assignment => assignment.student)
        ];
        previouslyAssignedStudents.value = [
            ...response.incomplete_assignments.map(assignment => assignment.student),
            ...response.completed_assignments.map(assignment => assignment.student)
        ];

        console.log("This is selected learners value after fetching incomletion status",selectedLearners.value)
        }

    
    const closeComponent = () => {
        emit('close');
    };  
    
    const assignWorksheetToCohorts = async () => {
        console.log("Assign worksheet to cohorts called=============")
        const currentlySelectedCohorts = selectedCohorts.value

        // Extract cohort IDs from preselectedCohorts if they are full objects
        const preselectedCohortIds = preselectedCohorts.value.map(cohort => 
            typeof cohort === 'object' ? cohort.cohort_id : cohort
        )

        // Find newly added cohorts
        const newlyAddedCohorts = currentlySelectedCohorts.filter(
            cohortId => !preselectedCohortIds.includes(cohortId)
        )

        // Find removed/ unassigned cohorts
        const removedCohorts = preselectedCohortIds.filter(
            cohortId => !currentlySelectedCohorts.includes(cohortId)
        )

        if (newlyAddedCohorts.length > 0 || removedCohorts.length > 0) {
            console.log("Newly added cohorts------:", newlyAddedCohorts)
            console.log("Removed cohorts:", removedCohorts)

            const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet_assigned_to_cohort.api.assign_worksheet_to_cohorts', {
                worksheet_id: props.worksheetName,
                added_cohort_ids: JSON.stringify(newlyAddedCohorts),
                removed_cohort_ids: JSON.stringify(removedCohorts)
            })

            // Update the preselectedCohorts
            preselectedCohorts.value = currentlySelectedCohorts.map(cohortId => ({ cohort_id: cohortId }))
        } else {
            console.log("No changes in cohort assignments")
        }
        closeComponent();
    }

    const fetchWorksheetAssignedCohorts = async () => {
       
        console.log("fetch worksheet assignment called+++++++++++++")
        const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet_assigned_to_cohort.api.fetch_worksheet_assigned_cohorts', {
        worksheet_id: props.worksheetName,
        });

        if (response && response.status === 'success' && response.assigned_cohorts) {
            preselectedCohorts.value = response.assigned_cohorts;
            selectedCohorts.value = response.assigned_cohorts.map(cohort => cohort.cohort_id);
        } else {
            console.error('Unexpected response structure:', response);
            preselectedCohorts.value = [];
            selectedCohorts.value = [];
        }
    }


    const selectedLearnersWithNames = computed(() => {
        if (!props.optionsResponse || !props.optionsResponse.students) return [];
        
        const studentMap = new Map(
            props.optionsResponse.students.map(student => [student.name, student.student_name])
        );

        return selectedLearners.value.map(studentId => ({
            id: studentId,
            name: studentMap.get(studentId) || studentId
        }));
    });

    const handleCohortTabClick = ()=>{
        learnerTabActive.value= false;
        cohortTabActive.value = true;
        fetchWorksheetAssignedCohorts()
    }
    const handleLearnerTabClick = ()=>{
        learnerTabActive.value= true;
        cohortTabActive.value = false;
        
        setTimeout(() => {
            window.HSStaticMethods.autoInit();},100)
    }

    watch(
    () => props.optionsResponse,
    (newVal, oldVal) => {
        if (newVal) {
        fetch_worksheet_completion_status();    
        setTimeout(() => {
            window.HSStaticMethods.autoInit();
            console.log("Preline initialised in Assign worksheet modal");

            // Initialize the select element for assinged worksheet field
            const learnersSelectElement = window.HSSelect.getInstance('#learnersSelect');
            if (learnersSelectElement) {
            learnersSelectElement.on('change', (value) => {
                console.log('Select value changed:', value);
                selectedLearners.value = value
            });
            } else {
            console.log('AdvancedSelect cohorts instance not found');
            }

             // Initialize the select element for completed assignment learners field
             const completeLearnersSelectElement = window.HSSelect.getInstance('#completeLearnersSelect');
            if (completeLearnersSelectElement) {
                completeLearnersSelectElement.on('change', (value) => {
                console.log('Select value changed:', value);
                selectedCompletedLearners.value = value
            });
            } else {
            console.log('AdvancedSelect completeLearnersSelect instance not found');
            }

             // Initialize the select element for incomplete assignment learners field
             const incompleteLearnersSelectElement = window.HSSelect.getInstance('#incompleteLearnersSelect');
            if (incompleteLearnersSelectElement) {
                incompleteLearnersSelectElement.on('change', (value) => {
                console.log('Select value changed:', value);
                selectedIncompleteLearners.value = value
            });
            } else {
            console.log('AdvancedSelect cohorts instance not found');
            }
        },100)
    }
  },
  { immediate: true } // This ensures the watcher is triggered immediately if optionsResponse is already set
    
) 


</script>