<template>
  <div class="flex flex-col gap-2">
    <div class="font-semibold">
      Options:
    </div>
    <div class="flex gap-2 justify-between">
      <div class="flex flex-col gap-2">
        <div class="flex justify-end gap-4 items-end">
          <span class="text-sm font-semibold text-gray-600">Fetch All Observations</span>
          <input type="checkbox" id="hs-basic-usage" class="relative w-[3.25rem] h-7 p-px bg-gray-100
            border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out
            duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none
            checked:text-[#fdd835] checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800
            dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600
            before:inline-block before:size-6 before:bg-white checked:before:bg-white before:translate-x-0
            checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0
            before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400
            dark:checked:before:bg-blue-200" v-model="allObservationsToggle">
          <label for="hs-basic-usage" class="sr-only">switch</label>
          <button type="button"  @click="props.reloadObservations"
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
            Reload Observations
          </button>
        </div>
        <div class="flex gap-2" v-if="!allObservationsToggle">
          <label>
            <p>Start Date:</p>
            <input type="date" id="summaryStartDate" name="start-date" v-model="startDate" min="2018-01-01"
              :max="today" />
          </label>
          <label>
            <p>End Date:</p>
              <input type="date" id="summaryEndDate" name="end-date" v-model="endDate" min="2018-01-01"
                :max="today" />
          </label>
        </div>
      </div>
      <div class="flex gap-2 justify-center items-center z-50">
        Model:
        <div class="hs-dropdown relative inline-flex">
          <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex
            items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white
            text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none
            dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
            {{ MODELMAP[props.pickedModel] }}
            <svg class="hs-dropdown-open:rotate-180 size-4" xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
          </button>
          <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100
            opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-neutral-800
            dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute
            after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4
            before:start-0 before:w-full" aria-labelledby="hs-dropdown-default">
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-3.5-turbo-0125')">
              GPT 3.5 Turbo
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-4')">
              GPT 4
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-4-32k')">
              GPT 4 32K
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-4-turbo')">
              GPT 4 Turbo
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-4o')">
              GPT 4o
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('claude-3-5-sonnet')">
              Claude 3.5 Sonnet
            </a>
          </div>
        </div>
      </div>
      <div class="flex gap-2 items-center">
        <button type="button"  :data-hs-overlay="`#hs-indiPromptModal`"
          class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
          <span v-if="props.prompt.name">Change Prompt</span>
          <span v-if="!props.prompt.name">Pick Prompt</span>
        </button>
        <button type="button" @click="checkForExistingReports"
          class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          :disabled="!props.prompt?.name">
          Generate
        </button>
        <button type="button" @click="props.openAllModal"
          class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          :disabled="!props.prompt?.name">
          Generate All
        </button>
      </div>
    </div>
  </div>
  <PromptModal :prompt="props.prompt" modalName="indiPromptModal" :usePrompt="props.usePrompt" @change:default-prompt="changedDefaultPrompt" />
</template>

<script setup>
import { getTodayDateJSFormat } from '@/lib/utils';
import { ref, watch } from 'vue';
import PromptModal from './PromptModal.vue';

const props = defineProps([
  'pickedModel', 'pickNewModel', 'prompt',
  'usePrompt', 'reloadObservations', 'checkForExistingReports',
  'openAllModal'
])

const emit = defineEmits([
  'change:start', 'change:end',
  'change:loadAll', 'change:defaultPrompt'
])

const changedDefaultPrompt = () => {
  emit('change:defaultPrompt')
}


const today = getTodayDateJSFormat();

const allObservationsToggle = ref(true);
const startDate = ref("2024-05-10");
const endDate = ref(today);

watch([startDate, endDate, allObservationsToggle], ([newStart, newEnd, newAll]) => {
  emit('change:start', newStart)
  emit('change:end', newEnd)
  emit('change:loadAll', newAll)
})

const MODELMAP = {
  'gpt-3.5-turbo-0125': 'GPT-3.5 Turbo',
  'gpt-4': 'GPT-4',
  'gpt-4-32k': 'GPT-4 32K',
  'gpt-4-turbo': 'GPT-4 Turbo',
  'gpt-4o': 'GPT-4o',
  'claude-3-5-sonnet': 'Claude 3.5 Sonnet'
}
</script>