<template>
  <div :class="cn(
    'p-8 pt-2 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
    props.pageNum !== props.componentPageNum && `hidden`
  )
    ">
    <div class="space-y-4">
      <legend class="text-xs font-semibold text-gray-500">
        <p class="pb-2 text-sm text-gray-800">Legend</p>
        <div class="flex gap-4"><span class="text-gray-700 w-6">HI</span>-<span>Highly Engaged</span></div>
        <div class="flex gap-4"><span class="text-gray-700 w-6">RE</span>-<span>Reasonably Engaged</span></div>
        <div class="flex gap-4"><span class="text-gray-700 w-6">SP</span>-<span>Sporadically Engaged</span></div>
        <div class="flex gap-4"><span class="text-gray-700 w-6">LF</span>-<span>Left Quickly</span></div>
        <div class="flex gap-4"><span class="text-gray-700 w-6">DN</span>-<span>Did Not Engage</span></div>
        <div class="flex gap-4"><span class="text-gray-700 w-6">AB</span>-<span>Absent</span></div>
      </legend>
      <p class="font-medium text-sm md:text-base">3. Engagement Level</p>
      <div class="flex flex-col relative">
        <div class="-m-1.5 overflow-x-auto min-w-full">
          <div class="p-1.5 pt-0 pr-0 min-w-full inline-block align-middle w-full">
            <div class="overflow-hidden overflow-y-auto max-h-60 w-full">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 mt-10">
                <thead class="absolute w-full -top-2">
                  <tr class="flex justify-between bg-white pl-24 md:pl-34">
                    <th scope="col" class="absolute left-0 w-24 md:w-28 px-2 py-3 text-start text-xs font-medium 
                      text-gray-500 uppercase hidden">
                      Name
                    </th>
                    <th scope="col" class="px-0 py-3 text-center text-xs font-medium text-gray-500 uppercase flex-auto basis-4">
                      HI
                    </th>
                    <th scope="col" class="px-0 py-3 text-center text-xs font-medium text-gray-500 uppercase flex-auto basis-4">
                      RE
                    </th>
                    <th scope="col" class="px-0 py-3 text-center text-xs font-medium text-gray-500 uppercase flex-auto basis-4">
                      SP
                    </th>
                    <th scope="col" class="px-0 py-3 text-center text-xs font-medium text-gray-500 uppercase flex-auto basis-4">
                      LF
                    </th>
                    <th scope="col" class="px-0 py-3 text-center text-xs font-medium text-gray-500 uppercase flex-auto basis-4">
                      DN
                    </th>
                    <th scope="col" class="px-0 py-3 text-center text-xs font-medium text-gray-500 uppercase flex-auto basis-4">
                      AB
                    </th>
                  </tr>
                </thead>
                <tbody v-if="filteredStudentList" class="divide-y divide-gray-200 dark:divide-gray-700">
                  <EngageFormRowVue v-for="student in filteredStudentList" :studentName="student.student_name" 
                    :studentID="student.name" :observation="props.observation" />
                  <EngageFormRowVue v-for="student in props.additionalStudents" 
                    :studentName="findValueByName(studentList.data, student, 'student_name')"
                    :studentID="student" :observation="props.observation" />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from "@/components/ui/utils.js";
import { computed } from "vue";
import { createListResource } from 'frappe-ui'
import EngageFormRowVue from "./EngageFormRow.vue";
import { findValueByName } from "@/lib/utils"

const props = defineProps([
  "pageNum", "componentPageNum", "cohorts", 
  'observation', "additionalStudents"
]);

const studentList = createListResource({
  doctype: 'Student',
  fields: ['name', 'student_name', 'cohort'],
  orderBy: 'student_name asc',
  filters: {
    is_enrolled: 1,
  },
  start: 0,
  pageLength: 30,
  auto: true
})

const filteredStudentList = computed(() => {
  if (props.cohorts && studentList.data) {
    return studentList.data.filter((student) => {
      return props.cohorts.includes(student.cohort) || props.observation?.engagement.some((engage) => engage.child === student.name)
    })
  }
  return [];
});
</script>
