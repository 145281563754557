<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== 1 && `hidden`)" >
    <div v-if="props.mainStudent" class="flex gap-2">
      <h4 class="font-medium text-sm md:text-base">
        Primary Subject:
      </h4>
      <p class="font-semibold text-sm md:text-base">
        {{ findValueByName(props.studentsList, props.mainStudent, 'student_name') }}
      </p>
    </div>
    <p class="font-medium text-sm md:text-base">
      Additional Students:
    </p>
    <div class="border rounded-md" @keydown.stop>
      <SelectSearch placeholder="Select Students..." v-if="renderSelect"
        :dataList="filteredStudentList" inputName="other_students" dispName="student_name"
         @valueModified="handleNewChildSelected" :defaultList="selectedStudents"
        selectId="addStudentSelectDaily" />
    </div>
    <div class="flex gap-2 text-base font-normal pl-4 flex-wrap text-xs md:text-sm">
      <div v-for="student in selectedStudents" class="bg-[#FDD835] pl-2 rounded flex 
        items-center justify-center gap-2">
        <span>{{ findValueByName(props.studentsList, student, 'student_name') }}</span>
        <div class="p-1 px-2 cursor-pointer hover:bg-[#efc51f]"
          @click="() => { removeStudent(student)}">
          X
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-4">
      <button type="button" class="py-2 md:py-3 px-4 inline-flex items-center gap-x-2 text-xs md:text-sm font-semibold 
        rounded-md border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 
        disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400 
        dark:hover:text-blue-500 dark:hover:border-blue-600" @click="cancelAdding">
        Cancel
      </button>
      <button type="button" class="py-2 md:py-3 px-4 inline-flex items-center gap-x-2 text-xs md:text-sm font-semibold 
        rounded-md border border-transparent bg-[#eac325] text-white hover:bg-yellow-600 
        disabled:opacity-50 disabled:pointer-events-none" @click="confirmSelection">
        Confirm
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { findValueByName } from '@/lib/utils.js'
import SelectSearch from '@/components/ui/SelectSearch.vue'

const props = defineProps([
  'pageNum', 'studentsList', 'mainStudent',
  'exitFn'
])
const emit = defineEmits(['selectionConfirmed'])

const filteredStudentList = computed(() => {
  if (!props.studentsList) return []
  if (!props.mainStudent) return props.studentsList
  return props.studentsList.filter((student) => {
    return student.name !== props.mainStudent
  })
})

const selectedStudents = ref([])
const renderSelect = ref(true) // workaround to refresh select options; change later

const handleNewChildSelected = (valArray) => {
  selectedStudents.value = valArray
}

const confirmSelection = () => {
  emit('selectionConfirmed', selectedStudents.value)
  props.exitFn()
}

watch(() => props.mainStudent, (newValue) => {
  selectedStudents.value = selectedStudents.value.filter((student) => student !== newValue)
  emit('selectionConfirmed', selectedStudents.value)
  renderSelect.value = false
  setTimeout(() => {
    renderSelect.value = true
  }, 100)
})

const cancelAdding = () => {
  selectedStudents.value = []
  props.exitFn()
}

// need modification manually rerendering to refresh doesn't seem right
const removeStudent = (studentID) => {
  selectedStudents.value = selectedStudents.value.filter((student) => student !== studentID)
  renderSelect.value = false
  setTimeout(() => {
    renderSelect.value = true
  }, 100)
}
</script>