<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 
        dark:border-gray-700 dark:shadow-slate-700/[.7] h-full">
        <div class="space-y-2">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-semibold text-[#6B7280] text-lg md:text-xl">
              Child Observation
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none
              dark:focus:ring-1 dark:focus:ring-gray-600" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
          <DateDisplay :date="selectedDate" />
        </div>
        <form @submit.prevent="handleSubmit" name="daily-observ-form" class="flex-auto flex flex-col font-normal"
          ref="formRef">
          <div :class="cn(`max-h-[60vh]`, (pageNum !== 3 && pageNum !== 2) &&  'overflow-y-auto')">
            <ParentFormPage1 :pageNum="pageNum" @date:selected="updateDate" />
            <ParentFormObservation :pageNum="pageNum" :componentPageNum="2" />
            <ParentFormComp :pageNum="pageNum" :componentPageNum="3" :competencyList="filteredCompetencyList" />
          </div>
          <div :class="cn(`text-sm font-semibold text-red-500 p-3 text-center`, 
            hideInputWarning && 'hidden')">
            Please answer all the required questions!
          </div>
          <!-- <div :class="cn(`text-sm font-semibold text-gray-600 p-3 text-center`, 
            !(isGettingCompMapping || isFetchingObservComps) && 'hidden')">
            Getting Competency suggestions...
          </div> -->
          <div class="flex flex-col lg:flex-row gap-4 lg:items-center lg:justify-between">
            <div class="flex gap-2 items-center">
              <div class="px-8 md:px-4 flex gap-1 items-center">
                <div v-for="n in totalPageNum" :class="cn(`h-2 w-3 bg-[#F3F4F6]`, 
                  n <= pageNum && 'bg-[#FFD700]')">
                  <!-- <div :class="cn(`h-full w-full`, n <= pageNum && 'bg-[#FFD700]')"></div> -->
                </div>
              </div>
              <div class="text-sm font-semibold text-gray-600">
                {{ pageNum }}/{{ totalPageNum }}
              </div>
            </div>
            <div class="flex justify-center sm:justify-end items-center gap-x-4 py-3 px-4">
              <button type="button" class="py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700
                dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1
                dark:focus:ring-gray-600" @click="goToPrevPage" :disabled="pageNum === 1">
                Back
              </button>
              <button type="button" :class="cn(`py-2.5  px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                dark:focus:ring-gray-600`, pageNum === totalPageNum && 'hidden')" @click="goToNextPage" 
                :disabled="pageNum === totalPageNum">
                Next
              </button>
              <button type="submit" :class="cn(`py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                dark:focus:ring-gray-600`, pageNum !== totalPageNum && 'hidden')" 
                :disabled="(pageNum !== totalPageNum) || isSubmitting || (fetchedComps.length && !fetchedCompDecision)">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { cn } from '@/components/ui/utils.js'
import ParentFormPage1 from './ParentFormPage1.vue'
import ParentFormObservation from './ParentFormObservation.vue'
import ParentFormComp from './ParentFormComp.vue'
import { createResource, createListResource } from 'frappe-ui'
import { HSOverlay } from 'preline/preline';
import { onMounted } from 'vue';
import DateDisplay from '../DateDisplay.vue';

const props = defineProps([
  'modalName', 'userData', 'reloadFn',
  'reloadObservations'
])

const pageNum = ref(1)
const totalPageNum = ref(3)
const selectedDate = ref(null)

const formRef = ref(null)
const hideInputWarning = ref(true)

const setHideWarningTime = (time) => {
  setTimeout(() => {
    if (hideInputWarning.value === false)
      hideInputWarning.value = true
  }, time)
}

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const competencyList = createListResource({
  doctype: 'Competency',
  fields: ['name','competency_name', 'description'],
  orderBy: 'competency_name asc',
  start: 0,
  pageLength: 90,
  auto: true
})

const filteredCompetencyList = computed(() => {
  if (!competencyList.data) return []
  return competencyList.data.filter((comp) => {
    const condition = !compItems.some(str => str.split(', ').includes(comp.competency_name))
    return !compItems.some(str => str.split(', ').includes(comp.competency_name))
  })
})

const observationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.parent_observations.api.create_new_observation'
})

const goToPrevPage = () => {
  if (pageNum.value === 1) return
  pageNum.value = pageNum.value - 1
}

const observCompsResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.api.get_observation_competency_maps'
})
const isFetchingObservComps = ref(false)
const prevAnecdote = ref('')
const fetchedComps = ref([])
const fetchedCompDecision = ref(false)

const goToNextPage = async () => {
  if (pageNum.value === totalPageNum.value) return
  const formData = new FormData(formRef.value)
  if (pageNum.value === 1) { 
    // const parentId = formData.get('parent_id')
    const date = formData.get('date')
    const student = formData.get('children')
    if (!(date && student)) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  } else if (pageNum.value === 2) { 
      const observText = formData.get('observation')
      if (!observText) {
        hideInputWarning.value = false
        setHideWarningTime(1500)
        return
      }

      // if (anecdotes !== prevAnecdote.value) {
      //   prevAnecdote.value = anecdotes
      //   isFetchingObservComps.value = true
      //   fetchedCompDecision.value = false
      //   await observCompsResource.submit({
      //     observation_text: anecdotes,
      //   })
      //   if (observCompsResource.data?.status === 'success' && Array.isArray(observCompsResource.data?.competencies)) {
      //     console.log('Fetched comp array exists')
      //     fetchedComps.value = observCompsResource.data.competencies
      //   }
      //   // totalPageNum.value = 4
      //   isFetchingObservComps.value = false
      //   isSubmitting.value = false
      // }
  }
  hideInputWarning.value = true
  pageNum.value = pageNum.value + 1
}

const updateDate = (date) => {
  selectedDate.value = date
}

const compItems = [
  "Emotional Intelligence",
  "Reading & Writing",
  "Math & Numeracy",
  "Science Exploration",
  "Physical Wellness"
]

const isSubmitting = ref(false)

const isGettingCompMapping = ref(false)
const compMapResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.api.get_matching_competencies'
})
const customText = ref('')

const customCompetencies = ref([])
const handleCompetencyCustomUpdate = (compList) => {
  customCompetencies.value = compList
}

const handleSubmit = async (e) => {
  isSubmitting.value = true
  const formData = new FormData(e.target)

  const formDataObj = {};
  formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });

  if (!Array.isArray(formDataObj.children))
    formDataObj.children = formDataObj.children ? [formDataObj.children] : []
  if (!Array.isArray(formDataObj.comp_pair))
    formDataObj.comp_pair = formDataObj.comp_pair ? [formDataObj.comp_pair] : []

  if (!formDataObj.competencies) {
    formDataObj.competencies = []
  } else if (!Array.isArray(formDataObj.competencies)) {
    console.log('anec', formDataObj.competencies)
    formDataObj.competencies = formDataObj.competencies ? [formDataObj.competencies] : []
    console.log('anec', formDataObj.competencies)
  }
  formDataObj.competencies = formDataObj.competencies.map((comp) => {
    return {
      comp: comp,
      is_id: true,
    }
  })
  formDataObj.comp_pair.forEach((competency) => {
    if (competency === 'Other') return
    formDataObj.competencies.push(
      { comp: competency, is_id:false }, 
    )
  })

  console.log(formDataObj)
  // isSubmitting.value = false
  // return;
  
  observationResource.update({
    params: {
      observation_data: JSON.stringify(formDataObj),
    }
  })
  HSOverlay.close(`#hs-${props.modalName}`)
  await observationResource.submit()

  if (observationResource.data.status === 'success') {
    //HSOverlay.close(`#hs-${props.modalName}`)
    pageNum.value = 1
    isSubmitting.value = false
    // formRef.value.reset()
    // resetFormInputs()
    HSOverlay.open('#hs-form-confirm-modal-1')
    await new Promise(resolve => setTimeout(resolve, 100));
    if (props.reloadObservations) props.reloadObservations()
    if (props.reloadFn) props.reloadFn()
  } else if(observationResource.data.status === 'fail') {
    alert("Form Submission Failed. Make sure you have filled everything correctly.")
    isSubmitting.value = false
  }
}
</script>