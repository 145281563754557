<template>
  <tr class="h-12 hover:bg-gray-100 dark:hover:bg-gray-700">
    <td class="w-24 md:w-28 px-2 md:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 
      dark:text-gray-200 border-t">
      {{ props.studentName }}
    </td>
    <EngageTableCell v-for="value in answers" :studentID="props.studentID" :inputValue="value"
      :defaultAnswer="defaultAnswer" />
  </tr>
</template>

<script setup>
import { computed } from 'vue';
import EngageTableCell from './EngageTableCell.vue';

const props = defineProps(['studentName', 'studentID', 'observation'])

const answers = [
  "Highly Engaged",
  "Engaged but occasionally distracted",
  "Sporadically interested",
  "Participated initially, left quickly",
  "Did not participate at all",
  "Absent",
]

const getStudentEngagement = (studentId, engagementList) => {
  const studentEngagement = engagementList.find(engagement => engagement.child === studentId)

  return studentEngagement ?  studentEngagement.engagement_level : ''
}

const defaultAnswer = computed(() => {
  if (!props.observation) return ''
  return getStudentEngagement(props.studentID, props.observation?.engagement || [])
})

console.log({student: props.studentName, defaultAnswer})
</script>