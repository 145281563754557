<template>
  <div v-if="props.comments?.length" class="flex flex-col gap-2 p-3 mx-3 rounded-md bg-[#FEF3C2]">
    <div class="font-semibold">Comments:</div>
    <Comment v-for="comment in props.comments" :comment="comment" :key="comment.name" />
  </div>
</template>

<script setup>
import Comment from './Comment.vue';

const props = defineProps(['comments'])
</script>