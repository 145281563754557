<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
    props.pageNum !== props.componentPageNum && 'hidden')" >
    <div class="text-xs text-gray-800 font-semibold">
      Not satisfied with the output? You can edit the details by clicking on each field or tap on 
      re-generate to let AI take another shot.
    </div>
    <div class="space-y-4">
      <label>
        <span class="text-xs font-semibold">Title:</span>
        <input type="text" class="py-2 px-2 block w-full border-gray-200 rounded-lg text-xs 
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" 
          placeholder="Title" name="activity_name" v-model="title">
      </label>
    </div>
    <div class="space-y-4">
      <label>
        <span class="text-xs font-semibold">Domain:</span>
        <!-- <input type="text" class="py-2 px-2 block w-full border-gray-200 rounded-lg text-xs 
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" 
          placeholder="Domain" name="domain" v-model="domain"> -->
        <SelectSearchSingle v-if="domainResource.data && renderDomain" placeholder="Select Domain..." :dataList="domainResource.data" 
          inputName="domain" dispName="domain_name" :defaultValue="defaultDomain" :hasSearch="false" />
      </label>
    </div>
    <!-- <div class="space-y-4">
      <label>
        <span class="text-xs font-semibold">Subdomain:</span>
        <input type="text" class="py-2 px-2 block w-full border-gray-200 rounded-lg text-xs 
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" 
          placeholder="Subdomain" name="subdomain">
      </label>
    </div> -->
    <!-- <div class="space-y-4">
      <label>
        <span class="text-xs font-semibold">Competencies:</span>
        <input type="text" class="py-2 px-2 block w-full border-gray-200 rounded-lg text-xs 
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
          dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" 
          placeholder="Competencies" name="competencies" v-model="competencies">
      </label>
    </div> -->
    <div class="space-y-4">
      <label>
        <span class="text-xs font-semibold">Competencies:</span>
        <MultiSelectNames selectId="activityCreationCompSelect" inputName="competencies" 
          :dataList="competencyList" displayField="competency_name" placeholder="Select Competencies..."
          :defaultList="defaultCompetencies" />
      </label>
    </div>
    <div class="space-y-4">
      <label class="">
        <span class="text-xs font-semibold">Activity Summary</span>
        <textarea id="hs-autoheight-textarea-daily-interests" class="py-2 px-2 block w-full border-gray-200 
          rounded-lg text-xs
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
          rows="10" placeholder="Paste Activity Here..." name="summary"  
          ref="summaryRef" @input="textareaAutoHeight" v-model="summaryText"></textarea>
      </label>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { computed, inject, ref, watch } from 'vue';
import SelectSearchSingle from '../../ui/SelectSearchSingle.vue';
import MultiSelectNames from '../../ui/input/MultiSelectNames.vue';
import { findNameByValue } from '@/lib/utils';

const props = defineProps(['componentPageNum', 'pageNum', 'detailsData'])

const competencyList = inject('competencyList')
const domainResource = inject('domainResource')
console.log("DOMAIN RESOURCE", domainResource)
console.log('Modal competencies',competencyList)

const summaryRef = ref(null)
const summaryText = ref(props.detailsData?.structured_data?.summary || '')

const title = ref(props.detailsData?.structured_data?.title || '')
const domain = ref(props.detailsData?.structured_data?.domain || '')

const defaultDomain = computed(() => {
  if (!props.detailsData?.structured_data?.domain || !domainResource.data) return ''
  return findNameByValue(domainResource.data, props.detailsData.structured_data.domain, 'domain_name')
})

const renderDomain = ref(true)
watch(defaultDomain, (newDomain) => {
  renderDomain.value = false
  setTimeout(() => {
    renderDomain.value = true
  }, 100)
})
// const competencies = ref(props.detailsData?.structured_data?.competencies || '')

const defaultCompetencies = computed(() => {
  if (!props.detailsData?.structured_data?.competencies) return [];
//  console.log("Trying to edit", props.detailsData)
  return props.detailsData.structured_data.competencies.map((comp) => {
    return findNameByValue(competencyList.value, comp, 'competency_name')
  }).filter((comp) => comp !== '');
})

watch(() => props.detailsData, (newDetails) => {
  if (!newDetails) return
  if (newDetails.status === 'fail') return
  title.value = newDetails.structured_data.title
  domain.value = newDetails.structured_data.domain
  // competencies.value = newDetails.structured_data.competencies.join(', ')
  summaryText.value = newDetails.structured_data.summary
})

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  summaryRef.value.style.height = 'auto'
  let calculatedHeight = summaryRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  summaryRef.value.style.height = `${calculatedHeight}px`
}
</script>