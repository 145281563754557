<!--Schedule-x calendar is instantiated here, later to be used in widget-->
<template>
 <!-- Cohorts Filter -->
 <div class="h-[20%]">

 <label for="cohortsSelect">Filter</label>
    <!-- Select -->
    <div class="relative">
    
    <select id="cohortsSelect" ref="cohortsSelect" multiple data-hs-select='{
      "hasSearch": true,
      "placeholder": "Select options...",
      "toggleTag": "<button type=\"button\"></button>",
      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
      "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
      "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
    }' class="hidden">
      <option v-for="cohort in cohortsOptions" :key="cohort.name" :value="cohort.cohort_name">{{ cohort.cohort_name }}</option>
    </select>
      <div class="absolute top-1/2 end-3 -translate-y-1/2">
        <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
      </div>
    </div>
    <!-- End Select -->
       
    <button @click="switchEventsSource">Apply Filter</button>
 </div>  

      <!-- <FullCalendar ref="fullCalendar" :options="calendarOptions" /> -->
    <div id="calendar">
      <ScheduleXCalendar v-if="props.events && props.events.length > 0" :calendar-app="calendarApp" class="calendar-wrapper" />
    </div>
</template>

<script setup>

import { onMounted } from 'vue';
import { ref } from 'vue';
import { createDragAndDropPlugin } from '@schedule-x/drag-and-drop'
import { createResizePlugin } from '@schedule-x/resize'
import { createScrollControllerPlugin } from '@schedule-x/scroll-controller'

import { ScheduleXCalendar } from '@schedule-x/vue'
import {
  createCalendar,
  viewDay,
  viewWeek,
  viewMonthGrid,
  viewMonthAgenda,
} from '@schedule-x/calendar'
import '@schedule-x/theme-default/dist/index.css'
import { createEventModalPlugin } from '@schedule-x/event-modal'

const props = defineProps({
  events: Array,
  optionsResponse: {
      type: Object,
      required: true,
    },
});

const emit = defineEmits(['update-error', 'event-clicked', 'update-event']);


console.log('OptionsResponse prop in calendar:', props.optionsResponse);
onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
    console.log("preline initialised in curriculumCalendar.vue");

    // Element with an ID of 'cohortsSelect'
    const selectElement = window.HSSelect.getInstance('#cohortsSelect');
    if (selectElement) {
      selectElement.on('change', (value) => {
         console.log('Select value changed:', value);
        // Directly call the method here, or emit an event with the new value
        switchEventsSource();
      });
    } else {
      console.log('AdvancedSelect instance not found');
    }
  }, 100);
});


const cohortsOptions = ref([]); // Initialize as an empty array
const cohortsSelect = ref(null);

function assignOptionsData() {
      try {
        console.log("assign options data called in calendar");
          // Directly assign fetched data 
          cohortsOptions.value = props.optionsResponse.cohorts;  
      } 
      catch (error) {
        console.error('Error assigning data:', error);
      }
    };

assignOptionsData();

const arr = [
    {
      id: "1",
      title: 'Event 1',
      start: '2024-01-30',
      end: '2024-01-30', 
    },
    {
      id: "2",
      title: 'Event 2',
      start: '2024-01-30 01:15',
      end: '2024-01-30 02:15',
      description: 'This is description test Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Scelerisque purus semper eget duis at tellus at urna condimentum. Commodo quis imperdiet massa tincidunt nunc pulvinar.',
      location: 'This is location test',
      people: ['student-1', 'student-2'],
      educators: 'this is led by test'
    },
  ];
  
  function clearAllEvents() {
  const allEvents = calendarApp.events.getAll();
  allEvents.forEach(event => calendarApp.events.remove(event.id));
}  

function addEventsFromSource(source) {
  clearAllEvents(); // Clear existing events first

  source.forEach(event => {
    calendarApp.events.add(event);
  });
}
  
 function switchEventsSource() {
  const selectedValues = getSelectedValues();
  const newSource = generateDynamicEventSource(selectedValues);
  addEventsFromSource(newSource);
  console.log("switch events called");
}


  console.log("This is events and arr---------------------------------",props.events, arr)

function handleEventClick(info) {
          // Emitting the clicked event data to the parent component
         
          emit('event-clicked', {
            id: info.id,
            title: info.title,
            start: info.start, // Assuming start and end are Date objects
            end: info.end,
          });
      }

const scrollController = createScrollControllerPlugin({
  initialScroll: '08:00'
})      

const calendarApp = createCalendar({
  selectedDate: '2024-01-30',
  views: [viewDay, viewWeek, viewMonthGrid, viewMonthAgenda],
  defaultView: viewWeek.name,
  calendars: {
    defaultCalendarId: {
      colorName: 'defaultCalendarId',
      lightColors: {
        main: '#D3D3D3', // Light Grey as the main color
        container: '#FFFFFF', // Very Light Grey (almost white) for the container background
        onContainer: '#', // Darker Grey for text or elements on the container
      },
      darkColors: {
        main: '#D3D3D3', // Light Grey remains the main color for consistency
        onContainer: '#FFFFFF', // Very Light Grey for text to ensure readability on dark backgrounds
        container: '#', // Darker Grey for the container background in dark mode
      },
    },
    bigkids: {
    colorName: 'bigkids',
    lightColors: {
      main: '#898AFF', // Light purple
      container: '#FFFFFF', // Lavender (lighter purple for background)
      onContainer: '#', // Indigo (dark color for text)
    },
    darkColors: {
      main: '#898AFF', // Light purple
      onContainer: '#E6E6FA', // Lavender (lighter purple for text)
      container: '#', // Indigo (dark color for background)
    },
  },
  batatas: {
    colorName: 'batatas',
    lightColors: {
      main: '#FFA500', // Orange
      container: '#FFFFFF', // Light orange for background
      onContainer: '#', // Dark orange for text
    },
    darkColors: {
      main: '#FFA500', // Orange
      onContainer: '#FFECB3', // Light orange for text
      container: '#', // Dark orange for background
    },
  },
  bombils: {
    colorName: 'bombils',
    lightColors: {
      main: '#20B2AA', // Light Sea Green
      container: '#FFFFFF', // Pale Turquoise for background
      onContainer: '#', // Dark Cyan for text
    },
    darkColors: {
      main: '#20B2AA', // Light Sea Green
      onContainer: '#FFFFFF', // Pale Turquoise for text
      container: '#', // Dark Cyan for background
    },
  },
  bimblis: {
    colorName: 'bimblis',
    lightColors: {
      main: '#FF74E9', // Pink
      container: '#FFFFFF', // Light Pink for background
      onContainer: '#', // Medium Violet Red for text
    },
    darkColors: {
      main: '#FF74E9', // Pink
      onContainer: '#FFC0CB', // Light Pink for text
      container: '#', // Medium Violet Red for background
    },
  },
  juniorbatatas: {
    colorName: 'juniorbatatas',
    lightColors: {
      main: '#7EFBF4', // Light teal
      container: '#FFFFFF', // Light Cyan for background
      onContainer: '#', // Teal for text
    },
    darkColors: {
      main: '#7EFBF4', // Light teal
      onContainer: '#E0FFFF', // Light Cyan for text
      container: '#', // Teal for background
    },
  },
    groupevent: {
      colorName: 'groupevent',
      lightColors: {
        main: '#FF4500', // Orange Red as the main color
        container: '#FFFFFF', // A lighter, softer orange for the container background
        onContainer: '#', // A darker shade for text or elements on the container (Dark Orange Red)

      },
      darkColors: {
        main: '#FF4500', // Orange Red as the main color
        container: '#FFE5CC', // A lighter, softer orange for the container background
        onContainer: '#', // A darker shade for text or elements on the container (Dark Orange Red)

      },
    }, 
  },
  events: props.events,
  plugins: [createDragAndDropPlugin(),createResizePlugin(),scrollController],
  callbacks: {
    onEventClick(calendarEvent) {
      console.log('onEventClick', calendarEvent)
      handleEventClick(calendarEvent);
    },
    onEventUpdate(updatedEvent) { //for drag and drop 
      updateEventInBackend(updatedEvent)
      console.log('onEventUpdate', updatedEvent)
    },
  }
  
});

function generateDynamicEventSource(selectedCohorts) {

      // If no cohorts are selected, return the full events array
        if (selectedCohorts.length === 0) {
          return props.events;
        }
        console.log("This is props.events before error----",props.events)
          // Filter events for selected cohorts
          const dynamicEvents = props.events.filter(event => 
          event.cohorts.some(cohort => selectedCohorts.includes(cohort))
          );
        
          console.log("This is filtered data ",dynamicEvents)
          return dynamicEvents;      
    }

function getSelectedValues() {
  const selectElement = cohortsSelect.value;
  if (selectElement && selectElement.multiple) {
    // Handle multi-select
    const selectedOptions = selectElement.selectedOptions;
    return Array.from(selectedOptions).map(option => option.value);
  } else if (selectElement) {
    // Handle single-select
    return selectElement.value;
  }
  return []; // Return an empty array as a fallback
  };


function updateEventInBackend(updatedEvent) {
  // Prepare the arguments as before
  const args = {
    event_name: updatedEvent.id,
    start: updatedEvent.start, // Assuming start is a Date object
    end: updatedEvent.end, // Assuming end is a Date object
  };

  // Emit the args to the parent component
  emit('update-event', args);
}


</script>

<style>
#calendar {
  width: 100%;
  height: 80%;
  /* max-height: 90vh; */ 
}
.calendar-wrapper{
  height: 100%;
}
</style>
