<template>
  <div :class="cn(`relative border rounded-lg`, props.class)">
    <select :data-hs-select="optionsString" class="hidden" :name="props.inputName" ref="selectedRef" v-model="domainSelected">
      <option value="">Choose</option>
      <option v-for="item in props.dataList" :value="item.name">
        {{ item[props.dispName] }}
      </option>
    </select>

    <div class="absolute top-1/2 end-3 -translate-y-1/2">
      <svg class="flex-shrink-0 size-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { cn } from '@/components/ui/utils'

const props = defineProps([
  'placeholder', 'dataList', 'inputName', 
  'dispName', 'defaultValue', 'class', 'hasSearch'
])

const domainSelected = ref(props.defaultValue)

watch(() => props.defaultValue, (newValue) => {
  console.log('selected cohort changed')
  if (newValue) {
    console.log(domainSelected.value)
    console.log(props.defaultValue)
    domainSelected.value = newValue
  } else {
    console.log(domainSelected.value)
    domainSelected.value = ''
    console.log(props.defaultValue)
  }
})

onMounted(() => {
  // console.log("preline mounted and initiated in App.vue");
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const selectRef = ref(null)

const getSelectedItems = () => {
  console.log(selectRef.value.value)
	return selectRef.value.value
}
defineExpose({getSelectedItems})

const options = {
        "hasSearch": props.hasSearch,
        "placeholder": props.placeholder,
        "toggleTag": "<button type=\"button\"></button>",
        "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-xs focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
        "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
        "optionClasses": "py-2 px-4 w-full text-xs text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
      }

const optionsString = JSON.stringify(options)
</script>