<template>
  <Card :class="cn(`basis-1/3 gap-2 rounded`, props.observTab !== 'child' && 'hidden', 'lg:flex h-full')">
    <template #title>
      <div class="flex justify-between items-center lg:items-stretch lg:gap-2 xl:flex-row 
        xl:items-center lg:flex-auto xl:flex-initial">
        <span class="font-medium text-sm md:text-lg lg:flex lg:flex-col xl:inline">
          Child <span>Observations</span>
        </span>
        <ParentObservationModal v-if="childModalActive" modalName="parent-form-modal" :userData="props.userData"
          :reloadFn="reloadChildModal" :reloadObservations="props.reloadObservations">
          <template #trigger>
            <button type="button" class="py-2 md:py-3 px-8 2xl:px-16 inline-flex items-center gap-x-2 text-sm
              font-semibold rounded border border-transparent bg-gray-800 text-white
              hover:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none
              dark:focus:ring-1 dark:focus:ring-gray-600 dark:bg-white dark:text-gray-800
              justify-center shrink-0" data-hs-overlay="#hs-parent-form-modal"
              @click="currentFormNum = 2">
              Add New
            </button>
          </template>
        </ParentObservationModal>
      </div>
    </template>
    <div class="flex flex-col md:h-[90%] gap-2">
      <div class="flex flex-col gap-2 md:gap-4 flex-auto overflow-y-auto pr-2">
        <ParentObservViewCard v-if="props.observeData"
          v-for="observation in props.observeData?.parent_observ?.slice(0,5)"
          :observation="observation" :selectObservation="selectObservation" />
      </div>
      <div class="flex items-center justify-start gap-8 mt-2">
        <router-link :to="`/front-portal/observations/view/parent/all`" class="flex-auto flex">
          <button type="button" class="py-2 px-16 inline-flex items-center gap-x-2 text-sm font-medium 
            rounded border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
            disabled:opacity-50 disabled:pointer-events-none flex justify-center items-center">
            View All
          </button>
        </router-link>
      </div>
    </div>
    <!-- <ObservEditModal modalName="observation-edit-modal" :version="selectedObservationVersion"
      :observation="selectedObservation" :selectedSignal="selectedSignal" /> -->
    <ParentObservEditModal modalName="parent-observ-edit-modal" :version="selectedObservationVersion"
      :observation="selectedObservation" :selectedSignal="selectedSignal" />
    <FormConfirmModal :createAnotherFn="addAnotherFn" :modalNum="1" :currentFormNum="currentFormNum" formType="parent" />
  </Card>
</template>

<script setup>
  import { HSOverlay } from 'preline/preline';
  import Card from '@/components/ui/Card.vue';
  import ParentObservationModal from '@/components/observations/forms/Parent/ParentObservationModal.vue';
  import ParentObservViewCard from '@/components/observations/view/ParentObservViewCard.vue';
  import ParentObservEditModal from '@/components/observations/edit/Parent/ParentObservEditModal.vue';
  import FormConfirmModal from '@/components/observations/forms/FormConfirmModal.vue';
  import { computed, ref, watch, inject } from 'vue';
  import { cn } from '@/components/ui/utils'
//   import ObservEditModal from './edit/ObservEditModal.vue';
//   import DailyObservViewCard from './view/DailyObservViewCard.vue';

  const props = defineProps([
    'userData', 'observTab', 'observeData',
    'reloadObservations'
  ])

  // view all route - changed to all
  // const viewAllEducator = computed(() => {
  //   if (props.userData?.is_educator)
  //     return props.userData.educator_id
  //   return 'all'
  // })

  const userDetails = inject('userDetails')

  const selectedObservation = ref(null)
  const selectedObservationVersion = ref(2)

  const selectedSignal = ref(false)

  const selectObservation = (observationData, version = 1) => {
    selectedObservation.value = observationData
    selectedObservationVersion.value = version
    selectedSignal.value = !selectedSignal.value
    HSOverlay.open('#hs-parent-observ-edit-modal')
  }

  const childModalActive = ref(true)
  const reloadChildModal = () => {
    console.log('Reloading Child observation modal')
    childModalActive.value = false
    setTimeout(() => childModalActive.value = true, 100)
  }

  const currentFormNum = ref(1)

  const addAnotherFn = (formNum) => {
    HSOverlay.open(`#hs-parent-form-modal`)
  }
</script>