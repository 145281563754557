<template>
<!-- Textarea -->
  <div class="relative w-2/3 drop-shadow-md">
    <textarea id="hs-textarea-play" class="p-4 pb-12 block w-full bg-gray-100 border-gray-200 rounded-lg text-sm 
      focus:border-blue-500 focus:ring-blue-500" placeholder="Enter the prompt..." v-model="prompt" ref="playInput"
      @input="textareaAutoHeight">
    </textarea>

    <!-- Toolbar -->
    <div class="absolute bottom-px inset-x-px p-2 rounded-b-md bg-gray-100">
      <div class="flex justify-between items-center">
        <!-- Button Group -->
        <div class="flex items-center">
          <button type="button" class="inline-flex flex-shrink-0 justify-center items-center rounded-lg
            h-8 w-24 bg-white font-bold text-sm"
            @click="clearText">
            Clear
          </button>
        </div>
        <!-- End Button Group -->

        <!-- Button Group -->
        <div class="flex items-center gap-x-1">
          <!-- Send Button -->
          <button type="button" class="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-lg 
            text-white bg-[#FDD835] hover:bg-[#fbdf62] focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-500 
            h-8 w-8" @click="openModal">
            <svg class="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"></path>
            </svg>
          </button>
          <!-- End Send Button -->
        </div>
        <!-- End Button Group -->
      </div>
    </div>
    <!-- End Toolbar -->
  </div>
<!-- End Textarea -->
</template>

<script setup>
import { onMounted, ref } from 'vue'

const props = defineProps(['action'])
const emit = defineEmits(['prompt'])

const playInput = ref(null)
const prompt = ref("")

const openModal = () => {
  emit('prompt', prompt.value)
  props.action(prompt.value)
}

const clearText = () => {
  prompt.value = ""
  setTimeout(() => {
    textareaAutoHeight()
  }, 100)
}

const getPrompt = () => {
  return prompt.value
}

const changePrompt = (newPrompt) => {
  prompt.value = newPrompt
}

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 3
  playInput.value.style.height = 'auto'
  let calculatedHeight = playInput.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2)
    calculatedHeight = windowHeight/2
  playInput.value.style.height = `${calculatedHeight}px`
}

onMounted(() => {
  textareaAutoHeight()
})

defineExpose({clearText, getPrompt, changePrompt})
</script>