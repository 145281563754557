<template>
  <div class="flex flex-col gap-2 p-4 md:h-[84vh] overflow-auto">
    <div v-for="report in props.reportList"
      :class="cn(`border rounded p-2 cursor-pointer`, props.selectedReport?.name === report.name && 
        'border-[#fdd835] border-2')" 
        @click="() => selectReport(report)">
      <div class="flex justify-between">
        <div>Start date: {{ report.start_date }}</div>
        <div>End date: {{ report.end_date }}</div>
        <div>Model: {{ report.model }}</div>
      </div>
      <div class="flex gap-2 items-start">
        <h5 class="font-semibold text-gray-700">
          Date:
        </h5>
        <p class="text-sm text-gray-500">{{ report.creation }}</p>
      </div>
      <h5 class="font-semibold text-gray-700">
        Prompt:
      </h5>
      <p class="text-gray-600 font-semibold">
        {{ report.prompt_link ? report.prompt_link?.prompt_name : "Untitled Prompt" }}
      </p>
      <p :class="cn(`text-ellipsis font-light text-gray-600 line-clamp-3`)">
        {{ report.prompt }}
      </p>
      <h5 class="font-semibold text-gray-700">
        Report:
      </h5>
      <!-- <p :class="cn(`text-ellipsis font-light text-gray-600`, 
        selectedReport !== report.name &&  ' line-clamp-4')">
        {{ mdToHtml(report.report) }}
      </p> -->
      <div v-html="mdToHtml(report.report)" :class="cn(`text-ellipsis font-light text-gray-600 line-clamp-3`)">
      </div>
    </div>
    <div v-if="!props.reportList?.length">
      No Existing reports found for the student!
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { marked } from 'marked'

const props = defineProps([
  'reportList', 'selectedReport'
])

const emit = defineEmits(['selected:report'])

const selectReport = (report) => {
  emit('selected:report', report)
}

const mdToHtml = (mdText) => {
  return marked(mdText)
}
</script>