<template>
  <div :class="cn('p-8 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div :class="cn(`space-y-3`, fetchedCompDecisionMade && 'hidden', !fetchedCompsExists && 'hidden')">
      <p class="font-medium text-sm md:text-base">The following competencies have been detected from the observation:</p>
        <div class="flex gap-2 gap-y-5 font-normal text-base flex-wrap">
        <div v-for="comp in filteredFetchedComps"   
          class="bg-[#FDD835] pl-2 rounded items-center justify-center py-0 flex gap-2 relative text-xs md:text-sm">
          <span>{{ comp }}</span>
          <div class="p-1 px-2 cursor-pointer hover:bg-[#efc51f]"
            @click="() => { removeFetchedComp(comp)}">
            X
          </div>
        </div>
      </div>
      <div class="flex gap-2">
        <button type="button" class="py-2 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700
          dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1
          dark:focus:ring-gray-600 flex-auto md:flex-initial md:px-10 basis-1/2 justify-center" @click="dontAddFetchComps">
          Add manually
        </button>
        <button type="button" :class="cn(`py-2 inline-flex items-center gap-x-2 text-sm font-semibold
          rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
          disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
          dark:focus:ring-gray-600 flex-auto md:flex-initial md:px-10 basis-1/2 justify-center`)" @click="fetchedCompDecisionMade = true">
          Confirm
        </button>
      </div>
    </div>
    <div :class="cn(`space-y-3`, fetchedCompsExists && !fetchedCompDecisionMade && 'hidden')">
      <p class="font-medium text-sm md:text-base">
        2. Competency anecdote is linked to:
      </p>
      <div :class="cn(`flex flex-col gap-y-2 md:gap-y-4`, textMode && 'hidden')">
        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Critical Thinking" 
              v-model="checked" name="comp_pair">
              Critical Thinking
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Emotional Intelligence" 
              v-model="checked" name="comp_pair">
            Emotional Intelligence
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Fine Motor Skills" 
              v-model="checked" name="comp_pair">
              Fine Motor Skills
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Math & Numeracy" 
              v-model="checked" name="comp_pair">
            Math & Numeracy
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Music & Rhythm" 
              v-model="checked" name="comp_pair">
            Music & Rhythm
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Physical Wellness" 
              v-model="checked" name="comp_pair">
            Physical Wellness
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Reading & Writing" 
              v-model="checked" name="comp_pair">
            Reading & Writing
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Science Exploration" 
              v-model="checked" name="comp_pair">
            Science Exploration
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Storytelling" 
              v-model="checked" name="comp_pair">
              Storytelling
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Visual Arts" 
              v-model="checked" name="comp_pair">
            Visual Arts
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Other" 
              v-model="checked" name="comp_pair">
            Other
          </label>
        </div>

        <div :class="cn(`space-y-4`, !checked.includes('Other') && 'hidden')" @keydown.stop>
          <SelectSearch v-if="props.competencyList.length && renderSelect" placeholder="Select Competencies..." 
            :dataList="filteredCompetencyList" inputName="anecdote_competencies" dispName="competency_name"
            ref="selectedCompRef" :defaultList="selectInputDefaultComps" @valueModified="handleCompSelectDisplay"
            selectId="dailyAnecdoteCompSelect" />
        </div>
      </div>

      <div class="flex flex-col gap-1 md:gap-2 rounded p-2 bg-[#FEF3C2]">
        <div class="text-sm font-normal pl-4">
          Cannot find what you are looking for?
        </div>
        <div class="flex">
          <label class="text-sm text-gray-800 ms-4 font-semibold flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value=""
              id="subcomp-checkbox-group-8" v-model="textMode" name="text_mode">
              Enter custom text
          </label>
        </div>
      </div>
      <div :class="cn(`space-y-4`, !textMode && 'hidden')" @keydown.stop >
        <div class="flex gap-2 font-normal text-xs md:text-sm flex-wrap">
          <div v-for="comp in checked.filter((item) => item !== 'Other')"   
            class="bg-[#FDD835] px-2 rounded items-center justify-center py-1">
            {{ comp }}
          </div>
          <div v-for="comp in otherComps" 
            class="bg-[#FDD835] px-2 rounded items-center justify-center py-1">
            {{ findValueByName(props.competencyList, comp, 'competency_name') }}
          </div>
        </div>
        <textarea id="hs-autoheight-textarea-custom-comp-act" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
          rows="10" placeholder="Enter competencies..." name="custom_anecdote_competencies" v-model="compText" ref="compTextRef"
          @input="textareaAutoHeight"></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref, computed, watch } from 'vue'
import SelectSearch from '@/components/ui/SelectSearch.vue';
import { findValueByName, findNameByValue } from '@/lib/utils'

const props = defineProps([
  'pageNum', 'componentPageNum', 'competencyList',
  'defaultCheckPairs', 'defaultCompetencies', 'fetchedComps'
])

const emit = defineEmits(['fetchedCompDecision'])

const checked = ref(props.defaultCheckPairs || [])
const textMode = ref(false)
const fetchedCompDecisionMade = ref(false)
const fetchedCompsExists = computed(() => {
  if (props.fetchedComps?.length) return true
  return false
})

const renderSelect = ref(true)

const compText = ref('')
const compTextRef = ref(null)
const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  compTextRef.value.style.height = 'auto'
  let calculatedHeight = compTextRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  compTextRef.value.style.height = `${calculatedHeight}px`
}

const otherComps = ref([])
const handleCompSelectDisplay = (selectedComps) => {
  otherComps.value = selectedComps
}

const compItems = [
  "Emotional Intelligence",
  "Reading & Writing",
  "Math & Numeracy",
  "Science Exploration",
  "Physical Wellness",
  "Critical Thinking",
  "Music & Rhythm",
  "Critical Thinking",
  "Storytelling",
  "Visual Arts"
]

const filteredCompetencyList = computed(() => {
  if (!props.competencyList) return []
  // if (!Array.isArray(competencyList.value)) return []
  return props.competencyList.filter((comp) => {
    return !compItems.some(str => str === comp.competency_name)
  })
})

const filteredFetchedComps = ref(props.fetchedComps || [])
watch(() => props.fetchedComps, () => {
  if (Array.isArray(props.fetchedComps))
    filteredFetchedComps.value = props.fetchedComps
})

const removeFetchedComp = (comp) => {
  filteredFetchedComps.value = filteredFetchedComps.value.filter((fetchComp) => fetchComp !== comp)
}

const dontAddFetchComps = () => {
  filteredFetchedComps.value = []
  fetchedCompDecisionMade.value = true
}

const selectInputFetchComps = ref([])

watch(() => props.fetchedComps, () => {
  fetchedCompDecisionMade.value = false
})

watch(fetchedCompDecisionMade, (newDecision) => {
  emit('fetchedCompDecision', newDecision)
  if (!newDecision) return
  filteredFetchedComps.value.forEach(comp => {
    if (compItems.includes(comp) && !checked.value.includes(comp)) {
      checked.value.push(comp)
    }
    else {
      if (!checked.value.includes('Other')) 
        checked.value.push('Other')
      const compId = findNameByValue(props.competencyList, comp, 'competency_name')
      if (compId !== '') 
        selectInputFetchComps.value.push(compId)
    }
  })
  renderSelect.value = false
  setTimeout(() => {
    renderSelect.value = true
  }, 100)
})

const selectInputDefaultComps = computed(() => {
  if (!props.defaultCompetencies && !selectInputFetchComps.value)
    return []
  if (!props.defaultCompetencies)
    return selectInputFetchComps.value
  if (!selectInputFetchComps.value)
    return props.defaultCompetencies
  const mergedArray = [...new Set([...props.defaultCompetencies, ...selectInputFetchComps.value])]
  return mergedArray
})
</script>