<script setup>
import { cn } from '@/components/ui/utils'

const props = defineProps(['class', 'segNum'])
const id = `segment-item-${props.segNum}`
const dataHS = `#segment-${props.segNum}`
const ariaControl = `segment-${props.segNum}`
</script>

<template>
  <button 
    type="button" 
    :class="
      cn(
        `hs-tab-active:bg-white hs-tab-active:text-gray-700 
         py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm text-gray-500 
        hover:text-gray-700 font-medium rounded hover:hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none`,
        props.class,
        props.segNum === '1' && 'active',
      )
      "
    :id="id"
    :data-hs-tab="dataHS" 
    :aria-controls="ariaControl" 
    role="tab"
  >
    <slot />
  </button>
</template>
