<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Prompts
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <div class="border-b border-gray-200 dark:border-neutral-700 p-2 px-4">
          <nav class="flex space-x-1" aria-label="Tabs" role="tablist">
            <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent 
              hs-tab-active:text-blue-600 dark:hs-tab-active:bg-neutral-800 
              dark:hs-tab-active:border-b-gray-800 dark:hs-tab-active:text-white -mb-px py-3 px-4 
              inline-flex items-center gap-x-2 bg-gray-50 text-sm font-medium text-center border 
              text-gray-500 rounded-t-lg hover:text-gray-700 disabled:opacity-50 disabled:pointer-events-none 
              ark:bg-neutral-700 dark:border-neutral-700 dark:text-neutral-400 active" 
              id="card-type-tab-item-1" data-hs-tab="#card-type-tab-preview" 
              aria-controls="card-type-tab-preview" role="tab">
              New Prompt
            </button>
            <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent 
              hs-tab-active:text-blue-600 dark:hs-tab-active:bg-neutral-800 
              dark:hs-tab-active:border-b-gray-800 dark:hs-tab-active:text-white -mb-px py-3 px-4 inline-flex 
              items-center gap-x-2 bg-gray-50 text-sm font-medium text-center border text-gray-500 
              rounded-t-lg hover:text-gray-700 disabled:opacity-50 disabled:pointer-events-none 
              dark:bg-neutral-700 dark:border-neutral-700 dark:text-neutral-400" id="card-type-tab-item-2" 
              data-hs-tab="#card-type-tab-2" aria-controls="card-type-tab-2" role="tab">
              Load Prompt
            </button>
          </nav>
        </div>

        <div class="mt-3">
          <div id="card-type-tab-preview" role="tabpanel" aria-labelledby="card-type-tab-item-1">
            <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col gap-4 p-4" ref="formRef">
              <div class="flex flex-col gap-4">
                <div>
                  <label class="font-semibold">Name: 
                    <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
                      focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      name="name" v-model="promptName" placeholder="Untitled Prompt">
                  </label>
                </div>
                <div class="space-y-4">
                  <label class="font-semibold">Prompt: </label>
                  <textarea id="hs-autoheight-textarea-1" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
                    focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" rows="10"
                    placeholder="Enter prompt here..." name="prompt" v-model="prompt" ref="promptInputRef"
                    @input="textareaAutoHeight"></textarea>
                </div>
              </div>
              <div>
                <span v-if="isSubmitting" class="font-semibold text-gray-600">
                  Saving Prompt...
                </span>
                <span v-if="!isSubmitting && isSaved" class="font-semibold text-gray-600">
                  Prompt Saved Successfully!
                </span>
              </div>
              <div class="flex gap-4 justify-end">
                <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                  rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                  dark:focus:ring-gray-600`)" :disabled="isSubmitting">
                  Save
                </button>
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                  rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                  dark:focus:ring-gray-600`)" :disabled="isSubmitting" @click="() => saveAndChangePrompt()">
                  Save & Use Prompt
                </button>
              </div>
            </form>
          </div>
          <div id="card-type-tab-2" class="hidden" role="tabpanel" aria-labelledby="card-type-tab-item-2">
            <div v-if="loadPromptResource.data?.status === 'success'" 
              class="flex flex-col gap-2 min-h-96 lg:max-h-[50vh] p-4 overflow-auto">
              <div v-for="prompt in loadPromptResource.data.prompts"
                class="border rounded-md p-2">
                <div class="flex gap-4">
                  <h5 class="font-semibold text-gray-700">
                    {{ prompt.prompt_name }}
                  </h5>
                  <p v-if="prompt.is_default" class="text-sm text-yellow-500 font-semibold">
                    default
                  </p>
                </div>
                <p class="text-ellipsis line-clamp-2 font-light text-gray-600">
                  {{ prompt.prompt }}
                </p>
                <div class="flex justify-end gap-4">
                  <button  class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
                    border border-gray-200 bg-white text-yellow-500 shadow-sm hover:bg-gray-50
                    disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
                    dark:hover:bg-neutral-800"
                  @click="() => makePromptDefault(prompt.name)">
                    Make Default
                  </button>
                  <button  class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
                    border border-gray-200 bg-white text-yellow-500 shadow-sm hover:bg-gray-50
                    disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
                    dark:hover:bg-neutral-800"
                  @click="() => changePrompt(prompt)">
                    Use this prompt
                  </button>
                </div>
              </div>
              <div v-if="!loadPromptResource.data.prompts.length">
                No Existing Prompts found!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline';
import { createResource } from 'frappe-ui'

const props = defineProps([
  'modalName', 'prompt', 'usePrompt'
])

const emit = defineEmits(['change:defaultPrompt'])

const promptName = ref('')
const prompt = ref(props.prompt?.prompt || '')

watch(() => props.prompt, (newPrompt) => {
  if (newPrompt)
    prompt.value = newPrompt.prompt
})

const formRef = ref(null)
const promptInputRef = ref(null)

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const changePrompt = (newPrompt) => {
  props.usePrompt(newPrompt)
  HSOverlay.close(`#hs-${props.modalName}`)
}

const savePromptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompt.api.save_prompt',
})

const loadPromptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompt.api.get_all_prompts',
  auto: true
})

const defaultPromptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompt.api.make_default',
})

const isSubmitting = ref(false)
const isSaved = ref(false)

const newPromptID = ref('')

const handleSubmit = async () => {
  isSaved.value = false
  const formData = new FormData(formRef.value)
  const name = formData.get('name')
  const type = 'child'
  const promptText = formData.get('prompt')

  console.log({name,type, promptText})
  const promptData = {
    prompt_name: name,
    observation_type: type,
    prompt: promptText,
  }
  if (!promptText) {
    alert('Prompt cannot be empty!')
    return
  }
  isSubmitting.value = true
  try {
    await savePromptResource.submit({
      prompt_data: promptData,
    })
    if (savePromptResource.data?.status === 'success') {
      console.log(savePromptResource.data.message)
      isSaved.value = true
      newPromptID.value = savePromptResource.data.prompt_id || ''
      setTimeout(() => {
        isSaved.value = false
      }, 3000)
      loadPromptResource.reload()
      promptName.value = ''
      prompt.value = ''
      // HSOverlay.close(`#hs-${props.modalName}`)
    } else {
      console.error(savePromptResource.data.message)
    }
    console.log(savePromptResource.data.message)
  } catch (error) {
    console.error(error)
  }
  isSubmitting.value = false
}

const saveAndChangePrompt = async () => {
  const promptText = prompt.value;
  const promptTitle = promptName.value;
  try {
    await handleSubmit();
    changePrompt({
      name: newPromptID.value,
      prompt: promptText,
      prompt_name: promptTitle,
    });
  } catch (error) {
    console.error("Failed to make save request", error)
  }
}

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  promptInputRef.value.style.height = 'auto'
  let calculatedHeight = promptInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight / 2.5)
    calculatedHeight = windowHeight / 2.5
  promptInputRef.value.style.height = `${calculatedHeight}px`
}

const makePromptDefault = async (prompt_id) => {
  try {
    await defaultPromptResource.submit({
      prompt_id
    })

    if (defaultPromptResource.data.status === 'fail') {
      console.log('Could not make prompt default. Try again')
    } else {
      emit('change:defaultPrompt')
      console.log('Prompt made defalt successfully')
      if (props.prompt?.name === prompt_id) {
        props.usePrompt({
          prompt: props.prompt.prompt,
          prompt_name: props.prompt.prompt_name,
          name: prompt_id,
          is_default: true,
        })
      }
      loadPromptResource.reload()
    }
  } catch (error) {
    console.error("Failed to make default request")
    console.error(error)
  }
}
</script>