<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Child Observation <span v-if="mode===2" class="text-sm">(edited)</span>
              <span v-if="isArchived" class="text-sm">(archived)</span>
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col" ref="formRef">
          <div :class="cn(`max-h-[70vh] overflow-y-auto`, isCommenting && 'max-h-[30dvh]')">
            <ParentObservPreview v-if="mode === 0":observation="props.observation" />
            <!-- <DailyEditPage1 v-if="mode === 1" :observation="props.observation" :pageNum="pageNum" />
            <DailyFormPage2 v-if="mode === 1" :observation="props.observation" :pageNum="pageNum" />
            <DailyEditAnectdoteComp v-if="mode === 1" :observation="props.observation" :pageNum="pageNum"
              :componentPageNum="3" /> -->
            <!-- <ObservPreview v-if="mode === 2":observation="previewData" :isSavePreview="true" /> -->
            <!-- <CommentsModule v-if="mode === 0 && fetchCommentsResource.data?.status === 'success'"
              :comments="fetchCommentsResource.data.comments" /> -->
            <div :class="cn(`text-sm font-semibold text-red-500 p-3 text-center`, 
              hideInputWarning && 'hidden')">
              Please answer all the required questions!
            </div>
          </div>
          <!-- <ObservationCommentInput v-if="isCommenting" :isSavingComment="isSavingComment" 
            :commentResponse="commentSaveResponse" />
          <div class="p-3 text-center">
            <span v-if="isSavingComment" :class="cn(`text-sm font-semibold text-gray-600 text-center`)">
              Saving comment... Please wait...
            </span>
            <span v-if="commentSaveResponse" :class="cn(`text-sm font-semibold text-red-500 text-center`)">
              {{ commentSaveResponse }}
            </span>
            <span v-if="isCommmentSaveSuccess":class="cn(`text-sm font-semibold text-green-600 text-center`)">
              Comment saved!
            </span>
          </div> -->
          <div class="flex flex-col md:flex-row gap-4 md:items-center md:justify-end">
            <div class="flex justify-center md:justify-end items-center gap-4 py-3 px-4 flex-wrap">
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden')" 
                :data-hs-overlay="`#hs-${props.modalName}`" :disabled="mode !== 0 || isCommenting">
                Close
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden')"  
                :disabled="mode !== 0 || isCommenting || true" @click="mode = 1">
                Edit
              </button>
              <!-- <button type="button" :class="cn(`py-2 md:px-10 inline-flex items-center gap-x-2 text-sm font-semibold
                flex-auto justify-center md:basis-auto
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden')"  
                :disabled="mode !== 0 || props.version === 1 || isCommenting || true" @click="isCommenting = true">
                Comment
              </button> -->
              <div class="flex gap-2">
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                  rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                  disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', !isCommenting && 'hidden')"
                  :disabled="mode !== 0 || !isCommenting" @click="isCommenting = false">
                  Discard
                </button>
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                  rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                  disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', !isCommenting && 'hidden')"
                  :disabled="mode !== 0 || props.version === 1 || !isCommenting || isSavingComment" @click="saveComment">
                  Save
                </button>
              </div>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none`, mode === 0 && 'hidden', isCommenting && 'hidden')" 
                @click="goToPrevPage" :disabled="(mode !== 1 && mode !== 2) || pageNum === 1">
                Back
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 1 && 'hidden', pageNum >= totalPageNum && 'hidden')" 
                @click="goToNextPage" :disabled="mode !== 1 ||pageNum >= totalPageNum">
                Next
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 1 && 'hidden', pageNum !== totalPageNum && 'hidden')" 
                @click="showPreview" :disabled="mode !== 1 || pageNum != totalPageNum">
                Preview
              </button>
              <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 2 && 'hidden')" 
                :disabled="mode !== 2">
                Save
              </button>
              <button type="button" :class="cn(`py-2 md:px-10 inline-flex items-center gap-x-2 text-sm font-semibold
                justify-center flex-auto md:basis-auto
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', 
                !userDetails?.is_system_manager && 'hidden', isArchived && 'hidden', isCommenting && 'hidden')" 
                @click="handleArchive" :disabled="mode !== 0 || !userDetails?.is_system_manager || isArchived
                || isCommenting">
                Archive
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', 
                !userDetails?.is_system_manager && 'hidden', !isArchived && 'hidden', isCommenting && 'hidden')" 
                @click="handleUnarchive" :disabled="mode !== 0 || !userDetails?.is_system_manager || !isArchived ||
                isCommenting">
                Unarchive
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden', 
                !userDetails?.is_system_manager && 'hidden', (!userDetails?.first_name === 'Sandeep') && 'hidden')" 
                @click="removeCompetencies" :disabled="mode !== 0 || !userDetails?.is_system_manager
                || (!userDetails?.first_name === 'Sandeep')">
                Remove Competencies
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, inject } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline';
import { createResource, createListResource } from 'frappe-ui';
import ParentObservPreview from './ParentObservPreview.vue';
// import ObservationCommentInput from './ObservationCommentInput.vue';
// import CommentsModule from './comments/CommentsModule.vue';

const props = defineProps([
  'modalName', 'observation', 'selectedSignal',
  'version'
])

const userDetails = inject('userDetails')

const mode = ref(0)  // 0 - view, 1 - edit, 2 - preview
const pageNum = ref(1)
const formRef = ref(null)
const previewData = ref(null)
const totalPageNum = 3

const isCommenting = ref(false)

watch(() => props.selectedSignal, () => {
  mode.value = 0
})

const hideInputWarning = ref(true)
const setHideWarningTime = (time) => {
  setTimeout(() => {
    if (hideInputWarning.value === false)
      hideInputWarning.value = true
  }, time)
}

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const goToNextPage = () => {
  if (pageNum.value < 1) return
  const formData = new FormData(formRef.value)
  if (pageNum.value === 1) {
    const educator = formData.get('educator_id')
    const student = formData.get('learner_name')
    const date = formData.get('date')
    if (!(educator && date && student)) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  } else if (pageNum.value === 2) {
    const anecdotes = formData.get('anecdotes')
    if (!anecdotes) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return

    }
  }
  
  pageNum.value++
}

const goToPrevPage = () => {
  if (mode.value === 2) mode.value = 1
  if (pageNum.value <= 1) return
  pageNum.value--
}

const showPreview = () => {
  const formData = new FormData(formRef.value)
  const formDataObj = {}
  
  formData.forEach((value, key) => {
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  
  if (!formDataObj.anecdote_competencies) {
    formDataObj.anecdote_competencies = []
  } else if (!Array.isArray(formDataObj.anecdote_competencies)) {
    formDataObj.anecdote_competencies = [formDataObj.anecdote_competencies]
  }
  formDataObj.anecdote_competencies = formDataObj.anecdote_competencies.map((comp) => {
    return {
      comp: comp,
      is_id: true,
    }
  })
  
  if (!formDataObj.comp_pair) {
    hideInputWarning.value = false
    setHideWarningTime(1500)
    return
  } else if (!Array.isArray(formDataObj.comp_pair)) {
    formDataObj.comp_pair = [formDataObj.comp_pair]
  }

  formDataObj.comp_pair.forEach((competency) => {
    if (competency === 'Other') return
    formDataObj.anecdote_competencies.push(
      { comp: competency, is_id:false }, 
    )
  })
//   if (!Array.isArray(formDataObj.competencies)) {
//     formDataObj.competencies = formDataObj.competencies.split(', ').map(comp => {
//       return comp
//     })
//   }
console.log(formDataObj)
  previewData.value = formDataObj
  mode.value = 2
}

const observationSaveResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.daily_observations.api.edit_observation'
})
const isSaving = ref(false)

const handleSubmit = async (e) => {
  const formDataObj = previewData.value
  formDataObj.id = props.observation.name

  console.log(formDataObj)
  observationSaveResource.update({
    params: {
      observation_data: formDataObj,
  }})
  try {
    isSaving.value = true
    await observationSaveResource.submit()
    isSaving.value = false
    if (observationSaveResource.data.status === 'success') {
      HSOverlay.close(`#hs-${props.modalName}`)
      pageNum.value = 1
      // HSOverlay.open('#hs-form-confirm-modal-2')
    } else if (observationSaveResource.data.status === 'fail') {
      alert("Form Submission Failed. Make sure you have filled everything correctly.")
    }
  } catch (error) {
    console.error(error)
  }
}

const archiveResource = createResource({
  'url': 'curriculum_planner.curriculum_planner.common.observations.api.archive_observation',
})

const isArchived = ref(props.observation?.is_archived === 1 ? true : false)

// const fetchCommentsResource = createResource({
//  url: '/api/method/curriculum_planner.curriculum_planner.doctype.daily_observations.api.fetch_comments'
// })

// watch(() => props.observation, () => {
//   isCommenting.value = false
//   if (props.observation) {
//     fetchCommentsResource.submit({
//       observation_id: props.observation.name
//     })
//   }
//   if (props.observation?.is_archived === 1)
//     isArchived.value = true
//   else if (props.observation?.is_archived === 0)
//     isArchived.value = false
// })

const handleArchive = async () => {
  archiveResource.update({
    params: {
      type: 'child',
      observation_id: props.observation.name,
      version: `v${props.version}`,
      archive_status: 1,
    }
  })
  
  try {
    await archiveResource.submit()
    if (archiveResource.data.status === 'success') {
      isArchived.value = true
    } else if (archiveResource.data.status === 'fail') {
      alert("Failed Archiving Observation.")
    }
  } catch (error) {
    console.error(error)
  }
}

const handleUnarchive = async () => {
  archiveResource.update({
    params: {
      type: 'child',
      observation_id: props.observation.name,
      version: `v${props.version}`,
      archive_status: 0,
    }
  })
  
  try {
    await archiveResource.submit()
    if (archiveResource.data.status === 'success') {
      isArchived.value = false
    } else if (archiveResource.data.status === 'fail') {
      alert("Failed Unarchiving Observation.")
    }
  } catch (error) {
    console.error(error)
  }
}

const removeCompResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.daily_observations.api.remove_old_competencies',
  params: {
    mode: 'single',
  }
})

const removeCompetencies = () => {
  try {
    removeCompResource.submit({
      id: props.observation.name
    })
    console.log(removeCompResource)
  } catch (error) {
    console.error("Failed to remove competencies")
    console.error(error)
  }
}

// const isSavingComment = ref(false)
// const commentSaveResponse = ref('')
// const isCommmentSaveSuccess = ref(false)

// const commentSaveResource = createResource({
//  url: '/api/method/curriculum_planner.curriculum_planner.doctype.daily_observations.api.save_comment'
// })

// const saveComment = async () => {
//   isSavingComment.value = true
//   const formData = new FormData(formRef.value)
//   const comment = formData.get('comment')

//   try {
//     await commentSaveResource.submit({
//       comment_data: {
//         'observation_id': props.observation.name,
//         'comment': comment,
//       },
//       version: 'v2'
//     })

//     if (commentSaveResource.data.status === 'success') {
//       fetchCommentsResource.reload()
//       isSavingComment.value = false
//       isCommenting.value = false
//       isCommmentSaveSuccess.value = true
//       setTimeout(() => {
//         isCommmentSaveSuccess.value = false
//       }, 1500)
//     } else if (commentSaveResource.data.status === 'fail') {
//       isSavingComment.value = false
//       commentSaveResponse.value = commentSaveResource.data.message
//       setTimeout(() => {
//         commentSaveResponse.value = ''
//       }, 1500)
//       console.error("Failed to save comment.")
//       console.error(commentSaveResource.data.message)
//     }
//   } catch (error) {
//     isSavingComment.value = false
//     console.error(error)
//   }
// }
</script>