<template>
  <div :class="cn('p-4 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-3" @keydown.stop>
      <div class="flex justify-between items-center">
        <label for="autoheightTextareaGroupIdea" class="block font-medium text-sm md:text-base mb-2">
          1. What is the big picture/core idea?
        </label>
      </div>
      <textarea id="autoheightTextareaGroupIdea" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        rows="10" placeholder="Enter text here..." name="core_idea" v-model="coreIdea" ref="coreIdeaInputRef"
        @input="textareaAutoHeight"></textarea>
    </div>
    <div>
      <AudioRecorder :userData="props.userData" observationType="activity" 
        @transcriptGenerated="appendCoreIdea" @transcriptStatus="handleTranscriptStatus" />
      <div v-if="transcriptStatusText"
        :class="cn(`text-sm font-semibold text-gray-600 p-3 text-center`)">
        {{ transcriptStatusText }}
      </div>
    </div>
    <div class="flex flex-col gap-1 md:gap-2 rounded p-2 bg-[#FEF3C2]">
      <!-- <div class="text-sm font-normal pl-4">
        Cannot find what you are looking for?
      </div> -->
      <div class="flex">
        <label class="text-sm text-gray-800 ms-4 font-semibold flex gap-4 items-start">
          <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600
            focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
            id="coreIdeaCheckBox" v-model="coreIdeaType" name="core_idea_type">
            Unsure?
        </label>
      </div>
      <div class="text-sm font-normal pl-4 text-gray-700">
        Check if you are unsure about the core idea of the activity
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref } from 'vue';
import AudioRecorder from '../AudioRecorder.vue'

const props = defineProps([
  'pageNum', 'componentPageNum', 'observation',
  'userData'
])

const coreIdeaType = ref((props.observation?.core_idea_type === 'Unsure' && true) || false)
const coreIdea = ref(props.observation?.core_idea || '')
const coreIdeaInputRef = ref(null)

const appendCoreIdea = (newText) => {
  if (coreIdea.value)
    coreIdea.value = summary.value + '\n\n' + newText
  else
    coreIdea.value = newText
}

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  coreIdeaInputRef.value.style.height = 'auto'
  let calculatedHeight = coreIdeaInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  coreIdeaInputRef.value.style.height = `${calculatedHeight}px`
}

const transcriptStatusText = ref('')

const handleTranscriptStatus = (statusObj) => {
  if (statusObj.status === 'start') {
    transcriptStatusText.value = "Generating transcript, please wait..."
  } else if (statusObj.status === 'fail') {
    transcriptStatusText.value = 'Transcript generation failed, please try again...'
    setTimeout(() => {
      transcriptStatusText.value = ''
    }, 3500)
  } else if (statusObj.status === 'success') {
    transcriptStatusText.value = ''
  }
}
</script>